 :local(.reactActivityStyle) {
  max-width: 347px;
  width: calc(100% - 670px);
  .ant-tabs-nav-container {
    padding: 0; }
  .ant-tabs-nav .ant-tabs-tab {
    // margin-right: 0
    font-size: 14px;
    font-family: Gilroy-Bold;
    padding-left: 0;
    padding-right: 0; }
  .ant-tabs-tab-arrow-show {
    display: none; }

  .recent-activity {
    min-width: 347px;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #DEE2EC;
    border-radius: 12px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05); }
  h3 {
    font-size: 18px;
    font-weight: bold; }
  .statistics-list {
    list-style: none;
    padding: 0;
    li {
      margin: 14px 0;
      display: flex;
      color: #323F4A;
      font-size: 13px;
      div {
        width: 50%;
        &:first-child {
          font-family: Gilroy-Bold; } }
      .status-circle:before {
        content: '';
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border-radius: 8px;
        display: inline-block;
        background: #F7C137; }
      .Opened:before {
        background: #7BAD2D; } } }

  .activity-list {
    list-style: none;
    padding: 0;
    .item {
      margin: 10px 0;
      display: flex;
      color: #323F4A;
      letter-spacing: 0.3px;
      font-size: 13px;
      .avatar {
        min-width: 32px;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 1px solid #e0e0e0;
        margin-right: 16px; }
      .avatar-placeholder {
        min-width: 32px;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        margin-right: 16px; }
      .bold {
        font-family: 'Gilroy-Bold';
        text-transform: capitalize; }
      .date {
        color: #97A0A7;
        font-size: 12px; } } }
  .show-all {
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    color: #264382;
    cursor: pointer; } }
