@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

$Red: #DF0726 !default;
$Green: #7BAD2D !default;
$Blue: #86C2D8 !default;
$Yellow: #FFB800 !default;
$Emerald: #40C47C !default;
$Orange: #FF6B00 !default;

@counter-style brackets-latin {
  prefix: '(';
  suffix: ')';
  system: extends lower-latin; }

@counter-style brackets-roman {
  prefix: '(';
  suffix: ')';
  system: extends lower-roman; }

// html
//   overflow: hidden
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #F1F3F6;
  height: -webkit-fill-available; }

#root, .App {
  min-height: 100%;
  width: 100%;
  // overflow: auto
  margin: 0 auto;
  font-family: Gilroy,Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style: normal;
  font-weight: 500;
  background: #F1F3F6; }
.App {
  max-width: 1440px;
  // max-width: 100%
  & > div {
    width: 100%;
    min-height: 100vh;
    &>div {
      width: 1440px;
      max-width: 100%; } } }
/* Attention!!! Don't change this attribute above, will effect window scroll(react-scroll) feature : height, overflow, min-height */

.mobile-show {
  display: none !important; }
.desktop-show {
  display: block !important; }
.footer {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  color: #5B616B;
  opacity: 0.4; }
button > img {
  margin-right: 10px; }

.x-hellosign-embedded.x-hellosign-embedded--in-modal {
  z-index: 99; }

.header-opr .ant-dropdown-menu-item {
  font-family: Gilroy;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.3px;
  font-weight: 500;
  color: #264382;
  .anticon {
    margin-right: 15px; } }

// cover antd common style
.ant-tooltip-inner {
  font-family: Gilroy;
  font-size: 14px;
  h3 {
    font-family: 'Gilroy-bold';
    color: #ffffff; } }
.ant-tabs-nav-list {
  .ant-tabs-tab {
    padding: 12px 16px;
    margin: 0 16px 0 0;
    .ant-tabs-tab-btn {
      color: rgba(0, 0, 0, 0.65); } }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #264382; } }
  .ant-tabs-ink-bar {
    background-color: #264382; } }

@media screen and (max-width: 750px) {
  #root,
  .App {
    max-width: 100%;
    min-width: auto;
    overflow: hidden;
 }    // min-height: 100%
  .App > div {
    min-height: 100vh;
    &>div {
      width: 100vw; } }
  .desktop-show {
    display: none !important; }
  .mobile-show {
    display: block !important; }
  .modal-report-file-detail.has-mobile-view {
    min-width: unset;
    width: 100% !important;
    video, img {
      width: 100%; } } }

.phs-footer {
  background: #ffffff;
  height: 62px;
  line-height: 62px;
  text-align: center;
  color: #98A9BC;
  font-size: 14px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid #EAEDF3; }
.phs-pointer {
  cursor: pointer; }
.ant-layout.ant-layout-has-sider {
  height: 100%;
  .ant-layout-sider {
    overflow: auto; }
  .ant-layout-content {
    overflow-y: auto; } }
.video-modal {
  .ant-modal-body {
    padding: 0;
    video {
      width: 100%;
      display: block; } }
  .ant-modal-footer {
    display: none; } }
.table-action-split {
  margin: 0 10px; }
span.as-link {
  color: #264382;
  cursor: pointer;
  padding: 3px;
  &.disabled {
    opacity: 0.25;
    cursor: not-allowed; } }

// modal style

.ant-modal-content {
  .ant-modal-header, .ant-modal-footer {
    border: none;
    padding: 20px 40px; }
  .ant-modal-header {
    padding-top: 40px;
    .ant-modal-title {
      font-size: 24px; } }
  .ant-modal-footer {
    padding-bottom: 40px; } }

.modal-report-file-detail {
  width: auto !important;
  min-width: 520px;
  text-align: center;
  display: inline-block;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  // transform-origin: none !important
  img, video {
    max-width: 1220px; } }
.modal-only-read {
  .ant-modal-footer {
    display: none; } }

.page-loading {
  text-align: center;
  font-size: 5em;
 }  // width: 100%
// section style
.phs-section {
  // display: inline-block
  background: #ffffff;
  border: 1px solid #EAEDF3;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 30px;
  .phs-section-header {
    display: flex;
    border-bottom: 1px solid #EAEDF3;
    padding: 25px 30px;
    height: 106px;
    .title {
      color: #B1B1B1;
      font-size: 12px;
      line-height: 29px;
      h2 {
        color: #3F4752;
        font-size: 18px;
        line-height: 29px;
        font-weight: bold; }
      p {
        text-transform: uppercase; } } }
  .phs-section-body {
    overflow: auto;
    padding: 25px 30px; } }
.avatar-small {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: rgba(38,67,130,0.1); }
.phs-button-outline {
  width: 120px;
  height: 47px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #264382;
  border: 2px solid #264382;
  border-radius: 36px; }

.page-loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.2);
  width: 100%;
  height: 100%;
  z-index: 2;
  .anticon-loading {
    color: #ffffff;
    position: absolute;
    top: 50%;
    margin-top: -35px; } }

// form style
.ant-btn-primary {
  background: #2B4B8F;
  border-color: transparent;
  &.ant-btn-background-ghost {
    color: #2B4B8F;
    border-color: #2B4B8F; } }
.ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: #45609b;
  background: #45609b; }

.ant-btn-none {
  border: none;
  box-shadow: none; }

.ant-legacy-form, .ant-legacy-form {
  [class^=ant-col] {}
  padding: 0 5px;
  .gray-bg {
    background: rgba(229,231,234,.3);
    color: rgba(76,96,114,.5);
    padding: 2px 20px;
    border-radius: 4px;
    height: 44px; } }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important; }

// disabled

.ant-legacy-form-item, .ant-select-disabled .ant-select-selection,.ant-select-disabled .ant-select-selector {
  background: transparent;
  .ant-input[disabled] {
    background: transparent; } }

.ant-input {
  font-weight: 500; }

.ant-picker-input > input {
  font-weight: 500; }

.ant-select-selection-item {
  font-weight: 500; }

.ant-input-number-input {
  font-weight: 500; }

.ant-input-number {
  box-sizing: content-box; }

.ant-legacy-form-item, .ant-form-item {
  position: relative;
  text-align: left;
  .ant-legacy-form-item-label {
    position: absolute;
    z-index: 1;
    background: #ffffff;
    top: 0;
    left: 10px;
    margin-top: -18px;
    height: 30px;
    label {
      // color: rgba(76,76,76, 0.5)
      color: #8D99A5;
      font-size: 12px;
      letter-spacing: 0.4px;
      text-transform: uppercase; } }
  .has-error .ant-input-number {
    box-sizing: content-box; }
  .has-error .ant-input-affix-wrapper .ant-input, .has-error .multi-tag-wapper {
    border-color: #FF0000;
    color: #FF0000; }
  .ant-legacy-form-item-control.has-error {
    margin-bottom: 20px; }

  .ant-legacy-form-item-control-wrapper {
    input {
      height: 45px; } }
  .ant-input-affix-wrapper {
    height: 45px;
    top: -8px;
    input {
      height: 39px; }
    .ant-input-suffix {
      margin-top: 5px; } }
  .ant-select-selection, .ant-picker {
    height: 45px; }
  .ant-radio-group {
    height: 40px;
    display: flex;
    align-items: flex-end; }
  .ant-input-group-addon {
    border: 0px;
    background-color: #fafafa;
    color: inherit; }
  .ant-select {
    .ant-select-selector {
      height: 45px;
      align-items: center; }
    .ant-select-selection--multiple, .ant-select-selector--multiple {
      height: auto;
      min-height: 45px;
      padding-top: 10px;
      .ant-select-selection__rendered, .ant-select-selector__rendered {
        line-height: 32px; } } }
  .ant-select.ant-select-multiple .ant-select-selector {
    height: auto;
    min-height: 45px;
    padding: 0px 4px;
    .ant-select-selection-overflow {
      padding-top: 10px;
      .ant-select-selection-search-input {
        height: 32px; } } }
  // .phs-form-file
  //   label
  //     display: block
  //     height: 45px
  //     padding: 10px 11px
  //     color: rgba(0, 0, 0, 0.65)
  //     font-size: 14px
  //     line-height: 1.5
  //     background-color: #fff
  //     background-image: none
  //     border: 1px solid #d9d9d9
  //     border-radius: 4px
  //     transition: all 0.3s
  //     cursor: pointer
  //   input
  //     display: none

  .ant-input-prefix + input {
    padding-left: 5px; }
  textarea {
    padding: 10px 11px 4px; }

  // select color component
  .select-color-container {
    .ant-select .ant-select-selector {
      &:before {
        content: '';
        display: inline-block;
        width: 50px;
        height: 25px;
        // margin-top: 11px
        background: #d2d2d2;
        float: left;
        margin-right: 10px; } }
    &[datacolor="red"] .ant-select .ant-select-selector:before {
      background: $Red; }
    &[datacolor="green"] .ant-select .ant-select-selector:before {
      background: $Green; }
    &[datacolor="blue"] .ant-select .ant-select-selector:before {
      background: $Blue; }
    &[datacolor="yellow"] .ant-select .ant-select-selector:before {
      background: $Yellow; }
    &[datacolor="orange"] .ant-select .ant-select-selector:before {
      background: $Orange; }
    &[datacolor="emerald"] .ant-select .ant-select-selector:before {
      background: $Emerald; } }

  // select style
  .ant-select {
    .ant-select-selection__rendered, .ant-select-selector {
      line-height: 45px; } } }

// checkbox style
.ant-checkbox-indeterminate .ant-checkbox-inner::after,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #05B800;
  border-color: #05B800; }

// seitch checked
.ant-switch {
  background-color: #C9D0E0; }
.ant-switch-checked {
  background: #05B800; }
.ant-btn {
  border-radius: 30px;
  font-weight: 500;
  &.phs-btn-primary {
    background-color: #264382;
    &.btn-text {
      color: #264382;
      background-color: transparent; } }
  &.phs-btn-success {
    background-color: #05B800;
    color: #ffffff; }
  &.ant-btn-success {
    color: #7FB800;
    border-color: #7FB800; }
  &.ant-btn-highlight {
    color: #1890ff;
    border-color: #1890ff; } }
.personal-value-edit {
  &.error .ant-input-affix-wrapper {
    border-bottom: 2px solid #f5222d; }
  .ant-input-affix-wrapper {
    width: 100%;
    border: none;
    border-bottom: 2px solid #264382;
    border-radius: 0;
    height: 34px;
    padding: 0;
    margin-right: 10px;
    margin-bottom: 15px;
    input {
      border: none; } }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #264382; }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 1px rgba(24, 14, 255, 0.2); }
  button {
    padding: 0 15px;
    &:last-child {
      margin-left: 15px; } } }
/* modal style */
.phs-modal {
  .title {
    font-weight: 300;
    line-height: 24px;
    font-size: 36px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #4C4C4C;
    margin-bottom: 40px; }
  .sub-title {
    font-weight: normal;
    line-height: 27px;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.366667px;
    text-transform: capitalize;
    color: #3F4651;
    margin-top: 40px; }
  .submit-button {
    border-radius: 89px;
    text-transform: uppercase; } }
.phs-info-modal {
  .ant-modal-content {
    border-radius: 12px; } }
.client-creation {
  width: 750px;
  .title {
    text-align: left;
    font-weight: normal;
    font-size: 32px;
    margin: 20px 0 40px 0; }
  .upload-avatar {
    margin-bottom: 35px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    .edit-avatar {
      position: absolute;
      margin-top: -100px;
      margin-left: 72px; } }
  .ant-modal-footer {
    border: none;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 10px;
    button {
      min-width: 120px; }
    .ant-btn-dangerous:not(.ant-btn-text) {
      background: #FBEEE8; } }
  .ant-collapse {
    background-color: transparent;
    border: unset;
    & > .ant-collapse-item {
      border-bottom: unset; }
    .ant-collapse-header {
      padding: 12px 0;
      border-top: 1px solid #E6E9F2;
      .ant-collapse-header-text {
        font-weight: 500;
        font-size: 24px;
        letter-spacing: 0.3px;
        color: #323F4A; }
      .ant-collapse-expand-icon > span {
        right: 0;
        font-size: 16px; } }
    .ant-collapse-content {
      border: unset; }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 16px 0px; } } }

.upload-footer {
  background: rgba(178,181,186, .1);
  margin: -10px -16px;
  padding: 10px 16px;
  button {
    margin-left: 10px; } }
.upload-progress {
  text-align: right;
  font-weight: 500;
  letter-spacing: 0.4px;
  font-size: 14px;
  text-transform: uppercase;
  .uploading {
    color: #264382; }
  .success {
    color: #05B800; } }
.title-center {
  color: #4C6072;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  margin-bottom: 32px; }

.phs-empty {
  margin-top: 200px;
  .empty-description {
    display: inline-block;
    font-size: 24px;
    color: #4C6072;
    line-height: 29px;
    margin: 15px 0; } }

// radio
.ant-radio-wrapper {
  &.blood-test-item {
    margin-bottom: 15px;
    border: 1px solid #d9d9d9;
    padding: 5px 10px;
    border-radius: 30px;
    &.ant-radio-wrapper-checked {
      border-color: #05B800; } }
  .ant-radio-checked .ant-radio-inner {
    border-color: #05B800;
    &:after {
      background-color: #05B800; } } }

// the menu of expert ipad view
.ipad-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 999;
  // -webkit-overflow-scrolling: touch
  touch-action: none;
  .menu-mask {
    content: ' ';
    // position: fixed
    // margin-top: 200px
    width: 100%;
    // min-height: 100%
    height: 100%;
    // height: calc(100% - 200px)
    background-color: rgba(0,0,0,.4);
    z-index: 79;
    .foo-menu {
      background-color: #ffffff;
      width: 46%;
      display: inline-block;
      height: 100%;
      overflow-y: scroll; } } }

// popup style for AR deck
.popover-no-arrow {
  min-width: 347px;
  .ant-popover-content {
    overflow: visible; }
  .ant-popover-arrow {
    display: none; }
  .ant-popover-inner {
    padding: 22px 24px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px; }
  .ant-popover-title {
    padding: 0;
    padding-bottom: 13px; }
  &.popover-for-HSD {
    min-width: 184px;
    .ant-popover-inner {
      padding: 16px;
      border: 1px solid #e3e3e3;
      box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      background: #ffffff; } } }

.popover-hide-arrow .ant-popover-arrow {
  display: none; }
