 :local(.markdownModalStyle) {
  font-family: Gilroy;
  letter-spacing: 0.3px;
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-header {
      padding-top: 40px;
      padding-left: 40px;
      border: none;
      border-radius: 8px 8px 0 0;
      .ant-modal-title {
        font-size: 30px;
        font-weight: 500; } }
    .ant-modal-body {
      padding: 40px;
      padding-top: 24px;
      &>p {
        text-align: right;
        margin-top: -15px;
        position: relative;
        z-index: 2;
        span {
          color: #264382;
          cursor: pointer; } }
      footer {
        text-align: right; }
      .tip-title {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.3px;
        color: rgba(0, 0, 0, 0.65);
        p {
          color: #82909C; } } } } }
