// @function padding($left, $right)
@mixin expert-container {
  border-radius: 12px;
  padding: 0  59px 32px 24px;
  width: 635px;
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border: 1px solid #DEE2EC; }

 :local(.summaryStyle) {
  padding-bottom: 150px;
  font-family: Gilroy;

  .ant-alert.ant-alert-warning {
    color: #383E48;
    .ant-alert-icon, .ant-alert-message {
      color: #383E48; } }

  &.User, &.Service_Provider {
    .main-container {
      width: 616px;
      padding-right: 32px;
      padding-left: 32px; }
    .bg-image {
      width: calc(100% + 32px + 32px);
      margin-left: -32px; } }

  .email-content {
    display: flex;
    justify-content: space-between; }
  img.delete-icon {
    width: 32px !important; }
  img.big-chart {
    width: 100% !important; }
  .switch-date-range {
    float: right;
    margin-top: -75px; }
  .extra-information {
    padding: 11px 16px;
    padding-bottom: 0;
    margin: 16px 0;
    border: 1px solid #E4EAEF;
    border-radius: 8px;
    font-size: 12px;
    line-height: 17px;
    h5 {
      font-size: 12px;
      font-family: Gilroy-Bold;
      color: #383E48; }
    p {
      color: #70808E; } }
  .profile-container {
    margin-top: 16px;
    padding-bottom: 16px;
    // display: flex
    // justify-content: space-between
    .profile-info {
      display: inline-block;
      vertical-align: top;
      width: 268px;
      &:first-child {
        margin-right: 14px; }
      &:first-child:last-child {
        width: 100%;
        margin: 0; } } }

  .yes-no-chart {
    display: flex;
    justify-content: space-around;
    padding: 0 40px;
    padding-top: 20px;
    .check-dot {
      text-align: center; }
    div {
      min-width: 0 !important; } }

  footer {
    margin-left: -38px;
    height: 88px;
    background: #FFFFFF;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 64px;
    position: fixed;
    width: calc(1440px - 250px - 64px);
    max-width: calc(100% - 250px - 64px);
    z-index: 2;
    span {
      font-size: 14px;
      color: #264382;
      letter-spacing: 0.3px;
      text-transform: uppercase; }
    button {
      width: 186px;
      height: 48px;
      margin-left: 24px;
      span {
        color: inherit; } } }
  .main-container {
    @include expert-container;
    margin-top: 24px; }
  .subject-container {
    @include expert-container; }

  .section-container {
    // margin: 0 auto
    margin-top: 16px;
    // padding: 0  32px
    // padding-bottom: 32px
    // width: 670px
    &:first-child {
      margin-top: 0; }

    .sub-section {
      margin: 0 -28px;
      padding: 0 28px;
      .delete, .deletable-part-opr {
        float: right;
        margin-right: -40px;
        display: none;
        cursor: pointer; }
      &:hover .delete {
        display: block; } }
    .deletable-part {
      margin: 0 -40px;
      padding: 0 40px; }
    .deletable-part:hover .deletable-part-opr {
      display: block;
      margin-top: 25px; }
    .summary-date {
      font-family: Gilroy-Bold;
      font-size: 12px;
      color: #70808E;
      text-transform: uppercase;
      // margin-top: 24px
      // margin-bottom: 10px
      padding-top: 24px; }
    & > h3 {
      margin: 0;
      margin-bottom: 24px;
      // margin-left: -32px
      // margin-right: -59px
      padding: 14px 25px;
      font-size: 18px;
      font-family: Gilroy-Bold;
      color: #ffffff;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        cursor: pointer;
        display: none; }
      &:hover svg {
        display: block; }
      &.Overview {
        color: #383E48;
        padding: 0;
        font-size: 24px;
        margin-bottom: 0; }
      &.Rejuvenation {
        background: #5C00D1; }
      &.Exercise {
        background: #FF6B00; }
      &.Nutrition {
        background: #7BAD2D; }
      &.Weight {
        background: #3194EB; } }

    .table-header {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      // font-size: 16px
      color: #70808E;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 100%; //140%
      vertical-align: top;
      div {
        display: inline-block;
        // transform: scale(0.5)
        .anticon {
          transform: scale(0.5); } }
      .days-7, .week-4 {
        text-align: right;
        width: 100px;
 }        // margin-right: -20px
      .days-7 {
        margin-right: -40px; }
      .change {
        margin-left: 30px;
        margin-right: 35px; }
      .calendar {
        margin-right: -31px;
        display: flex;
        width: 140px;
        margin-right: 45px;
        justify-content: space-between;
        text-align: center; } }
    .ref-container img {
      max-width: 100%; }
    //   margin: -20px
    //   padding: 20px
    .overview-section {
      display: flex;
      margin-bottom: 15px;
      .yes-no-chart {
        padding-top: 5px; }
      .left-title {
        width: 32px;
        // font-weight: 800
        // font-size: 12px
        text-transform: uppercase;
        // letter-spacing: 1px
        border-radius: 8px 0px 0px 8px;
        display: flex;
        align-items: center;
        // font-family: 'Gilroy-Bold'
        // span, b
        //   transform: rotate(-90deg) translateY(-195%)
        //   display: block
        //   // margin-left: -30px
        //   width: 103px
        //   text-align: center
        &.Rejuvenation {
          color: #5C00D1;
          background: #EFE6FA; }
        //   b
        //     margin-left: 3px
        &.Exercise {
          color: #FF6B00;
          background: #FFF0E6; }
        //   span
        //     transform: rotate(-90deg) translateY(-85%)
        &.Nutrition {
          color: #7BAD2D;
          background: #F2F7EA; }
        &.Weight {
          color: #3194EB;
          background: #E2F2FF; }
        //   span
 }        //     transform: rotate(-90deg) translateY(-115%)
      .right-content {
        .row {
          position: relative;
          display: flex;
          align-items: center;
          border-top: 1px solid rgba(145,159,171,.1);
          margin-left: 16px;
          text-align: center;
          font-size: 12px;
          color: #383E48;
          letter-spacing: 0.5px;
          font-weight: bold;
          font-family: 'Gilroy-Bold';
          //....
          min-height: 72px;
          &:last-child {
            border-bottom: 1px solid rgba(145,159,171,.1); }
          &:hover .delete {
            display: block; }
          .chart {
            width: 225px;
            text-align: right; }
          .name {
            width: 115px;
            text-align: left;
            letter-spacing: 1px;
            // text-transform: uppercase
            font-weight: 800;
            span, b {
              display: inline-block;
              width: 100px; } }
          .gray {
            color: #B7BFC6; }
          .green {
            color:#7BAD2D {} }
          .red {
            color: #FF6B00; }
          .normal {
            color: #70808E; }
          .black {
            color: #383E48; }
          div {
            min-width: 65px; }
          img {
            // max-width: 151px
            margin-right: 20px;
            width: 150px;
            height: 50px; }
          .delete {
            width: 0;
            position: absolute;
            right: -50px;
            top: 50%;
            transform: translateY(-50%);
            display: none;
            cursor: pointer; } } } }
    .zone-list {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      li {
        min-width: 42px;
        color: #8596A4;
        font-size: 12px; }
      li:before {
        content: '';
        width: 6px;
        height: 6px;
        display: inline-block;
        border-radius: 6px;
        background: #c1c1c1;
        margin-right: 4px;
        vertical-align: middle; }
      .z-5:before {
        background: #FF501A; }
      .z-4:before {
        background: #FC7558; }
      .z-3:before {
        background: #FB9043; }
      .z-2:before {
        background: #F7AD69; } }

    h4.sub-title {
      margin-top: 27px;
      margin-bottom: 16px;
      font-family: "Gilroy-Bold";
      font-weight: 800;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #383E48; }
    .big-chart {
      margin: 20px 0;
      overflow: hidden;
      .chart-container {
        // background: rgba(0,0,0,.1)
        // margin: 0 -20px
        // padding: 0 20px
        margin-top: -45px;
        display: flex;
        position: relative;
        .label {
          position: absolute;
          top: 25%;
          // transform: translateY(-50%)
          // width: 15px
          color: #70808E;
          font-size: 12px;
          text-transform: uppercase;
          align-items: center;
          display: flex;
          span {
            display: block;
            text-align: center;
            white-space: nowrap; } }
        .left-axis {
          left: 0;
          span {
            transform: rotate(-90deg);
            height: 43px; } }
        .right-axis {
          right: 0;
          span {
            transform: rotate(90deg);
            margin-left: -30px; } } }
      .workout-labels {
        padding: 0 40px;
        .activity-type, .activity-name,.activity-label {
          display: flex;
          justify-content: space-between;
          align-items: center; }
        .name {
          // display: flex
          // align-items: center
          svg {
            vertical-align: middle; } }
        .activity-label {
          border-top: 2px solid #E4EAEF;
          padding: 10px 0; }
        .workout-summary {
          width: 202px;
          display: flex;
          justify-content: space-between; }
        .activity-type {
          border-top: 2px solid #E4EAEF;
          padding-top: 16px;
          padding-bottom: 5px;
          font-family: 'Gilroy-bold';
          .name > span {
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 4px;
            margin-right: 8px;
            vertical-align: middle;
            &.Resistance {
              background: #58646B; }
            &.Aerobic {
              background: #FF6B00; }
            &.Activity {
              background: #BCCA70; }
            &.Movement {
              background: #C29E7C; }
            &.non-program {
              background: #C4C4C4; } } }
        .activity-name {
          padding-left: 22px;
          padding-bottom: 5px;
          &:last-child {
            padding-bottom: 15px; }
          .name:before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-left: -14px; } } }

      .chart-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 10px 16px;
        border-radius: 8px;
        background: rgba(38, 67, 130, 0.05);
        .tip {
          color: #70808E;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          font-weight: bold;
          font-size: 12px;
          svg {
            vertical-align: middle; }
          span:first-child, i:first-child {
            margin-right: 8px;
            display: inline-block;
            vertical-align: middle;
            box-sizing: border-box; }
          span {
            margin-top: -2px; }
          .circle {
            border-radius: 25px;
            border: 2px solid #479FEC;
            width: 14px;
            height: 14px; }
          .rect {
            border-radius: 4px;
            width: 14px;
            height: 14px;
            &.sleep-duration {
              background: #5C00D1; }
            &.aerobic {
              background: #F3B555; }
            &.resistance {
              background: #58646B; }
            &.flexibility {
              background: #C9D48A; }
            &.percentages {
              background: #FF6B00; }
            &.cummulative {
              background: #479FEC; } } } } } } }

