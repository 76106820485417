 :local(.succeedMessageStyle) {
  background: #7BAD2D;
  margin: -15px -20px;
  padding: 20px 16px;
  border-radius: 6px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-family: Gilroy;
  min-width: 711px;
  .icon {
    display: flex;
    .anticon {
      padding-right: 5px; } }
  h3 {
    color: #ffffff;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.3px; }
  p {
    margin: 0;
    font-size: 13px;
    line-height: 19px; } }
