 :local(.progressStyle) {
  .pillars-container {
    padding-top: 25px;
    min-width: calc(337px * 3 + 15px);
    .header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0,0,0,.1);
      padding-bottom: 20px;
      .name {
        font-size: 24px;
        width: 200px;
        margin-left: 10px; }
      .rate {
        font-size: 14px;
        border-left: 1px solid rgba(0,0,0,.1);
        div>span {
          margin-left: 15px; } } }
    .list-container {
      .card {
        display: inline-block;
        vertical-align: top;
        margin-top: 16px;
        width: 337px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-right: 7px;
        font-size: 12px;
        user-select: none;
        border: 1px solid #DEE2EC;
        box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.1);
        &:nth-child(3n) {
          margin-right: 0; }
        .title {
          padding: 15px;
          padding-bottom: 0;
          margin-bottom: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          font-family: Gilroy; }
        .ant-typography {
          font-weight: bold;
          margin-bottom: 0;
          color: #383E48;
          &:first-letter {
            text-transform: capitalize; } }
        .chart-tags {
          font-size: 12px;
          font-style: normal;
          &>span {
            margin-left: 16px;
            &>span {
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 100%;
              margin-right: 4px; } } }
        .body {
          position: relative;
          padding: 10px 15px; }
        .VictoryContainer {
          margin: 0 -5px;
          svg {
            overflow: visible; } }
        .chat-area {
          position: absolute;
          padding-right: 10px; }
        // .calendar-container
        //   padding-top: 25px
        //   .color-cicle
        //     margin: 0 -15px
        //     padding: 0 15px
        //     padding-top: 10px
        //     border-top: 1px solid rgba(0,0,0,.1)
        //     display: flex
        //     font-family: Gilroy
        //     font-size: 12px
        //     font-weight: 600
        //     line-height: 24px
        //     text-align: center
        //     flex-wrap: wrap
        //     span
        //       display: inline-block
        //       width: 6px
        //       height: 6px
        //       border-radius: 4px
        //       &.Resistance
        //         background: #58646B
        //       &.Aerobic
        //         background: #FF6B00
        //       &.Activity
        //         background: #BCCA70
        //       &.Movement-Quality
        //         background: #C29E7C
        //     &>div
        //       margin-right: 24px
        //       color: #383E48
        //       span
        //         margin-right: 8px
        //   &.any-activity
        //     .date-item .color-cicle
        //       border: none
        //       padding: 0
        //       margin: 0
        //       display: block
        //       text-align: center
        //       line-height: 1
        //       span
        //         margin: 0 1px

        //   & + .data-sync
        //     padding-top: 10px
        //     border-top: 1px solid rgba(0,0,0,.1)
        //     display: flex
        //     font-family: Gilroy
        //     font-size: 12px
        //     font-weight: 600
        //     line-height: 24px
        //     text-align: center
        //     span
        //       display: inline-block
        //       width: 6px
        //       height: 6px
        //       border-radius: 4px
        //       &.Resistance
        //         background: #641717
        //       &.Aerobic
        //         background: #E9AF8E
        //       &.Yoga
        //         background: #2AC3D7
        //     &>div
        //       margin-right: 24px
        //       color: #383E48
        //       span
        //         margin-right: 8px
        //   &.any-activity
        //     .date-item .color-cicle
        //       border: none
        //       padding: 0
        //       margin: 0
        //       display: block
        //       text-align: center
        //       line-height: 1
        //       span
        //         margin: 0 1px

        & + .data-sync {
          padding-top: 10px;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          white-space: nowrap; } }

      .number-statistical {
        padding: 10px 15px;
        display: flex;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        div {
          color: #383E48;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.3px;
          line-height: 21px;
          padding: 0 10px;
          border-right: 1px solid rgba(0,0,0,.1);
          min-width: 90px;
          &:first-child {
            padding-left: 0; }
          &:last-child {
            border: none; }
          span {
            color: #C0C7D1;
            margin-left: 5px;
            font-weight: normal; } } }
      .rate {
        padding: 10px 15px;
        border-top: 1px solid rgba(0,0,0,.1);
        &>div {
          margin-right: 10px;
          padding-left: 10px;
          border-left: 1px solid rgba(0,0,0,.1);
          font-size: 12px;
          position: relative;
          padding-top: 20px;
          min-width: 80px;
          span {
            position: absolute;
            top: 0;
            left: 10px;
            font-size: 14px; }
          &:first-child {
            padding-left: 0;
            border: none;
            span {
              left: 0; } } } }
      .footer {
        border-top: 1px solid rgba(0,0,0,.1);
        padding: 5px 15px;
        color: #60656D;
        opacity: 0.8;
        letter-spacing: 0.3px;
        text-transform: uppercase; }
      .data-sync, .only-expert {
        padding:  0 15px;
        padding-bottom: 15px;
        font-family: Heebo;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.6px;
        color: rgba(56,62,72,.5);
        white-space: nowrap;
        img {
          margin-right: 8px; } }
      .only-expert {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 10px;
        .anticon {
          margin-right: 8px; } }
      .archived {
        background: #E8ECF0;
        border: 0.5px solid #BAC3CE;
        .archived-opr {
          border-radius: 8px 8px 0px 0px;
          background: #BAC3CE;
          padding: 10px 15px;
          text-align: right;
          color: rgba(38,67,130,.8);
          font-family: Heebo;
          font-weight: 500;
          line-height: 150%;
          letter-spacing: 0.6px;
          span {
            margin-left: 23px;
            cursor: pointer;
            img {
              margin-right: 5px; } } } } }
    // .calendar-container
    //   .label-item, .date-item
    //     display: inline-block
    //     width: 14.25%
    //     font-size: 12px
    //     letter-spacing: 0.3px
    //     color: #9C9FA4
    //     text-align: center
    //     margin-bottom: 5px
    //     vertical-align: top
    //   .date-item
    //     .input-cicle
    //       padding: 2px
    //       width: 36px
    //       height: 36px
    //       margin: 0 3px
    //       border:  2px dotted transparent
    //       border-radius: 100%
    //       &.activity
    //         border-color: #7BAD2D
    //       &>div
    //         cursor: pointer
    //       .green
    //         background: #7BAD2D
    //       .red
    //         background: #E75F25
    //       .blue
    //         background: #2B4B8F
    //       div
    //         width: 100%
    //         height: 100%
    //         background: #BAC3CE
    //         color: #ffffff
    //         font-size: 14px
    //         border-radius: 100%
    //         position: relative
    //         line-height: 28px
    //         .today
    //           position: absolute
    //           display: block
    //           width: 5px
    //           height: 5px
    //           border-radius: 100%
    //           background: #297FCA
    //           bottom: 2px
    //           left: 50%
    //           margin-left: -2.5px


    .text-list {
      color: #383E48;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.3px;
      .text-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid rgba(0,0,0,.1);
        &:last-child {
          border: none; }
        span:first-child {
          // width: calc(100% - 100px)
          overflow-wrap: break-word; }
        span:last-child {
          // width: 190px
          display: block;
          text-align: right;
          margin-left: 10px; } } } } }
