.compareContainer {
  border-radius: 12px;
  background: rgba(239,242,245,.4);
  border: 1px solid rgba(221,221,221,.4);
  font-family: Gilroy;
  padding: 20px 16px;
  color: rgba(96,101,109,.8);
  font-size: 12px;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .compareTitle {
    & > div {
      width: 13%;
      &:last-child {
        text-align: right; } }
    .vs {
       width: 74%; } }
  .vs {
    border-top: 1px solid rgba(0,0,0,.1);
    position: relative;
    &>div {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      border-radius: 100%;
      line-height: 28px;
      text-align: center;
      background: #FFFFFF;
      border: 1px solid #E6E6E6; } }
  .compareRow {
    margin-top: 16px; } }

.boldText {
  font-family: Gilroy-Bold;
  color: #383E48;
  font-size: 18px;
  letter-spacing: 0.3px;
  width: 13%;
  white-space: nowrap;
  &:last-child {
    text-align: right; } }

.itemInfoTitle {
  color: #5B6177;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600; }
.itemInfoValue {
  color: #323F4A;
  font-size: 16px;
  font-family: Inter;
  font-weight: 700; }

.descriptionTitle {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #8D99A5; }
.description {
  font-family: Inter;
  margin-top: 6px;
  color: #52606C;
  font-size: 14px;
  line-height: 145%;
  font-weight: 400; }

.document {
  display: flex;
  background: rgba(38,67,130,.05);
  border-radius: 8px;
  padding: 15px 20px;
  color: #70808E;
  font-size: 10px;
  margin-top: 8px;
  &:first-child {
    margin-top: 16px; }
  img {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    object-fit: cover; }
  .fileName {
    color: #383E48;
    font-size: 14px; } }

.heartRateTitle {
  font-family: 'Gilroy-bold';
  font-weight: 600;
  font-size: 14px; }

 :global(.activity-trend) {
  height: 336px;
   :global(.VictoryContainer) {
    display: flex;
    svg {
      height: 100% !important; } } }

