.status-drop-down {
  width: 151px !important;
  .status-circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 8px;
    display: inline-block;
    background: gray;
    &.draft {
      background: #F7C137; }
    &.done {
      background: #7BAD2D; } } }

 :local(.listStyle) {
  font-family: Gilroy;
  margin-top: 40px;
  padding-bottom: 20px;
  .ant-table-thead {
    // position: sticky
    // top: 0
    // font-size: 12px
    .ant-table-column-sorters {
      // display: flex
      // align-items: center
      // .ant-table-column-sorter
      //   margin-top: 2px
 } }      //   margin-left: 3px
  // .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-has-filters
  //   padding-right: 16px !important
  .ant-table-thead > tr > th {
    // padding-right: 16px !important
    padding-left: 8px;
    font-size: 12px;
    background: transparent;
    color: #5C626A;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    opacity: 0.8;
    border-bottom: none;
    &:first-child {
      padding-left: 24px; }
    &:last-child {
      text-align: right;
      padding-right: 24px; } }
  .ant-table-tbody {
 }    // white-space: nowrap

  .ant-table-tbody > tr > td {
    padding: 16px 8px;
    word-wrap: break-word;
    word-break: break-word;
    border-bottom-color: #F4F6F8;
    font-size: 14px;
    color: #4C6072;
    background: #ffffff;
    &:first-child {
      border-radius: 12px 0 0 12px;
      color: #323F4A;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      font-family: 'Gilroy-bold';
      padding-left: 24px; }
    &:last-child {
      border-radius: 0 12px 12px 0;
      text-align: right;
      padding-right: 24px; }
    .status-container {
 }      // width: 80px
    .name-container {
      width: 180px;
      cursor: pointer;
      word-break: break-word;
      &:hover {
        color: #1890ff; } } }
  .status-circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 8px;
    display: inline-block;
    background: gray;
    &.Sent, &.Draft, &.draft {
      background: #F7C137; }
    &.Opened, &.done {
      background: #7BAD2D; } }
  .ant-select {
    .ant-select-selection__rendered {
      margin-left: 0; }
    .ant-select-selection {
      border: none;
      background-color: transparent; }
    .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
      border-color: transparent;
      box-shadow: none; }
    .ant-select-arrow {
      color: #4C6072; } } }
