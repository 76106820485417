 :local(.calendarStyle) {
  .calendar-container {
    padding-top: 25px;
    .color-cicle {
      margin: 0 -15px;
      padding: 0 15px;
      padding-top: 10px;
      border-top: 1px solid rgba(0,0,0,.1);
      display: flex;
      font-family: Gilroy;
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      flex-wrap: wrap;
      span {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 4px;
        &.Resistance {
          background: #58646B; }
        &.Aerobic {
          background: #FF6B00; }
        &.Activity {
          background: #BCCA70; }
        &.Movement-Quality {
          background: #C29E7C; }
        &.non-program {
          background: #C4C4C4; } }
      &>div {
        margin-right: 24px;
        color: #383E48;
        span {
          margin-right: 8px; } } }
    &.any-activity {
      .date-item .color-cicle {
        border: none;
        padding: 0;
        margin: 0;
        display: block;
        text-align: center;
        line-height: 1;
        span {
          margin: 0 1px; } } }

    & + .data-sync {
      padding-top: 10px;
      border-top: 1px solid rgba(0,0,0,.1);
      display: flex;
      font-family: Gilroy;
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      span {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 4px;
        &.Resistance {
          background: #641717; }
        &.Aerobic {
          background: #E9AF8E; }
        &.Yoga {
          background: #2AC3D7; } }
      &>div {
        margin-right: 24px;
        color: #383E48;
        span {
          margin-right: 8px; } } }
    &.any-activity {
      .date-item .color-cicle {
        border: none;
        padding: 0;
        margin: 0;
        display: block;
        text-align: center;
        line-height: 1;
        span {
          margin: 0 1px; } } }

    .label-item, .date-item {
      display: inline-block;
      width: 14.25%;
      font-size: 12px;
      letter-spacing: 0.3px;
      color: #9C9FA4;
      text-align: center;
      margin-bottom: 5px;
      vertical-align: top; }
    .date-item {
      .input-cicle {
        padding: 2px;
        width: 36px;
        height: 36px;
        margin: 0 3px;
        border:  2px dotted transparent;
        border-radius: 100%;
        &.activity {
          border-color: #7BAD2D; }
        &>div {
          cursor: pointer; }
        .green {
          background: #7BAD2D; }
        .red {
          background: #E75F25; }
        .blue {
          background: #2B4B8F; }
        div {
          width: 100%;
          height: 100%;
          background: #BAC3CE;
          color: #ffffff;
          font-size: 14px;
          border-radius: 100%;
          position: relative;
          line-height: 28px;
          .today {
            position: absolute;
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background: #297FCA;
            bottom: 2px;
            left: 50%;
            margin-left: -2.5px; } } } } } }
