 :local(.heartRateTipsStyle) {
  padding-right: 10%;
  font-size: 12px;
  padding-top: 10px;
  .zoon-container {
    width: 50%;
    display: inline-block;
    &:nth-child(2n) {
      padding-left: 10px; }
    &:nth-child(2n+1) {
      padding-right: 10px; } }
  .zoon {
    display: flex;
    justify-content: space-between;
    &>div {
      white-space: nowrap; }
    .circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      margin-right: 5px;
      vertical-align: middle; }
    svg {
      vertical-align: middle; } } }
