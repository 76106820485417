 :local(.zoneTypeStyle) {
  // display: flex
  margin-top: 22px;
  .ant-tabs-bar {
    border: none; }
  .ant-tabs-nav {
    .ant-tabs-tab {
      margin-right: 18px;
      border: none;
      background: transparent;
      padding: 10px 20px;
      border-radius: 12px;
      color: #BAC3CE;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.3px;
      cursor: pointer; }
    .ant-tabs-tab-active {
      background: rgba(38,67,130,.1);
      color: #264382; }
    .ant-tabs-ink-bar {
      background-color: transparent; } }
  .activity-tab-description {
    color: #80848A;
    padding-bottom: 20px;
    span {
      text-transform: lowercase; } } }
