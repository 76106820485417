 :local(.categoriesStyle) {
  background: #ffffff;
  border: 1px solid #EAEDF3;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding-left: 32px;
  margin-bottom: 30px;
  .logo-container {
    // padding: 25px
    padding-top: 30px;
    width: 100%;
    & + .root-category {
      padding-top: 0; } }

  .root-category {
    line-height: 29px;
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #3F4752;
    img {
      margin-left: 5px; }
    div:first-child:last-child {
      flex: auto; } }
  .root-category {
    color: #4C6072;
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin-left: -32px;
    // padding: 0 32px
    padding-left: 32px;
    padding-top: 30px;
    display: flex;
    flex-wrap: normal;
    justify-content: space-between;
    &.root-title {
      padding-right: 32px; }
    .full-width {
      flex: auto; }
    .root-category {
      padding-top: 0;
      padding-right: 32px;
 }      // margin-right: -32px
    & + .right {
      height: 60px; }
    h1 {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
      font-weight: normal; }
    p {
      letter-spacing: 0.3px;
      font-size: 14px;
      line-height: 26px;
      // font-style: italic
      opacity: 0.8;
      margin-top: 5px; }
    .description {
      width: 675px; } }
  .sub-category-container {
    margin-left: -32px;
    padding-left: 32px;
    padding-right: 32px;
    // padding: 0 25px
    // width: 100%
    flex: auto;
    width: calc(100% + 32px);
    .top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-right: 0;
      padding-top: 15px;
      border-bottom: 1px solid rgba(0,0,0,.1);
      padding: 30px 0;
      .description {
        color: #4C6072;
        width: 675px;
        opacity: 0.8;
        font-size: 14px;
        padding-top: 8px; }
      .name {
        color: #323A46;
        font-weight: 700;
        line-height: 29px;
        font-size: 18px;
        text-transform: capitalize;
        cursor: pointer;
        padding-right: 10px;
        span {
          opacity: .5;
          margin-right: 12px; }
        &>div {
          display: inline-block; } } }
    .bottom {
      padding-top: 25px;
      margin: 0;
      box-shadow: none;
      min-height: auto; } }
  .single-file {
    margin-right: 32px;
    margin-bottom: 32px; }
  &.category-container {
    width: 100%; }

  @media screen and (max-width: 1024px) {
    .root-category {
      border: none; }
    .sub-category-container {
      margin-left: -32px;
      .left {
        width: 100%;
        padding-left: 32px;
        display: block; }
      .right {
        width: 100%;
        padding: 15px 32px;
        .biomarker-container {
          margin-left: 0; } } } } }


 :local(.clientCategoriesStyle) {
  .full-width {
    flex: auto; }
  .sec-no-data {
    text-align: center;
    color: #BAC3CE;
    opacity: 0.8;
    // padding: 30px 0
    font-size: 12px;
    font-weight: 500;
    font-family: Heebo;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    margin-left: -35px; }
  .category-container {
    background: #fff;
    border: 1px solid #eaedf3;
    box-sizing: border-box;
    box-shadow: 0 4px 30px rgba(0,0,0,.05);
    border-radius: 4px;
    padding: 32px;
    margin-bottom: 30px;
    .image-content {
      text-align: center;
      img {
        max-width: 100%; } } } }


