 :local(.containerStyle) {
  .btn-generate-report {
    text-transform: upperCase;
    float: right;
    margin-right: 15px;
    margin-top: -60px; }
  .btn-date-set {
    // float: right
    position: absolute;
    vertical-align: middle;
    padding-left: 20px;
    right: 45px;
    margin-top: 30px;
    .ant-switch {
      margin-right: 10px; }
    &>span {
      margin-right: 20px;
      font-weight: bold;
      line-height: 27px;
      font-size: 16px;
      letter-spacing: 0.366667px;
      color: #3F4651; }
    .ant-btn {
      text-transform: upperCase;
      // float: right
      margin-right: 15px; } } }


 :local(.clientContainerStyle) {
  .category-container {
    padding-top: 25px;
    padding-bottom: 30px;
    .root-category {
      // padding-right: 79px
      img {
        max-width: 100%; }
      .description {
        h2 {
          font-size: 24px;
          letter-spacing: 0.3px;
          font-weight: normal;
          color: #44433D; } } }
    .show-more-text {
      color: #3D3D3D;
      opacity: 0.8;
      text-decoration: underline;
      cursor: pointer; }
    .ant-typography {
      font-size: 12px;
      font-size: 14px;
      color: #44433D; }
    .ant-divider-horizontal.ant-divider-with-text-left {
      .ant-divider-inner-text {
        font-size: 18px; }
      &:before {
        width: 0; } }
    .ant-typography-expand {
      display: inline-block;
      color: transparent;
      position: relative;
      &:before {
        content: 'See more';
        position: absolute;
        color: #3D3D3D;
        opacity: 0.8;
        text-decoration: underline;
        width: 70px;
        letter-spacing: -0.5px; } }
    .card-list {
      margin: 20px 0 0 35px; } } }
