 :local(.detailStyle) {
  font-family: Gilroy;
  .ant-modal-body {
    padding: 40px; }
  .optimal, .concern, .at-risk, .risk {
    font-size: 10px;
    letter-spacing: 0.3px;
    line-height: 150%;
    text-transform: uppercase;
    font-family: 'Gilroy-Bold'; }
  .optimal {
    color: #40C47C;
    border-color: #40C47C; }
  .concern {
    color: #F5BA40;
    border-color: #F5BA40; }
  .at-risk, .risk {
    color: #FF6B00;
    border-color: #FF6B00; }

  .title {
    font-size: 24px;
    line-height: 29px;
    color: #4C6072;
    margin-bottom: 0; }
  .status-group {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    // position: absolute
    // right: 20px
    padding-top: 12px;
    margin-bottom: 0;
    li:before {
      content: '';
      display: inline-block;
      width: 9px;
      height: 9px;
      border: 2px solid;
      border-radius: 9px;
      margin-left: 17px;
      margin-right: 5px; } }
  .chart-container {
    margin: 0 -20px;
    position: relative;
    padding-left: 20px;
    .chart-label {
      position: absolute;
      font-family: 'Gilroy-Bold';
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #70808E;
      height: 13px;
      left: 25px;
      top: 50%;
      margin-top: -20px;
      transform: translateY(-50%);
      .label-text {
        transform: translateX(-50%) rotate(-90deg) scale(0.72); } } }

  .choice-list {
    list-style: none;
    margin-bottom: 16;
    padding: 20px 28px;
    border: 1px solid #E2E5E9;
    border-radius: 8px;
    font-size: 13px;
    color: #70808E;
    line-height: 19px;
    li {
      display: flex;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0; }
      &>div:last-child {
        width: calc(100% - 40px); }
      .score {
        width: 24px;
        height: 24px;
        border: 2px solid;
        line-height: 20px;
        text-align: center;
        border-radius: 24px;
        margin-right: 19px; }
      .score-1 {
        border-color: #FF6B00; }
      .score-2 {
        border-color: #F5BA40; }
      .score-3 {
        border-color: #40C47C; } } }
  .generalChart {
    padding: 0px;
    .header {
      display: block;
      .name {
        font-size: 24px;
        display: flex;
        > span {
          line-height: 19px;
          margin-left: 8px; } } } }

  .info-container {
    display: flex;
    .info-list {
      background: rgba(38, 67, 130, 0.05);
      border-radius: 8px;
      width: 100%;
      list-style: none;
      padding: 24px;
      margin: 0;
      &.has-icon {
        width: calc(100% - 284px); }
      li {
        display: flex;
        color: #70808E;
        font-size: 13px;
        line-height: 19px;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0; }
        img {
          margin-right: 15px; }
        div:last-child {
          align-items: center;
          display: flex;
          flex-wrap: wrap; }
        a {
          color: #2B4B8F;
          // margin: 0 5px
          text-decoration: underline;
          font-family: 'Gilroy-Bold'; } } }

    .icons {
      width: 260px;
      border-radius: 8px;
      border: 1px solid #F4F6F9;
      margin-left: 24px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #4C6072;
      font-size: 13px;
      &>div {
        display: flex;
        padding: 0;
        width: 100%;
        align-items: center;
        justify-content: space-around; }
      img {
        margin-bottom: 12px; }
      div {
        min-width: 50%;
        text-align: center;
        padding: 5px 3px; } } }

  &.mobile {
    background: white;
    padding-bottom: 40px;
    // padding: 30px
    // .am-modal-content
    //   height: 90vh
    //   border-radius: 8px 8px 0 0
    // .am-modal-body
    //   padding-bottom: 30px
    .title {
      font-size: 17px;
      text-align: center;
      padding-top: 23px;
      .anticon {
        float: left;
        margin-left: 20px;
        margin-top: 5px; }
      & + div {
        text-align: center;
        padding-bottom: 13px;
        border-bottom: 1px solid rgba(0,0,0,.1); } }
    .chart-container {
      margin: 0; }
    .status-group {
      padding-right: 20px; }
    .choice-list {
      // width: 100%
      margin: 0 16px;
      text-align: left;
      padding: 20px;
      li {
        // &>div:last-child
        //   width: calc(100% - 40px)
        .score {
          margin-right: 16px; } } }
    .info-container {
      flex-wrap: wrap;
      .icons,.info-list {
        width: 100%;
        margin: 0 16px; }
      .icons {
        order: 1; }
      .info-list {
        order: 2;
        margin-top: 16px;
        text-align: left;
        padding: 20px; } } } }
