 :local(.nutritionScoreStyle) {
  padding-top: 40px;
  padding-right: 60px;
  .description {
    margin-top: 0; }
  &.User {
    .information-container.Opened {
      border: none; }
    .nutrition-score-container {
      .information-container.Opened .content {
        color: #E7E9EC; } }
    .section-container {
      .information-container.Opened {
        background: rgba(38, 67, 130, 0.05); } } } }

