 :local(.modalStyle) {
  .ant-select-selection-selected-value {
    width: 90px; }
  .ant-modal-content {
    border-radius: 20px; }
  .ant-modal-header, .ant-modal-body, .ant-modal-footer {
    padding-left: 40px;
    padding-right: 40px; }
  .ant-modal-footer, .ant-modal-header {
    padding-top: 32px;
    padding-bottom: 32px; }
  .ant-modal-header {
    border-radius: 20px 20px 0 0;
    .ant-modal-close {
      right: 25px; }
    .ant-modal-title {
      font-family: Gilroy;
      line-height: 29px;
      font-size: 24px;
      font-weight: bold;
      color: #4C6072; } }

  .body-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: -40px;
    margin-right: -40px;
    padding: 24px 40px;
    padding-top: 0;
    border-bottom: 1px solid rgba(0,0,0,.1); }
  .body-data {
    margin-left: -40px;
    margin-right: -40px;
    padding: 24px 40px;
    font-weight: bold;
    &.divider {
      border-bottom: 1px solid rgba(0,0,0,.1); }
    &:last-child {
      padding-bottom: 0; }
    &>div, .flex-row {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0,0,0,.1);
      padding: 16px 0;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0; }

      .column {
        font-family: 'Gilroy';
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
        color: #383E48; }
      .data-list {
        display: flex;
        width: 77%;
        &>div {
          width: 14.2%;
          text-align: center;
          padding: 0 5px;
          input {
            background: #F5F5F5; }
          .ant-input-number {
            width: auto; } } } }
    .add-workout {
      .column {
        color: #264382;
        cursor: pointer;
        border-bottom: 2px dashed #264382; } }

    .workout-container {
      display: block;
      padding-top: 0;
      .sub-tip {
        .column {
          padding-left: 16px; } }
      .data-list {
        .ant-select-open .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
          box-shadow: none; }
        .ant-select-selection {
          border: none;
          .ant-select-selection__rendered {
            font-family: Gilroy; } }
        .ant-select-auto-complete.ant-select .ant-input {
          padding: 0px;
          border: none !important;
          background: transparent !important;
          text-overflow: ellipsis;
          &:focus {
            box-shadow: none; }
          &.has-error, &.has-error::placeholder {
            color: #f5222d; } } } }


    .header {
      .column {
        font-size: 18px; }
      .data-list {
        color: #8D99A5;
        font-size: 12px;
        letter-spacing: 0.4px; } }
    .ant-checkbox-disabled.ant-checkbox-checked {
      opacity: .5;
      .ant-checkbox-inner::after {
        border-color: #ffffff; } } }
  .ant-input-number {
    margin: 0 10px; }
  .ant-input-number-handler-wrap {
    display: none; }
  .prefix-container {
    position: relative;
    img {
      position: absolute;
      z-index: 2;
      width: 16px;
      top: 7px;
      left: 13px; }
    // .ant-input-number-handler-wrap
    //   display: none
    .ant-input-number-input {
      padding-left: 22px;
      padding-right: 3px; } } }

 :local(.modalFooter) {
  display: flex;
  text-align: left;
  justify-content: space-between; }
