 :local(.style) {
  width: 100vw !important;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #ffffff;
  .cover-header, {
    position: relative;
    margin: 0 auto;
    header {
      position: absolute;
      padding: 28px 5px;
      left: 10px;
      height: 83px;
      background: #ffffff; }
    &.back {
      position: fixed;
      top: 0;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      header {
        position: static;
        text-align: center;
        .anticon {
          font-size: 26px;
          float: left;
          color: #4C6072; } } } }
  iframe {
    width: 100%;
    height: 100%;
    border: none; }
  @media (min-width: 576px) {
    .cover-header {
      max-width: 510px;
      header {
        left: 0; } } }
  @media (min-width: 768px) {
    .cover-header {
      max-width: 690px; } }
  @media (min-width: 992px) {
    .cover-header {
      max-width: 930px; } }
  @media (min-width: 1200px) {
    .cover-header {
      max-width: 1053px; } } }



