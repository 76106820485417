 :local(.mobileNutritionScore) {
  .information-container.Opened {
    border: none; }

  .nutrition-score-container {
    margin-top: 0;
    border-radius: 0;
    img {
      width: 240%;
      right: -50%; }
    .chart-container {
      width: calc(50% + 100px + 300px);
      margin-left: -350px; }
    .information-container.Opened {
      .content {
        color: #E7E9EC; } } }
  .section-container {
    padding: 20px;
    section {
      display: block; }
    .score-left {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #E3E6EA; }
    .score-right {
      width: 100%; }
    .item-list .item {
      width: 100%;
      border-right: none; }
    .information-container.Opened {
      background: rgba(38, 67, 130, 0.05); } }

  img[alt="empty"] {
    max-width: 55%; } }


