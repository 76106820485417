 :local(.fileInputStyle) {
  label {
    display: block;
    height: 45px;
    padding: 12px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
    cursor: pointer; }
  input {
    display: none !important; } }
