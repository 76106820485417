 :local(.overviewMetricsStyle) {
  &.User .goal-detail-bg {
    margin-top: -66px;
    margin-left: -343px; }
  .goal-detail-bg {
    margin-right: -44px;
    margin-top: -20px;
    margin-left: -314px; }
  .core-metrics-top {
    display: flex;
    // justify-content: flex-end
    align-items: center;
    // margin-top: -60px
    // button
    //// margin-top: -24px
    .today {
      font-family: Gilroy;
      font-size: 14px;
      line-height: 150%;
      // margin-top: -24px
      color: #52606C;
      border: 1px solid #DEE2EC;
      border-radius: 12px;
      padding: 8px 15px;
      display: inline-block;
      // float: right
      text-transform: uppercase;
      margin-right: 18px;
      margin-left: 8px;
      background: #ffffff; }

    & + .healthy-container {
      margin-top: 40px;
      .healthy {
        border-radius: 12px 12px 0 0;
        h3 {
          margin: 0 -20px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 18px;
          border-bottom: 1px solid #DEE2EC; } } }

    &.healthy-top-container {
      margin-top: 0;
      justify-content: space-between;
      .ant-select-selector {
        border-radius: 12px; }
      .ant-divider-vertical {
        height: 28px;
        border-color: #000000;
        opacity: 0.2;
        margin-left: 48px;
        & + div {
          display: flex; } } } }

  .core-metrics {
    margin-top: 40px;
    .metrics-card {
      padding: 18px 20px;
      padding-bottom: 36px;
      border-radius: 12px;
      background: #FFFFFF;
      border: 1px solid #DEE2EC;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
      margin-bottom: 22px;
      h3 {
        padding-bottom: 18px;
        font-weight: bold;
        font-size: 17px;
        line-height: 124%;
        letter-spacing: 0.3px;
        border-bottom: 1px solid #E4EAEF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right-tag {
          color: #8596A4;
          font-size: 12px;
          line-height: 14px; } }
      &.nutrition, &.rejuvenation {
        h3 {
          color: #7BAD2D; }
        .metric:not(.first-level) > div {
          width: 100%;
          .value {
            justify-content: flex-start; } } }
      &.exercise h3 {
        color: #FF6B00;
        border-bottom: 0;
        padding-top: 25px; }

      &.rejuvenation h3 {
        color: #5C00D1; }

      &.healthy h3 {
        color: #2B4B8F; }

      &.weight {
        padding-bottom: 10px;
        h3 {
          color: #FF6B00; } }
      .null-status {
        padding-top: 77px;
        padding-bottom: 60px;
        text-align: center;
        p {
          font-size: 14px;
          color: #8596A4;
          margin-top: 26px; } }
      .metrics-body {
        display: flex;
        justify-content: space-between;
        &>div {
          width: 50%;
          &.full-row {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .metric.nutrition {
              width: 50%; } } }
        .weight-chart-container {
          padding-right: 18px;
          .title-view {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .title-view-date {
              font-size: 12px;
              font-weight: 600;
              color: #8596A4; }
            .title-view-unit {
              color: #97A0A7; } } } }
      .rjn-item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #E4EAEF;
        padding-bottom: 24px;
        padding-top: 24px;
        &>div {
          width: 50%;
          &.full-row {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .metric.nutrition {
              width: 50%; } } }
        .resting {
          .r-left {
            width: 420px; }
          .r-right {
            .label {
              margin-left: 20px;
              margin-bottom: 0px; } } }
        &.sleep {
          .s-left {
            width: 350px;
            display: flex;
            flex-direction: column;
            justify-content: space-around; }
          .s-right {
            flex: 1; } } }
      .body {
        margin-top: 25px;
        // .half-width
 }        //   width: 50%
      &.exercise .body {
        display: block; }
      .metric {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        font-size: 18px;
        line-height: 124%;
        letter-spacing: 0.3px;
        color: #383E48;
        // margin-bottom: 20px
        &.gotobed {
          display: flex;
          flex-direction: column;
          .label {
            margin-bottom: 0px; } }
        .clickable {
          color: #264382;
          cursor: pointer;
          .anticon {
            font-size: 12px; }
          // &>span
 }          //   border-bottom: 2px dashed #264382

        &:last-child {
          margin: 0; }
        &.nutrition {
          margin-bottom: 20px; }
        &.first-level {
          .label {
            margin-bottom: 15px; }
          .value {
            justify-content: flex-start;
            span {
              color: #383E48;
              margin-right: 10px; } }
          &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            border-top: 1px solid #E4EAEF;
            padding-top: 25px; } }
        &>div {
          width: 50%; }
        .label {
          font-size: 12px;
          color: #8596A4;
          line-height: 150%;
          margin-bottom: 15px;
          .healthy-status {
            margin-left: 6px;
            text-transform: uppercase;
            &.over-allocated {
              color: #7BAD2D; }
            &.on-track {
              color: #3561C0; }
            &.under-allocated {
              color: #FF6B00; } } }
        .value {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          span {
            font-size: 12px;
            color: #8596A4; }
          &>div:first-child {
            width: 178px; } }
        .l-number {
          font-size: 40px !important;
          .no-data span {
            line-height: 12px; } }
        .more-week-avg {
          margin-top: 23px;
          color: #A5ABB2;
          font-size: 12px;
          font-weight: 500;
          line-height: 150%; }
        .more-week-avg-value {
          color:  #323F4A;
          font-family: Gilroy;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.3px;
          margin-top: 3px; }
        .more-week-avg-nodata {
          color:  #C0C6D1; }
        // ...

        .calendar-container {
          display: flex;
          margin-top: 10px;
          .date-item {
            margin-right: 8px;
            &> div {
              border: 2px solid #C9CDD2;
              border-radius: 15px;
              width: 30px;
              height: 30px;
              font-weight: bold;
              color: #8596A4;
              font-size: 14px;
              text-align: center;
              line-height: 26px;
              background: #FBFBFB; }
            .lighter {
              opacity: .6; } } }
        .no-data {
          color: #BAC3CE;
          margin-top: 10px;
          span {
            display: inline-block;
            background: rgba(43,75,143,.2);
            width: 18px;
            height: 18px;
            border-radius: 100%;
            text-align: center;
            line-height: 15px;
            margin-left: 8px; } }
        .chart {
          padding-right: 20%; }
        .sleep-tip {
          padding-top: 10px;
          display: flex;
          font-size: 12px;
          color: #60656D;
          div {
            margin-right: 12px; }
          span {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            margin-right: 5px; } } } } }

  .healthy-signal-top-labels {
    display: flex;
    position: absolute;
    right: 0;
    margin-top: 15px;
    color: #8596A4;
    font-size: 12px;
    &>div {
      margin-left: 16px; }
    svg {
      margin-right: 6px;
      vertical-align: middle; } }

  .healthy-container {
    margin: 0;
    border-bottom: 1px solid #DEE2EC;
    .healthy {
      margin: 0;
      padding-bottom: 22px;
      border: none;
      border-radius: 0;
      h3 {
        border-bottom: 0;
        padding-bottom: 0; }
      .metric .l-number {
        font-size: 32px !important; }
      &.client-view {
        padding-top: 36px;
        h3 {
          margin: 0;
          border: none;
          padding: 0; }

        .body .ant-row.metric {
          margin-top: -55px;
          & > div:first-child {
            margin-top: 70px; } } } }

    &:last-child .healthy {
      border-radius: 0 0 12px 12px; } } }
