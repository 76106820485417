 :local(.biomarkerStyle) {
  margin-bottom: 25px;
  padding: 20px 25px;
  padding-bottom: 0;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  .personal-value-container, .range {
    display: inline-block;
    .ranges-box {
      text-transform: none; } }
  .ant-divider-vertical {
    height: auto;
    margin: 20px 20px 40px; }
  .personal-value-container {
    min-height: 190px;
    padding: 20px 0;
    width: 200px;
    align-self: center;
    .summary {
      font-size: 16px;
      color: inheirt;
      opacity: .8;
      &:first-letter {
        text-transform: uppercase; } }
    .name {
      font-weight: bold;
      line-height: 17px;
      font-size: 16px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      color: #4C6072;
      padding-bottom: 20px; }
    .value {
      margin: 5px 0;
      color: #75879D;

      &>span {
        line-height: 17px;
        font-size: 12px;
        letter-spacing: 0.006em;
        text-transform: uppercase;
        color: #75879D; } }
    .date {
      font-weight: bold;
      line-height: 29px;
      font-size: 12px;
      color: #75879D;
      span, img {
        vertical-align: middle;
        margin-right: 8px; } } }
  .range {
    // width: 54%
    // vertical-align: top
    padding-bottom: 10px;
    width: 200px;
    padding-right: 30px;
    align-self: center;
    .ant-switch {
      margin: 15px 0; }
    &>div {
      // display: flex
      // height: 95px
      align-items: center;
      justify-content: space-between;
      padding: 0 18px;
      color: rgba(76,96,114,.8);
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.366667px;
      text-transform: capitalize;
      padding-bottom: 10px;
      .ranges {
        font-size: 16px;
        opacity: 0.8; }
      &:first-child {
        border-bottom: 1px solid #EAEDF3; }
      &:first-child:last-child {
        border: none; }
 }      //   height: 190px
    .range-box {
      font-size: 14px;
      margin-bottom: 13px; } }
  .chat {
    margin-bottom: 20px;
    // margin-left: 50px
    // background: gray
    flex: auto; } }

 :local(.metricStyle) {
  &.metric-container {
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 265px;
    border: 1px solid #EAEDF3;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    overflow: hidden;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    &>div {
      padding: 0 15px; }
    .top-tip {
      color: #ffffff !important;
      text-align: right;
      text-transform: uppercase;
      font-size: 12px;
      height: 20px;
      line-height: 20px; }
    .name {
      color: #297FCA;
      opacity: 0.8;
      line-height: 26px;
      margin-top: 10px;
      font-family: Gilroy-Bold; }
    .summary {
      font-size: 14px;
      color: #4C6072;
      opacity: 0.8;
      letter-spacing: 0.3px;
      margin-top: 5px;
      font-family: Heebo; }
    .personal-value {
      font-size: 18px;
      color: #4C6072;
      margin: 10px 0;
      font-family: Gilroy-Bold;
      font-weight: bold;
      .unit {
        font-family: Gilroy;
        font-size: 12px;
        margin-left: 5px; } } } }
