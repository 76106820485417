 :local(.profileStyle) {
  position: relative;
  font-family: Gilroy;
  letter-spacing: 0.3px;
  .type-list {
    display: flex;
    padding: 0;
    list-style: none;
    text-transform: uppercase;
    color: #9DAABA;
    letter-spacing: 0.8px;
    font-size: 13px;
    li {
      margin-right: 32px;
      cursor: pointer;
      &.active {
        color: #264382;
        border-bottom: 3px solid #264382; } } }

  .section-title {
    font-weight: 600;
    font-size: 24px;
    color: #383E48;
    line-height: 150%;
    padding: 0; }

  .title {
    font-size: 36px;
    font-weight: 500;
    margin-top: -10px;
    // letter-spacing: 0.3px
    .avatar-container {
      width: 72px;
      height: 72px;
      border-radius: 100%;
      background: rgba(38,67,130,0.1);
      margin-right: 17px;
      img {
        width: 72px;
        height: 72px; } }
    .challenge-link {
      font-size: 17px;
      color: #2B4B8F;
      img {
        margin-right: 11px; }
      a {
        color: #2B4B8F;
        text-decoration: underline; } } }
  h3 {
    font-size: 18px;
    line-height: 150%;
    color: #383E48; }
  .info-content {
    display: flex;
    justify-content: space-between;
    .info-card {
      width: 49%;
      padding: 24px;
      margin-bottom: 20px;
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #DEE2EC;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
      h3 {
        margin-bottom: 20px;
        button {
          float: right;
          width: 76px; } } }
    .personal-info-list {
      list-style: none;
      padding: 0;
      li {
        margin-bottom: 18px;
        font-size: 14px;
        color: #6D7D8C;
        font-weight: 500;
        span {
          display: inline-block;
          color: #44433D;
          width: 150px; } } } }

  .overview {
    // display: flex
    // justify-content: space-between
    .divider {
      border-bottom: 1px solid rgba(0,0,0,.1);
      margin: 40px 0;
      span {
        padding-bottom: 3px;
        margin-top: -2px;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 2.3px;
        text-transform: uppercase;
        color: #264382;
        border-bottom: 3px solid #264382;
        font-family: Gilroy; } }
    .section {
      background: #ffffff;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      border: 1px solid #DEE2EC;
      padding: 24px;
      margin-bottom: 16px;
      width: 49%;
      font-size: 12px;
      color: #70808E;
      display: inline-block;
      vertical-align: top;
      &:first-child {
        margin-right: 2%; }
      .switch-month {
        width: 152px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: right;
        padding: 0 17px;
        border: 1px solid #DEE2EC;
        border-radius: 12px;
        font-size: 14px;
        color: #52606C;
        font-weight: 600;
        .anticon {
          color: #A4B9D2;
          cursor: pointer; } }
      .edit-focus {
        float: right;
        cursor: pointer;
        color: #264382;
        font-size: 14px; }
      .aggregation {
        padding: 0;
        list-style: none;
        margin-top: 45px;
        display: flex;
        justify-content: space-between;
        li {
          vertical-align: top;
          border-right: 1px solid #E6E6E6;
          padding: 0 40px;
          &:first-child {
            padding-left: 0; }
          &:last-child {
            padding-right: 0;
            border-right: none; }
          &>div {
            width: 291px; }
          .item {
            font-weight: bold;
            font-size: 14px;
            color: #383E48;
            display: flex;
            justify-content: space-between;
            span:first-child {
              font-size: 18px;
              &.achieved {
                color: #44433D;
                opacity: .5;
                font-size: 14px;
                margin-right: 5px; } } }
          .ant-progress-success-bg, .ant-progress-bg {
            background-color: #7BAD2D; } } } } } }

 :local(.deleteClientStyle) {
  .ant-modal-content {
    border-radius: 12px; }
  .ant-modal-confirm-body {
    font-family: 'Gilroy';
    .ant-modal-confirm-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #262626; }
    .ant-modal-confirm-content {
      margin-top: 14px;
      font-weight: 500;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.3px;
      color: #60656D;
      p {
        margin-bottom: 0px; }
      ul {
        padding-inline-start: 22px; } } }
  .ant-modal-confirm-btns {
    .ant-btn {
      min-width: 80px;
      height: 40px;
      font-family: 'Lato';
      font-size: 13px; } } }
