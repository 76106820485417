 :local(.summaryEmailStyle) {
  padding-top: 40px;
  padding-bottom: 60px;
  padding-right: 64px;
  .title {
    font-size: 36px;
    font-weight: 500; }
  &.backup {
    .information-container.Opened {
      border: 1px solid #70808E; } } }
// 　.email-content
//     background: red !important
//     .main-container
//       width: 616px
//       padding-right: 24px
