 :local(.commonToolsStyle) {
  .left, .right {
    display: inline-block;
    vertical-align: top;
    text-align: left; }
  .left {
    width: 280px;
    margin-right: 26px;
    &>div {
      height: auto; } }
  .right {
    overflow: auto;
    // height: calc(100vh - 71px)
    //
    width: calc(100% - 280px - 26px);
    min-height: calc(100vh - 71px);
    padding-right: 44px;
    padding-left: 38px;
    padding-top: 45px;
    margin-top: -37px;
    background: #F4F6F8;
    box-shadow: -1px 4px 20px rgba(0, 0, 0, 0.03);
    .page-title {
      font-family: Gilroy;
      line-height: 120%;
      font-size: 32px;
      color: #4C6072;
      padding-bottom: 20px; } } }
