 :local(.multiTagInputStyle) {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  min-height: 45px;
  padding: 4px 11px;
  display: flex;
  flex-wrap: wrap;
  &.disabled {
    opacity: .6;
    cursor: not-allowed; }
  &.has-error {
    border-color: #f5222d;
    box-sizing: content-box;
    .ant-input:focus {
      border-color: transparent;
      box-shadow: none; } }
  .ant-tag {
    border-radius: 33px;
    background: #E8ECF0;
    padding: 0 10px;
    margin-top: 10px; }
  .ant-input {
    border: none;
    height: auto !important;
    padding: 0;
    margin-top: 10px;
    &:focus, &:hover {
      border-color: transparent !important;
      box-shadow: none !important; } } }

 :local(.selectOptionContainer) {
  input {
    height: auto !important; } }
