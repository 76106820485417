 :local(.richTextStyle) {
  display: flex;
  // width: 268px
  border: 1px solid #E4EAEF;
  border-radius: 8px;
  padding: 14px 20px;
  font-size: 12px;
  color: #70808E;
  position: relative;
  line-height: 1.7;
  &.Draft {
    border: 2px dashed #264382; }
  .anticon {
    margin-right: 16px;
    margin-top: 3px;
    font-size: 16px;
    & + div {
      width: calc(100% - 32px);
      white-space: normal;
      word-break: break-word; } }
  h4 {
    font-size: 14px;
    color: #383E48; }
  .null-state {
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    color: #264382;
    font-size: 14px;
    margin-left: -32px;
    width: calc(100% + 32px); }
  .editor-container {
    margin-left: -32px;
    width: calc(100% + 32px);
    .buttons {
      margin-top: 10px; }
    .buttons {
      text-align: right;
      button {
        // margin-left: 8px
        font-family: 'Gilroy-bold';
        border: none;
        box-shadow: none;
        // margin-top: -10px
        font-weight: bold; } } }
  // &:first-child:last-child
  //   width: 100%
  &:hover .opr {
    display: flex; }
  .opr {
    display: none;
    font-size: 32px;
    justify-content: flex-end;
    align-items: baseline;
    float: right;
    position: absolute;
    background-image: linear-gradient(to top right, rgba(255,255,255,.5) 90%, white 10%);
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    top: 0;
    left: 0;
    padding: 20px 16px;
    .opr-button {
      margin-left: 8px; }
    .anticon {
      font-size: 32px;
      margin-right: 0; } } }
