.facilities-expert-modal {
  width: 745px !important;
  .ant-modal-body {
    padding-top: 43px;
    .title {
      text-align: left;
      font-family: Gilroy;
      font-size: 32px;
      line-height: 29px; }
    .ant-divider.ant-divider-with-text-left {
      font-family: Gilroy;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: #979797;
      &:before {
        width: 0%; } }
    .facility-name {
      font-family: Gilroy;
      padding-left: 10px;
      color: #323F4A;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.3px;
      span {
        margin-right: 15px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: #979797; } }
    .available-list {
      padding: 0;
      list-style: none;
      margin: 0 -5px;
      li {
        display: inline-block;
        margin: 8px;
        padding: 10px 15px;
        border-radius: 4px;
        background: #EFF0F1;
        font-family: Gilroy;
        font-size: 14px;
        color: #4C6072;
        cursor: pointer;
        &.selected {
          background: #3561C0;
          color: #ffffff; } } } }
  .ant-modal-footer {
    border: none;
    padding-bottom: 34px; } }

.experts-modal {
  .ant-checkbox-wrapper {
    display: flex;
    margin: 10px 0;
    align-items: center; }
  .experts-list {
    padding-top: 20px;
    .avatar, .name {
      display: inline-block;
      vertical-align: middle; }
    .avatar {
      width: 32px;
      height: 32px;
      background: rgba(0,0,0,.1);
      border-radius: 100%;
      margin-right: 16px;
      overflow: hidden;
      img {
        max-width: 100%; } } } }

 :local(.Style) {
  font-family: Gilroy;
  letter-spacing: 0.3px;
  .time-area {
    font-size: 12px;
    text-transform: capitalize;
    color: #4C6072;
    opacity: 0.8;
    padding: 10px 0; }
  .address {
    font-size: 14px;
    color: #52606C;
    padding: 20px 0;
    .anticon {
      color: #264382;
      margin-right: 10px; } }
  .facility {
    background: #ffffff;
    border: 1px solid #E8ECF0;
    border-radius: 4px;
    padding: 15px;
    padding-bottom: 25px;
    margin: 20px 0;
    .title {
      font-size: 18px;
      color: #52606C;
      display: flex;
      justify-content: space-between;
      .arrow {
        cursor: pointer; } }

    .ant-divider-with-text-left {
      color: #BAC3CE;
      font-size: 12px;
      margin: 0 -10px;
      &:before {
        width: 0 !important; } }
    .services {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      .list {
        padding: 0;
        list-style: none;
        li {
          display: inline-block;
          padding: 5px 10px;
          text-align: center;
          min-width: 100px;
          margin-right: 10px;
          background: #E8ECF0;
          border-radius: 33px;
          font-size: 12px;
          color: #323F4A;
          &:last-child {
            margin-right: 0; } } }
      .others {
        color: #264382;
        padding: 5px; } } }
  .service {
    .service-empty {
      font-family: Lato;
      font-weight: bold;
      font-size: 14px;
      line-height: 29px;
      text-transform: capitalize;
      color: #323F4A;
      letter-spacing: 0.3px;
      margin-top: 17px; }
    .facility-info {
      margin-top: 30px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #BAC3CE;
      .time-area, .address {
        display: inline-block;
        margin-right: 50px; }
      .opr {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 29px;
        height: 61px;
        color: #264382;
        font-weight: bold;
        cursor: pointer;
        .del {
          color: #FF0000;
          margin-right: 30px; } } }

    .service-item {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border-top: 10px solid #BAC3CE;
      padding: 10px 20px;
      margin-top: 20px;
      padding-bottom: 20px;
      .title {
        line-height: 29px;
        text-transform: capitalize;
        color: #52606C;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        span {
          padding: 10px 0;
          font-size: 14px; }
        .anticon {
          color: #264382;
          vertical-align: bottom;
          font-size: 18px;
          margin: 0 7px;
          cursor: pointer; } }
      .duration {
        color: #BAC3CE;
        font-size: 12px;
        margin-bottom: 20px; }
      .expert {
        display: flex;
        margin: 10px 0;
        .avatar {
          width: 32px;
          height: 32px;
          background: rgba(0,0,0,.1);
          border-radius: 100%;
          margin-right: 16px;
          overflow: hidden;
          img {
            max-width: 100%; } }
        .name {
          font-size: 12px;
          line-height: 29px;
          text-transform: capitalize;
          color: #000000; } } } } }

