 :local(.activityStyle) {
  font-family: Gilroy;
  // margin: 0 -22px
  // padding: 0 22px
  .peroid-bar {
    margin: 0 -22px;
    padding: 2px 22px 24px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0,0,0,.1);
    .select-peroid {
      list-style: none;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      li {
        margin-right: 32px;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        color: #9DAABA;
        letter-spacing: 0.8px;
        cursor: pointer;
        span {
          padding-bottom: 5px; } }
      span.active {
        color: #264382;
        border-bottom: 3px solid #264382; } }

    .switch-date-range {
      height: 40px;
      margin-top: 0; } }
  .rpe-value-update-info-box {
    margin-top: 16px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Gilroy;
    font-size: 12px;
    .info-label {
      color: #70808E; }
    .undo {
      color: #2B4B8F;
      cursor: pointer; } }
  .top-bottom-line {
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1); }

  /* the same as zone type ready to remove -- start */

  .activity-toggle-container {
    margin-top: 22px;
    .ant-tabs-bar {
      border: none; }
    .ant-tabs-nav {
      .ant-tabs-tab {
        margin-right: 18px;
        border: none;
        background: transparent;
        padding: 10px 20px;
        border-radius: 12px;
        color: #BAC3CE;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.3px;
        cursor: pointer; }
      .ant-tabs-tab-active {
        background: rgba(38,67,130,.1);
        color: #264382; }
      .ant-tabs-ink-bar {
        background-color: transparent; } }
    .activity-tab-description {
      color: #80848A;
      padding-bottom: 20px;
      span {
        text-transform: lowercase; } } }

  /* the same as zone type ready to remove -- end */

  .location-date-info {
    padding: 12px 0;
    .location-info {
      height: 21px;
      font-family: Gilroy;
      font-size: 22px;
      line-height: 150%;
      color: #000; }
    .date-info {
      margin-top: 12px;
      height: 18px;
      font-family: Gilroy;
      font-size: 12px;
      line-height: 150%;
      color: #60656D; } }
  .activity-section-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: #383E48; }
  .activity-type-text-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 24px;
    .activity-text-title {
      font-family: Gilroy;
      font-size: 12px;
      line-height: 150%;
      color: #60656D; }

    .activity-text-content {
      font-family: Gilroy-Bold;
      font-size: 18px;
      line-height: 150%;
      color: #383E48;
      &.source {
        text-transform: capitalize; }
      &.day-summary {
        // display: flex
        font-size: 16px;
        font-weight: 400;
        .circle {
          width: 10px;
          height: 10px;
          display: inline-block;
          border-radius: 100%;
          margin-right: 10px;
          margin-left: -20px;
          &.Resistance {
            background: #383E48; }
          &.Aerobic {
            background: #FF6B00; }
          &.Activity {
            background: #BCCA70; }
          &.Movement {
            background: #C29E7C; } } } } }

  .activity-chart-container {
    display: flex;
    padding-top: 25px;
    font-family: Heebo;
    font-size: 12px;
    line-height: 150%;
    color: #60656D;
    .activity-heart-rate-container {
      width: 334px; }
    .activity-heart-rate-zone-container {
      width: 334px; } }
  .heart-rate-title {
    font-family: 'Gilroy-bold';
    font-weight: 600;
    font-size: 14px;
    .anticon {
      color: #2B4B8F;
      margin-left: 8px; }
    & + .activity-heart-rate-zone {
      margin-top: 15px; } }
  .activity-heart-rate-zone-container {
    .activity-heart-rate-pregress {
      margin-bottom: 24px;
      padding-bottom: 24px;
      font-size: 12px;
      .progress-title {
        margin-top: 15px;
        color: #383E48;
        font-weight: bold; }
      .moderate .ant-progress-bg {
        background: #FFB570; }
      .intense .ant-progress-bg {
        background: #FF7759; }
      .info {
        color: #A6B0B9; } }
    .activity-heart-rate-zone {
      margin-bottom: 15px;
      display: flex;
      // justify-content: space-between
      height: 48px;

      .activity-heart-rate-info-container {
        display: flex;
        flex-direction: column;
        // align-items: flex-end
        padding-top: 5px;
        margin-left: 12px;
        .time {
          font-family: Gilroy-Bold;
          font-size: 12px;
          line-height: 150%;
          color: #383E48; }
        .sub-content {
          font-size: 10px;
          line-height: 150%;
          color: #A6B0B9;
 } } } }          // text-align: right

  .hrz-message {
    border: 1px solid #E4EAEF;
    border-radius: 8px;
    padding: 16px;
    .title {
      font-family: 'Gilroy-bold';
      font-size: 14px;
      line-height: 22px;
      color: #383E48;
      margin-bottom: 8px; }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      letter-spacing: 0.3;
      li {
        display: flex;
        font-size: 12px;
        color: #70808E;
        align-items: center;
        line-height: 17px;
        margin-bottom: 5px;
        span[class^='message-state'] {
          width: 5px;
          height: 5px;
          margin-right: 13px;
          border-radius: 100%; }
        .message-state-1 {
          background: #7BAD2D; }
        .message-state-0 {
          background: #FF6B00; } } } }

  .activity-trend {
    height: calc(100% - 42px);
    overflow: hidden;
    .VictoryContainer {
      display: flex;
      svg {
        height: 100% !important; } } }

  .chart-title {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #60656D;
    font-weight: 500;
    line-height: 150%;
    span {
      margin-right: 30px; }
    span.circle {
      margin-right: 10px;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px; } }
  .input-null-status {
    text-align: center;
    padding-top: 30px;
    h3 {
      font-size: 18px;
      margin-top: 30px; } }

  .rpe-container {
    padding-top: 24px;
    letter-spacing: 0.3px;
    .title {
      font-family: Gilroy-bold;
      font-size: 17px;
      color: #383E48;
      margin: 0;
      display: block;
      .anticon {
        color: #2B4B8F;
        margin-left: 8px;
        cursor: pointer; } }
    .explain-info {
      font-size: 12px;
      color: #264382;
      background: rgba(38,67,130,.15);
      padding: 12px 24px;
      border-radius: 12px;
      margin: 16px 0; }
    .manual-input {
      padding-bottom: 24px;
      .ant-checkbox-wrapper {
        margin-right: 12px; } }

    .score, .explain-text {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #60656D;
      &>.bold {
        margin-left: 0; }
      .bold {
        font-family: Gilroy;
        margin-left: 9px; } }
    .explain-text {
      padding-top: 10px; }
    .extra-info {
      margin-top: 17px;
      font-size: 12px;
      line-height: 17px;
      color: #70808E;
      padding: 12px 15px;
      border: 1px solid #E4EAEF;
      box-sizing: border-box;
      border-radius: 8px; }
    .rate-level {
      font-size: 14px;
      color: #383E48; }
    .slider-container {
      padding: 17px 0;
      position: relative;
      .slider-bg {
        position: absolute;
        top: 0;
        width: calc(100% - 12px);
        margin: 40px 6px;
        padding: 4px 0;
        margin-bottom: 10px;
        span {
          height: 3px;
          display: inline-block;
          width: 10%;
          background: rgba(196,196,196,.4);
          border-right: 3px solid #2B4B8F; }
        &.disabled span {
          border-color: rgba(43,75,143,.4); } }
      .ant-slider {
        position: relative;
        z-index: 1;
        .ant-slider-rail {
          background-color: transparent; }
        .ant-slider-track {
          background-color: #2B4B8F; }
        .ant-slider-handle {
          background-color: #2B4B8F;
          border-color: #ffffff !important;
          border-width: 4px;
          width: 20px;
          height: 20px;
          margin-top: -8px;
          box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2); }
        &.ant-slider-disabled {
          .ant-slider-track {
            background-color: #C7CFDF !important; }
          .ant-slider-handle {
            background-color: #C7CFDF; } } } } } }

 :local(.zoneTypeStyle) {
  // display: flex
  margin-top: 22px;
  .ant-tabs-bar {
    border: none; }
  .ant-tabs-nav {
    .ant-tabs-tab {
      margin-right: 18px;
      border: none;
      background: transparent;
      padding: 10px 20px;
      border-radius: 12px;
      color: #BAC3CE;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.3px;
      cursor: pointer; }
    .ant-tabs-tab-active {
      background: rgba(38,67,130,.1);
      color: #264382; }
    .ant-tabs-ink-bar {
      background-color: transparent; } }
  .activity-tab-description {
    color: #80848A;
    padding-bottom: 20px;
    span {
      text-transform: lowercase; } } }



