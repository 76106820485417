 :local(.programStyle) {
  font-family: Gilroy;
  padding-bottom: 100px;
  footer {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    padding: 17px 48px;
    height: 74px;
    width: 100%;
    max-width: 1440px;
    background: #FCFDFD;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #798187;
    button {
      height: 40px;
      margin-left: 16px; } }
  .top-opr {
    text-align: right;
    button {
      height: 40px; } }
  .section {
    padding-bottom: 30px;
    .program-title {
      padding-bottom: 16px;
      font-size: 24px;
      line-height: 150%;
      letter-spacing: 0.3px;
      color: #383E48; }
    .active-title {
      font-size: 24px; }
    .active-box {
      border-color: #7FB800;
      margin-right: 90px; } }
  .pipe-chart {
    display: flex;
    padding: 20px 0;
    align-items: center;
    &:last-child {
      padding-right: 50px; }
    .ant-divider-vertical {
      height: 84px;
      margin: auto 33px; }
    .description {
      margin-left: 24px;
      .progress {
        color: #0D2350;
        font-size: 24px;
        line-height: 38px;
        white-space: nowrap; }
      .text {
        color: #8691A7;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.3px; } }
    .ant-progress-circle-path {
      // stroke: #FF6B00 !important
      stroke: #0D2350 !important; } }

  .detail-top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .title {
      font-size: 36px;
      font-family: Gilroy;
      color: #4C6072;
      .ant-tag {
        border-radius: 15px;
        padding: 0 10px;
        margin-left: 10px;
        vertical-align: middle;
        text-transform: uppercase;
        font-size: 9px; }
      &:first-letter {
        text-transform: uppercase; }
      .date-range {
        display: block;
        font-size: 14px;
        color: #798187; } }
    .top-opr {
      display: flex;
      justify-content: space-between;
      margin: 0;
      height: 40px;
      .switch-date-range {
        margin-top: 0; }
      button {
        height: 40px;
        margin-left: 16px;
        // &:last-child
 } } }        //   background: #ffffff !important
  .right-opr {
    text-align: right;
    // padding-top: 22px
    button {
      height: 40px;
      &:first-child {
        margin-right: 16px; } } }
  // .detail-container
  //   display: flex
  .detail-description {
    height: calc(100% - 30px);
    margin-top: 30px;
    padding: 22px 24px;
    border: 1px solid #DEE2EC;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    &.select-expert {
      text-align: center;
      min-width: 250px;
      &.draft {
        box-shadow: 4px 4px 20px rgba(255, 0, 0, 0.30); } }
    h4 {
      font-family: 'Gilroy-Bold';
      margin-bottom: 14px;
      font-size: 14px;
      color: #383E48;
      letter-spacing: 0.3px;
      img {
        margin-right: 15px; }
      .anticon {
        color: #FF6B00;
        margin-right: 13px; } }
    pre {
      font-family: Gilroy;
      color: #70808E;
      font-size: 14px;
      margin-bottom: 0;
      white-space: pre-wrap; }
    .ant-btn {
      margin-top: 12px;
      width: 100%;
      border-width: 2px;
      font-size: 14px;
      font-family: 'Gilroy-Bold'; } }

  .date-top-bar {
    display: flex;
    justify-content: flex-end;
    margin-top: -110px;
    margin-bottom: 55px;
    .switch-date-range {
      margin-top: 0; } }
  .section-top {
    margin: 37px 0 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 24px;
      line-height: 150%; }
    .exercise {
      color: #FF6B00; }
    .nutrition {
      color: #7FB800; }
    .rejuvenation {
      color: #5C00D1; }
    .right-opr {
      display: flex;
      align-items: center;
      .program-container {
        margin-right: 24px;
        background: rgba(38, 67, 130, 0.05);
        border-radius: 8px;
        padding: 12px 20px;
        color: #383E48;
        .ant-divider-vertical {
          height: 1.5em;
          background: rgba(0,0,0,.1);
          margin: 0 15px; }
        .ant-progress-inner {
          margin-right: 12px; }
        .ant-progress-circle-trail {
          stroke: rgba(0,0,0,.1) !important; }
        strong {
          font-family: Gilroy-Bold;
          margin-right: 5px; } } } }



  .goal-detail-bg {
    margin-left: -275px;
    .modal-detail-container .header>.title .ant-typography {
      display: inline-block; } } }

 :local(.programCompletedInfoBoxStyle) {
  font-size: 14px;
  line-height: 1.5;
  margin-right: 24px;
  background: rgba(38, 67, 130, 0.05);
  border-radius: 8px;
  padding: 12px 20px;
  color: #383E48;
  display: flex;
  justify-content: space-between;
  .ant-divider-vertical {
    height: auto;
    background: rgba(0,0,0,.1);
    margin: 0 15px; }

  .ant-progress-inner {
    margin-right: 12px; }
  .ant-progress-circle-trail {
    stroke: rgba(0,0,0,.1) !important; }
  strong {
    font-family: Gilroy-Bold;
    margin-right: 5px; }
  .exercise-completed-container {
    display: inline-flex;
    align-items: center;
    div:last-child {
      margin-left: 16px; } }
  &>div>div {
    margin: 5px 0; }
  .align-bottom {
    align-self: flex-end; }
  .dot {
    padding-left: 20px;
    &>div:first-child:before {
      content: '';
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 8px;
      margin-left: -18px;
      margin-right: 10px; }
    .dot-red:before {
      background: #FF6F03; }
    .dot-green:before {
      background: #7BAD2D; } }
  .text-unit {
    color: #70808E; } }

