 :local(.headerStyle) {
  font-family: Lato;
  color: #3F4752;
  &.signle {
    margin-bottom: 36px; }
  .phs-header-content {
    background: #FAFCFD;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    // height: 60px
    position: relative;
    z-index: 99;
    .main-content {
      padding: 12px 25px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .polygon-avatar {
      overflow: hidden; }
    .avatar {
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
      width: 40px;
      height: 40px;
      border-radius: 100%; }
    .phs-text {
      font-weight: 900;
      line-height: 27px;
      font-size: 16px;
      letter-spacing: 0.366667px;
      img {
        width: 145px;
        border-radius: 4px; } }
    .login-user {
      display: flex;
      align-items: center; }
    .role-name {
      letter-spacing: 0.366667px;
      text-align: right;
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px; } }
  .sub-header {
    position: relative;
    background: #ffffff;
    margin-bottom: 30px;
    .ant-tabs {
      .ant-tabs-bar {
        margin: 0; }
      .ant-tabs-nav-container {
        padding: 0 20px;
        .ant-tabs-ink-bar {
          background-color: #3A64C1; } }
      .ant-tabs-tab {
        // margin-left: 3px
        border: none;
        // &:first-child
        //   margin-left: 15px
        .tab-title {
          text-transform: uppercase;
          font-size: 14px;
          color: #3F4651;
          font-weight: bold;
          img {
            width: 23px;
            margin-right: 5px; } } }
      .ant-tabs-top-content {
        background: #FAFCFD;
        padding: 12px 25px; } }
    .ant-breadcrumb-link {
      text-transform: uppercase;
      font-size: 12px; }
    .header-opr {
      position: absolute;
      top: 10px;
      right: 22px;
      .header-opr-icon {
        margin: 0 15px;
        cursor: pointer; } } } }

