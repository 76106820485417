 :local(.clientStyle) {
  /* rewrite alert style */
  // padding-bottom: 60px
  .ant-alert.ant-alert-warning {
    background: #FF9E2E;
    color: #ffffff;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-family: Gilroy;
    .ant-alert-icon {
      color: #ffffff;
      font-size: 22px; }

    .ant-alert-message {
      color: #ffffff;
      font-size: 16px;
      &:first-letter {
        text-transform: uppercase; } }
    .ant-alert-description {
      font-size: 13px; } }
  .notes-modal-bg {
    margin-left: 0;
    margin-top: -30px; }
  .client-content {
    position: relative;
    // min-height: calc(100vh - 150px)
 }    // background: red
  .section-title {
    padding: 0 30px;
    margin: 0;
    height: 58px;
    line-height: 29px;
    font-size: 32px;
    color: #4C6072; }
  .male-vs-female {
    padding: 0 30px;
    font-size: 14px;
    color: #9C9C9C;
    letter-spacing: 0.3px;
    text-align: right;
    text-transform: uppercase;
    margin-top: 15px;
    position: absolute;
    width: 100%;
    span {
      margin: 0 15px; } }
  .list-section {
    margin: 0 30px;
    margin-bottom: 20px;
    border-radius: 4px;
    min-height: calc(100vh - 120px - 100px);
    table {
      border: 1px solid #EAEDF3; }
    .link-group {
      min-width: 175px;
      display: flex;
      justify-content: flex-end; }
    .ant-table-tbody {
      background: #ffffff;
      .devices-list {
        max-width: 110px;
        & > img {
          margin: 10px;
          width: 32px; } } }
    .ant-table-thead {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
      &> tr> th {
        background: rgba(135,152,173, .1);
        border: none;
        min-height: 60px;
        font-size: 12px;
        color: #9C9C9C;
        &:last-child {
          text-align: right;
          padding-right: 24px; } } }
    .ant-table-tbody > tr > td:last-child {
      text-align: right;
      padding-right: 24px; }
    // checkbox style
    .medical-status {
      .ant-checkbox-indeterminate .ant-checkbox-inner::after,
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #EA5400;
        border-color: #EA5400; }
      .ant-checkbox-inner::after {
        left: 25%; } }
    .polygon-avatar {
      width: 46px;
      height: 46px; }
    .avatar, .empty-avatar {
      display: inline-block;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(0, 0, 0, .05); } }
  .detail-content {
    text-align: center;
    .left, .right {
      display: inline-block;
      vertical-align: top;
      text-align: left; }
    .left {
      width: 270px;
      position: sticky;
      top: 0;
      z-index: 2;
      &.close {
        position: absolute;
        left: 0;
        width: 0px; }
      .swift-left-btn {
        position: absolute;
        top: 0;
        right: -46px;
        &.close {
          left: 0; } } }
    .close {
      > div:first-child {
        padding-right: 0px; } }
    .right {
      width: calc(100% - 250px - 26px);
      min-height: calc(100vh - 71px - 45px);
      // min-height: 100vh
      margin-top: -30px;
      // margin-bottom: -60px
      padding-bottom: 60px;
      padding-top: 30px;
      padding-left: 60px;
      padding-right: 60px;
      position: relative;
      background: #F4F6F8;
      box-shadow: -1px 4px 20px rgba(0, 0, 0, 0.03);
      &.detail-right {
        width: 100%;
        padding: 0 25px;
        margin-left: 0;
        &.no-padding {
          padding: 0; } }
      .mobile-menu {
        display: none; }
      .page-title {
        font-family: Gilroy;
        line-height: 120%;
        font-size: 32px;
        // color: #383E48
        color: #4C6072;
        padding-bottom: 20px;
        letter-spacing: 0.3px;
        padding-top: 20px;
        img {
          cursor: pointer; }
        .date {
          padding: 2px 15px;
          margin-left: 10px;
          display: inline-block;
          vertical-align: middle;
          background: rgba(117,135,157,.1);
          border: 1px solid #1D3952;
          border-radius: 5px;
          color: #75879D;
          font-size: 12px;
          line-height: 29px;
          font-weight: bold;
          img {
            margin-right: 5px;
            vertical-align: middle; } } } }

    .date-set-container {} }


  /* start of metric detail */
  .client-biomarker-container {
    display: flex;
    .biomarker-info {
      background: #ffffff;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      display: inline-block;
      width: calc(558px + 45px);
      margin-left: 20px;
      position: relative;
      min-height: 340px;
      .tabs {
        display: flex;
        &>ul {
          list-style: none;
          padding: 0;
          text-align: center;
          border-bottom:  1px solid #EAEDF3;
          width: 100%;
          li {
            display: inline-block;
            // width: 33.3%
            width: 50%;
            border-right: 1px solid #EAEDF3;
            background: #FAFAFA;
            padding: 31px 0;
            font-size: 12px;
            font-weight: bold;
            &:last-child {
              border: none; }
            &.active {
              color: #3F4752;
              background: #ffffff; } } } }
      .information-container {
        padding: 20px;
        font-family: Heebo;
        .information {
          position: absolute;
          width: calc(100% - 40px);
          height: calc(100% - 104px - 40px);
          overflow-y: scroll; } } }
    .ranges-info {
      background: #ffffff;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      // overflow: hidden
      display: inline-block;
      width: 767px;
      .header {
        padding: 15px 20px;
        height: 88px;
        border-bottom:  1px solid #EAEDF3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        line-height: 29px;
        h1 {
          margin: 0;
          font-size: 18px; }
        .date {
          font-size: 12px;
          line-height: 29px;
          color: #B1B1B1;
          img {
            margin-right: 10px; } }
        .show-options {
          text-transform: uppercase;
          text-align: right;
          margin-top: -45px; } }
      .ranges-area-container {
        width: 100%;
        min-height: 100px;
        display: inline-block;
        padding: 20px;
        vertical-align: top;
        padding-bottom: 0; }
      .ranges-area-container {
        .ranges-area:last-child {
          margin-top: 20px; }
        .options-container {
          display: flex;
          justify-content: space-between;
          div>.label {
            font-size: 12px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            color: #4C6072; }
          &>div {
            font-size: 24px;
            line-height: 36px;
            text-transform: capitalize; }
          .value {
            text-align: right; } } } } }
  .trend-graph {
    background: #ffffff;
    margin-top: 20px;
    border-radius: 4px;
    padding-bottom: 20px;
    h1 {
      margin: 0;
      padding: 20px;
      border-bottom: 1px solid #EAEDF3;
      font-size: 18px;
      line-height: 29px; }
    .VictoryContainer {
      margin: 0 20px; } }

  .com-report {
    min-height: 500px;
    // .anticon
    //   margin-right: 10px
    .phs-section-header {
      display: flex;
      justify-content: space-between;
      h1 {
        color: #4C6072;
        letter-spacing: 0.3px;
        line-height: 29px;
        font-size: 24px;
        font-weight: normal; } }
    .report-status {
      font-weight: 600;
      font-size: 14px;
      line-height: 27px;
      letter-spacing: 0.366667px;
      text-transform: uppercase;
      .editing {
        color: #297FCA; }
      .published {
         color: #05B800; } } }

  @media screen and (max-width: 768px) {
    &.permanent-link {
      overflow-x: hidden;
      overflow-y: scroll;
      position: absolute;
      z-index: 1;
      width: 100%;
      top: 0;
      bottom: 0;
      -webkit-overflow-scrolling: touch;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0,0,0);
      width: 100vw;
      max-width: 750px;
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);
      .header {
        text-align: right;
        background: #ffffff;
        padding: 20px 0;
        .welcome, .polygon-avatar {
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;
          .name {
            font-weight: bold; } }
        .menu-icon {
          float: left;
          margin: 20px; } }
      .menu {
        border-top: 1px solid rgba(0,0,0,.1);
        .menu-item {
          background: #ffffff;
          font-size: 14px;
          color: #3F4752;
          line-height: 32px;
          padding: 15px 30px;
          &.active {
            background: rgba(135,152,173,.1); } } }
      .root-category {
        padding: 20px 0; }
      .sub-category-container {
        .description {
 }          // margin-left: 0 !importent
        .biomarker-container {
          margin: 20px 25px 0 0;
          .personal-value-container {
            border: none;
            border-bottom: 1px solid #EAEDF3;
            padding-bottom: 0; }
          .personal-value-container, .range {
            display: block;
            width: 100%; }
          .range > div:first-child {
            border: none; } } }
      .primary-data {
        background: #ffffff;
        padding: 25px 30px;
        border-radius: 4px;
        margin: 20px 0;
        &>div {
          display: flex;
          justify-content: space-between;
          line-height: 27px;
          font-size: 14px;
          letter-spacing: 0.366667px;
          .item {
            min-width: 130px;
            color: #9EA0A5; } } }
      .page-title {
        padding: 0 25px; }
      .mobile-show {
        .trend-graph {
          overflow-x: scroll;
          &>div {
           min-width: 1213px; } } }
      .ranges-area-container, .trend-graph {
        width: 100%; }
      .biomarker-info {
        .tabs {
          display: block;
          font-family: Heebo;
          font-weight: bold;
          font-size: 12px;
          letter-spacing: 0.6px;
          color: rgba(76,96,114,0.3);
          &>ul {
            display: flex;
            padding: 0 10px;
            width: 100%;
            border-bottom: 1px solid rgba(0,0,0,.1);
            &>li {
              display: inline-block;
              // width: 33.3%
              width: 50%;
              padding: 20px 0;
              &.active {
                color: rgba(76,96,114,0.8);
                border-bottom: 2px solid #3A64C1; } } }
          .information {
            width: 100%; } } } }
    &.permanent-link.show-link, &.permanent-link {
      .client-biomarker-container {
        display: block;
        .ranges-info {
          width: 100%;
          margin-bottom: 20px;
          .header {
            display: block;
            text-align: left; }
          h1 {
            font-size: 16px; } }
        .biomarker-info {
          margin-left: 0;
          width: 100%;
          .tabs > ul {
            margin: 0;
            padding: 0; }
          .information-container  .information {
            position: zative; } } }
      .biomarker-container {
        margin-right: 0; }
      .mobile-categories-container {
        .root-category {
          // padding: 20px
          width: 100vw;
          .attachment-name {
            font-size: 18px; }
          img {
            width: 100%; } }

        .sub-category-container.permanent-category {
          display: block; } } } }
  // comprehensive report
  @media screen and (max-width: 1024px) {
    .detail-content {
      .left {
        display: none;
        .description {
          padding-bottom: 25px; } }
      .right {
        width: 100%;
        padding: 0;
        margin-left: 0;
        .mobile-menu {
          position: relative;
          z-index: 99;
          display: inline-block;
          background: #ffffff;
          border-radius: 100%;
          margin-right: 10px;
          width: 52px;
          height: 52px;
          font-size: 30px;
          line-height: 52px;
          text-align: center;
          float: left;
          margin-left: 20px;
          margin-top: 18px; }
        .page-title {
          padding: 0 25px;
          padding-top: 25px; }
        // .menu-mask
        //   content: ' '
        //   position: fixed
        //   top: 200px
        //   width: 100%
        //   // min-height: 100%
        //   height: calc(100% - 214px)
        //   background-color: rgba(0,0,0,.4)
        //   z-index: 79
        //   .foo-menu
        //     background-color: #ffffff
        //     width: 46%
        //     display: inline-block
        //     height: 100%
 } } } }        //     overflow-y: scroll

 :local(.deleteClientStyle) {
  .ant-modal-content {
    border-radius: 12px; }
  .ant-modal-confirm-body {
    font-family: 'Gilroy';
    .ant-modal-confirm-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #262626; }
    .ant-modal-confirm-content {
      margin-top: 14px;
      font-weight: 500;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.3px;
      color: #60656D;
      p {
        margin-bottom: 0px; }
      ul {
        padding-inline-start: 22px; } } }
  .ant-modal-confirm-btns {
    .ant-btn {
      min-width: 80px;
      height: 40px;
      font-family: 'Lato';
      font-size: 13px; } } }
