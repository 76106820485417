.activityTypeTextContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 24px;
  .activityTextTitle {
    font-family: Gilroy;
    font-size: 12px;
    line-height: 150%;
    color: #60656D; }

  .activityTextContent {
    font-family: Gilroy-Bold;
    font-size: 18px;
    line-height: 150%;
    color: #383E48;
    &.source {
      text-transform: capitalize; }
    &.daySummary {
      // display: flex
      // font-size: 16px
      font-weight: 400;
       :global(.circle) {
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 100%;
        margin-right: 10px;
        margin-left: -20px; }
       :global(.Resistance) {
        background: #383E48; }
       :global(.Aerobic) {
        background: #FF6B00; }
       :global(.Activity) {
        background: #BCCA70; }
       :global(.Movement) {
        background: #C29E7C; } }
    .durationSuffix {
      font-size: 12px;
      font-family: Gilroy;
      font-weight: 500;
      margin-left: 7px;
      color: gray; } } }
