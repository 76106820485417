 :local(.emailTemplateStyle) {
  .ant-select {
    text-transform: capitalize; }
  .catergory-filter {
    margin-bottom: 30px;
    .tag {
      font-size: 12px;
      color: #8D99A5;
      margin: 8px 14px 0 0; }
    .catergory-sel {
      width: 134px;
      height: 29px;
      border-radius: 4px; } }
  .template-card {
    min-height: 244px;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 2px 8px #E8ECF0;
    &.add {
      border: 2px dashed #000000;
      display: flex;
      justify-content:space-around {}
      align-items: center;
      cursor: pointer;
      color: #52606C;
      .ant-card-body {
        text-align: center;
        width: 100%;
        div {
          margin-top: 5px; } } }
    .ant-card-body {
      padding: 16px; }
    .category {
      font-size: 9px;
      line-height: 140%;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #70808E; }
    .sub-category {
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.3px;
      color: #323F4A;
      padding: 10px 0;
      text-transform: capitalize; }
    .template {
      font-size: 12px;
      color: #70808E;
      line-height: 17px; } } }
