 :local(.emailReportStyle) {
  position: relative;
  margin-top:  -30px;
  font-family: Gilroy;
  letter-spacing: 0.3px;
  min-height: calc(100vh - 145px);
  @media screen and (max-width: 1280px) {
    margin-left: -20px;
    margin-right: -30px; }
  .date-range {
    display: flex;
    justify-content: flex-end; }
  .dashboard-container {
    margin-top: 40px;
    background: #FFFFFF;
    border: 1px solid #DEE2EC;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 32px;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    .dashboard-info {
      width: 100%;
      color: #383E48;
      span {
        margin-bottom: 10px;
        color: #60656D;
        font-size: 12px;
        line-height: 150%;
        display: block;
        font-weight: normal;
        font-family: Gilroy; }
      .large-text {
        font-size: 40px;
        line-height: 124%;
        font-family: 'Gilroy-bold';
        width: 20%;
        display: inline-block; }

      .normal-text {
        font-size: 18px;
        line-height: 124%;
        font-family: 'Gilroy-bold'; }

      // .detail-info div
      //   display: inline-block
      //   width: 50%
 }      //   margin-top: 29px
    .chart {
      width: 56%;
      // background: gray
 } }      // height: 300px
  .chart-tips {
    text-align: right;
    font-size: 12px;
    padding-right: 32px;
    &>span {
      margin-left: 20px; }
    .tip {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      margin-right: 4px; }
    .sent {
      background: #264382; }
    .opened {
      background: #7BAD2D; } }

  .notes-modal-bg {
    margin-right: -44px;
    margin-top: -72px;
    margin-left: -344px; } }
