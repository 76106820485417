 :local(.goalsModal) {
  width: 725px !important;
  &.select-modal {
    width: 595px !important;
    border-radius: 12px;
    .ant-modal-header, .ant-modal-body, .ant-modal-footer {
      padding-left: 30px;
      padding-right: 30px; }
    .select-type-icon {
      width: 260px;
      height: 260px;
      display: inline-block;
      text-align: center;
      padding-top: 90px;
      font-size: 18px;
      cursor: pointer;
      &.selected {
        background: #F1F3F6;
        border-radius: 12px; }
      img {
        margin-bottom: 20px;
        width: 36px; } } }
  &.delete-confirm {
    font-weight: bold;
    color: #383E48;
    .ant-modal-close-x {
      font-size: 22px; }
    .confirm {
      margin-top: 35px;
      margin-bottom: 30px;
      font-size: 18px; }
    .name {
      font-size: 24px;
      margin-bottom: 90px; }
    .footer-opr {
      text-align: right;
      color: #264382;
      font-size: 18px;
      font-weight: 500;
      span {
        margin-left: 50px;
        cursor: pointer; } } }
  .ant-modal-close {
    top: 10px;
    right: 10px; }
  .ant-modal-header {
    padding: 0 50px;
    padding-top: 50px;
    border: none;
    .ant-modal-title {
      color: #8D99A5;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.3px;
      text-transform: capitalize; } }
  .ant-modal-body {
    padding: 24px 40px;
    padding-top: 10px; }
  .ant-modal-footer {
    border: none;
    padding: 16px 50px;
    padding-bottom: 50px;
    .ant-btn {
      text-transform: uppercase;
      padding: 5px 15px;
      height: 35px; } }

  .body-title {
    font-weight: normal;
    font-size: 32px;
    line-height: 29px;
    color: #4C6072;
    margin-bottom: 50px; }
  .only-expert {
    padding-left: 5px;
    padding-bottom: 40px;
    color: rgba(56,62,72,.5); } }


 :local(.goalsStyle) {
  min-height: calc( 100vh - 70px - 47px);
  margin-top: -40px;
  position: relative;
  z-index: 2;
  .title {
    color: #383E48;
    font-size: 32px;
    line-height: 29px;
    margin-bottom: 35px;
    display: flex;
    justify-content: space-between;
    & div {
      text-align: right;
      display: inline-block; }
    .switch-date-range {
      display: flex; }
    &.user {
      padding-top: 40px;
      margin-bottom: 25px; }
    &.Expert {
      text-align: right;
      margin-top: -77px;
      &>div {
        width: 100%; }
      .today {
        float: none; } }
    // .today
    //   font-family: Gilroy
    //   font-size: 14px
    //   line-height: 150%
    //   color: #52606C
    //   border: 1px solid #DEE2EC
    //   border-radius: 12px
    //   padding: 8px 15px
    //   display: inline-block
    //   // float: right
    //   text-transform: uppercase
    //   margin-right: 58px
    //   margin-left: 8px
 }    //   background: #ffffff
  .left, .right {
    display: inline-block; }
  .left {
    position: absolute;
    height: 100%;
    width: 338px;
    background: #ffffff;
    padding: 30px;
    .user-name {
      span {
        display: inline-block;
        width: 150px; }
      img {
        margin: 0 10px; } }
    .primary-data > div {
      .item, .value {
        display: inline-block;
        width: 50%;
        font-size: 14px;
        line-height: 27px;
        letter-spacing: 0.366667px; }
      .item {
        color: #70808E;
        opacity: 0.8;
        text-transform: uppercase; } } }
  .right {
    width: calc(100% - 338px);
    margin-left: 338px;
    padding: 45px 33px;
    .archived-goals {
      float: right;
      color: #4C6072;
      font-size: 12px;
      opacity: 0.5;
      cursor: pointer;
      letter-spacing: 1px;
      line-height: 42px;
      .anticon {
        margin-right: 7px; } } }
  .rate {
    display: flex;
    padding: 0 15px;
    align-items: center;
    div {
      margin-right: 30px;
      color: #666762;
      letter-spacing: 0.3px;
      span {
        color: #95999F; }
      .green {
        color: #80B32E; }
      .red {
        color: #EA4D00; } } }
  .select-bar {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin-right: 57px;
    .period {
      border-radius: 3px 0px 0px 3px;
      text-transform: uppercase;
      display: inline-block;
      div {
        display: inline-block;
        color: #BAC3CE;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: pointer;
        &:first-child {
          margin-right: 37px; } }
      .selected {
        color: #383E48;
        border-bottom: 3px solid #264382; } } }
  // detail panel
  &.Expert .goal-detail-bg {
    margin-right: -44px;
    margin-top: -85px;
    margin-left: -314px; }

  // .null-status
  //     margin-top: 112px
  //     text-align: center
  //     margin-left: -78px
  //     img
  //       width: 287px
  //       margin-bottom: 30px
  //     p
  //       font-family: Heebo
  //       font-size: 14px
  //       line-height: 150%
  //       color: #79879B
  //       opacity: 0.8
  // .attach-item-container
  //   width: 256px
  //   display: inline-block
  //   margin-right: 10px
  //   vertical-align: top
  // .attach-item
  //   background: #E8ECF0
  //   border-radius: 6px
  //   margin-bottom: 10px
  //   padding: 8px
  //   display: flex
  //   justify-content: space-between
  //   align-items: center
  //   .thumb
  //     width: 47px
  //     height: 47px
  //     object-fit: cover
  //     // background: gray
  //     color: #E8ECF0
  //     text-align: center
  //     overflow: hidden
  //     &.link
  //       font-size: 30px
  //     &.text
  //       line-height: 47px
  //       text-transform: uppercase
  //   .name
  //     font-family: Heebo
  //     font-weight: 500
  //     font-size: 12px
  //     line-height: 150%
  //     letter-spacing: 0.703548px
  //     color: rgba(186,195,206,.7)
  //     width: 180px
  //     .title
  //       font-family: Gilroy
  //       font-size: 12px
  //       line-height: 150%
  //       letter-spacing: 0.3px
  //       height: 18px
  //       color: #52606C
  //       margin: 0
  //     .ant-typography
  //       margin: 0
  //       text-align: left
  //   .anticon-close
 }  //     cursor: pointer
