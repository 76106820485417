 :local(.resourceStyle) {
  font-family: Gilroy;
  padding-right: 50px;
  padding-top: 40px;
  padding-bottom: 50px;
  .title {
    display: flex;
    justify-content: space-between;
    .sort {
      font-size: 18px; }
    // .ant-select
 }    //   height: 40px
  .select-container {
    color: #8D99A5;
    // text-transform: uppercase
    letter-spacing: 0.4px;
    font-size: 12px;
    .sub-title {
      font-weight: 600;
      font-size: 24px;
      color: #383E48; }
    .ant-select {
      margin-left: 15px; }
    .content-list, .resource-list {
      margin-top: 16px;
      .content-item {
        display: inline-block;
        margin-bottom: 24px;
        .ant-card-body {
          padding: 13px 16px 16px; }
        .item-type {
          font-size: 12px;
          line-height: 150%;
          letter-spacing: 0.3px;
          font-weight: 600;
          margin-bottom: 8px;
          &.nutrition, &.Nutrition {
            color: #7BAD2D; }
          &.exercise, &.Exercise {
            color: #FF6B00; }
          &.rejuvenation, &.Rejuvenation {
            color: #5C00D1; } }
        .ant-card-meta-title {
          color: #323F4A;
          font-size: 14px;
          line-height: 120%; }
        .ant-card-meta-description {
          color: #A5ABB1;
          font-size: 12px;
          line-height: 23px; }
        .item-more {
          margin-top: 30px;
          font-size: 14px;
          font-family: Heebo;
          color: #264382; } }
      .resource-item {
        display: inline-block;
        margin-bottom: 24px;
        .ant-card-body {
          margin-top: -85px;
          padding: 20px 16px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0.16%, rgba(0, 13, 40, 0.6) 100%);
          .ant-card-meta-title {
            color: #ffffff;
            font-size: 20px;
            line-height: 22px;
 }            // font-family: 'Gilroy-blod Gilroy'
          .ant-card-meta-description {
            color: #ffffff; } }
        .ant-card-cover img {
          border-radius: 4px; } }
      .category-item {
        width: 100%;
        .ant-card-meta-title {
          white-space: normal; }
        .ant-card-cover {
          height: 114px;
          object-fit: cover;
          overflow: hidden; }
        .item-tip {
          color: #A5ABB1;
          font-size: 12px;
          margin-top: 19px; }
        .item-type {
          text-transform: capitalize; } } } } }


