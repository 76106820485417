 :local(.table) {
  .action-list {
    text-align: right;
    .action-item {
      margin: 0 20px;
      cursor: pointer;
      .anticon {
        margin-right: 10px; } } }
  .com-report {
    .ant-table-thead > tr > th {
      background: #ffffff;
      color: #75879D;
      text-transform: uppercase;
      letter-spacing: 0.006em;
      line-height: 17px;
      font-size: 12px;
      font-weight: 500;
      opacity: 0.8;
      padding: 16px 30px; }
    .ant-table-tbody tr {
      &:nth-child(2n) {
        background: rgba(241,243,245,.3); }
      td {
        padding: 16px 30px;
        border: none; } } } }
