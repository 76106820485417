 :local(.undoStyle) {
    position: fixed;
    bottom: 136px;
    transform: translateX(-50%);
    left: 50%;
    background: #383E48;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    width: 711px;
    padding: 20px 24px;
    color: #FFFFFF;
    font-family: Gilroy;
    &>div:last-child {
      cursor: pointer; } }
