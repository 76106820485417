@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
.common .ant-input-number, ._3--e7T2xh5zMZrRYgo9fEN .ant-input-number, ._2P_-pSkfcxDdUbKrPpONwh .ant-input-number {
  width: 100%; }

.common .ant-form-item .ant-input-group-addon, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-input-group-addon, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item .ant-input-group-addon, .common .ant-form-item .ant-input-group-addon + .ant-input, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-input-group-addon + .ant-input, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item .ant-input-group-addon + .ant-input {
  border: none; }

.common .ant-form-item .ant-select-lg, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-select-lg, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item .ant-select-lg, .common .ant-form-item .ant-input-lg, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-input-lg, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item .ant-input-lg, .common .ant-form-item .ant-picker-large .ant-picker-input > input, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-picker-large .ant-picker-input > input, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item .ant-picker-large .ant-picker-input > input {
  font-size: 14px; }

.common .ant-form-item .ant-form-item-label > label, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-form-item-label > label, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item .ant-form-item-label > label {
  text-transform: uppercase;
  font-size: 12px;
  height: auto !important; }

.common .ant-form-item .ant-input-affix-wrapper-lg, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-input-affix-wrapper-lg, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item .ant-input-affix-wrapper-lg {
  max-height: 45px; }

.common .ant-form-item .ant-picker, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-picker, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item .ant-picker, .common .ant-form-item .ant-input-number-lg input, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-input-number-lg input, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item .ant-input-number-lg input {
  height: 45px; }

.common .ant-select-selector, ._3--e7T2xh5zMZrRYgo9fEN .ant-select-selector, ._2P_-pSkfcxDdUbKrPpONwh .ant-select-selector, .common .ant-input-lg, ._3--e7T2xh5zMZrRYgo9fEN .ant-input-lg, ._2P_-pSkfcxDdUbKrPpONwh .ant-input-lg, .common .ant-input-group-wrapper-lg .ant-input-lg, ._3--e7T2xh5zMZrRYgo9fEN .ant-input-group-wrapper-lg .ant-input-lg, ._2P_-pSkfcxDdUbKrPpONwh .ant-input-group-wrapper-lg .ant-input-lg,
.common .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
._3--e7T2xh5zMZrRYgo9fEN .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
._2P_-pSkfcxDdUbKrPpONwh .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 45px; }
  .common .ant-select-selector .ant-select-selection-item, ._3--e7T2xh5zMZrRYgo9fEN .ant-select-selector .ant-select-selection-item, ._2P_-pSkfcxDdUbKrPpONwh .ant-select-selector .ant-select-selection-item, .common .ant-input-lg .ant-select-selection-item, ._3--e7T2xh5zMZrRYgo9fEN .ant-input-lg .ant-select-selection-item, ._2P_-pSkfcxDdUbKrPpONwh .ant-input-lg .ant-select-selection-item, .common .ant-input-group-wrapper-lg .ant-input-lg .ant-select-selection-item, ._3--e7T2xh5zMZrRYgo9fEN .ant-input-group-wrapper-lg .ant-input-lg .ant-select-selection-item, ._2P_-pSkfcxDdUbKrPpONwh .ant-input-group-wrapper-lg .ant-input-lg .ant-select-selection-item,
  .common .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  ._3--e7T2xh5zMZrRYgo9fEN .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  ._2P_-pSkfcxDdUbKrPpONwh .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    line-height: 45px; }

.common .ant-input-affix-wrapper > input.ant-input, ._3--e7T2xh5zMZrRYgo9fEN .ant-input-affix-wrapper > input.ant-input, ._2P_-pSkfcxDdUbKrPpONwh .ant-input-affix-wrapper > input.ant-input {
  height: 32px; }

.common .ant-form-item, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item {
  min-height: 40px; }
  .common .ant-form-item .ant-radio-group, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-radio-group, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item .ant-radio-group {
    padding: 0 10px; }

._3--e7T2xh5zMZrRYgo9fEN .ant-form-item {
  position: relative;
  text-align: left; }
  ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-form-item-margin-offset {
    margin-bottom: 0 !important; }
  ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-form-item-label {
    position: absolute;
    z-index: 2;
    background: #fff;
    top: 8px;
    left: 10px;
    margin-top: -18px;
    padding: 0 3px;
    overflow: visible; }
    ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-form-item-label > label {
      color: #8d99a5;
      letter-spacing: .4px;
      background: #fff;
      white-space: nowrap; }
  ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-input-prefix + input {
    padding-left: 5px; }
  ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-select-disabled .ant-select-selector, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-input[disabled], ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-picker.ant-picker-disabled, ._3--e7T2xh5zMZrRYgo9fEN .ant-form-item .ant-input-number-disabled {
    background: #ffffff !important; }

._2P_-pSkfcxDdUbKrPpONwh .ant-form-item-label {
  height: 30px;
  letter-spacing: .3px;
  padding-bottom: 4px;
  padding-top: 4px; }
  ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item-label > label {
    opacity: .8;
    color: #5c626a; }

._2P_-pSkfcxDdUbKrPpONwh .ant-input, ._2P_-pSkfcxDdUbKrPpONwh .ant-picker, ._2P_-pSkfcxDdUbKrPpONwh .ant-select-selector, ._2P_-pSkfcxDdUbKrPpONwh .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f5f6f8 !important;
  border: 1px solid rgba(38, 67, 130, 0.5); }

._2P_-pSkfcxDdUbKrPpONwh .ant-form-item-control .ant-input-disabled, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item-control .ant-picker-disabled, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item-control .ant-select-disabled .ant-select-selector, ._2P_-pSkfcxDdUbKrPpONwh .ant-form-item-control .ant-input-affix-wrapper {
  border: none; }

._2uJDkrIqurwF9AZtpjwKC9 {
  font-family: Gilroy;
  font-weight: 500; }
  ._2uJDkrIqurwF9AZtpjwKC9 .toolbar {
    margin-bottom: 0;
    border-color: #d9d9d9; }
  ._2uJDkrIqurwF9AZtpjwKC9 .toolbar + .editor {
    border-color: #d9d9d9;
    border-top: none; }
  ._2uJDkrIqurwF9AZtpjwKC9 .editor {
    border: 1px solid #d9d9d9;
    padding: 5px; }
    ._2uJDkrIqurwF9AZtpjwKC9 .editor .public-DraftStyleDefault-block {
      margin: 2px 0;
      line-height: 1.5;
      padding-bottom: 5px; }

._1GrDaVgoNK15ekqxhu1MBc label {
  display: block;
  height: 45px;
  padding: 12px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  cursor: pointer; }

._1GrDaVgoNK15ekqxhu1MBc input {
  display: none !important; }

._3OAvG4ydcVcb28Izlg1Kn4 {
  padding-top: 15px;
  color: #4C6072;
  font-size: 12px; }
  ._3OAvG4ydcVcb28Izlg1Kn4 .days-item {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin-right: 7px;
    background: #F4F5F6;
    border-radius: 100%;
    cursor: pointer; }
    ._3OAvG4ydcVcb28Izlg1Kn4 .days-item:last-child {
      margin-right: 0; }
    ._3OAvG4ydcVcb28Izlg1Kn4 .days-item.selected {
      background: #264382;
      color: #ffffff; }

.RgIz36muZBSHEqznsm1cA {
  margin-left: -5px;
  margin-right: -5px; }
  .RgIz36muZBSHEqznsm1cA .ant-legacy-form-item {
    margin-bottom: 0; }

._382Q-SxGI99fg4Z_GqJfkT.ant-radio-group {
  padding: 0 10px; }
  ._382Q-SxGI99fg4Z_GqJfkT.ant-radio-group .ant-radio-wrapper {
    vertical-align: bottom; }

._35z9XW-TsiVJmv5nmd_qJz {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  min-height: 45px;
  padding: 4px 11px;
  display: flex;
  flex-wrap: wrap; }
  ._35z9XW-TsiVJmv5nmd_qJz.disabled {
    opacity: .6;
    cursor: not-allowed; }
  ._35z9XW-TsiVJmv5nmd_qJz.has-error {
    border-color: #f5222d;
    box-sizing: content-box; }
    ._35z9XW-TsiVJmv5nmd_qJz.has-error .ant-input:focus {
      border-color: transparent;
      box-shadow: none; }
  ._35z9XW-TsiVJmv5nmd_qJz .ant-tag {
    border-radius: 33px;
    background: #E8ECF0;
    padding: 0 10px;
    margin-top: 10px; }
  ._35z9XW-TsiVJmv5nmd_qJz .ant-input {
    border: none;
    height: auto !important;
    padding: 0;
    margin-top: 10px; }
    ._35z9XW-TsiVJmv5nmd_qJz .ant-input:focus, ._35z9XW-TsiVJmv5nmd_qJz .ant-input:hover {
      border-color: transparent !important;
      box-shadow: none !important; }

._2N7Soa3By2MWxCS8vEmlPi input {
  height: auto !important; }

._1FnoLlp6fUh4Tf3WNSNq8E .target-goal .am-slider-wrapper {
  margin-top: 15px; }
  ._1FnoLlp6fUh4Tf3WNSNq8E .target-goal .am-slider-wrapper .am-slider-rail {
    height: 8px;
    border-radius: 100px;
    background: #E6E6E6; }
  ._1FnoLlp6fUh4Tf3WNSNq8E .target-goal .am-slider-wrapper .am-slider-track.am-slider-track-1 {
    height: 8px;
    background: #2B4B8F; }
  ._1FnoLlp6fUh4Tf3WNSNq8E .target-goal .am-slider-wrapper .am-slider-handle {
    margin-top: -6px;
    border: none;
    box-shadow: 0px 2px 4px rgba(140, 140, 140, 0.3), 0px -2px 4px rgba(140, 140, 140, 0.2); }

._1FnoLlp6fUh4Tf3WNSNq8E .target-goal .target-number {
  display: flex;
  justify-content: space-between;
  margin-top: 10px; }

._1FnoLlp6fUh4Tf3WNSNq8E .wrapper-title {
  color: #38435E;
  opacity: 0.5;
  font-size: 12px;
  font-weight: bold; }

._1FnoLlp6fUh4Tf3WNSNq8E .ant-calendar-picker-input {
  height: 45px; }
  ._1FnoLlp6fUh4Tf3WNSNq8E .ant-calendar-picker-input .ant-calendar-range-picker-separator {
    vertical-align: baseline; }

._1FnoLlp6fUh4Tf3WNSNq8E .hide-label-colon .ant-legacy-form-item-label > label::after {
  content: ''; }

._1FnoLlp6fUh4Tf3WNSNq8E .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: none; }

._1UONl6EdMVAK6mCinOTo3- {
  font-family: Lato;
  color: #3F4752; }
  ._1UONl6EdMVAK6mCinOTo3-.signle {
    margin-bottom: 36px; }
  ._1UONl6EdMVAK6mCinOTo3- .phs-header-content {
    background: #FAFCFD;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 99; }
    ._1UONl6EdMVAK6mCinOTo3- .phs-header-content .main-content {
      padding: 12px 25px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    ._1UONl6EdMVAK6mCinOTo3- .phs-header-content .polygon-avatar {
      overflow: hidden; }
    ._1UONl6EdMVAK6mCinOTo3- .phs-header-content .avatar {
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
      width: 40px;
      height: 40px;
      border-radius: 100%; }
    ._1UONl6EdMVAK6mCinOTo3- .phs-header-content .phs-text {
      font-weight: 900;
      line-height: 27px;
      font-size: 16px;
      letter-spacing: 0.366667px; }
      ._1UONl6EdMVAK6mCinOTo3- .phs-header-content .phs-text img {
        width: 145px;
        border-radius: 4px; }
    ._1UONl6EdMVAK6mCinOTo3- .phs-header-content .login-user {
      display: flex;
      align-items: center; }
    ._1UONl6EdMVAK6mCinOTo3- .phs-header-content .role-name {
      letter-spacing: 0.366667px;
      text-align: right;
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px; }
  ._1UONl6EdMVAK6mCinOTo3- .sub-header {
    position: relative;
    background: #ffffff;
    margin-bottom: 30px; }
    ._1UONl6EdMVAK6mCinOTo3- .sub-header .ant-tabs .ant-tabs-bar {
      margin: 0; }
    ._1UONl6EdMVAK6mCinOTo3- .sub-header .ant-tabs .ant-tabs-nav-container {
      padding: 0 20px; }
      ._1UONl6EdMVAK6mCinOTo3- .sub-header .ant-tabs .ant-tabs-nav-container .ant-tabs-ink-bar {
        background-color: #3A64C1; }
    ._1UONl6EdMVAK6mCinOTo3- .sub-header .ant-tabs .ant-tabs-tab {
      border: none; }
      ._1UONl6EdMVAK6mCinOTo3- .sub-header .ant-tabs .ant-tabs-tab .tab-title {
        text-transform: uppercase;
        font-size: 14px;
        color: #3F4651;
        font-weight: bold; }
        ._1UONl6EdMVAK6mCinOTo3- .sub-header .ant-tabs .ant-tabs-tab .tab-title img {
          width: 23px;
          margin-right: 5px; }
    ._1UONl6EdMVAK6mCinOTo3- .sub-header .ant-tabs .ant-tabs-top-content {
      background: #FAFCFD;
      padding: 12px 25px; }
    ._1UONl6EdMVAK6mCinOTo3- .sub-header .ant-breadcrumb-link {
      text-transform: uppercase;
      font-size: 12px; }
    ._1UONl6EdMVAK6mCinOTo3- .sub-header .header-opr {
      position: absolute;
      top: 10px;
      right: 22px; }
      ._1UONl6EdMVAK6mCinOTo3- .sub-header .header-opr .header-opr-icon {
        margin: 0 15px;
        cursor: pointer; }

._1wup6GwNSoR8swKpgavIAr .action-list {
  text-align: right; }
  ._1wup6GwNSoR8swKpgavIAr .action-list .action-item {
    margin: 0 20px;
    cursor: pointer; }
    ._1wup6GwNSoR8swKpgavIAr .action-list .action-item .anticon {
      margin-right: 10px; }

._1wup6GwNSoR8swKpgavIAr .com-report .ant-table-thead > tr > th {
  background: #ffffff;
  color: #75879D;
  text-transform: uppercase;
  letter-spacing: 0.006em;
  line-height: 17px;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.8;
  padding: 16px 30px; }

._1wup6GwNSoR8swKpgavIAr .com-report .ant-table-tbody tr:nth-child(2n) {
  background: rgba(241, 243, 245, 0.3); }

._1wup6GwNSoR8swKpgavIAr .com-report .ant-table-tbody tr td {
  padding: 16px 30px;
  border: none; }

._1RZqgoLrf__tTv8Rf4Fjnx {
  font-family: Gilroy;
  text-align: left; }
  ._1RZqgoLrf__tTv8Rf4Fjnx .ant-modal-header {
    border: none;
    padding: 40px 46px;
    padding-bottom: 0; }
  ._1RZqgoLrf__tTv8Rf4Fjnx .ant-modal-body {
    padding: 15px 32px;
    padding-bottom: 0; }
    ._1RZqgoLrf__tTv8Rf4Fjnx .ant-modal-body .title-center {
      padding-top: 10px;
      margin-bottom: 10px; }
    ._1RZqgoLrf__tTv8Rf4Fjnx .ant-modal-body .radio-group-container {
      padding-top: 30px;
      padding-bottom: 20px; }
      ._1RZqgoLrf__tTv8Rf4Fjnx .ant-modal-body .radio-group-container .ant-radio-wrapper {
        display: block;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        font-family: Gilroy; }
        ._1RZqgoLrf__tTv8Rf4Fjnx .ant-modal-body .radio-group-container .ant-radio-wrapper .ant-radio {
          padding-bottom: 1px; }
  ._1RZqgoLrf__tTv8Rf4Fjnx .ant-modal-footer {
    border: none;
    padding: 15px 46px;
    padding-bottom: 40px; }
    ._1RZqgoLrf__tTv8Rf4Fjnx .ant-modal-footer .modal-footer {
      background: #ffffff;
      margin-top: 20px;
      position: relative;
      top: 15px; }
      ._1RZqgoLrf__tTv8Rf4Fjnx .ant-modal-footer .modal-footer button {
        height: 48px;
        min-width: 115px;
        font-family: Lato;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        border: none; }
      ._1RZqgoLrf__tTv8Rf4Fjnx .ant-modal-footer .modal-footer .cancel {
        box-shadow: none;
        color: #264382; }
  ._1RZqgoLrf__tTv8Rf4Fjnx .ant-modal-title {
    font-size: 24px;
    color: #4C6072;
    letter-spacing: 0.3px;
    line-height: 150%; }

._12axLGEAMVy-zdANJ9FAkU {
  letter-spacing: 0.3px; }
  ._12axLGEAMVy-zdANJ9FAkU .ant-input {
    min-height: 44px;
    border: 1px solid #DEE2EC;
    border-radius: 4px;
    font-size: 14px;
    color: #323A46;
    margin-bottom: 16px;
    padding: 10px; }
  ._12axLGEAMVy-zdANJ9FAkU .submit-button {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px; }
    ._12axLGEAMVy-zdANJ9FAkU .submit-button button {
      width: 94px; }
    ._12axLGEAMVy-zdANJ9FAkU .submit-button .upload-icon .anticon {
      color: #264382;
      font-size: 18px;
      cursor: pointer; }
      ._12axLGEAMVy-zdANJ9FAkU .submit-button .upload-icon .anticon.disabled {
        color: rgba(0, 0, 0, 0.25); }
  ._12axLGEAMVy-zdANJ9FAkU .message-item {
    display: flex;
    margin-bottom: 20px;
    font-family: 'Gilroy'; }
    ._12axLGEAMVy-zdANJ9FAkU .message-item img {
      max-width: 100%; }
    ._12axLGEAMVy-zdANJ9FAkU .message-item:hover .message-tag .opr {
      display: block; }
    ._12axLGEAMVy-zdANJ9FAkU .message-item .avatar {
      margin-right: 16px; }
    ._12axLGEAMVy-zdANJ9FAkU .message-item .message-content {
      width: calc(100% - 56px); }
    ._12axLGEAMVy-zdANJ9FAkU .message-item .message-tag {
      font-size: 12px;
      color: #97A0A7;
      display: flex;
      justify-content: space-between; }
      ._12axLGEAMVy-zdANJ9FAkU .message-item .message-tag strong {
        color: #323F4A;
        font-size: 14px;
        margin-right: 5px;
        font-family: 'Gilroy-Bold'; }
      ._12axLGEAMVy-zdANJ9FAkU .message-item .message-tag .opr {
        display: none;
        font-size: 14px;
        color: #264382;
        cursor: pointer; }
    ._12axLGEAMVy-zdANJ9FAkU .message-item .message-media-info {
      position: relative;
      display: inline-block;
      margin-top: 10px;
      border: 1px solid #DEE2EC;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      overflow: hidden; }
      ._12axLGEAMVy-zdANJ9FAkU .message-item .message-media-info img {
        max-width: 100%; }
      ._12axLGEAMVy-zdANJ9FAkU .message-item .message-media-info .page-loading, ._12axLGEAMVy-zdANJ9FAkU .message-item .message-media-info .error {
        position: absolute;
        width: 100%;
        vertical-align: middle;
        top: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.9);
        display: flex;
        align-items: center;
        justify-content: center; }
      ._12axLGEAMVy-zdANJ9FAkU .message-item .message-media-info .error {
        font-size: 18px; }
        ._12axLGEAMVy-zdANJ9FAkU .message-item .message-media-info .error .try-aggin {
          cursor: pointer;
          color: #2B4B8F; }
    ._12axLGEAMVy-zdANJ9FAkU .message-item .message-info {
      position: relative;
      border: 1px solid #DEE2EC;
      border-radius: 4px;
      font-size: 14px;
      color: #323A46;
      opacity: 0.8;
      width: 100%;
      padding: 12px;
      line-height: 1.75;
      margin-top: 10px;
      word-break: break-word; }
      ._12axLGEAMVy-zdANJ9FAkU .message-item .message-info pre {
        margin-bottom: 0;
        white-space: pre-wrap;
        font-family: Gilroy; }
      ._12axLGEAMVy-zdANJ9FAkU .message-item .message-info .is-edited {
        color: #97a0a7; }
      ._12axLGEAMVy-zdANJ9FAkU .message-item .message-info p:last-child {
        margin-bottom: 0; }
    ._12axLGEAMVy-zdANJ9FAkU .message-item .btns {
      margin-top: 10px;
      text-align: right; }
      ._12axLGEAMVy-zdANJ9FAkU .message-item .btns button:first-child {
        margin: 0 5px; }
  ._12axLGEAMVy-zdANJ9FAkU .empty-container {
    padding-top: 40px; }
    ._12axLGEAMVy-zdANJ9FAkU .empty-container > div {
      margin: 0 auto; }
    ._12axLGEAMVy-zdANJ9FAkU .empty-container .team-member {
      background: #FFFFFF;
      box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      width: 360px;
      padding: 13px 16px;
      margin-bottom: 16px; }
    ._12axLGEAMVy-zdANJ9FAkU .empty-container .empty-info {
      width: 281px;
      margin-top: 32px;
      padding-bottom: 34px;
      color: #909399;
      font-size: 14px;
      text-align: center; }

._3_eKMKWxTFu9iXBzjnXNkD {
  /* rewrite alert style */
  /* start of metric detail */ }
  ._3_eKMKWxTFu9iXBzjnXNkD .ant-alert.ant-alert-warning {
    background: #FF9E2E;
    color: #ffffff;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-family: Gilroy; }
    ._3_eKMKWxTFu9iXBzjnXNkD .ant-alert.ant-alert-warning .ant-alert-icon {
      color: #ffffff;
      font-size: 22px; }
    ._3_eKMKWxTFu9iXBzjnXNkD .ant-alert.ant-alert-warning .ant-alert-message {
      color: #ffffff;
      font-size: 16px; }
      ._3_eKMKWxTFu9iXBzjnXNkD .ant-alert.ant-alert-warning .ant-alert-message:first-letter {
        text-transform: uppercase; }
    ._3_eKMKWxTFu9iXBzjnXNkD .ant-alert.ant-alert-warning .ant-alert-description {
      font-size: 13px; }
  ._3_eKMKWxTFu9iXBzjnXNkD .notes-modal-bg {
    margin-left: 0;
    margin-top: -30px; }
  ._3_eKMKWxTFu9iXBzjnXNkD .client-content {
    position: relative; }
  ._3_eKMKWxTFu9iXBzjnXNkD .section-title {
    padding: 0 30px;
    margin: 0;
    height: 58px;
    line-height: 29px;
    font-size: 32px;
    color: #4C6072; }
  ._3_eKMKWxTFu9iXBzjnXNkD .male-vs-female {
    padding: 0 30px;
    font-size: 14px;
    color: #9C9C9C;
    letter-spacing: 0.3px;
    text-align: right;
    text-transform: uppercase;
    margin-top: 15px;
    position: absolute;
    width: 100%; }
    ._3_eKMKWxTFu9iXBzjnXNkD .male-vs-female span {
      margin: 0 15px; }
  ._3_eKMKWxTFu9iXBzjnXNkD .list-section {
    margin: 0 30px;
    margin-bottom: 20px;
    border-radius: 4px;
    min-height: calc(100vh - 120px - 100px); }
    ._3_eKMKWxTFu9iXBzjnXNkD .list-section table {
      border: 1px solid #EAEDF3; }
    ._3_eKMKWxTFu9iXBzjnXNkD .list-section .link-group {
      min-width: 175px;
      display: flex;
      justify-content: flex-end; }
    ._3_eKMKWxTFu9iXBzjnXNkD .list-section .ant-table-tbody {
      background: #ffffff; }
      ._3_eKMKWxTFu9iXBzjnXNkD .list-section .ant-table-tbody .devices-list {
        max-width: 110px; }
        ._3_eKMKWxTFu9iXBzjnXNkD .list-section .ant-table-tbody .devices-list > img {
          margin: 10px;
          width: 32px; }
    ._3_eKMKWxTFu9iXBzjnXNkD .list-section .ant-table-thead {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05); }
      ._3_eKMKWxTFu9iXBzjnXNkD .list-section .ant-table-thead > tr > th {
        background: rgba(135, 152, 173, 0.1);
        border: none;
        min-height: 60px;
        font-size: 12px;
        color: #9C9C9C; }
        ._3_eKMKWxTFu9iXBzjnXNkD .list-section .ant-table-thead > tr > th:last-child {
          text-align: right;
          padding-right: 24px; }
    ._3_eKMKWxTFu9iXBzjnXNkD .list-section .ant-table-tbody > tr > td:last-child {
      text-align: right;
      padding-right: 24px; }
    ._3_eKMKWxTFu9iXBzjnXNkD .list-section .medical-status .ant-checkbox-indeterminate .ant-checkbox-inner::after,
    ._3_eKMKWxTFu9iXBzjnXNkD .list-section .medical-status .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #EA5400;
      border-color: #EA5400; }
    ._3_eKMKWxTFu9iXBzjnXNkD .list-section .medical-status .ant-checkbox-inner::after {
      left: 25%; }
    ._3_eKMKWxTFu9iXBzjnXNkD .list-section .polygon-avatar {
      width: 46px;
      height: 46px; }
    ._3_eKMKWxTFu9iXBzjnXNkD .list-section .avatar, ._3_eKMKWxTFu9iXBzjnXNkD .list-section .empty-avatar {
      display: inline-block;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(0, 0, 0, 0.05); }
  ._3_eKMKWxTFu9iXBzjnXNkD .detail-content {
    text-align: center; }
    ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .left, ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right {
      display: inline-block;
      vertical-align: top;
      text-align: left; }
    ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .left {
      width: 270px;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2; }
      ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .left.close {
        position: absolute;
        left: 0;
        width: 0px; }
      ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .left .swift-left-btn {
        position: absolute;
        top: 0;
        right: -46px; }
        ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .left .swift-left-btn.close {
          left: 0; }
    ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .close > div:first-child {
      padding-right: 0px; }
    ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right {
      width: calc(100% - 250px - 26px);
      min-height: calc(100vh - 71px - 45px);
      margin-top: -30px;
      padding-bottom: 60px;
      padding-top: 30px;
      padding-left: 60px;
      padding-right: 60px;
      position: relative;
      background: #F4F6F8;
      box-shadow: -1px 4px 20px rgba(0, 0, 0, 0.03); }
      ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right.detail-right {
        width: 100%;
        padding: 0 25px;
        margin-left: 0; }
        ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right.detail-right.no-padding {
          padding: 0; }
      ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right .mobile-menu {
        display: none; }
      ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right .page-title {
        font-family: Gilroy;
        line-height: 120%;
        font-size: 32px;
        color: #4C6072;
        padding-bottom: 20px;
        letter-spacing: 0.3px;
        padding-top: 20px; }
        ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right .page-title img {
          cursor: pointer; }
        ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right .page-title .date {
          padding: 2px 15px;
          margin-left: 10px;
          display: inline-block;
          vertical-align: middle;
          background: rgba(117, 135, 157, 0.1);
          border: 1px solid #1D3952;
          border-radius: 5px;
          color: #75879D;
          font-size: 12px;
          line-height: 29px;
          font-weight: bold; }
          ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right .page-title .date img {
            margin-right: 5px;
            vertical-align: middle; }
  ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container {
    display: flex; }
    ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .biomarker-info {
      background: #ffffff;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      display: inline-block;
      width: calc(558px + 45px);
      margin-left: 20px;
      position: relative;
      min-height: 340px; }
      ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .biomarker-info .tabs {
        display: flex; }
        ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .biomarker-info .tabs > ul {
          list-style: none;
          padding: 0;
          text-align: center;
          border-bottom: 1px solid #EAEDF3;
          width: 100%; }
          ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .biomarker-info .tabs > ul li {
            display: inline-block;
            width: 50%;
            border-right: 1px solid #EAEDF3;
            background: #FAFAFA;
            padding: 31px 0;
            font-size: 12px;
            font-weight: bold; }
            ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .biomarker-info .tabs > ul li:last-child {
              border: none; }
            ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .biomarker-info .tabs > ul li.active {
              color: #3F4752;
              background: #ffffff; }
      ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .biomarker-info .information-container {
        padding: 20px;
        font-family: Heebo; }
        ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .biomarker-info .information-container .information {
          position: absolute;
          width: calc(100% - 40px);
          height: calc(100% - 104px - 40px);
          overflow-y: scroll; }
    ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info {
      background: #ffffff;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      display: inline-block;
      width: 767px; }
      ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info .header {
        padding: 15px 20px;
        height: 88px;
        border-bottom: 1px solid #EAEDF3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        line-height: 29px; }
        ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info .header h1 {
          margin: 0;
          font-size: 18px; }
        ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info .header .date {
          font-size: 12px;
          line-height: 29px;
          color: #B1B1B1; }
          ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info .header .date img {
            margin-right: 10px; }
        ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info .header .show-options {
          text-transform: uppercase;
          text-align: right;
          margin-top: -45px; }
      ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info .ranges-area-container {
        width: 100%;
        min-height: 100px;
        display: inline-block;
        padding: 20px;
        vertical-align: top;
        padding-bottom: 0; }
      ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info .ranges-area-container .ranges-area:last-child {
        margin-top: 20px; }
      ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info .ranges-area-container .options-container {
        display: flex;
        justify-content: space-between; }
        ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info .ranges-area-container .options-container div > .label {
          font-size: 12px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          color: #4C6072; }
        ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info .ranges-area-container .options-container > div {
          font-size: 24px;
          line-height: 36px;
          text-transform: capitalize; }
        ._3_eKMKWxTFu9iXBzjnXNkD .client-biomarker-container .ranges-info .ranges-area-container .options-container .value {
          text-align: right; }
  ._3_eKMKWxTFu9iXBzjnXNkD .trend-graph {
    background: #ffffff;
    margin-top: 20px;
    border-radius: 4px;
    padding-bottom: 20px; }
    ._3_eKMKWxTFu9iXBzjnXNkD .trend-graph h1 {
      margin: 0;
      padding: 20px;
      border-bottom: 1px solid #EAEDF3;
      font-size: 18px;
      line-height: 29px; }
    ._3_eKMKWxTFu9iXBzjnXNkD .trend-graph .VictoryContainer {
      margin: 0 20px; }
  ._3_eKMKWxTFu9iXBzjnXNkD .com-report {
    min-height: 500px; }
    ._3_eKMKWxTFu9iXBzjnXNkD .com-report .phs-section-header {
      display: flex;
      justify-content: space-between; }
      ._3_eKMKWxTFu9iXBzjnXNkD .com-report .phs-section-header h1 {
        color: #4C6072;
        letter-spacing: 0.3px;
        line-height: 29px;
        font-size: 24px;
        font-weight: normal; }
    ._3_eKMKWxTFu9iXBzjnXNkD .com-report .report-status {
      font-weight: 600;
      font-size: 14px;
      line-height: 27px;
      letter-spacing: 0.366667px;
      text-transform: uppercase; }
      ._3_eKMKWxTFu9iXBzjnXNkD .com-report .report-status .editing {
        color: #297FCA; }
      ._3_eKMKWxTFu9iXBzjnXNkD .com-report .report-status .published {
        color: #05B800; }
  @media screen and (max-width: 768px) {
    ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link {
      overflow-x: hidden;
      overflow-y: scroll;
      position: absolute;
      z-index: 1;
      width: 100%;
      top: 0;
      bottom: 0;
      -webkit-overflow-scrolling: touch;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      width: 100vw;
      max-width: 750px;
      margin: 0 auto;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .header {
        text-align: right;
        background: #ffffff;
        padding: 20px 0; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .header .welcome, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .header .polygon-avatar {
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px; }
          ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .header .welcome .name, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .header .polygon-avatar .name {
            font-weight: bold; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .header .menu-icon {
          float: left;
          margin: 20px; }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .menu {
        border-top: 1px solid rgba(0, 0, 0, 0.1); }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .menu .menu-item {
          background: #ffffff;
          font-size: 14px;
          color: #3F4752;
          line-height: 32px;
          padding: 15px 30px; }
          ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .menu .menu-item.active {
            background: rgba(135, 152, 173, 0.1); }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .root-category {
        padding: 20px 0; }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .sub-category-container .biomarker-container {
        margin: 20px 25px 0 0; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .sub-category-container .biomarker-container .personal-value-container {
          border: none;
          border-bottom: 1px solid #EAEDF3;
          padding-bottom: 0; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .sub-category-container .biomarker-container .personal-value-container, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .sub-category-container .biomarker-container .range {
          display: block;
          width: 100%; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .sub-category-container .biomarker-container .range > div:first-child {
          border: none; }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .primary-data {
        background: #ffffff;
        padding: 25px 30px;
        border-radius: 4px;
        margin: 20px 0; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .primary-data > div {
          display: flex;
          justify-content: space-between;
          line-height: 27px;
          font-size: 14px;
          letter-spacing: 0.366667px; }
          ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .primary-data > div .item {
            min-width: 130px;
            color: #9EA0A5; }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .page-title {
        padding: 0 25px; }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .mobile-show .trend-graph {
        overflow-x: scroll; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .mobile-show .trend-graph > div {
          min-width: 1213px; }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .ranges-area-container, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .trend-graph {
        width: 100%; }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .biomarker-info .tabs {
        display: block;
        font-family: Heebo;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.6px;
        color: rgba(76, 96, 114, 0.3); }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .biomarker-info .tabs > ul {
          display: flex;
          padding: 0 10px;
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
          ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .biomarker-info .tabs > ul > li {
            display: inline-block;
            width: 50%;
            padding: 20px 0; }
            ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .biomarker-info .tabs > ul > li.active {
              color: rgba(76, 96, 114, 0.8);
              border-bottom: 2px solid #3A64C1; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .biomarker-info .tabs .information {
          width: 100%; }
    ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .client-biomarker-container, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .client-biomarker-container {
      display: block; }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .client-biomarker-container .ranges-info, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .client-biomarker-container .ranges-info {
        width: 100%;
        margin-bottom: 20px; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .client-biomarker-container .ranges-info .header, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .client-biomarker-container .ranges-info .header {
          display: block;
          text-align: left; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .client-biomarker-container .ranges-info h1, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .client-biomarker-container .ranges-info h1 {
          font-size: 16px; }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .client-biomarker-container .biomarker-info, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .client-biomarker-container .biomarker-info {
        margin-left: 0;
        width: 100%; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .client-biomarker-container .biomarker-info .tabs > ul, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .client-biomarker-container .biomarker-info .tabs > ul {
          margin: 0;
          padding: 0; }
        ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .client-biomarker-container .biomarker-info .information-container .information, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .client-biomarker-container .biomarker-info .information-container .information {
          position: zative; }
    ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .biomarker-container, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .biomarker-container {
      margin-right: 0; }
    ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .mobile-categories-container .root-category, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .mobile-categories-container .root-category {
      width: 100vw; }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .mobile-categories-container .root-category .attachment-name, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .mobile-categories-container .root-category .attachment-name {
        font-size: 18px; }
      ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .mobile-categories-container .root-category img, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .mobile-categories-container .root-category img {
        width: 100%; }
    ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link.show-link .mobile-categories-container .sub-category-container.permanent-category, ._3_eKMKWxTFu9iXBzjnXNkD.permanent-link .mobile-categories-container .sub-category-container.permanent-category {
      display: block; } }
  @media screen and (max-width: 1024px) {
    ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .left {
      display: none; }
      ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .left .description {
        padding-bottom: 25px; }
    ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right {
      width: 100%;
      padding: 0;
      margin-left: 0; }
      ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right .mobile-menu {
        position: relative;
        z-index: 99;
        display: inline-block;
        background: #ffffff;
        border-radius: 100%;
        margin-right: 10px;
        width: 52px;
        height: 52px;
        font-size: 30px;
        line-height: 52px;
        text-align: center;
        float: left;
        margin-left: 20px;
        margin-top: 18px; }
      ._3_eKMKWxTFu9iXBzjnXNkD .detail-content .right .page-title {
        padding: 0 25px;
        padding-top: 25px; } }

._3TBbXpTtDmiCILYkVJWCCU .ant-modal-content {
  border-radius: 12px; }

._3TBbXpTtDmiCILYkVJWCCU .ant-modal-confirm-body {
  font-family: 'Gilroy'; }
  ._3TBbXpTtDmiCILYkVJWCCU .ant-modal-confirm-body .ant-modal-confirm-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.3px;
    color: #262626; }
  ._3TBbXpTtDmiCILYkVJWCCU .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.3px;
    color: #60656D; }
    ._3TBbXpTtDmiCILYkVJWCCU .ant-modal-confirm-body .ant-modal-confirm-content p {
      margin-bottom: 0px; }
    ._3TBbXpTtDmiCILYkVJWCCU .ant-modal-confirm-body .ant-modal-confirm-content ul {
      -webkit-padding-start: 22px;
              padding-inline-start: 22px; }

._3TBbXpTtDmiCILYkVJWCCU .ant-modal-confirm-btns .ant-btn {
  min-width: 80px;
  height: 40px;
  font-family: 'Lato';
  font-size: 13px; }

.tribute-container {
  z-index: 1000; }
  .tribute-container ul {
    list-style: none;
    padding: 10px 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px; }
    .tribute-container ul li {
      padding: 5px 20px; }
      .tribute-container ul li.highlight {
        background: #F4F6F9; }
  .tribute-container .menu-template {
    display: flex; }
    .tribute-container .menu-template .avatar {
      width: 45px;
      height: 45px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 20px; }
      .tribute-container .menu-template .avatar .avatar-placeholder {
        width: 45px;
        height: 45px;
        background: rgba(18, 22, 45, 0.05);
        display: block; }
      .tribute-container .menu-template .avatar img {
        max-width: 100%;
        object-fit: cover; }
    .tribute-container .menu-template .info {
      color: #4C6072;
      font-size: 13px; }
      .tribute-container .menu-template .info .name {
        font-size: 15px;
        color: #000000; }

._1Yh1XAkOXx_3fYG54iMDPi {
  font-family: Gilroy;
  font-weight: 500;
  text-align: left; }
  ._1Yh1XAkOXx_3fYG54iMDPi .ant-modal-header {
    border: none;
    padding: 40px 46px;
    padding-bottom: 0; }
  ._1Yh1XAkOXx_3fYG54iMDPi .ant-modal-body {
    padding: 15px 46px;
    padding-bottom: 0; }
    ._1Yh1XAkOXx_3fYG54iMDPi .ant-modal-body .title-center {
      padding-top: 10px;
      margin-bottom: 10px; }
  ._1Yh1XAkOXx_3fYG54iMDPi .ant-modal-footer {
    border: none;
    padding: 15px 46px;
    padding-bottom: 40px; }
    ._1Yh1XAkOXx_3fYG54iMDPi .ant-modal-footer .upload-footer {
      background: #ffffff;
      margin-top: 20px;
      position: relative;
      top: 15px; }
      ._1Yh1XAkOXx_3fYG54iMDPi .ant-modal-footer .upload-footer button {
        height: 48px;
        min-width: 115px; }
  ._1Yh1XAkOXx_3fYG54iMDPi .ant-modal-title {
    font-size: 32px;
    color: #4C6072; }
  ._1Yh1XAkOXx_3fYG54iMDPi .ant-upload {
    width: 100%;
    padding: 5px; }
  ._1Yh1XAkOXx_3fYG54iMDPi .upload-container {
    background: #F8F8F8;
    padding: 11px;
    width: 100%;
    min-height: 73px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(129, 144, 156, 0.2);
    border-radius: 8px;
    letter-spacing: 0.3px;
    font-size: 16px; }
    ._1Yh1XAkOXx_3fYG54iMDPi .upload-container.empty {
      border: 2px dashed #E2E2E2; }
    ._1Yh1XAkOXx_3fYG54iMDPi .upload-container.has-error {
      border: 1px solid #f5222d; }
    ._1Yh1XAkOXx_3fYG54iMDPi .upload-container .opr {
      color: #264382;
      cursor: pointer; }
    ._1Yh1XAkOXx_3fYG54iMDPi .upload-container .select {
      text-align: center;
      width: 100%; }
    ._1Yh1XAkOXx_3fYG54iMDPi .upload-container .name {
      width: 80%;
      display: flex;
      color: #4C6072;
      font-size: 14px; }
      ._1Yh1XAkOXx_3fYG54iMDPi .upload-container .name .speed {
        color: #7FB800;
        margin-left: 20px;
        text-transform: lowercase; }
      ._1Yh1XAkOXx_3fYG54iMDPi .upload-container .name img {
        margin-right: 16px; }
      ._1Yh1XAkOXx_3fYG54iMDPi .upload-container .name > div {
        max-width: calc(100% - 45px); }
      ._1Yh1XAkOXx_3fYG54iMDPi .upload-container .name .ant-typography {
        margin: 0;
        font-size: 16px;
        font-family: Heebo;
        font-weight: 500;
        font-size: 16px; }
  ._1Yh1XAkOXx_3fYG54iMDPi .file-required {
    color: #f5222d;
    font-size: 14px; }
  ._1Yh1XAkOXx_3fYG54iMDPi .ant-progress-inner {
    margin-top: -30px; }
    ._1Yh1XAkOXx_3fYG54iMDPi .ant-progress-inner .ant-progress-bg {
      height: 4px !important; }
  ._1Yh1XAkOXx_3fYG54iMDPi .upload-progress span {
    display: inline-block;
    margin-bottom: 10px; }
  ._1Yh1XAkOXx_3fYG54iMDPi .title-normal {
    font-size: 24px; }
  ._1Yh1XAkOXx_3fYG54iMDPi .noti-container {
    font-size: 16px; }

.ptTnZpi4-ZVpCmkWLUaam .show-all {
  color: #264382;
  cursor: pointer; }

.ptTnZpi4-ZVpCmkWLUaam .ant-typography a {
  color: #3A64C1; }

.ptTnZpi4-ZVpCmkWLUaam .text-primary {
  color: #415A92; }

.ptTnZpi4-ZVpCmkWLUaam .text-complete {
  color: #05B800; }

.ptTnZpi4-ZVpCmkWLUaam .upload-btn {
  cursor: pointer; }

.ptTnZpi4-ZVpCmkWLUaam .phs-section-body {
  padding: 25px 22px; }
  .ptTnZpi4-ZVpCmkWLUaam .phs-section-body .title {
    font-family: Gilroy;
    font-weight: bold;
    font-size: 18px;
    color: #4C6072;
    letter-spacing: 0.3px; }

.ptTnZpi4-ZVpCmkWLUaam h2 .right-opr {
  font-size: 14px;
  float: right; }
  .ptTnZpi4-ZVpCmkWLUaam h2 .right-opr .oprate {
    color: #3F4752;
    text-transform: uppercase;
    letter-spacing: 0.366667px;
    font-size: 14px;
    font-weight: bold;
    line-height: 27px;
    margin-right: 20px;
    cursor: pointer; }
    .ptTnZpi4-ZVpCmkWLUaam h2 .right-opr .oprate .anticon {
      color: #97AAC6;
      margin-right: 10px; }

.ptTnZpi4-ZVpCmkWLUaam .tray-file-container {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 25px -30px -25px;
  padding: 0 30px; }
  .ptTnZpi4-ZVpCmkWLUaam .tray-file-container .ant-col-6 {
    padding: 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.1); }
    .ptTnZpi4-ZVpCmkWLUaam .tray-file-container .ant-col-6:first-child, .ptTnZpi4-ZVpCmkWLUaam .tray-file-container .ant-col-6:nth-child(4n+1) {
      padding-left: 0; }
    .ptTnZpi4-ZVpCmkWLUaam .tray-file-container .ant-col-6:last-child, .ptTnZpi4-ZVpCmkWLUaam .tray-file-container .ant-col-6:nth-child(4n) {
      border: none; }
  .ptTnZpi4-ZVpCmkWLUaam .tray-file-container .tray-file-item {
    display: flex;
    align-items: center; }
    .ptTnZpi4-ZVpCmkWLUaam .tray-file-container .tray-file-item img {
      width: 21px;
      margin-right: 10px; }
    .ptTnZpi4-ZVpCmkWLUaam .tray-file-container .tray-file-item .file-name {
      width: 63%; }
      .ptTnZpi4-ZVpCmkWLUaam .tray-file-container .tray-file-item .file-name .ant-typography {
        margin-bottom: 0; }
    .ptTnZpi4-ZVpCmkWLUaam .tray-file-container .tray-file-item .anticon {
      margin: 0 10px; }

.ptTnZpi4-ZVpCmkWLUaam .files-null-state, .ptTnZpi4-ZVpCmkWLUaam .files-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.006em;
  color: #3F4752; }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state .files-item-col, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .files-item-col {
    border-right: 1px solid #EAEDF3;
    padding-right: 50px; }
    .ptTnZpi4-ZVpCmkWLUaam .files-null-state .files-item-col + div, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .files-item-col + div {
      padding-left: 50px; }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state .item-label, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .item-label {
    line-height: 17px;
    font-size: 14px;
    color: rgba(117, 135, 157, 0.8);
    margin-bottom: 8px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 150%; }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state .item-label-value, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .item-label-value {
    font-size: 14px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.3px; }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state .file-icon, .ptTnZpi4-ZVpCmkWLUaam .files-null-state .empty-icon, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .file-icon, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .empty-icon {
    width: 8%; }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state .file-icon, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .file-icon {
    position: relative; }
    .ptTnZpi4-ZVpCmkWLUaam .files-null-state .file-icon span, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .file-icon span {
      position: absolute;
      color: #FFFFFF;
      font-size: 24px;
      line-height: 17px;
      top: 30px;
      left: 25px;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state .name, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .name {
    width: 20%;
    overflow: hidden; }
    .ptTnZpi4-ZVpCmkWLUaam .files-null-state .name.no-test-date, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .name.no-test-date {
      width: 30%; }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state .operator, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .operator {
    width: 30%; }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state .date, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .date {
    width: 35%;
    display: flex;
    justify-content: space-between; }
    .ptTnZpi4-ZVpCmkWLUaam .files-null-state .date > div:first-child:last-child, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .date > div:first-child:last-child {
      margin: 0 auto; }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state .opr, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .opr {
    width: 12%;
    min-width: 125px;
    text-align: right; }
    .ptTnZpi4-ZVpCmkWLUaam .files-null-state .opr img, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .opr img {
      margin-left: 22px; }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state .empty-icon > span, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .empty-icon > span {
    background: #3A64C1;
    font-size: 18px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 100%;
    display: inline-block;
    color: #ffffff;
    text-align: center;
    margin-left: -15px; }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state .empty-icon > img, .ptTnZpi4-ZVpCmkWLUaam .files-list-item .empty-icon > img {
    vertical-align: baseline; }

.ptTnZpi4-ZVpCmkWLUaam .files-null-state {
  display: block;
  text-align: center; }
  .ptTnZpi4-ZVpCmkWLUaam .files-null-state p {
    font-size: 18px;
    line-height: 29px;
    color: #4C6072;
    opacity: 0.5;
    margin: 20px 0;
    text-transform: none;
    font-weight: normal; }

@media screen and (max-width: 1024px) {
  .ptTnZpi4-ZVpCmkWLUaam .files-list-item .files-item-col {
    padding-right: 30px; }
    .ptTnZpi4-ZVpCmkWLUaam .files-list-item .files-item-col + div {
      padding-left: 30px; }
  .ptTnZpi4-ZVpCmkWLUaam .files-list-item .date {
    width: 40%; }
  .ptTnZpi4-ZVpCmkWLUaam .files-list-item .operator {
    width: auto; } }

._2jyXEthWclL4VXdSw2Fnc1 {
  background-color: white;
  font-family: Gilroy;
  font-style: normal; }
  ._2jyXEthWclL4VXdSw2Fnc1 .show-all {
    color: #264382;
    cursor: pointer;
    padding: 16px 22px 24px; }
  ._2jyXEthWclL4VXdSw2Fnc1 .section-title {
    margin: 24px 0px 7px 21px;
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #383E48;
    display: inline-block; }
  ._2jyXEthWclL4VXdSw2Fnc1 .section-separator {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1); }
  ._2jyXEthWclL4VXdSw2Fnc1 .file-list-container .file-separator {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 0px 21px; }
  ._2jyXEthWclL4VXdSw2Fnc1 .file-list-container .file-image {
    margin: 30px 16px 29px 22px;
    width: 32px; }
  ._2jyXEthWclL4VXdSw2Fnc1 .file-list-container .file-container {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    ._2jyXEthWclL4VXdSw2Fnc1 .file-list-container .file-container .file-share-image {
      margin-right: 21px;
      margin-left: 21px; }
    ._2jyXEthWclL4VXdSw2Fnc1 .file-list-container .file-container .file-info-container {
      display: flex;
      flex-grow: 1;
      flex-direction: column; }
      ._2jyXEthWclL4VXdSw2Fnc1 .file-list-container .file-container .file-info-container .file-name {
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.3px;
        color: #383E48;
        word-break: break-word; }
      ._2jyXEthWclL4VXdSw2Fnc1 .file-list-container .file-container .file-info-container .file-test-date {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.3px;
        color: #44433d;
        opacity: 0.8; }

.modal-container {
  text-align: center;
  color: #4C6072; }
  .modal-container .upload-file-btn {
    background: #264382;
    padding: 0 20px;
    font-size: 14px; }
  .modal-container > img {
    margin-bottom: 20px; }
  .modal-container .file-uploaded {
    text-align: left; }

.report-blood-chats {
  margin: 30px 0;
  height: 630px;
  border: 1px solid #EAEDF3; }
  .report-blood-chats .summary {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 196px; }
    .report-blood-chats .summary .pie {
      margin-left: 42px;
      width: 141px;
      height: 141px; }
    .report-blood-chats .summary .pie-number {
      margin-left: 53px; }
  .report-blood-chats .line {
    border-top: 1px solid #EAEDF3;
    height: 0px;
    width: 100%; }
  .report-blood-chats .bars {
    position: relative;
    overflow: hidden;
    display: flex;
    height: 434px;
    justify-content: flex-start;
    padding-bottom: 20px; }
    .report-blood-chats .bars .VictoryContainer svg {
      width: auto !important;
      max-width: 100%; }

.report-files .upload-btn {
  margin-left: 35px; }

.report-files .files-card {
  padding: 10px;
  color: #4C6072;
  border-radius: 8px; }
  .report-files .files-card:hover {
    background: rgba(196, 196, 196, 0.2); }

.report-files .card-cover {
  overflow: hidden;
  height: 134px;
  border-radius: 8px; }
  .report-files .card-cover > img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .report-files .card-cover .video-thum, .report-files .card-cover .file-content {
    position: relative;
    width: 100%;
    height: 100%; }
    .report-files .card-cover .video-thum > img, .report-files .card-cover .file-content > img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .report-files .card-cover .video-thum {
    background: rgba(0, 0, 0, 0.8); }
  .report-files .card-cover .file-content {
    background: #264382; }

.report-files .ant-card-body {
  padding: 0; }
  .report-files .ant-card-body .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .report-files .ant-card-body .card-content .file-info {
      width: 85%;
      font-size: 14px;
      letter-spacing: 0.366667px;
      text-transform: capitalize; }
      .report-files .ant-card-body .card-content .file-info .ant-typography {
        margin: 0; }

.report-blood-test-result {
  margin-bottom: 95px;
  margin-top: 45px; }
  .report-blood-test-result .category .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-end; }
    .report-blood-test-result .category .header .left {
      width: auto !important;
      color: #4C6072;
      font-size: 21px;
      font-weight: bold;
      line-height: 29px; }
      .report-blood-test-result .category .header .left span {
        color: #323A46;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 2px;
        opacity: 0.5; }
    .report-blood-test-result .category .header .right {
      width: auto !important; }
      .report-blood-test-result .category .header .right span {
        color: rgba(50, 58, 70, 0.8);
        text-transform: uppercase;
        letter-spacing: 0.3px;
        line-height: 29px;
        font-size: 12px;
        display: inline-block;
        margin: 0 35px; }
        .report-blood-test-result .category .header .right span:before {
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          margin-right: 5px; }
        .report-blood-test-result .category .header .right span.optimized:before {
          background: #40C47C; }
        .report-blood-test-result .category .header .right span.concern:before {
          background: #FFB800; }
        .report-blood-test-result .category .header .right span.risk:before {
          background: #FF6B00; }
  .report-blood-test-result .biomarkers-cotainer .biomarker-item {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4C6072;
    font-size: 14px;
    text-transform: uppercase; }
    .report-blood-test-result .biomarkers-cotainer .biomarker-item:nth-child(2n) {
      background: rgba(241, 243, 245, 0.3); }
    .report-blood-test-result .biomarkers-cotainer .biomarker-item .name {
      width: calc(100% - 200px - 119*5px); }
    .report-blood-test-result .biomarkers-cotainer .biomarker-item .ranage {
      width: 100px;
      text-align: center; }
    .report-blood-test-result .biomarkers-cotainer .biomarker-item .unit {
      text-align: center;
      width: 100px;
      color: rgba(76, 96, 114, 0.6); }
    .report-blood-test-result .biomarkers-cotainer .biomarker-item .range-bar .range-item {
      display: inline-block;
      position: relative; }
      .report-blood-test-result .biomarkers-cotainer .biomarker-item .range-bar .range-item .item-bar {
        width: 117px;
        height: 8px;
        margin: 0 1px; }
      .report-blood-test-result .biomarkers-cotainer .biomarker-item .range-bar .range-item svg {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }

._1wtI-3JBY5iUnOwHATyHVN.single-file {
  min-height: 100px; }
  ._1wtI-3JBY5iUnOwHATyHVN.single-file .cover-photo {
    position: relative; }
    ._1wtI-3JBY5iUnOwHATyHVN.single-file .cover-photo > img {
      width: 100%;
      margin: 0;
      object-fit: cover; }
    ._1wtI-3JBY5iUnOwHATyHVN.single-file .cover-photo .tool-bar {
      padding: 10px 0;
      height: 61px;
      background: #75879D;
      text-align: right;
      width: 100%;
      color: #ffffff; }
      ._1wtI-3JBY5iUnOwHATyHVN.single-file .cover-photo .tool-bar .upload-progress {
        position: absolute;
        width: 100%;
        left: 0;
        top: 45px; }
      ._1wtI-3JBY5iUnOwHATyHVN.single-file .cover-photo .tool-bar > .anticon {
        font-size: 1.3em;
        margin: 0 20px;
        vertical-align: middle; }
      ._1wtI-3JBY5iUnOwHATyHVN.single-file .cover-photo .tool-bar .cancel {
        margin: 0 20px; }
      ._1wtI-3JBY5iUnOwHATyHVN.single-file .cover-photo .tool-bar .ant-btn.white {
        color: #ffffff;
        border-color: #ffffff; }

._1A1LoVeQk_8WkfoKrc8T1j {
  position: relative; }
  ._1A1LoVeQk_8WkfoKrc8T1j.disabled {
    cursor: not-allowed; }
    ._1A1LoVeQk_8WkfoKrc8T1j.disabled .anticon {
      opacity: .5;
      cursor: not-allowed; }
  ._1A1LoVeQk_8WkfoKrc8T1j .list-container {
    position: absolute; }
  ._1A1LoVeQk_8WkfoKrc8T1j .current-value {
    height: 50px;
    line-height: 50px;
    display: inline-block;
    border-bottom: 1px solid #264382;
    color: #4C6072;
    font-size: 24px; }
    ._1A1LoVeQk_8WkfoKrc8T1j .current-value .anticon {
      margin-left: 20px;
      font-size: 16px;
      cursor: pointer; }
  ._1A1LoVeQk_8WkfoKrc8T1j .options-list {
    position: relative;
    list-style: none;
    padding: 0;
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    transition: height 1s ease;
    z-index: 4; }
    ._1A1LoVeQk_8WkfoKrc8T1j .options-list.open {
      height: auto; }
    ._1A1LoVeQk_8WkfoKrc8T1j .options-list.close {
      height: 0; }
    ._1A1LoVeQk_8WkfoKrc8T1j .options-list .option-item {
      font-size: 14px;
      min-height: 45px;
      padding: 12px;
      padding-right: 60px;
      margin: 0;
      cursor: pointer; }
      ._1A1LoVeQk_8WkfoKrc8T1j .options-list .option-item.active {
        background: #eaeeee; }
      ._1A1LoVeQk_8WkfoKrc8T1j .options-list .option-item:hover {
        background: #EAEDF3; }

._8T6GXZW48l7spsZq2I835 .personal-value {
  display: inline-block;
  line-height: 17px;
  font-size: 24px;
  color: #323A46;
  padding: 5px 0; }
  ._8T6GXZW48l7spsZq2I835 .personal-value img, ._8T6GXZW48l7spsZq2I835 .personal-value .text {
    display: inline-block;
    margin: 10px 0;
    margin-left: 10px; }
  ._8T6GXZW48l7spsZq2I835 .personal-value .text {
    margin-left: 0;
    line-height: 1; }
  ._8T6GXZW48l7spsZq2I835 .personal-value button {
    padding: 0 20px;
    margin: 10px 0;
    margin-left: 10px; }
  ._8T6GXZW48l7spsZq2I835 .personal-value.disabled {
    cursor: not-allowed; }
    ._8T6GXZW48l7spsZq2I835 .personal-value.disabled img {
      opacity: .5; }

._8T6GXZW48l7spsZq2I835 .error-message {
  color: #f5222d;
  font-size: 12px;
  margin-top: -15px; }

._3eJleHI15OxP8vt9VMcSZy {
  margin-top: 15px; }
  ._3eJleHI15OxP8vt9VMcSZy .rectangular-container {
    width: 103px;
    min-height: 32px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px; }
  ._3eJleHI15OxP8vt9VMcSZy .rectangular-text {
    color: white;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;
    text-align: center; }

._1nawxxYi__HdnBHPRiSX0n {
  margin-bottom: 25px;
  padding: 20px 25px;
  padding-bottom: 0;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex; }
  ._1nawxxYi__HdnBHPRiSX0n .personal-value-container, ._1nawxxYi__HdnBHPRiSX0n .range {
    display: inline-block; }
    ._1nawxxYi__HdnBHPRiSX0n .personal-value-container .ranges-box, ._1nawxxYi__HdnBHPRiSX0n .range .ranges-box {
      text-transform: none; }
  ._1nawxxYi__HdnBHPRiSX0n .ant-divider-vertical {
    height: auto;
    margin: 20px 20px 40px; }
  ._1nawxxYi__HdnBHPRiSX0n .personal-value-container {
    min-height: 190px;
    padding: 20px 0;
    width: 200px;
    align-self: center; }
    ._1nawxxYi__HdnBHPRiSX0n .personal-value-container .summary {
      font-size: 16px;
      color: inheirt;
      opacity: .8; }
      ._1nawxxYi__HdnBHPRiSX0n .personal-value-container .summary:first-letter {
        text-transform: uppercase; }
    ._1nawxxYi__HdnBHPRiSX0n .personal-value-container .name {
      font-weight: bold;
      line-height: 17px;
      font-size: 16px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      color: #4C6072;
      padding-bottom: 20px; }
    ._1nawxxYi__HdnBHPRiSX0n .personal-value-container .value {
      margin: 5px 0;
      color: #75879D; }
      ._1nawxxYi__HdnBHPRiSX0n .personal-value-container .value > span {
        line-height: 17px;
        font-size: 12px;
        letter-spacing: 0.006em;
        text-transform: uppercase;
        color: #75879D; }
    ._1nawxxYi__HdnBHPRiSX0n .personal-value-container .date {
      font-weight: bold;
      line-height: 29px;
      font-size: 12px;
      color: #75879D; }
      ._1nawxxYi__HdnBHPRiSX0n .personal-value-container .date span, ._1nawxxYi__HdnBHPRiSX0n .personal-value-container .date img {
        vertical-align: middle;
        margin-right: 8px; }
  ._1nawxxYi__HdnBHPRiSX0n .range {
    padding-bottom: 10px;
    width: 200px;
    padding-right: 30px;
    align-self: center; }
    ._1nawxxYi__HdnBHPRiSX0n .range .ant-switch {
      margin: 15px 0; }
    ._1nawxxYi__HdnBHPRiSX0n .range > div {
      align-items: center;
      justify-content: space-between;
      padding: 0 18px;
      color: rgba(76, 96, 114, 0.8);
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.366667px;
      text-transform: capitalize;
      padding-bottom: 10px; }
      ._1nawxxYi__HdnBHPRiSX0n .range > div .ranges {
        font-size: 16px;
        opacity: 0.8; }
      ._1nawxxYi__HdnBHPRiSX0n .range > div:first-child {
        border-bottom: 1px solid #EAEDF3; }
      ._1nawxxYi__HdnBHPRiSX0n .range > div:first-child:last-child {
        border: none; }
    ._1nawxxYi__HdnBHPRiSX0n .range .range-box {
      font-size: 14px;
      margin-bottom: 13px; }
  ._1nawxxYi__HdnBHPRiSX0n .chat {
    margin-bottom: 20px;
    flex: auto; }

._1eimwBhF1l96JGUKkI74zL.metric-container {
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 265px;
  border: 1px solid #EAEDF3;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  overflow: hidden;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer; }
  ._1eimwBhF1l96JGUKkI74zL.metric-container > div {
    padding: 0 15px; }
  ._1eimwBhF1l96JGUKkI74zL.metric-container .top-tip {
    color: #ffffff !important;
    text-align: right;
    text-transform: uppercase;
    font-size: 12px;
    height: 20px;
    line-height: 20px; }
  ._1eimwBhF1l96JGUKkI74zL.metric-container .name {
    color: #297FCA;
    opacity: 0.8;
    line-height: 26px;
    margin-top: 10px;
    font-family: Gilroy-Bold; }
  ._1eimwBhF1l96JGUKkI74zL.metric-container .summary {
    font-size: 14px;
    color: #4C6072;
    opacity: 0.8;
    letter-spacing: 0.3px;
    margin-top: 5px;
    font-family: Heebo; }
  ._1eimwBhF1l96JGUKkI74zL.metric-container .personal-value {
    font-size: 18px;
    color: #4C6072;
    margin: 10px 0;
    font-family: Gilroy-Bold;
    font-weight: bold; }
    ._1eimwBhF1l96JGUKkI74zL.metric-container .personal-value .unit {
      font-family: Gilroy;
      font-size: 12px;
      margin-left: 5px; }

._160VYHXSYybiFQhaTi8qoy {
  background: #ffffff;
  border: 1px solid #EAEDF3;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding-left: 32px;
  margin-bottom: 30px; }
  ._160VYHXSYybiFQhaTi8qoy .logo-container {
    padding-top: 30px;
    width: 100%; }
    ._160VYHXSYybiFQhaTi8qoy .logo-container + .root-category {
      padding-top: 0; }
  ._160VYHXSYybiFQhaTi8qoy .root-category {
    line-height: 29px;
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #3F4752; }
    ._160VYHXSYybiFQhaTi8qoy .root-category img {
      margin-left: 5px; }
    ._160VYHXSYybiFQhaTi8qoy .root-category div:first-child:last-child {
      flex: auto; }
  ._160VYHXSYybiFQhaTi8qoy .root-category {
    color: #4C6072;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: -32px;
    padding-left: 32px;
    padding-top: 30px;
    display: flex;
    flex-wrap: normal;
    justify-content: space-between; }
    ._160VYHXSYybiFQhaTi8qoy .root-category.root-title {
      padding-right: 32px; }
    ._160VYHXSYybiFQhaTi8qoy .root-category .full-width {
      flex: auto; }
    ._160VYHXSYybiFQhaTi8qoy .root-category .root-category {
      padding-top: 0;
      padding-right: 32px; }
    ._160VYHXSYybiFQhaTi8qoy .root-category + .right {
      height: 60px; }
    ._160VYHXSYybiFQhaTi8qoy .root-category h1 {
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 33px;
      font-weight: normal; }
    ._160VYHXSYybiFQhaTi8qoy .root-category p {
      letter-spacing: 0.3px;
      font-size: 14px;
      line-height: 26px;
      opacity: 0.8;
      margin-top: 5px; }
    ._160VYHXSYybiFQhaTi8qoy .root-category .description {
      width: 675px; }
  ._160VYHXSYybiFQhaTi8qoy .sub-category-container {
    margin-left: -32px;
    padding-left: 32px;
    padding-right: 32px;
    flex: auto;
    width: calc(100% + 32px); }
    ._160VYHXSYybiFQhaTi8qoy .sub-category-container .top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-right: 0;
      padding-top: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 30px 0; }
      ._160VYHXSYybiFQhaTi8qoy .sub-category-container .top .description {
        color: #4C6072;
        width: 675px;
        opacity: 0.8;
        font-size: 14px;
        padding-top: 8px; }
      ._160VYHXSYybiFQhaTi8qoy .sub-category-container .top .name {
        color: #323A46;
        font-weight: 700;
        line-height: 29px;
        font-size: 18px;
        text-transform: capitalize;
        cursor: pointer;
        padding-right: 10px; }
        ._160VYHXSYybiFQhaTi8qoy .sub-category-container .top .name span {
          opacity: .5;
          margin-right: 12px; }
        ._160VYHXSYybiFQhaTi8qoy .sub-category-container .top .name > div {
          display: inline-block; }
    ._160VYHXSYybiFQhaTi8qoy .sub-category-container .bottom {
      padding-top: 25px;
      margin: 0;
      box-shadow: none;
      min-height: auto; }
  ._160VYHXSYybiFQhaTi8qoy .single-file {
    margin-right: 32px;
    margin-bottom: 32px; }
  ._160VYHXSYybiFQhaTi8qoy.category-container {
    width: 100%; }
  @media screen and (max-width: 1024px) {
    ._160VYHXSYybiFQhaTi8qoy .root-category {
      border: none; }
    ._160VYHXSYybiFQhaTi8qoy .sub-category-container {
      margin-left: -32px; }
      ._160VYHXSYybiFQhaTi8qoy .sub-category-container .left {
        width: 100%;
        padding-left: 32px;
        display: block; }
      ._160VYHXSYybiFQhaTi8qoy .sub-category-container .right {
        width: 100%;
        padding: 15px 32px; }
        ._160VYHXSYybiFQhaTi8qoy .sub-category-container .right .biomarker-container {
          margin-left: 0; } }

._17LPOdqiAHRpQz5w_aJ7-0 .full-width {
  flex: auto; }

._17LPOdqiAHRpQz5w_aJ7-0 .sec-no-data {
  text-align: center;
  color: #BAC3CE;
  opacity: 0.8;
  font-size: 12px;
  font-weight: 500;
  font-family: Heebo;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-left: -35px; }

._17LPOdqiAHRpQz5w_aJ7-0 .category-container {
  background: #fff;
  border: 1px solid #eaedf3;
  box-sizing: border-box;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 32px;
  margin-bottom: 30px; }
  ._17LPOdqiAHRpQz5w_aJ7-0 .category-container .image-content {
    text-align: center; }
    ._17LPOdqiAHRpQz5w_aJ7-0 .category-container .image-content img {
      max-width: 100%; }

._2PIyjjgvzplb8sQ0zpFuOT {
  min-height: 51px;
  color: rgba(117, 135, 157, 0.8); }
  ._2PIyjjgvzplb8sQ0zpFuOT .date-set-tab {
    margin-top: 10px;
    margin-bottom: 15px;
    margin-right: 12px;
    min-width: 137px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(117, 135, 157, 0.1);
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.366667px;
    text-transform: uppercase;
    color: #3F4752;
    cursor: pointer; }
    ._2PIyjjgvzplb8sQ0zpFuOT .date-set-tab svg {
      margin-left: 10px;
      color: #75879D; }
    ._2PIyjjgvzplb8sQ0zpFuOT .date-set-tab.active {
      background: #75879D;
      color: #ffffff; }
      ._2PIyjjgvzplb8sQ0zpFuOT .date-set-tab.active svg {
        color: #ffffff; }
  ._2PIyjjgvzplb8sQ0zpFuOT .data-set-action-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 10px;
    padding-right: 160px;
    margin-bottom: 40px; }
  @media screen and (max-width: 1024px) {
    ._2PIyjjgvzplb8sQ0zpFuOT .data-set-action-container {
      padding-left: 25px; } }

._1lNSc8rHP0IfvWvADJBvQq .btn-generate-report {
  text-transform: upperCase;
  float: right;
  margin-right: 15px;
  margin-top: -60px; }

._1lNSc8rHP0IfvWvADJBvQq .btn-date-set {
  position: absolute;
  vertical-align: middle;
  padding-left: 20px;
  right: 45px;
  margin-top: 30px; }
  ._1lNSc8rHP0IfvWvADJBvQq .btn-date-set .ant-switch {
    margin-right: 10px; }
  ._1lNSc8rHP0IfvWvADJBvQq .btn-date-set > span {
    margin-right: 20px;
    font-weight: bold;
    line-height: 27px;
    font-size: 16px;
    letter-spacing: 0.366667px;
    color: #3F4651; }
  ._1lNSc8rHP0IfvWvADJBvQq .btn-date-set .ant-btn {
    text-transform: upperCase;
    margin-right: 15px; }

._1nzbx1hOnlnmacul52_Haa .category-container {
  padding-top: 25px;
  padding-bottom: 30px; }
  ._1nzbx1hOnlnmacul52_Haa .category-container .root-category img {
    max-width: 100%; }
  ._1nzbx1hOnlnmacul52_Haa .category-container .root-category .description h2 {
    font-size: 24px;
    letter-spacing: 0.3px;
    font-weight: normal;
    color: #44433D; }
  ._1nzbx1hOnlnmacul52_Haa .category-container .show-more-text {
    color: #3D3D3D;
    opacity: 0.8;
    text-decoration: underline;
    cursor: pointer; }
  ._1nzbx1hOnlnmacul52_Haa .category-container .ant-typography {
    font-size: 12px;
    font-size: 14px;
    color: #44433D; }
  ._1nzbx1hOnlnmacul52_Haa .category-container .ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text {
    font-size: 18px; }
  ._1nzbx1hOnlnmacul52_Haa .category-container .ant-divider-horizontal.ant-divider-with-text-left:before {
    width: 0; }
  ._1nzbx1hOnlnmacul52_Haa .category-container .ant-typography-expand {
    display: inline-block;
    color: transparent;
    position: relative; }
    ._1nzbx1hOnlnmacul52_Haa .category-container .ant-typography-expand:before {
      content: 'See more';
      position: absolute;
      color: #3D3D3D;
      opacity: 0.8;
      text-decoration: underline;
      width: 70px;
      letter-spacing: -0.5px; }
  ._1nzbx1hOnlnmacul52_Haa .category-container .card-list {
    margin: 20px 0 0 35px; }

._3swFWnhfM_84U8Nx5W0tMh .ranges-container {
  display: flex; }

._3swFWnhfM_84U8Nx5W0tMh .range-area {
  display: inline-block;
  position: relative;
  vertical-align: top; }
  ._3swFWnhfM_84U8Nx5W0tMh .range-area .polygon-value {
    position: absolute;
    margin-top: 10px; }

._3swFWnhfM_84U8Nx5W0tMh .range-level {
  text-transform: capitalize; }

._3swFWnhfM_84U8Nx5W0tMh .range-title, ._3swFWnhfM_84U8Nx5W0tMh .range-level {
  display: flex;
  justify-content: space-between;
  color: #4C6072; }

._3swFWnhfM_84U8Nx5W0tMh .range-title {
  text-transform: uppercase;
  font-family: Heebo;
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: bold;
  line-height: 19px;
  color: #4C6072; }

._3swFWnhfM_84U8Nx5W0tMh .range-level {
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.3px;
  margin: 10px 0;
  font-family: Gilroy-Bold;
  font-weight: bold; }

._3swFWnhfM_84U8Nx5W0tMh .progress-container {
  padding: 40px 0;
  background: #ffffff; }

._3swFWnhfM_84U8Nx5W0tMh .progress-bar {
  background: #D2D2D2;
  height: 6px;
  margin: 0 2px; }

._3swFWnhfM_84U8Nx5W0tMh .range-info {
  color: rgba(76, 96, 114, 0.8);
  font-size: 12px;
  padding: 12px 8px;
  font-weight: bold;
  font-family: Heebo;
  text-align: center; }
  ._3swFWnhfM_84U8Nx5W0tMh .range-info .name {
    text-transform: uppercase;
    font-family: Heebo;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #323A46;
    opacity: 0.8; }
  ._3swFWnhfM_84U8Nx5W0tMh .range-info .area {
    font-size: 16px;
    font-family: Heebo;
    font-weight: bold;
    line-height: 21px;
    display: block;
    min-height: 21px; }

._3swFWnhfM_84U8Nx5W0tMh .range-info-container {
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
  background-color: rgba(196, 196, 196, 0.1);
  display: flex;
  flex-wrap: wrap; }
  ._3swFWnhfM_84U8Nx5W0tMh .range-info-container .long {
    width: 100%; }
    ._3swFWnhfM_84U8Nx5W0tMh .range-info-container .long .range-info {
      text-align: left;
      padding: 12px 0;
      display: flex;
      align-items: center; }
    ._3swFWnhfM_84U8Nx5W0tMh .range-info-container .long .area {
      display: inline-block;
      margin-right: 10px;
      min-width: 116px; }
    ._3swFWnhfM_84U8Nx5W0tMh .range-info-container .long .name {
      max-width: calc(100% - 116px - 15px);
      padding-right: 10px; }

._35cjIfInffZlY2ul8-wp6S .calendar-container {
  padding-top: 25px; }
  ._35cjIfInffZlY2ul8-wp6S .calendar-container .color-cicle {
    margin: 0 -15px;
    padding: 0 15px;
    padding-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    font-family: Gilroy;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    flex-wrap: wrap; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container .color-cicle span {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 4px; }
      ._35cjIfInffZlY2ul8-wp6S .calendar-container .color-cicle span.Resistance {
        background: #58646B; }
      ._35cjIfInffZlY2ul8-wp6S .calendar-container .color-cicle span.Aerobic {
        background: #FF6B00; }
      ._35cjIfInffZlY2ul8-wp6S .calendar-container .color-cicle span.Activity {
        background: #BCCA70; }
      ._35cjIfInffZlY2ul8-wp6S .calendar-container .color-cicle span.Movement-Quality {
        background: #C29E7C; }
      ._35cjIfInffZlY2ul8-wp6S .calendar-container .color-cicle span.non-program {
        background: #C4C4C4; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container .color-cicle > div {
      margin-right: 24px;
      color: #383E48; }
      ._35cjIfInffZlY2ul8-wp6S .calendar-container .color-cicle > div span {
        margin-right: 8px; }
  ._35cjIfInffZlY2ul8-wp6S .calendar-container.any-activity .date-item .color-cicle {
    border: none;
    padding: 0;
    margin: 0;
    display: block;
    text-align: center;
    line-height: 1; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container.any-activity .date-item .color-cicle span {
      margin: 0 1px; }
  ._35cjIfInffZlY2ul8-wp6S .calendar-container + .data-sync {
    padding-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    font-family: Gilroy;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    text-align: center; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container + .data-sync span {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 4px; }
      ._35cjIfInffZlY2ul8-wp6S .calendar-container + .data-sync span.Resistance {
        background: #641717; }
      ._35cjIfInffZlY2ul8-wp6S .calendar-container + .data-sync span.Aerobic {
        background: #E9AF8E; }
      ._35cjIfInffZlY2ul8-wp6S .calendar-container + .data-sync span.Yoga {
        background: #2AC3D7; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container + .data-sync > div {
      margin-right: 24px;
      color: #383E48; }
      ._35cjIfInffZlY2ul8-wp6S .calendar-container + .data-sync > div span {
        margin-right: 8px; }
  ._35cjIfInffZlY2ul8-wp6S .calendar-container.any-activity .date-item .color-cicle {
    border: none;
    padding: 0;
    margin: 0;
    display: block;
    text-align: center;
    line-height: 1; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container.any-activity .date-item .color-cicle span {
      margin: 0 1px; }
  ._35cjIfInffZlY2ul8-wp6S .calendar-container .label-item, ._35cjIfInffZlY2ul8-wp6S .calendar-container .date-item {
    display: inline-block;
    width: 14.25%;
    font-size: 12px;
    letter-spacing: 0.3px;
    color: #9C9FA4;
    text-align: center;
    margin-bottom: 5px;
    vertical-align: top; }
  ._35cjIfInffZlY2ul8-wp6S .calendar-container .date-item .input-cicle {
    padding: 2px;
    width: 36px;
    height: 36px;
    margin: 0 3px;
    border: 2px dotted transparent;
    border-radius: 100%; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container .date-item .input-cicle.activity {
      border-color: #7BAD2D; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container .date-item .input-cicle > div {
      cursor: pointer; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container .date-item .input-cicle .green {
      background: #7BAD2D; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container .date-item .input-cicle .red {
      background: #E75F25; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container .date-item .input-cicle .blue {
      background: #2B4B8F; }
    ._35cjIfInffZlY2ul8-wp6S .calendar-container .date-item .input-cicle div {
      width: 100%;
      height: 100%;
      background: #BAC3CE;
      color: #ffffff;
      font-size: 14px;
      border-radius: 100%;
      position: relative;
      line-height: 28px; }
      ._35cjIfInffZlY2ul8-wp6S .calendar-container .date-item .input-cicle div .today {
        position: absolute;
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #297FCA;
        bottom: 2px;
        left: 50%;
        margin-left: -2.5px; }

.ant-popover-content {
  box-shadow: none;
  border-radius: 4px;
  overflow: hidden; }
  .ant-popover-content .ant-popover-arrow {
    border-color: #E8ECF0 !important;
    box-shadow: none !important; }
  .ant-popover-content .ant-popover-inner-content {
    padding: 0 !important; }
    .ant-popover-content .ant-popover-inner-content .popove-tool {
      padding: 0;
      background: #E8ECF0; }
      .ant-popover-content .ant-popover-inner-content .popove-tool div {
        padding: 5px 16px;
        padding-right: 30px;
        font-family: Gilroy;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.3px;
        color: #52606C;
        cursor: pointer; }
        .ant-popover-content .ant-popover-inner-content .popove-tool div:first-child {
          border-bottom: 1px solid #BAC3CE; }
        .ant-popover-content .ant-popover-inner-content .popove-tool div .anticon {
          color: #BAC3CE;
          margin-right: 8px; }

.qZjVFbOqnMsGpeDLVn6BJ .add-icon {
  color: #3561C0;
  display: inline-block; }
  .qZjVFbOqnMsGpeDLVn6BJ .add-icon .anticon {
    margin-right: 5px; }
  .qZjVFbOqnMsGpeDLVn6BJ .add-icon div {
    display: inline-block; }

.qZjVFbOqnMsGpeDLVn6BJ .attach-list {
  margin-bottom: 22px;
  margin-top: 29px; }
  .qZjVFbOqnMsGpeDLVn6BJ .attach-list .attach-item, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item {
    background: #E8ECF0;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .qZjVFbOqnMsGpeDLVn6BJ .attach-list .attach-item .thumb, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item .thumb, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .thumb {
      width: 47px;
      height: 47px;
      object-fit: cover;
      color: #E8ECF0;
      text-align: center;
      overflow: hidden; }
      .qZjVFbOqnMsGpeDLVn6BJ .attach-list .attach-item .thumb.link, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item .thumb.link, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .thumb.link {
        font-size: 30px; }
      .qZjVFbOqnMsGpeDLVn6BJ .attach-list .attach-item .thumb.text, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item .thumb.text, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .thumb.text {
        line-height: 47px;
        text-transform: uppercase; }
    .qZjVFbOqnMsGpeDLVn6BJ .attach-list .attach-item .name, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item .name, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .name {
      font-family: Heebo;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.703548px;
      color: rgba(186, 195, 206, 0.7);
      max-width: 100px; }
      .qZjVFbOqnMsGpeDLVn6BJ .attach-list .attach-item .name .title, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item .name .title, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .name .title {
        font-family: Gilroy;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.3px;
        height: 18px;
        color: #52606C; }
      .qZjVFbOqnMsGpeDLVn6BJ .attach-list .attach-item .name .ant-typography, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item .name .ant-typography, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .name .ant-typography {
        margin: 0; }
    .qZjVFbOqnMsGpeDLVn6BJ .attach-list .attach-item .anticon-close, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item .anticon-close, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .anticon-close {
      cursor: pointer; }
  .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item {
    padding: 10px; }
    .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item .name, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .name {
      max-width: 300px;
      width: 300px; }
    .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item .anticon-close, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .anticon-close {
      color: #3561C0; }
    .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item .rate, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .rate {
      font-family: Gilroy;
      font-size: 14px;
      letter-spacing: 0.3px;
      color: #323F4A; }
      .qZjVFbOqnMsGpeDLVn6BJ .attach-list .file-item .rate.finish, .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .rate.finish {
        color: #7BAD2D; }
  .qZjVFbOqnMsGpeDLVn6BJ .attach-list .link-item .name {
    max-width: 425px;
    width: 425px;
    margin-right: 55px; }

.qZjVFbOqnMsGpeDLVn6BJ .upload-text .ant-upload {
  font-family: Gilroy;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: #3561C0;
  cursor: pointer; }
  .qZjVFbOqnMsGpeDLVn6BJ .upload-text .ant-upload .anticon {
    margin-right: 5px; }
    .qZjVFbOqnMsGpeDLVn6BJ .upload-text .ant-upload .anticon + div {
      display: inline-block; }

.qZjVFbOqnMsGpeDLVn6BJ .ant-upload-list {
  display: none; }

.qZjVFbOqnMsGpeDLVn6BJ .ant-legacy-form-item-link-container {
  display: flex;
  align-items: center; }
  .qZjVFbOqnMsGpeDLVn6BJ .ant-legacy-form-item-link-container .ant-legacy-form-item-label > label {
    text-transform: none; }
  .qZjVFbOqnMsGpeDLVn6BJ .ant-legacy-form-item-link-container .ant-legacy-form-item-label > label::after {
    content: ''; }
  .qZjVFbOqnMsGpeDLVn6BJ .ant-legacy-form-item-link-container .control {
    flex-grow: 1; }
  .qZjVFbOqnMsGpeDLVn6BJ .ant-legacy-form-item-link-container .add {
    width: 84px;
    height: 38px;
    line-height: 35px;
    text-align: center;
    font-family: Heebo;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #264382;
    cursor: pointer;
    border-radius: 19px;
    border: 2px solid #264382;
    margin-left: 16px; }
  .qZjVFbOqnMsGpeDLVn6BJ .ant-legacy-form-item-link-container .unclickable {
    opacity: 0.5; }

.qZjVFbOqnMsGpeDLVn6BJ .link-input {
  position: relative; }
  .qZjVFbOqnMsGpeDLVn6BJ .link-input .ant-input {
    padding-right: 60px; }

.qZjVFbOqnMsGpeDLVn6BJ .separator {
  border: 0.5px solid #E8ECF0; }

.qZjVFbOqnMsGpeDLVn6BJ .ant-modal-header, .qZjVFbOqnMsGpeDLVn6BJ .ant-modal-footer {
  border: none; }
  .qZjVFbOqnMsGpeDLVn6BJ .ant-modal-header .ant-modal-title, .qZjVFbOqnMsGpeDLVn6BJ .ant-modal-footer .ant-modal-title {
    font-family: Gilroy;
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #4C6072; }

.TwX0Htv1Epz3aHtGsl9bW .ant-modal-footer {
  padding: 0px 0px; }

._2HVmhtbYgBotYBSVMioFk3 {
  width: 725px !important; }
  ._2HVmhtbYgBotYBSVMioFk3.select-modal {
    width: 595px !important;
    border-radius: 12px; }
    ._2HVmhtbYgBotYBSVMioFk3.select-modal .ant-modal-header, ._2HVmhtbYgBotYBSVMioFk3.select-modal .ant-modal-body, ._2HVmhtbYgBotYBSVMioFk3.select-modal .ant-modal-footer {
      padding-left: 30px;
      padding-right: 30px; }
    ._2HVmhtbYgBotYBSVMioFk3.select-modal .select-type-icon {
      width: 260px;
      height: 260px;
      display: inline-block;
      text-align: center;
      padding-top: 90px;
      font-size: 18px;
      cursor: pointer; }
      ._2HVmhtbYgBotYBSVMioFk3.select-modal .select-type-icon.selected {
        background: #F1F3F6;
        border-radius: 12px; }
      ._2HVmhtbYgBotYBSVMioFk3.select-modal .select-type-icon img {
        margin-bottom: 20px;
        width: 36px; }
  ._2HVmhtbYgBotYBSVMioFk3.delete-confirm {
    font-weight: bold;
    color: #383E48; }
    ._2HVmhtbYgBotYBSVMioFk3.delete-confirm .ant-modal-close-x {
      font-size: 22px; }
    ._2HVmhtbYgBotYBSVMioFk3.delete-confirm .confirm {
      margin-top: 35px;
      margin-bottom: 30px;
      font-size: 18px; }
    ._2HVmhtbYgBotYBSVMioFk3.delete-confirm .name {
      font-size: 24px;
      margin-bottom: 90px; }
    ._2HVmhtbYgBotYBSVMioFk3.delete-confirm .footer-opr {
      text-align: right;
      color: #264382;
      font-size: 18px;
      font-weight: 500; }
      ._2HVmhtbYgBotYBSVMioFk3.delete-confirm .footer-opr span {
        margin-left: 50px;
        cursor: pointer; }
  ._2HVmhtbYgBotYBSVMioFk3 .ant-modal-close {
    top: 10px;
    right: 10px; }
  ._2HVmhtbYgBotYBSVMioFk3 .ant-modal-header {
    padding: 0 50px;
    padding-top: 50px;
    border: none; }
    ._2HVmhtbYgBotYBSVMioFk3 .ant-modal-header .ant-modal-title {
      color: #8D99A5;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.3px;
      text-transform: capitalize; }
  ._2HVmhtbYgBotYBSVMioFk3 .ant-modal-body {
    padding: 24px 40px;
    padding-top: 10px; }
  ._2HVmhtbYgBotYBSVMioFk3 .ant-modal-footer {
    border: none;
    padding: 16px 50px;
    padding-bottom: 50px; }
    ._2HVmhtbYgBotYBSVMioFk3 .ant-modal-footer .ant-btn {
      text-transform: uppercase;
      padding: 5px 15px;
      height: 35px; }
  ._2HVmhtbYgBotYBSVMioFk3 .body-title {
    font-weight: normal;
    font-size: 32px;
    line-height: 29px;
    color: #4C6072;
    margin-bottom: 50px; }
  ._2HVmhtbYgBotYBSVMioFk3 .only-expert {
    padding-left: 5px;
    padding-bottom: 40px;
    color: rgba(56, 62, 72, 0.5); }

.NJHCeZQSeCmoaJUQQ2zkl {
  min-height: calc( 100vh - 70px - 47px);
  margin-top: -40px;
  position: relative;
  z-index: 2; }
  .NJHCeZQSeCmoaJUQQ2zkl .title {
    color: #383E48;
    font-size: 32px;
    line-height: 29px;
    margin-bottom: 35px;
    display: flex;
    justify-content: space-between; }
    .NJHCeZQSeCmoaJUQQ2zkl .title div {
      text-align: right;
      display: inline-block; }
    .NJHCeZQSeCmoaJUQQ2zkl .title .switch-date-range {
      display: flex; }
    .NJHCeZQSeCmoaJUQQ2zkl .title.user {
      padding-top: 40px;
      margin-bottom: 25px; }
    .NJHCeZQSeCmoaJUQQ2zkl .title.Expert {
      text-align: right;
      margin-top: -77px; }
      .NJHCeZQSeCmoaJUQQ2zkl .title.Expert > div {
        width: 100%; }
      .NJHCeZQSeCmoaJUQQ2zkl .title.Expert .today {
        float: none; }
  .NJHCeZQSeCmoaJUQQ2zkl .left, .NJHCeZQSeCmoaJUQQ2zkl .right {
    display: inline-block; }
  .NJHCeZQSeCmoaJUQQ2zkl .left {
    position: absolute;
    height: 100%;
    width: 338px;
    background: #ffffff;
    padding: 30px; }
    .NJHCeZQSeCmoaJUQQ2zkl .left .user-name span {
      display: inline-block;
      width: 150px; }
    .NJHCeZQSeCmoaJUQQ2zkl .left .user-name img {
      margin: 0 10px; }
    .NJHCeZQSeCmoaJUQQ2zkl .left .primary-data > div .item, .NJHCeZQSeCmoaJUQQ2zkl .left .primary-data > div .value {
      display: inline-block;
      width: 50%;
      font-size: 14px;
      line-height: 27px;
      letter-spacing: 0.366667px; }
    .NJHCeZQSeCmoaJUQQ2zkl .left .primary-data > div .item {
      color: #70808E;
      opacity: 0.8;
      text-transform: uppercase; }
  .NJHCeZQSeCmoaJUQQ2zkl .right {
    width: calc(100% - 338px);
    margin-left: 338px;
    padding: 45px 33px; }
    .NJHCeZQSeCmoaJUQQ2zkl .right .archived-goals {
      float: right;
      color: #4C6072;
      font-size: 12px;
      opacity: 0.5;
      cursor: pointer;
      letter-spacing: 1px;
      line-height: 42px; }
      .NJHCeZQSeCmoaJUQQ2zkl .right .archived-goals .anticon {
        margin-right: 7px; }
  .NJHCeZQSeCmoaJUQQ2zkl .rate {
    display: flex;
    padding: 0 15px;
    align-items: center; }
    .NJHCeZQSeCmoaJUQQ2zkl .rate div {
      margin-right: 30px;
      color: #666762;
      letter-spacing: 0.3px; }
      .NJHCeZQSeCmoaJUQQ2zkl .rate div span {
        color: #95999F; }
      .NJHCeZQSeCmoaJUQQ2zkl .rate div .green {
        color: #80B32E; }
      .NJHCeZQSeCmoaJUQQ2zkl .rate div .red {
        color: #EA4D00; }
  .NJHCeZQSeCmoaJUQQ2zkl .select-bar {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 57px; }
    .NJHCeZQSeCmoaJUQQ2zkl .select-bar .period {
      border-radius: 3px 0px 0px 3px;
      text-transform: uppercase;
      display: inline-block; }
      .NJHCeZQSeCmoaJUQQ2zkl .select-bar .period div {
        display: inline-block;
        color: #BAC3CE;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: pointer; }
        .NJHCeZQSeCmoaJUQQ2zkl .select-bar .period div:first-child {
          margin-right: 37px; }
      .NJHCeZQSeCmoaJUQQ2zkl .select-bar .period .selected {
        color: #383E48;
        border-bottom: 3px solid #264382; }
  .NJHCeZQSeCmoaJUQQ2zkl.Expert .goal-detail-bg {
    margin-right: -44px;
    margin-top: -85px;
    margin-left: -314px; }

.RgYPnu0UeMcyARADRS9bu .switch-date-range {
  display: flex;
  width: 270px;
  font-size: 14px;
  font-family: Gilroy;
  font-weight: 500;
  border-radius: 12px;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  color: #52606C;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  background: #ffffff;
  border: 1px solid #DEE2EC; }
  .RgYPnu0UeMcyARADRS9bu .switch-date-range .arrow {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    text-align: center;
    line-height: 30px; }
    .RgYPnu0UeMcyARADRS9bu .switch-date-range .arrow.hide {
      visibility: hidden; }

.detailItem_activityTypeTextContainer__2UDZ_ {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 24px; }
  .detailItem_activityTypeTextContainer__2UDZ_ .detailItem_activityTextTitle__2QccD {
    font-family: Gilroy;
    font-size: 12px;
    line-height: 150%;
    color: #60656D; }
  .detailItem_activityTypeTextContainer__2UDZ_ .detailItem_activityTextContent__2AKwJ {
    font-family: Gilroy-Bold;
    font-size: 18px;
    line-height: 150%;
    color: #383E48; }
    .detailItem_activityTypeTextContainer__2UDZ_ .detailItem_activityTextContent__2AKwJ.detailItem_source__T8On6 {
      text-transform: capitalize; }
    .detailItem_activityTypeTextContainer__2UDZ_ .detailItem_activityTextContent__2AKwJ.detailItem_daySummary__1X84y {
      font-weight: 400; }
      .detailItem_activityTypeTextContainer__2UDZ_ .detailItem_activityTextContent__2AKwJ.detailItem_daySummary__1X84y .circle {
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 100%;
        margin-right: 10px;
        margin-left: -20px; }
      .detailItem_activityTypeTextContainer__2UDZ_ .detailItem_activityTextContent__2AKwJ.detailItem_daySummary__1X84y .Resistance {
        background: #383E48; }
      .detailItem_activityTypeTextContainer__2UDZ_ .detailItem_activityTextContent__2AKwJ.detailItem_daySummary__1X84y .Aerobic {
        background: #FF6B00; }
      .detailItem_activityTypeTextContainer__2UDZ_ .detailItem_activityTextContent__2AKwJ.detailItem_daySummary__1X84y .Activity {
        background: #BCCA70; }
      .detailItem_activityTypeTextContainer__2UDZ_ .detailItem_activityTextContent__2AKwJ.detailItem_daySummary__1X84y .Movement {
        background: #C29E7C; }
    .detailItem_activityTypeTextContainer__2UDZ_ .detailItem_activityTextContent__2AKwJ .detailItem_durationSuffix__1loun {
      font-size: 12px;
      font-family: Gilroy;
      font-weight: 500;
      margin-left: 7px;
      color: gray; }

._2Hcbo6C_EKOrVwD22HKWrg {
  margin-top: 22px; }
  ._2Hcbo6C_EKOrVwD22HKWrg .ant-tabs-bar {
    border: none; }
  ._2Hcbo6C_EKOrVwD22HKWrg .ant-tabs-nav .ant-tabs-tab {
    margin-right: 18px;
    border: none;
    background: transparent;
    padding: 10px 20px;
    border-radius: 12px;
    color: #BAC3CE;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.3px;
    cursor: pointer; }
  ._2Hcbo6C_EKOrVwD22HKWrg .ant-tabs-nav .ant-tabs-tab-active {
    background: rgba(38, 67, 130, 0.1);
    color: #264382; }
  ._2Hcbo6C_EKOrVwD22HKWrg .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: transparent; }
  ._2Hcbo6C_EKOrVwD22HKWrg .activity-tab-description {
    color: #80848A;
    padding-bottom: 20px; }
    ._2Hcbo6C_EKOrVwD22HKWrg .activity-tab-description span {
      text-transform: lowercase; }

.HRZStage_heartRateTitle__2Nk2P {
  font-family: 'Gilroy-bold';
  font-weight: 600;
  font-size: 14px; }
  .HRZStage_heartRateTitle__2Nk2P .HRZStage_anticon__3nrRt {
    color: #2B4B8F;
    margin-left: 8px; }
  .HRZStage_heartRateTitle__2Nk2P + .HRZStage_activityHeartRateZone__G29iI {
    margin-top: 15px; }

.HRZStage_activityHeartRateZoneContainer__3kVM8 .HRZStage_activityHeartRateZone__G29iI {
  margin-bottom: 15px;
  display: flex;
  height: 48px; }
  .HRZStage_activityHeartRateZoneContainer__3kVM8 .HRZStage_activityHeartRateZone__G29iI .HRZStage_activityHeartRateInfoContainer__wD1iT {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    margin-left: 12px; }
    .HRZStage_activityHeartRateZoneContainer__3kVM8 .HRZStage_activityHeartRateZone__G29iI .HRZStage_activityHeartRateInfoContainer__wD1iT .HRZStage_time__1Bos4 {
      font-family: Gilroy-Bold;
      font-size: 12px;
      line-height: 150%;
      color: #383E48; }
    .HRZStage_activityHeartRateZoneContainer__3kVM8 .HRZStage_activityHeartRateZone__G29iI .HRZStage_activityHeartRateInfoContainer__wD1iT .HRZStage_subContent__1x_Nv {
      font-size: 10px;
      line-height: 150%;
      color: #A6B0B9; }

._1YwS_e83inZrsk3mUYUO8B {
  margin: 5px 24px;
  display: flex;
  align-items: stretch;
  flex-direction: column; }
  ._1YwS_e83inZrsk3mUYUO8B .zone-background {
    background: #EAEFF7;
    border-radius: 6px;
    overflow: hidden;
    height: 36px;
    display: flex;
    position: relative; }
    ._1YwS_e83inZrsk3mUYUO8B .zone-background .percentage {
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #383E48;
      position: absolute;
      top: 34%; }
    ._1YwS_e83inZrsk3mUYUO8B .zone-background .hit-ideal-line {
      background-color: #383E48;
      width: 2px;
      height: 36px;
      position: absolute; }
    ._1YwS_e83inZrsk3mUYUO8B .zone-background .left-border {
      border-radius: 6px 0px 0px 6px; }
    ._1YwS_e83inZrsk3mUYUO8B .zone-background .right-border {
      border-radius: 0px 6px 6px 0px; }
  ._1YwS_e83inZrsk3mUYUO8B .zone-target {
    margin-top: 2px;
    position: relative;
    height: 20px; }
    ._1YwS_e83inZrsk3mUYUO8B .zone-target .zone-target-label {
      position: absolute;
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 8px;
      line-height: 140%;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #70808E; }

._3DgZhSsqQ3DPs2BE8Py8Bd .zone-breakdown-container {
  margin-bottom: 16px;
  display: flex; }
  ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-breakdown-container .zone-background {
    background: #EAEFF7;
    border-radius: 6px;
    height: 32px; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-breakdown-container .zone-background .zone {
      border-radius: 6px;
      height: 32px; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-breakdown-container .zone-background .zone-minimum {
      display: inline;
      height: 32px;
      width: 2px;
      float: left; }
  ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-breakdown-container .zone-data-container {
    margin-left: 16px; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-breakdown-container .zone-data-container .zone-data svg {
      vertical-align: bottom; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-breakdown-container .zone-data-container .zone-desc svg {
      vertical-align: top; }

._3DgZhSsqQ3DPs2BE8Py8Bd .zone-chart-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 16px;
  border-radius: 8px;
  background: rgba(38, 67, 130, 0.05); }
  ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-chart-label svg {
    vertical-align: middle; }
  ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-chart-label .tip {
    color: #70808E;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: bold;
    font-size: 9px; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-chart-label .tip span:first-child, ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-chart-label .tip i:first-child {
      margin-right: 8px;
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-chart-label .tip .rectangle {
      background: #EAEFF6;
      border: 2px solid #CFD7E2;
      box-sizing: border-box;
      border-radius: 4px;
      width: 14px;
      height: 14px; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .zone-chart-label .tip .dot {
      background: black;
      width: 2px;
      height: 2px;
      display: inline-block;
      margin-right: 2px; }

._3DgZhSsqQ3DPs2BE8Py8Bd .list {
  width: 251px; }
  ._3DgZhSsqQ3DPs2BE8Py8Bd .list > div {
    padding-left: 25px; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .list > div:first-child {
      border-bottom: 1px solid #E4EAEF; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .list > div span.icon {
      margin-left: -26px;
      margin-top: 8px;
      float: left;
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 4px; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .list > div .Moderate {
      background: #F7AD69; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .list > div .Intense {
      background: #FC7558; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .list > div div {
      padding: 7px 0;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      border-bottom: 1px solid #E4EAEF; }
      ._3DgZhSsqQ3DPs2BE8Py8Bd .list > div div span:first-child {
        text-transform: uppercase; }
      ._3DgZhSsqQ3DPs2BE8Py8Bd .list > div div:last-child {
        border: none; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .list > div .title {
      font-family: "Gilroy-Bold";
      letter-spacing: 1px;
      color: #383E48; }
    ._3DgZhSsqQ3DPs2BE8Py8Bd .list > div .detail-item {
      letter-spacing: 0.5px;
      color: #70808E; }

._3DgZhSsqQ3DPs2BE8Py8Bd .pie {
  position: relative;
  margin-left: 60px;
  width: 200px; }
  ._3DgZhSsqQ3DPs2BE8Py8Bd .pie img {
    width: 166px; }
  ._3DgZhSsqQ3DPs2BE8Py8Bd .pie .pie-percentage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 24px;
    font-family: 'Gilroy-Bold';
    color: #383E48;
    text-align: center;
    line-height: 200px;
    letter-spacing: 0.3px; }

.rpe_rpeContainer__3vCy5 {
  padding-top: 24px;
  letter-spacing: 0.3px; }
  .rpe_rpeContainer__3vCy5 .rpe_title__lw7ff {
    font-family: Gilroy-bold;
    font-size: 17px;
    color: #383E48;
    margin: 0;
    display: block; }
    .rpe_rpeContainer__3vCy5 .rpe_title__lw7ff .rpe_anticon__3lcnw {
      color: #2B4B8F;
      margin-left: 8px;
      cursor: pointer; }
  .rpe_rpeContainer__3vCy5 .rpe_score__1GFFD, .rpe_rpeContainer__3vCy5 .rpe_explainText__j7du_ {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #60656D; }
    .rpe_rpeContainer__3vCy5 .rpe_score__1GFFD > .rpe_bold__1s1MY, .rpe_rpeContainer__3vCy5 .rpe_explainText__j7du_ > .rpe_bold__1s1MY {
      margin-left: 0; }
    .rpe_rpeContainer__3vCy5 .rpe_score__1GFFD .rpe_bold__1s1MY, .rpe_rpeContainer__3vCy5 .rpe_explainText__j7du_ .rpe_bold__1s1MY {
      font-family: Gilroy;
      margin-left: 9px; }
  .rpe_rpeContainer__3vCy5 .rpe_explainText__j7du_ {
    padding-top: 10px; }
  .rpe_rpeContainer__3vCy5 .rpe_extraInfo__29I0m {
    margin-top: 17px;
    font-size: 12px;
    line-height: 17px;
    color: #70808E;
    padding: 12px 15px;
    border: 1px solid #E4EAEF;
    box-sizing: border-box;
    border-radius: 8px; }
    .rpe_rpeContainer__3vCy5 .rpe_extraInfo__29I0m h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 124%;
      letter-spacing: 0.3px;
      color: #383E48; }
  .rpe_rpeContainer__3vCy5 .rpe_rateLevel__2QOL5 {
    font-size: 14px;
    color: #383E48; }
  .rpe_rpeContainer__3vCy5 .rpe_sliderContainer__7RRas {
    padding: 17px 0;
    position: relative; }
    .rpe_rpeContainer__3vCy5 .rpe_sliderContainer__7RRas .rpe_sliderBg__21grw {
      position: absolute;
      top: 0;
      width: calc(100% - 12px);
      margin: 40px 6px;
      padding: 4px 0;
      margin-bottom: 10px; }
      .rpe_rpeContainer__3vCy5 .rpe_sliderContainer__7RRas .rpe_sliderBg__21grw span {
        height: 3px;
        display: inline-block;
        width: 11.11%;
        background: rgba(196, 196, 196, 0.4);
        border-right: 3px solid #2B4B8F; }
      .rpe_rpeContainer__3vCy5 .rpe_sliderContainer__7RRas .rpe_sliderBg__21grw.rpe_disabled__2WJW4 span {
        border-color: rgba(43, 75, 143, 0.4); }
    .rpe_rpeContainer__3vCy5 .rpe_sliderContainer__7RRas .ant-slider {
      position: relative;
      z-index: 1; }
      .rpe_rpeContainer__3vCy5 .rpe_sliderContainer__7RRas .ant-slider .ant-slider-rail {
        background-color: transparent; }
      .rpe_rpeContainer__3vCy5 .rpe_sliderContainer__7RRas .ant-slider .ant-slider-track {
        background-color: #2B4B8F; }
      .rpe_rpeContainer__3vCy5 .rpe_sliderContainer__7RRas .ant-slider .ant-slider-handle {
        background-color: #2B4B8F;
        border-color: #ffffff !important;
        border-width: 4px;
        width: 20px;
        height: 20px;
        margin-top: -8px;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2); }
    .rpe_rpeContainer__3vCy5 .rpe_sliderContainer__7RRas .ant-slider-disabled .ant-slider-track {
      background-color: #C7CFDF !important; }
    .rpe_rpeContainer__3vCy5 .rpe_sliderContainer__7RRas .ant-slider-disabled .ant-slider-handle {
      background-color: #C7CFDF; }

.rpe_rpeValueUpdateInfoBox__3sq1F {
  margin-top: 16px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Gilroy;
  font-size: 12px; }
  .rpe_rpeValueUpdateInfoBox__3sq1F .rpe_infoLabel__3cW7j {
    color: #70808E; }
  .rpe_rpeValueUpdateInfoBox__3sq1F .rpe_undo__1CQAS {
    color: #2B4B8F;
    cursor: pointer; }

.rpe_topBottomLine__2AX0u {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

._3j3rtgD9ZuoHwhWSc7t0zf {
  font-family: Gilroy;
  /* the same as zone type ready to remove -- start */
  /* the same as zone type ready to remove -- end */ }
  ._3j3rtgD9ZuoHwhWSc7t0zf .peroid-bar {
    margin: 0 -22px;
    padding: 2px 22px 24px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    ._3j3rtgD9ZuoHwhWSc7t0zf .peroid-bar .select-peroid {
      list-style: none;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .peroid-bar .select-peroid li {
        margin-right: 32px;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        color: #9DAABA;
        letter-spacing: 0.8px;
        cursor: pointer; }
        ._3j3rtgD9ZuoHwhWSc7t0zf .peroid-bar .select-peroid li span {
          padding-bottom: 5px; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .peroid-bar .select-peroid span.active {
        color: #264382;
        border-bottom: 3px solid #264382; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .peroid-bar .switch-date-range {
      height: 40px;
      margin-top: 0; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-value-update-info-box {
    margin-top: 16px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Gilroy;
    font-size: 12px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-value-update-info-box .info-label {
      color: #70808E; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-value-update-info-box .undo {
      color: #2B4B8F;
      cursor: pointer; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .top-bottom-line {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  ._3j3rtgD9ZuoHwhWSc7t0zf .activity-toggle-container {
    margin-top: 22px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-toggle-container .ant-tabs-bar {
      border: none; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-toggle-container .ant-tabs-nav .ant-tabs-tab {
      margin-right: 18px;
      border: none;
      background: transparent;
      padding: 10px 20px;
      border-radius: 12px;
      color: #BAC3CE;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.3px;
      cursor: pointer; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-toggle-container .ant-tabs-nav .ant-tabs-tab-active {
      background: rgba(38, 67, 130, 0.1);
      color: #264382; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-toggle-container .ant-tabs-nav .ant-tabs-ink-bar {
      background-color: transparent; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-toggle-container .activity-tab-description {
      color: #80848A;
      padding-bottom: 20px; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .activity-toggle-container .activity-tab-description span {
        text-transform: lowercase; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .location-date-info {
    padding: 12px 0; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .location-date-info .location-info {
      height: 21px;
      font-family: Gilroy;
      font-size: 22px;
      line-height: 150%;
      color: #000; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .location-date-info .date-info {
      margin-top: 12px;
      height: 18px;
      font-family: Gilroy;
      font-size: 12px;
      line-height: 150%;
      color: #60656D; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .activity-section-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: #383E48; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .activity-type-text-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 24px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-type-text-container .activity-text-title {
      font-family: Gilroy;
      font-size: 12px;
      line-height: 150%;
      color: #60656D; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-type-text-container .activity-text-content {
      font-family: Gilroy-Bold;
      font-size: 18px;
      line-height: 150%;
      color: #383E48; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .activity-type-text-container .activity-text-content.source {
        text-transform: capitalize; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .activity-type-text-container .activity-text-content.day-summary {
        font-size: 16px;
        font-weight: 400; }
        ._3j3rtgD9ZuoHwhWSc7t0zf .activity-type-text-container .activity-text-content.day-summary .circle {
          width: 10px;
          height: 10px;
          display: inline-block;
          border-radius: 100%;
          margin-right: 10px;
          margin-left: -20px; }
          ._3j3rtgD9ZuoHwhWSc7t0zf .activity-type-text-container .activity-text-content.day-summary .circle.Resistance {
            background: #383E48; }
          ._3j3rtgD9ZuoHwhWSc7t0zf .activity-type-text-container .activity-text-content.day-summary .circle.Aerobic {
            background: #FF6B00; }
          ._3j3rtgD9ZuoHwhWSc7t0zf .activity-type-text-container .activity-text-content.day-summary .circle.Activity {
            background: #BCCA70; }
          ._3j3rtgD9ZuoHwhWSc7t0zf .activity-type-text-container .activity-text-content.day-summary .circle.Movement {
            background: #C29E7C; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .activity-chart-container {
    display: flex;
    padding-top: 25px;
    font-family: Heebo;
    font-size: 12px;
    line-height: 150%;
    color: #60656D; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-chart-container .activity-heart-rate-container {
      width: 334px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-chart-container .activity-heart-rate-zone-container {
      width: 334px; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .heart-rate-title {
    font-family: 'Gilroy-bold';
    font-weight: 600;
    font-size: 14px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .heart-rate-title .anticon {
      color: #2B4B8F;
      margin-left: 8px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .heart-rate-title + .activity-heart-rate-zone {
      margin-top: 15px; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .activity-heart-rate-zone-container .activity-heart-rate-pregress {
    margin-bottom: 24px;
    padding-bottom: 24px;
    font-size: 12px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-heart-rate-zone-container .activity-heart-rate-pregress .progress-title {
      margin-top: 15px;
      color: #383E48;
      font-weight: bold; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-heart-rate-zone-container .activity-heart-rate-pregress .moderate .ant-progress-bg {
      background: #FFB570; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-heart-rate-zone-container .activity-heart-rate-pregress .intense .ant-progress-bg {
      background: #FF7759; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-heart-rate-zone-container .activity-heart-rate-pregress .info {
      color: #A6B0B9; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .activity-heart-rate-zone-container .activity-heart-rate-zone {
    margin-bottom: 15px;
    display: flex;
    height: 48px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-heart-rate-zone-container .activity-heart-rate-zone .activity-heart-rate-info-container {
      display: flex;
      flex-direction: column;
      padding-top: 5px;
      margin-left: 12px; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .activity-heart-rate-zone-container .activity-heart-rate-zone .activity-heart-rate-info-container .time {
        font-family: Gilroy-Bold;
        font-size: 12px;
        line-height: 150%;
        color: #383E48; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .activity-heart-rate-zone-container .activity-heart-rate-zone .activity-heart-rate-info-container .sub-content {
        font-size: 10px;
        line-height: 150%;
        color: #A6B0B9; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .hrz-message {
    border: 1px solid #E4EAEF;
    border-radius: 8px;
    padding: 16px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .hrz-message .title {
      font-family: 'Gilroy-bold';
      font-size: 14px;
      line-height: 22px;
      color: #383E48;
      margin-bottom: 8px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .hrz-message ul {
      list-style: none;
      margin: 0;
      padding: 0;
      letter-spacing: 0.3; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .hrz-message ul li {
        display: flex;
        font-size: 12px;
        color: #70808E;
        align-items: center;
        line-height: 17px;
        margin-bottom: 5px; }
        ._3j3rtgD9ZuoHwhWSc7t0zf .hrz-message ul li span[class^='message-state'] {
          width: 5px;
          height: 5px;
          margin-right: 13px;
          border-radius: 100%; }
        ._3j3rtgD9ZuoHwhWSc7t0zf .hrz-message ul li .message-state-1 {
          background: #7BAD2D; }
        ._3j3rtgD9ZuoHwhWSc7t0zf .hrz-message ul li .message-state-0 {
          background: #FF6B00; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .activity-trend {
    height: calc(100% - 42px);
    overflow: hidden; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .activity-trend .VictoryContainer {
      display: flex; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .activity-trend .VictoryContainer svg {
        height: 100% !important; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .chart-title {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #60656D;
    font-weight: 500;
    line-height: 150%; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .chart-title span {
      margin-right: 30px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .chart-title span.circle {
      margin-right: 10px;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .input-null-status {
    text-align: center;
    padding-top: 30px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .input-null-status h3 {
      font-size: 18px;
      margin-top: 30px; }
  ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container {
    padding-top: 24px;
    letter-spacing: 0.3px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .title {
      font-family: Gilroy-bold;
      font-size: 17px;
      color: #383E48;
      margin: 0;
      display: block; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .title .anticon {
        color: #2B4B8F;
        margin-left: 8px;
        cursor: pointer; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .explain-info {
      font-size: 12px;
      color: #264382;
      background: rgba(38, 67, 130, 0.15);
      padding: 12px 24px;
      border-radius: 12px;
      margin: 16px 0; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .manual-input {
      padding-bottom: 24px; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .manual-input .ant-checkbox-wrapper {
        margin-right: 12px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .score, ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .explain-text {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #60656D; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .score > .bold, ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .explain-text > .bold {
        margin-left: 0; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .score .bold, ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .explain-text .bold {
        font-family: Gilroy;
        margin-left: 9px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .explain-text {
      padding-top: 10px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .extra-info {
      margin-top: 17px;
      font-size: 12px;
      line-height: 17px;
      color: #70808E;
      padding: 12px 15px;
      border: 1px solid #E4EAEF;
      box-sizing: border-box;
      border-radius: 8px; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .rate-level {
      font-size: 14px;
      color: #383E48; }
    ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .slider-container {
      padding: 17px 0;
      position: relative; }
      ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .slider-container .slider-bg {
        position: absolute;
        top: 0;
        width: calc(100% - 12px);
        margin: 40px 6px;
        padding: 4px 0;
        margin-bottom: 10px; }
        ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .slider-container .slider-bg span {
          height: 3px;
          display: inline-block;
          width: 10%;
          background: rgba(196, 196, 196, 0.4);
          border-right: 3px solid #2B4B8F; }
        ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .slider-container .slider-bg.disabled span {
          border-color: rgba(43, 75, 143, 0.4); }
      ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .slider-container .ant-slider {
        position: relative;
        z-index: 1; }
        ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .slider-container .ant-slider .ant-slider-rail {
          background-color: transparent; }
        ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .slider-container .ant-slider .ant-slider-track {
          background-color: #2B4B8F; }
        ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .slider-container .ant-slider .ant-slider-handle {
          background-color: #2B4B8F;
          border-color: #ffffff !important;
          border-width: 4px;
          width: 20px;
          height: 20px;
          margin-top: -8px;
          box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2); }
        ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .slider-container .ant-slider.ant-slider-disabled .ant-slider-track {
          background-color: #C7CFDF !important; }
        ._3j3rtgD9ZuoHwhWSc7t0zf .rpe-container .slider-container .ant-slider.ant-slider-disabled .ant-slider-handle {
          background-color: #C7CFDF; }

._1diu1n6_4qYF6XHZP0ENTS {
  margin-top: 22px; }
  ._1diu1n6_4qYF6XHZP0ENTS .ant-tabs-bar {
    border: none; }
  ._1diu1n6_4qYF6XHZP0ENTS .ant-tabs-nav .ant-tabs-tab {
    margin-right: 18px;
    border: none;
    background: transparent;
    padding: 10px 20px;
    border-radius: 12px;
    color: #BAC3CE;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.3px;
    cursor: pointer; }
  ._1diu1n6_4qYF6XHZP0ENTS .ant-tabs-nav .ant-tabs-tab-active {
    background: rgba(38, 67, 130, 0.1);
    color: #264382; }
  ._1diu1n6_4qYF6XHZP0ENTS .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: transparent; }
  ._1diu1n6_4qYF6XHZP0ENTS .activity-tab-description {
    color: #80848A;
    padding-bottom: 20px; }
    ._1diu1n6_4qYF6XHZP0ENTS .activity-tab-description span {
      text-transform: lowercase; }

._2ny-iaMTaf6dZVgKHWlxGB {
  position: absolute;
  z-index: 5;
  background: rgba(46, 48, 51, 0.5);
  top: 0;
  left: 0;
  right: 0;
  margin-left: -359px;
  display: flex;
  justify-content: flex-end;
  min-height: calc(100% + 200px); }
  ._2ny-iaMTaf6dZVgKHWlxGB .modal-detail-container {
    background: #fff;
    width: 716px; }
    ._2ny-iaMTaf6dZVgKHWlxGB .modal-detail-container .container-body > div {
      padding: 20px 22px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      ._2ny-iaMTaf6dZVgKHWlxGB .modal-detail-container .container-body > div:last-child {
        border: none; }
    ._2ny-iaMTaf6dZVgKHWlxGB .modal-detail-container .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8.5px 22px; }
      ._2ny-iaMTaf6dZVgKHWlxGB .modal-detail-container .header .title {
        color: #383E48;
        font-weight: bold;
        font-size: 18px;
        line-height: 29px;
        max-width: 525px;
        margin-bottom: 0;
        margin-top: 0; }
        ._2ny-iaMTaf6dZVgKHWlxGB .modal-detail-container .header .title div.ant-typography {
          margin: 0;
          text-align: left; }
        ._2ny-iaMTaf6dZVgKHWlxGB .modal-detail-container .header .title .anticon.anticon-left {
          line-height: 32px; }

.hToa3nEOdji235cG5VxrY .select-bar {
  margin-right: 0;
  border-bottom: none; }

.hToa3nEOdji235cG5VxrY .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8.5px 22px; }
  .hToa3nEOdji235cG5VxrY .header .title {
    color: #383E48;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    max-width: 525px;
    margin-bottom: 0;
    margin-top: 0; }
    .hToa3nEOdji235cG5VxrY .header .title div.ant-typography {
      margin: 0;
      text-align: left; }
    .hToa3nEOdji235cG5VxrY .header .title .anticon.anticon-left {
      line-height: 32px; }
  .hToa3nEOdji235cG5VxrY .header .operate {
    margin-right: -15px; }
    .hToa3nEOdji235cG5VxrY .header .operate img {
      margin: 18px;
      cursor: pointer; }
    .hToa3nEOdji235cG5VxrY .header .operate .anticon {
      margin: 15px;
      vertical-align: middle;
      cursor: pointer; }

.hToa3nEOdji235cG5VxrY .date-range {
  display: flex;
  justify-content: flex-end;
  padding-top: 48px; }

.hToa3nEOdji235cG5VxrY .select-bar .switch-date-range .arrow {
  cursor: pointer; }
  .hToa3nEOdji235cG5VxrY .select-bar .switch-date-range .arrow:first-child {
    margin-right: 15px; }
  .hToa3nEOdji235cG5VxrY .select-bar .switch-date-range .arrow:last-child {
    margin-left: 15px; }

.hToa3nEOdji235cG5VxrY .rate {
  padding: 0; }
  .hToa3nEOdji235cG5VxrY .rate > div {
    padding-top: 50px;
    position: relative;
    font-size: 15px; }
    .hToa3nEOdji235cG5VxrY .rate > div span {
      font-size: 18px;
      position: absolute;
      top: 30px;
      left: 0; }

.hToa3nEOdji235cG5VxrY .input-type {
  padding-left: 130px;
  font-size: 12px;
  color: #000000; }
  .hToa3nEOdji235cG5VxrY .input-type span {
    display: inline-block;
    width: 95px; }

.hToa3nEOdji235cG5VxrY .section-name {
  font-size: 12px;
  color: #383E48;
  opacity: 0.5;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  padding-bottom: 10px; }

.hToa3nEOdji235cG5VxrY .text-info {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #44433D; }

.hToa3nEOdji235cG5VxrY .goal-category-tracking-view .container > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #383E48; }
  .hToa3nEOdji235cG5VxrY .goal-category-tracking-view .container > div > div {
    text-transform: lowercase; }
    .hToa3nEOdji235cG5VxrY .goal-category-tracking-view .container > div > div .source {
      display: inline-block; }
    .hToa3nEOdji235cG5VxrY .goal-category-tracking-view .container > div > div:first-letter, .hToa3nEOdji235cG5VxrY .goal-category-tracking-view .container > div > div .source:first-letter {
      text-transform: uppercase; }
  .hToa3nEOdji235cG5VxrY .goal-category-tracking-view .container > div .device {
    padding: 3px 10px;
    background: #264382;
    border-radius: 6px;
    color: #ffffff;
    display: block;
    text-align: center;
    text-transform: capitalize; }
  .hToa3nEOdji235cG5VxrY .goal-category-tracking-view .container > div .device-auto img {
    width: 37px;
    height: 37px;
    border-radius: 7px;
    border: 1px solid #E5E7EA;
    margin-right: 10px; }
  .hToa3nEOdji235cG5VxrY .goal-category-tracking-view .container > div .device-auto .source {
    display: inline-block; }
  .hToa3nEOdji235cG5VxrY .goal-category-tracking-view .container > div .week-day {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-right: 10px;
    background: #264382;
    display: inline-block;
    color: #ffffff;
    text-align: center;
    line-height: 35px; }

.hToa3nEOdji235cG5VxrY .date-item {
  font-weight: bold;
  font-size: 12px;
  line-height: 42px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #383E48;
  padding-bottom: 20px; }
  .hToa3nEOdji235cG5VxrY .date-item .number-input:nth-child(3) {
    margin-left: 10px; }
  .hToa3nEOdji235cG5VxrY .date-item > div {
    display: inline-block;
    vertical-align: middle; }
  .hToa3nEOdji235cG5VxrY .date-item.sunday .date-text {
    color: #297FCA; }
  .hToa3nEOdji235cG5VxrY .date-item.today .date-text {
    position: relative; }
    .hToa3nEOdji235cG5VxrY .date-item.today .date-text:before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      background: #264382;
      border: 2px solid #FFFFFF;
      border-radius: 10px; }
  .hToa3nEOdji235cG5VxrY .date-item.text-item {
    padding-bottom: 15px;
    height: 100%; }
  .hToa3nEOdji235cG5VxrY .date-item .date-text {
    font-family: 'Gilroy-bold';
    display: inline-block;
    width: 130px;
    position: relative;
    letter-spacing: 0.3px; }
    .hToa3nEOdji235cG5VxrY .date-item .date-text .is-manual {
      position: absolute;
      display: inline-block;
      font-size: 12px;
      left: 0;
      top: 22px;
      opacity: 0.5; }
    .hToa3nEOdji235cG5VxrY .date-item .date-text .activity {
      cursor: pointer;
      color: #7BAD2D;
      border-bottom: 2px dotted #7BAD2D;
      padding-bottom: 2px; }
  .hToa3nEOdji235cG5VxrY .date-item .input-circle {
    margin-right: 16px;
    height: 30px;
    cursor: pointer; }
    .hToa3nEOdji235cG5VxrY .date-item .input-circle:nth-child(2n+1) {
      border: 2px dotted transparent; }
    .hToa3nEOdji235cG5VxrY .date-item .input-circle:last-child {
      margin-right: 0; }
    .hToa3nEOdji235cG5VxrY .date-item .input-circle.activity {
      border: 2px dotted #7BAD2D;
      border-radius: 100%;
      padding: 2px;
      height: 38px; }
    .hToa3nEOdji235cG5VxrY .date-item .input-circle.not-allowed {
      cursor: not-allowed; }
  .hToa3nEOdji235cG5VxrY .date-item .number-input, .hToa3nEOdji235cG5VxrY .date-item .text-input {
    display: inline-block;
    width: 86px; }
    .hToa3nEOdji235cG5VxrY .date-item .number-input.has-error, .hToa3nEOdji235cG5VxrY .date-item .text-input.has-error {
      position: relative; }
      .hToa3nEOdji235cG5VxrY .date-item .number-input.has-error input, .hToa3nEOdji235cG5VxrY .date-item .text-input.has-error input {
        border: 1px solid #FF0000; }
      .hToa3nEOdji235cG5VxrY .date-item .number-input.has-error span, .hToa3nEOdji235cG5VxrY .date-item .text-input.has-error span {
        position: absolute;
        top: 28px;
        color: #FF0000;
        letter-spacing: 0.3px;
        font-size: 12px;
        text-transform: capitalize;
        width: 100px; }
    .hToa3nEOdji235cG5VxrY .date-item .number-input input, .hToa3nEOdji235cG5VxrY .date-item .number-input textarea, .hToa3nEOdji235cG5VxrY .date-item .text-input input, .hToa3nEOdji235cG5VxrY .date-item .text-input textarea {
      text-align: right;
      background: #f5f5f5;
      border: none;
      border-radius: 4px;
      resize: none; }
  .hToa3nEOdji235cG5VxrY .date-item .text-input {
    width: 300px;
    height: calc(100% - 30px); }
    .hToa3nEOdji235cG5VxrY .date-item .text-input textarea {
      text-align: left;
      min-height: 100% !important; }
  .hToa3nEOdji235cG5VxrY .date-item .number-input .number-input-formater-con .fmt-h sub, .hToa3nEOdji235cG5VxrY .date-item .number-input .number-input-formater-con .fmt-m sub {
    text-transform: none;
    bottom: 0; }
  .hToa3nEOdji235cG5VxrY .date-item .number-input .number-input-formater-con .fmt-t {
    background-color: #f5f5f5;
    border-radius: 4px;
    height: 32px;
    line-height: 32px;
    display: inherit;
    text-align: right;
    padding-right: 10px; }

.hToa3nEOdji235cG5VxrY .null-status {
  margin-top: 112px;
  text-align: center;
  margin-left: -78px; }
  .hToa3nEOdji235cG5VxrY .null-status img {
    width: 287px;
    margin-bottom: 30px;
    max-width: 100%; }
  .hToa3nEOdji235cG5VxrY .null-status p {
    font-family: Heebo;
    font-size: 14px;
    line-height: 150%;
    color: #79879B;
    opacity: 0.8; }

.attach-item-container {
  width: 256px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top; }

.attach-item {
  background: #E8ECF0;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .attach-item .thumb {
    width: 47px;
    height: 47px;
    object-fit: cover;
    color: #E8ECF0;
    text-align: center;
    overflow: hidden; }
    .attach-item .thumb.link {
      font-size: 30px; }
    .attach-item .thumb.text {
      line-height: 47px;
      text-transform: uppercase; }
  .attach-item .name {
    font-family: Heebo;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.703548px;
    color: rgba(186, 195, 206, 0.7);
    width: 180px; }
    .attach-item .name .title {
      font-family: Gilroy;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.3px;
      height: 18px;
      color: #52606C;
      margin: 0; }
    .attach-item .name .ant-typography {
      margin: 0;
      text-align: left; }
  .attach-item .anticon-close {
    cursor: pointer; }

._2mR-Pgpzzk_oYta-Y2qFfk.goals-container {
  padding-top: 40px; }

._2mR-Pgpzzk_oYta-Y2qFfk .goals-category-container {
  margin: 30px 0;
  margin-right: 57px; }
  ._2mR-Pgpzzk_oYta-Y2qFfk .goals-category-container .title-container {
    display: flex;
    align-items: center; }
    ._2mR-Pgpzzk_oYta-Y2qFfk .goals-category-container .title-container > div {
      width: calc(100% - 160px - 40px); }
    ._2mR-Pgpzzk_oYta-Y2qFfk .goals-category-container .title-container .title {
      color: #383E48;
      font-size: 24px;
      font-weight: 600;
      padding-left: 20px;
      margin-bottom: 5px;
      display: inline-block; }
    ._2mR-Pgpzzk_oYta-Y2qFfk .goals-category-container .title-container .empty {
      padding-left: 20px;
      color: #383E48;
      font-size: 14px;
      opacity: .5; }
    ._2mR-Pgpzzk_oYta-Y2qFfk .goals-category-container .title-container .rate {
      padding: 0 20px; }
      ._2mR-Pgpzzk_oYta-Y2qFfk .goals-category-container .title-container .rate span {
        margin-left: 5px; }
  ._2mR-Pgpzzk_oYta-Y2qFfk .goals-category-container .category-body .empty {
    padding: 30px 0;
    color: #383E48;
    letter-spacing: 0.3px;
    opacity: .5;
    padding-left: 20px; }
    ._2mR-Pgpzzk_oYta-Y2qFfk .goals-category-container .category-body .empty img {
      margin-right: 10px;
      vertical-align: bottom; }
  ._2mR-Pgpzzk_oYta-Y2qFfk .goals-category-container .list-container {
    width: 100%;
    color: #8D99A5; }

._2mR-Pgpzzk_oYta-Y2qFfk .subtitle {
  font-weight: bold;
  font-size: 18px;
  color: #4C6072;
  letter-spacing: 0.3px; }

._2mR-Pgpzzk_oYta-Y2qFfk .subtitle-number {
  color: #C1C7CD;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.3px;
  margin-left: 8px; }

._2mR-Pgpzzk_oYta-Y2qFfk .separator-line {
  border: 1px solid #000000;
  opacity: 0.1;
  margin-top: 30px; }

._2mR-Pgpzzk_oYta-Y2qFfk .fix-separator-line {
  flex-grow: 1; }

._2mR-Pgpzzk_oYta-Y2qFfk .add-goal-button {
  font-size: 16px;
  background: #264382;
  color: white; }
  ._2mR-Pgpzzk_oYta-Y2qFfk .add-goal-button .anticon {
    margin-right: 5px; }

._37A1fVh44l50dwXhJD4s2u {
  padding-top: 21px; }
  ._37A1fVh44l50dwXhJD4s2u .goals-category-container {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 25px;
    padding-bottom: 46px; }
  ._37A1fVh44l50dwXhJD4s2u .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 23px; }
    ._37A1fVh44l50dwXhJD4s2u .title-container .icon-img {
      border: 4px solid rgba(166, 176, 185, 0.2);
      border-radius: 100%;
      padding: 3px;
      margin-right: 22px; }
      ._37A1fVh44l50dwXhJD4s2u .title-container .icon-img img {
        width: 51px;
        height: 51px;
        border-radius: 100%; }
    ._37A1fVh44l50dwXhJD4s2u .title-container .title {
      font-family: Gilroy;
      font-size: 18px;
      line-height: 150%;
      color: #4C6072;
      font-weight: 500; }
  ._37A1fVh44l50dwXhJD4s2u .card {
    width: 329px;
    margin-right: 13px;
    display: inline-block;
    background: #EAEDF3;
    border-radius: 12px;
    padding: 10px 18px;
    position: relative;
    font-size: 12px;
    margin-bottom: 30px;
    vertical-align: top; }
    ._37A1fVh44l50dwXhJD4s2u .card .title {
      font-size: 14px;
      color: #383E48;
      font-weight: bold;
      margin-bottom: 15px; }
    ._37A1fVh44l50dwXhJD4s2u .card .date {
      color: rgba(112, 128, 142, 0.8);
      letter-spacing: 0.006em; }
    ._37A1fVh44l50dwXhJD4s2u .card .opr {
      position: absolute;
      width: 100%;
      text-align: right;
      bottom: 0;
      right: 18px;
      padding-bottom: 10px;
      color: rgba(38, 67, 130, 0.8);
      font-weight: bold; }
      ._37A1fVh44l50dwXhJD4s2u .card .opr span {
        cursor: pointer;
        margin-left: 10px; }
        ._37A1fVh44l50dwXhJD4s2u .card .opr span img {
          margin-right: 5px;
          vertical-align: middle; }

._2DedsNXgzuQFY6TPf4sfJc .pillars-container {
  padding-top: 25px;
  min-width: calc(337px * 3 + 15px); }
  ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 20px; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .header .name {
      font-size: 24px;
      width: 200px;
      margin-left: 10px; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .header .rate {
      font-size: 14px;
      border-left: 1px solid rgba(0, 0, 0, 0.1); }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .header .rate div > span {
        margin-left: 15px; }
  ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card {
    display: inline-block;
    vertical-align: top;
    margin-top: 16px;
    width: 337px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-right: 7px;
    font-size: 12px;
    -webkit-user-select: none;
            user-select: none;
    border: 1px solid #DEE2EC;
    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.1); }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card:nth-child(3n) {
      margin-right: 0; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card .title {
      padding: 15px;
      padding-bottom: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: Gilroy; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card .ant-typography {
      font-weight: bold;
      margin-bottom: 0;
      color: #383E48; }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card .ant-typography:first-letter {
        text-transform: capitalize; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card .chart-tags {
      font-size: 12px;
      font-style: normal; }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card .chart-tags > span {
        margin-left: 16px; }
        ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card .chart-tags > span > span {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          margin-right: 4px; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card .body {
      position: relative;
      padding: 10px 15px; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card .VictoryContainer {
      margin: 0 -5px; }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card .VictoryContainer svg {
        overflow: visible; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card .chat-area {
      position: absolute;
      padding-right: 10px; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .card + .data-sync {
      padding-top: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      white-space: nowrap; }
  ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .number-statistical {
    padding: 10px 15px;
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .number-statistical div {
      color: #383E48;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.3px;
      line-height: 21px;
      padding: 0 10px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      min-width: 90px; }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .number-statistical div:first-child {
        padding-left: 0; }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .number-statistical div:last-child {
        border: none; }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .number-statistical div span {
        color: #C0C7D1;
        margin-left: 5px;
        font-weight: normal; }
  ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .rate {
    padding: 10px 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .rate > div {
      margin-right: 10px;
      padding-left: 10px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 12px;
      position: relative;
      padding-top: 20px;
      min-width: 80px; }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .rate > div span {
        position: absolute;
        top: 0;
        left: 10px;
        font-size: 14px; }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .rate > div:first-child {
        padding-left: 0;
        border: none; }
        ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .rate > div:first-child span {
          left: 0; }
  ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 15px;
    color: #60656D;
    opacity: 0.8;
    letter-spacing: 0.3px;
    text-transform: uppercase; }
  ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .data-sync, ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .only-expert {
    padding: 0 15px;
    padding-bottom: 15px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: rgba(56, 62, 72, 0.5);
    white-space: nowrap; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .data-sync img, ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .only-expert img {
      margin-right: 8px; }
  ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .only-expert {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 10px; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .only-expert .anticon {
      margin-right: 8px; }
  ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .archived {
    background: #E8ECF0;
    border: 0.5px solid #BAC3CE; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .archived .archived-opr {
      border-radius: 8px 8px 0px 0px;
      background: #BAC3CE;
      padding: 10px 15px;
      text-align: right;
      color: rgba(38, 67, 130, 0.8);
      font-family: Heebo;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.6px; }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .archived .archived-opr span {
        margin-left: 23px;
        cursor: pointer; }
        ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .list-container .archived .archived-opr span img {
          margin-right: 5px; }
  ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .text-list {
    color: #383E48;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px; }
    ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .text-list .text-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .text-list .text-item:last-child {
        border: none; }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .text-list .text-item span:first-child {
        overflow-wrap: break-word; }
      ._2DedsNXgzuQFY6TPf4sfJc .pillars-container .text-list .text-item span:last-child {
        display: block;
        text-align: right;
        margin-left: 10px; }

.lIgCfcXxSroxSr9HSoi8t {
  width: 880px;
  background: #fff;
  box-shadow: 10px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 48px 40px 40px 40px; }
  .lIgCfcXxSroxSr9HSoi8t .result-group:last-child {
    margin-bottom: 0px; }
  .lIgCfcXxSroxSr9HSoi8t .result-group {
    margin-bottom: 48px; }
    .lIgCfcXxSroxSr9HSoi8t .result-group .group-title {
      font-family: Gilroy;
      font-size: 28px;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #323F4A; }
    .lIgCfcXxSroxSr9HSoi8t .result-group .group-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.3px;
      color: #52606C;
      margin-top: 8px; }
      .lIgCfcXxSroxSr9HSoi8t .result-group .group-desc .highlight {
        font-family: Gilroy-Bold; }
    .lIgCfcXxSroxSr9HSoi8t .result-group .target-container {
      padding: 0px 24px 0px 24px;
      background: rgba(38, 67, 130, 0.05);
      border-radius: 6px; }
      .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container {
        padding-top: 34px;
        padding-bottom: 25px; }
        .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container p {
          font-family: Gilroy-Bold;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.3px;
          color: #4C6072; }
        .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .ranked-goals-container {
          font-family: Gilroy;
          font-size: 14px;
          line-height: 34px;
          letter-spacing: 0.3px;
          color: #4C6072; }
          .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .ranked-goals-container .ranked-goal {
            display: flex;
            align-items: center; }
            .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .ranked-goals-container .ranked-goal .ranked-goal-title {
              margin-left: 12px; }
          .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .ranked-goals-container .ranked-block {
            border-radius: 2px;
            margin-top: 11px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .ranked-goals-container .ranked-block .rank-index-container {
              font-family: Gilroy-Bold;
              font-size: 20px;
              line-height: 31px;
              color: #46515A;
              text-align: center; }
              .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .ranked-goals-container .ranked-block .rank-index-container .super-index {
                vertical-align: text-bottom;
                display: inline-block; }
          .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .ranked-goals-container .ST {
            background: #F5CA5C;
            height: 70px; }
          .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .ranked-goals-container .ND {
            background: #F5A55C;
            height: 50px; }
          .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .ranked-goals-container .RD {
            background: #CCCCCC;
            height: 26px; }
        .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .future-goals-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between; }
          .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .future-goals-container .future-goal {
            display: flex;
            flex-grow: 1;
            align-items: center;
            padding-top: 9px; }
            .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .target-group-container .future-goals-container .future-goal .future-goal-title {
              padding-left: 12px;
              font-family: Gilroy;
              font-size: 14px;
              letter-spacing: 0.3px;
              color: #4C6072; }
      .lIgCfcXxSroxSr9HSoi8t .result-group .target-container .bottom-line {
        border-bottom: 1px solid rgba(152, 169, 188, 0.2); }
    .lIgCfcXxSroxSr9HSoi8t .result-group .resilience-scale-container {
      margin-top: 18px;
      height: 57px; }
      .lIgCfcXxSroxSr9HSoi8t .result-group .resilience-scale-container .level-line-container {
        display: flex;
        align-items: center;
        height: 34px;
        position: relative; }
        .lIgCfcXxSroxSr9HSoi8t .result-group .resilience-scale-container .level-line-container .level-line {
          background: #E3E6EA;
          opacity: 0.5;
          border-radius: 13px;
          height: 10px;
          flex-grow: 1; }
        .lIgCfcXxSroxSr9HSoi8t .result-group .resilience-scale-container .level-line-container .point {
          float: left;
          position: absolute;
          left: 66.6%;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%); }
      .lIgCfcXxSroxSr9HSoi8t .result-group .resilience-scale-container .level-title-container {
        margin-top: 7px;
        position: relative; }
        .lIgCfcXxSroxSr9HSoi8t .result-group .resilience-scale-container .level-title-container .level-title {
          position: absolute;
          font-family: Gilroy;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.3px;
          color: #4C6072;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%); }
          .lIgCfcXxSroxSr9HSoi8t .result-group .resilience-scale-container .level-title-container .level-title:first-child {
            left: 0%;
            -webkit-transform: none;
                    transform: none; }
          .lIgCfcXxSroxSr9HSoi8t .result-group .resilience-scale-container .level-title-container .level-title:last-child {
            right: 0%;
            -webkit-transform: none;
                    transform: none; }
          .lIgCfcXxSroxSr9HSoi8t .result-group .resilience-scale-container .level-title-container .level-title:nth-child(2) {
            left: 33.3%; }
          .lIgCfcXxSroxSr9HSoi8t .result-group .resilience-scale-container .level-title-container .level-title:nth-child(3) {
            left: 66.6%; }
    .lIgCfcXxSroxSr9HSoi8t .result-group .recommend-program-container {
      padding: 24px;
      margin-top: 16px; }
      .lIgCfcXxSroxSr9HSoi8t .result-group .recommend-program-container .title {
        font-family: Gilroy-Bold;
        font-size: 20px;
        line-height: 124%;
        letter-spacing: 0.3px;
        color: #FFFFFF; }
      .lIgCfcXxSroxSr9HSoi8t .result-group .recommend-program-container .desc {
        margin-top: 16px;
        font-family: Gilroy;
        font-size: 14px;
        line-height: 140%;
        color: #FFFFFF;
        opacity: 0.7; }
      .lIgCfcXxSroxSr9HSoi8t .result-group .recommend-program-container .view-program-container {
        margin-top: 8px;
        background: #FFFFFF;
        box-shadow: 10px 10px 30px rgba(38, 67, 130, 0.1);
        border-radius: 30px;
        padding: 15px 23px 12px 23px;
        display: inline-block; }
        .lIgCfcXxSroxSr9HSoi8t .result-group .recommend-program-container .view-program-container .label {
          font-family: Gilroy-Bold;
          font-size: 13px;
          line-height: 100%;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #264382; }
  .lIgCfcXxSroxSr9HSoi8t img[alt="empty"] {
    width: 300px; }
  .lIgCfcXxSroxSr9HSoi8t .send-btn {
    height: 40px;
    text-transform: uppercase;
    width: 191px;
    font-size: 13px;
    float: right;
    margin-top: -55px; }

._7NB5EXBquq1N5wugLApOz {
  background: #ffffff;
  margin-top: 24px;
  overflow: hidden;
  border: 1px solid #E3E6EA;
  border-radius: 8px;
  display: flex; }
  ._7NB5EXBquq1N5wugLApOz:first-child {
    margin-top: 0; }
  ._7NB5EXBquq1N5wugLApOz .score-left {
    background: #FAFAFA;
    width: 50%;
    border-right: 1px solid #E3E6EA; }
    ._7NB5EXBquq1N5wugLApOz .score-left .title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      height: 57px;
      align-items: center;
      margin: 0 24px;
      border-bottom: 1px solid #E3E6EA;
      text-transform: capitalize;
      font-family: 'Gilroy-Bold'; }
      ._7NB5EXBquq1N5wugLApOz .score-left .title .value {
        font-size: 20px; }
        ._7NB5EXBquq1N5wugLApOz .score-left .title .value span {
          color: #a3afb9; }
  ._7NB5EXBquq1N5wugLApOz .score-right {
    padding: 0px 24px;
    width: 50%;
    font-size: 12px; }
    ._7NB5EXBquq1N5wugLApOz .score-right .title {
      display: flex;
      height: 57px;
      align-items: center;
      font-size: 14px;
      color: #4C6072;
      letter-spacing: 0.3px;
      border-bottom: 1px solid #E3E6EA;
      text-transform: capitalize; }
    ._7NB5EXBquq1N5wugLApOz .score-right .item-list {
      margin: 0 -24px;
      padding-top: 18px;
      display: flex;
      flex-wrap: wrap; }
      ._7NB5EXBquq1N5wugLApOz .score-right .item-list .item {
        display: flex;
        width: 50%;
        justify-content: space-between;
        padding: 0 24px;
        line-height: 29px; }
        ._7NB5EXBquq1N5wugLApOz .score-right .item-list .item:hover {
          color: #2B4B8F; }
        ._7NB5EXBquq1N5wugLApOz .score-right .item-list .item:nth-child(2n+1) {
          border-right: 1px solid rgba(135, 148, 161, 0.2); }
        ._7NB5EXBquq1N5wugLApOz .score-right .item-list .item .name {
          cursor: pointer; }
      ._7NB5EXBquq1N5wugLApOz .score-right .item-list .status {
        text-transform: uppercase;
        min-width: 48px;
        font-family: 'Gilroy-Bold';
        text-align: right; }
        ._7NB5EXBquq1N5wugLApOz .score-right .item-list .status.RISK {
          color: #FF6B00; }
        ._7NB5EXBquq1N5wugLApOz .score-right .item-list .status.CONCERN {
          color: #F5BA40; }
        ._7NB5EXBquq1N5wugLApOz .score-right .item-list .status.OPTIMAL {
          color: #40C47C; }
    ._7NB5EXBquq1N5wugLApOz .score-right .more-opr {
      color: #2B4B8F;
      line-height: 12px;
      margin-top: 23px;
      font-family: 'Gilroy-Bold';
      cursor: pointer; }
  ._7NB5EXBquq1N5wugLApOz .pipe-container {
    padding-left: 10px;
    padding-top: 10px; }
    ._7NB5EXBquq1N5wugLApOz .pipe-container .pipe {
      width: 110px;
      display: inline-block;
      vertical-align: top; }
    ._7NB5EXBquq1N5wugLApOz .pipe-container .pipe-list {
      width: calc(100% - 110px);
      display: inline-block;
      list-style: none;
      font-size: 12px;
      padding-left: 10px;
      padding-top: 15px;
      padding-right: 24px; }
      ._7NB5EXBquq1N5wugLApOz .pipe-container .pipe-list li {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #4C6072;
        margin-bottom: 10px;
        letter-spacing: 0.3px;
        font-family: 'Gilroy-Bold'; }
        ._7NB5EXBquq1N5wugLApOz .pipe-container .pipe-list li .label-name {
          color: #A5AFB8;
          letter-spacing: 0.7px; }
          ._7NB5EXBquq1N5wugLApOz .pipe-container .pipe-list li .label-name span {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 8px;
            border-radius: 2px; }
  ._7NB5EXBquq1N5wugLApOz .entries-container {
    padding-top: 19px; }
    ._7NB5EXBquq1N5wugLApOz .entries-container .entry {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      ._7NB5EXBquq1N5wugLApOz .entries-container .entry .name {
        font-family: Gilroy;
        font-size: 12px;
        line-height: 29px;
        letter-spacing: 0.3px;
        text-transform: capitalize;
        color: #4C6072; }
      ._7NB5EXBquq1N5wugLApOz .entries-container .entry .status {
        text-transform: uppercase;
        min-width: 48px;
        font-family: 'Gilroy-Bold';
        text-align: right; }
        ._7NB5EXBquq1N5wugLApOz .entries-container .entry .status.RISK {
          color: #FF6B00; }
        ._7NB5EXBquq1N5wugLApOz .entries-container .entry .status.CONCERN {
          color: #F5BA40; }
        ._7NB5EXBquq1N5wugLApOz .entries-container .entry .status.OPTIMAL {
          color: #40C47C; }
  ._7NB5EXBquq1N5wugLApOz .more-opr {
    color: #2B4B8F;
    line-height: 12px;
    margin-top: 10px;
    margin-bottom: 28px;
    font-family: 'Gilroy-Bold';
    cursor: pointer; }

._29R5Ka0xH5QAApM4OsOqor.select-modal {
  width: 595px !important;
  border-radius: 12px; }
  ._29R5Ka0xH5QAApM4OsOqor.select-modal .ant-modal-header, ._29R5Ka0xH5QAApM4OsOqor.select-modal .ant-modal-body, ._29R5Ka0xH5QAApM4OsOqor.select-modal .ant-modal-footer {
    padding-left: 30px;
    padding-right: 30px; }
  ._29R5Ka0xH5QAApM4OsOqor.select-modal .select-type-icon {
    width: 260px;
    height: 260px;
    display: inline-block;
    text-align: center;
    padding-top: 90px;
    font-size: 18px;
    cursor: pointer; }
    ._29R5Ka0xH5QAApM4OsOqor.select-modal .select-type-icon.selected {
      background: #F1F3F6;
      border-radius: 12px; }
    ._29R5Ka0xH5QAApM4OsOqor.select-modal .select-type-icon img {
      margin-bottom: 20px;
      width: 36px; }

._29R5Ka0xH5QAApM4OsOqor.delete-confirm {
  font-weight: bold;
  color: #383E48; }
  ._29R5Ka0xH5QAApM4OsOqor.delete-confirm .ant-modal-close-x {
    font-size: 22px; }
  ._29R5Ka0xH5QAApM4OsOqor.delete-confirm .confirm {
    margin-top: 35px;
    margin-bottom: 30px;
    font-size: 18px; }
  ._29R5Ka0xH5QAApM4OsOqor.delete-confirm .name {
    font-size: 24px;
    margin-bottom: 90px; }
  ._29R5Ka0xH5QAApM4OsOqor.delete-confirm .footer-opr {
    text-align: right;
    color: #264382;
    font-size: 18px;
    font-weight: 500; }
    ._29R5Ka0xH5QAApM4OsOqor.delete-confirm .footer-opr span {
      margin-left: 50px;
      cursor: pointer; }

._29R5Ka0xH5QAApM4OsOqor .ant-modal-close {
  top: 10px;
  right: 10px; }

._29R5Ka0xH5QAApM4OsOqor .ant-modal-header {
  padding: 0 50px;
  padding-top: 50px;
  border: none; }
  ._29R5Ka0xH5QAApM4OsOqor .ant-modal-header .ant-modal-title {
    color: #8D99A5;
    font-size: 12px;
    line-height: 29px;
    letter-spacing: 0.3px;
    text-transform: capitalize; }

._29R5Ka0xH5QAApM4OsOqor .ant-modal-body {
  padding: 24px 40px;
  padding-top: 0; }
  ._29R5Ka0xH5QAApM4OsOqor .ant-modal-body .body-title {
    font-family: Gilroy;
    font-size: 36px;
    color: #4C6072; }

._29R5Ka0xH5QAApM4OsOqor .ant-modal-footer {
  border: none;
  padding: 16px 50px;
  padding-bottom: 50px; }
  ._29R5Ka0xH5QAApM4OsOqor .ant-modal-footer .ant-btn {
    text-transform: uppercase;
    padding: 5px 15px;
    height: 35px; }

._2QESw52PFjcB2OYvAaL68x .ant-select-selection-selected-value {
  width: 90px; }

._2QESw52PFjcB2OYvAaL68x .ant-modal-content {
  border-radius: 20px; }

._2QESw52PFjcB2OYvAaL68x .ant-modal-header, ._2QESw52PFjcB2OYvAaL68x .ant-modal-body, ._2QESw52PFjcB2OYvAaL68x .ant-modal-footer {
  padding-left: 40px;
  padding-right: 40px; }

._2QESw52PFjcB2OYvAaL68x .ant-modal-footer, ._2QESw52PFjcB2OYvAaL68x .ant-modal-header {
  padding-top: 32px;
  padding-bottom: 32px; }

._2QESw52PFjcB2OYvAaL68x .ant-modal-header {
  border-radius: 20px 20px 0 0; }
  ._2QESw52PFjcB2OYvAaL68x .ant-modal-header .ant-modal-close {
    right: 25px; }
  ._2QESw52PFjcB2OYvAaL68x .ant-modal-header .ant-modal-title {
    font-family: Gilroy;
    line-height: 29px;
    font-size: 24px;
    font-weight: bold;
    color: #4C6072; }

._2QESw52PFjcB2OYvAaL68x .body-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: -40px;
  margin-right: -40px;
  padding: 24px 40px;
  padding-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

._2QESw52PFjcB2OYvAaL68x .body-data {
  margin-left: -40px;
  margin-right: -40px;
  padding: 24px 40px;
  font-weight: bold; }
  ._2QESw52PFjcB2OYvAaL68x .body-data.divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  ._2QESw52PFjcB2OYvAaL68x .body-data:last-child {
    padding-bottom: 0; }
  ._2QESw52PFjcB2OYvAaL68x .body-data > div, ._2QESw52PFjcB2OYvAaL68x .body-data .flex-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 0; }
    ._2QESw52PFjcB2OYvAaL68x .body-data > div:last-child, ._2QESw52PFjcB2OYvAaL68x .body-data .flex-row:last-child {
      border-bottom: none;
      padding-bottom: 0; }
    ._2QESw52PFjcB2OYvAaL68x .body-data > div .column, ._2QESw52PFjcB2OYvAaL68x .body-data .flex-row .column {
      font-family: 'Gilroy';
      font-weight: 600;
      font-size: 14px;
      line-height: 32px;
      color: #383E48; }
    ._2QESw52PFjcB2OYvAaL68x .body-data > div .data-list, ._2QESw52PFjcB2OYvAaL68x .body-data .flex-row .data-list {
      display: flex;
      width: 77%; }
      ._2QESw52PFjcB2OYvAaL68x .body-data > div .data-list > div, ._2QESw52PFjcB2OYvAaL68x .body-data .flex-row .data-list > div {
        width: 14.2%;
        text-align: center;
        padding: 0 5px; }
        ._2QESw52PFjcB2OYvAaL68x .body-data > div .data-list > div input, ._2QESw52PFjcB2OYvAaL68x .body-data .flex-row .data-list > div input {
          background: #F5F5F5; }
        ._2QESw52PFjcB2OYvAaL68x .body-data > div .data-list > div .ant-input-number, ._2QESw52PFjcB2OYvAaL68x .body-data .flex-row .data-list > div .ant-input-number {
          width: auto; }
  ._2QESw52PFjcB2OYvAaL68x .body-data .add-workout .column {
    color: #264382;
    cursor: pointer;
    border-bottom: 2px dashed #264382; }
  ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container {
    display: block;
    padding-top: 0; }
    ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container .sub-tip .column {
      padding-left: 16px; }
    ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container .data-list .ant-select-open .ant-select-selection, ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container .data-list .ant-select-selection:focus, ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container .data-list .ant-select-selection:active {
      box-shadow: none; }
    ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container .data-list .ant-select-selection {
      border: none; }
      ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container .data-list .ant-select-selection .ant-select-selection__rendered {
        font-family: Gilroy; }
    ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container .data-list .ant-select-auto-complete.ant-select .ant-input {
      padding: 0px;
      border: none !important;
      background: transparent !important;
      text-overflow: ellipsis; }
      ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container .data-list .ant-select-auto-complete.ant-select .ant-input:focus {
        box-shadow: none; }
      ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container .data-list .ant-select-auto-complete.ant-select .ant-input.has-error::-webkit-input-placeholder {
        color: #f5222d; }
      ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container .data-list .ant-select-auto-complete.ant-select .ant-input.has-error, ._2QESw52PFjcB2OYvAaL68x .body-data .workout-container .data-list .ant-select-auto-complete.ant-select .ant-input.has-error::placeholder {
        color: #f5222d; }
  ._2QESw52PFjcB2OYvAaL68x .body-data .header .column {
    font-size: 18px; }
  ._2QESw52PFjcB2OYvAaL68x .body-data .header .data-list {
    color: #8D99A5;
    font-size: 12px;
    letter-spacing: 0.4px; }
  ._2QESw52PFjcB2OYvAaL68x .body-data .ant-checkbox-disabled.ant-checkbox-checked {
    opacity: .5; }
    ._2QESw52PFjcB2OYvAaL68x .body-data .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #ffffff; }

._2QESw52PFjcB2OYvAaL68x .ant-input-number {
  margin: 0 10px; }

._2QESw52PFjcB2OYvAaL68x .ant-input-number-handler-wrap {
  display: none; }

._2QESw52PFjcB2OYvAaL68x .prefix-container {
  position: relative; }
  ._2QESw52PFjcB2OYvAaL68x .prefix-container img {
    position: absolute;
    z-index: 2;
    width: 16px;
    top: 7px;
    left: 13px; }
  ._2QESw52PFjcB2OYvAaL68x .prefix-container .ant-input-number-input {
    padding-left: 22px;
    padding-right: 3px; }

._36yGCm0LBtEMV_-BT9FiSW {
  display: flex;
  text-align: left;
  justify-content: space-between; }

._2v0eXxeg4CQPas4wEs9ebn {
  padding-right: 10%;
  font-size: 12px;
  padding-top: 10px; }
  ._2v0eXxeg4CQPas4wEs9ebn .zoon-container {
    width: 50%;
    display: inline-block; }
    ._2v0eXxeg4CQPas4wEs9ebn .zoon-container:nth-child(2n) {
      padding-left: 10px; }
    ._2v0eXxeg4CQPas4wEs9ebn .zoon-container:nth-child(2n+1) {
      padding-right: 10px; }
  ._2v0eXxeg4CQPas4wEs9ebn .zoon {
    display: flex;
    justify-content: space-between; }
    ._2v0eXxeg4CQPas4wEs9ebn .zoon > div {
      white-space: nowrap; }
    ._2v0eXxeg4CQPas4wEs9ebn .zoon .circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      margin-right: 5px;
      vertical-align: middle; }
    ._2v0eXxeg4CQPas4wEs9ebn .zoon svg {
      vertical-align: middle; }

.szv3cn_GJXaBzNlklz-A5.email {
  padding: 0 40px; }
  .szv3cn_GJXaBzNlklz-A5.email .total-heart-rate {
    padding-right: 0;
    padding-left: 10px; }
  .szv3cn_GJXaBzNlklz-A5.email .zoon-container:nth-child(2n) {
    padding-left: 20px; }
  .szv3cn_GJXaBzNlklz-A5.email .zoon-container:nth-child(2n+1) {
    padding-right: 20px; }

.HryT9eKUXICv65I3vqz8A {
  padding-right: 0 !important;
  font-family: Gilroy; }
  .HryT9eKUXICv65I3vqz8A .workout-list {
    list-style: none;
    padding: 0 24px;
    margin: 0 -22px;
    margin-top: -15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .HryT9eKUXICv65I3vqz8A .workout-list li {
      display: block;
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: #383E48;
      letter-spacing: 0.3px;
      font-size: 13px;
      font-family: 'Gilroy-bold'; }
      .HryT9eKUXICv65I3vqz8A .workout-list li label {
        font-size: 12px;
        color: #60656D;
        font-family: 'Gilroy'; }
      .HryT9eKUXICv65I3vqz8A .workout-list li .name {
        padding-right: 10px; }
      .HryT9eKUXICv65I3vqz8A .workout-list li .clickable {
        color: #264382;
        cursor: pointer; }
        .HryT9eKUXICv65I3vqz8A .workout-list li .clickable > span {
          border-bottom: 2px dashed #264382; }
      .HryT9eKUXICv65I3vqz8A .workout-list li .date span {
        text-transform: none; }
    .HryT9eKUXICv65I3vqz8A .workout-list .activity-toggle-container .ant-tabs-nav .ant-tabs-tab {
      margin-right: 0; }

.programItem_container__3eNmZ {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-family: Gilroy;
  font-size: 14px;
  color: #4C6072;
  background: #FFFFFF;
  border: 1px solid #DEE2EC;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  overflow: hidden; }
  .programItem_container__3eNmZ .programItem_contentBox__2aJNR {
    border-left: 6px solid #C4C4C4;
    padding: 20px; }
    .programItem_container__3eNmZ .programItem_contentBox__2aJNR .programItem_title__2rCk3 {
      color: #383E48;
      font-size: 16px;
      padding-bottom: 5px; }
      .programItem_container__3eNmZ .programItem_contentBox__2aJNR .programItem_title__2rCk3:first-letter {
        text-transform: uppercase; }
      .programItem_container__3eNmZ .programItem_contentBox__2aJNR .programItem_title__2rCk3 + div {
        margin: 10px 0; }
    .programItem_container__3eNmZ .programItem_contentBox__2aJNR .anticon {
      margin-right: 12px; }
    .programItem_container__3eNmZ .programItem_contentBox__2aJNR .programItem_activeTitle__1eqhL {
      font-size: 24px; }
  .programItem_container__3eNmZ .programItem_activeBox__3oswv {
    border-color: #0D2350; }

.active.noItems {
  display: inline-block; }

.programItem_exerciseCard__2y8IU {
  overflow: hidden;
  border: 1px solid #DEE2EC;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background: #ffffff;
  width: 163px;
  margin-right: 10px;
  margin-bottom: 12px; }
  .programItem_exerciseCard__2y8IU .programItem_cardBox__1lJT1 {
    border-left: 4px solid #C4C4C4;
    padding: 6px 13px;
    height: 100%;
    display: flex;
    flex-wrap: wrap; }
    .programItem_exerciseCard__2y8IU .programItem_cardBox__1lJT1 .programItem_cardTagContainer__28-mC {
      display: flex;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between; }
      .programItem_exerciseCard__2y8IU .programItem_cardBox__1lJT1 .programItem_cardTagContainer__28-mC .programItem_cardTag__SUzcc {
        min-width: 49px;
        color: #ffffff;
        padding: 3px 5px;
        border-radius: 18px;
        font-size: 8px;
        text-transform: uppercase;
        letter-spacing: 0; }
  .programItem_exerciseCard__2y8IU .programItem_cardTitle__QrJzu, .programItem_exerciseCard__2y8IU .programItem_cardBody__2YtGv, .programItem_exerciseCard__2y8IU .programItem_cardWorkoutTitle__1UZ5P {
    width: 100%;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    letter-spacing: 0.3px;
    color: #383E48;
    font-family: 'Gilroy-Bold'; }
    .programItem_exerciseCard__2y8IU .programItem_cardTitle__QrJzu span:first-letter, .programItem_exerciseCard__2y8IU .programItem_cardBody__2YtGv span:first-letter, .programItem_exerciseCard__2y8IU .programItem_cardWorkoutTitle__1UZ5P span:first-letter {
      text-transform: uppercase; }
  .programItem_exerciseCard__2y8IU .programItem_cardBody__2YtGv {
    align-items: flex-end; }
  .programItem_exerciseCard__2y8IU .programItem_cardWorkoutTitle__1UZ5P {
    margin: 0;
    maargin-top: -5px; }
  .programItem_exerciseCard__2y8IU .programItem_cardSubTitle__14eFb {
    font-family: Gilroy;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #A6B0B9; }
  .programItem_exerciseCard__2y8IU .programItem_duration__HRERV {
    font-size: 10px;
    margin-left: 1px; }

.programItem_inActive__21RXe {
  box-shadow: 4px 4px 20px rgba(255, 0, 0, 0.3); }

._7vUl-5L1LYFNCbK-Xnlf6 {
  position: absolute;
  z-index: 9;
  background: rgba(46, 48, 51, 0.5);
  top: 0;
  left: 0;
  right: 0;
  min-height: calc(100% + 200px);
  display: flex;
  font-family: Gilroy; }
  ._7vUl-5L1LYFNCbK-Xnlf6.right-position {
    justify-content: flex-end; }
  ._7vUl-5L1LYFNCbK-Xnlf6 .container-body {
    background: #fff;
    width: 716px; }
  ._7vUl-5L1LYFNCbK-Xnlf6 .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-height: 80px; }
    ._7vUl-5L1LYFNCbK-Xnlf6 .header .operate {
      display: flex;
      align-items: center;
      margin-right: -18px; }
      ._7vUl-5L1LYFNCbK-Xnlf6 .header .operate > * {
        margin: 18px;
        cursor: pointer; }
    ._7vUl-5L1LYFNCbK-Xnlf6 .header .title {
      color: #383E48;
      font-weight: bold;
      font-size: 18px;
      line-height: 29px;
      max-width: 525px;
      margin-bottom: 0;
      margin-top: 0;
      font-size: 18px; }
      ._7vUl-5L1LYFNCbK-Xnlf6 .header .title > div:first-child {
        display: flex;
        align-items: center; }
      ._7vUl-5L1LYFNCbK-Xnlf6 .header .title .anticon:first-child {
        margin-right: 13px; }
      ._7vUl-5L1LYFNCbK-Xnlf6 .header .title .ant-typography {
        margin: 0; }
        ._7vUl-5L1LYFNCbK-Xnlf6 .header .title .ant-typography:first-letter {
          text-transform: uppercase; }
      ._7vUl-5L1LYFNCbK-Xnlf6 .header .title .sub-title {
        font-size: 14px;
        letter-spacing: 0.3px;
        color: #4C6072;
        padding-left: 30px; }
  ._7vUl-5L1LYFNCbK-Xnlf6 .main-container {
    padding: 25px; }

.detail_compareContainer__1-Ruh {
  border-radius: 12px;
  background: rgba(239, 242, 245, 0.4);
  border: 1px solid rgba(221, 221, 221, 0.4);
  font-family: Gilroy;
  padding: 20px 16px;
  color: rgba(96, 101, 109, 0.8);
  font-size: 12px; }
  .detail_compareContainer__1-Ruh > div {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .detail_compareContainer__1-Ruh .detail_compareTitle__3-C_E > div {
    width: 13%; }
    .detail_compareContainer__1-Ruh .detail_compareTitle__3-C_E > div:last-child {
      text-align: right; }
  .detail_compareContainer__1-Ruh .detail_compareTitle__3-C_E .detail_vs__2EWI0 {
    width: 74%; }
  .detail_compareContainer__1-Ruh .detail_vs__2EWI0 {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: relative; }
    .detail_compareContainer__1-Ruh .detail_vs__2EWI0 > div {
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      border-radius: 100%;
      line-height: 28px;
      text-align: center;
      background: #FFFFFF;
      border: 1px solid #E6E6E6; }
  .detail_compareContainer__1-Ruh .detail_compareRow__2Ywvv {
    margin-top: 16px; }

.detail_boldText__3_0PL {
  font-family: Gilroy-Bold;
  color: #383E48;
  font-size: 18px;
  letter-spacing: 0.3px;
  width: 13%;
  white-space: nowrap; }
  .detail_boldText__3_0PL:last-child {
    text-align: right; }

.detail_itemInfoTitle__350zc {
  color: #5B6177;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600; }

.detail_itemInfoValue__3MSJ3 {
  color: #323F4A;
  font-size: 16px;
  font-family: Inter;
  font-weight: 700; }

.detail_descriptionTitle__19cSl {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #8D99A5; }

.detail_description__2_zO8 {
  font-family: Inter;
  margin-top: 6px;
  color: #52606C;
  font-size: 14px;
  line-height: 145%;
  font-weight: 400; }

.detail_document__2ClOT {
  display: flex;
  background: rgba(38, 67, 130, 0.05);
  border-radius: 8px;
  padding: 15px 20px;
  color: #70808E;
  font-size: 10px;
  margin-top: 8px; }
  .detail_document__2ClOT:first-child {
    margin-top: 16px; }
  .detail_document__2ClOT img {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    object-fit: cover; }
  .detail_document__2ClOT .detail_fileName__u4udy {
    color: #383E48;
    font-size: 14px; }

.detail_heartRateTitle__1f27f {
  font-family: 'Gilroy-bold';
  font-weight: 600;
  font-size: 14px; }

.activity-trend {
  height: 336px; }
  .activity-trend .VictoryContainer {
    display: flex; }
    .activity-trend .VictoryContainer svg {
      height: 100% !important; }

.programEditor_title__234VQ {
  padding-bottom: 20px;
  font-family: Gilroy;
  font-size: 36px;
  letter-spacing: 0.3px;
  color: #4C6072; }

.programEditor_footer__3K4qR {
  display: flex;
  justify-content: space-between; }
  .programEditor_footer__3K4qR button {
    color: #4C6072;
    font-size: 16px;
    height: 48px;
    font-weight: 400; }
    .programEditor_footer__3K4qR button:first-child {
      margin-right: 10px;
      color: #4C6072; }
  .programEditor_footer__3K4qR .ant-btn-primary {
    min-width: 155px;
    color: #ffffff; }
  .programEditor_footer__3K4qR .ant-btn-background-ghost {
    border: none;
    box-shadow: none; }

.programEditor_item__31dGP, .programEditor_itemActive__2iZc0 {
  border-radius: 6px;
  padding: 15px;
  margin: 20px 0; }
  .programEditor_item__31dGP .anticon, .programEditor_itemActive__2iZc0 .anticon {
    margin-right: 20px;
    font-size: 20px;
    vertical-align: middle; }

.programEditor_item__31dGP {
  background: #F1F3F6;
  color: rgba(56, 62, 72, 0.9);
  margin: 20px 0; }

.programEditor_itemActive__2iZc0 {
  background: rgba(38, 67, 130, 0.02);
  border: 2px solid #264382;
  color: rgba(38, 67, 130, 0.9); }

.programEditor_divider__m2D9D {
  background: transparent; }
  .programEditor_divider__m2D9D .ant-divider {
    margin-top: -20px !important; }
  .programEditor_divider__m2D9D .ant-divider-inner-text {
    font-size: 14px;
    color: #8D99A5;
    opacity: 0.9;
    position: relative;
    top: 10px;
    font-family: Gilroy;
    padding: 0 10px; }

.programData_detailAchievement__3FQzH {
  margin-top: 30px;
  padding: 12px 24px;
  border: 1px solid #DEE2EC;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  display: flex; }

.programData_avatar__iEcXv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center; }
  .programData_avatar__iEcXv > div {
    margin: 0 auto; }
  .programData_avatar__iEcXv img {
    width: 100%;
    object-fit: cover; }
  .programData_avatar__iEcXv .programData_avatarContainer__24-Qr {
    margin: 0 auto;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    overflow: hidden;
    background: #BAC3CE; }
  .programData_avatar__iEcXv .programData_expertInfo__3n42k {
    margin-left: 15px;
    color: #8691A7; }
    .programData_avatar__iEcXv .programData_expertInfo__3n42k .ant-select-selection {
      border: none;
      margin-left: -11px; }
    .programData_avatar__iEcXv .programData_expertInfo__3n42k .ant-select-selection {
      box-shadow: none;
      display: inline-block; }
    .programData_avatar__iEcXv .programData_expertInfo__3n42k .ant-select-selection__rendered {
      font-size: 18px;
      color: #52606C; }
    .programData_avatar__iEcXv .programData_expertInfo__3n42k .ant-select-arrow {
      color: #52606C; }
  .programData_avatar__iEcXv .programData_fullName__1J3UN {
    font-size: 18px;
    color: #52606C;
    text-transform: capitalize;
    margin-bottom: 5px; }

.programData_summary__2BwbU {
  font-size: 14px;
  color: #383E48;
  font-family: Gilroy; }

.programData_overview__2SpgP {
  background: rgba(239, 242, 245, 0.4);
  border-color: rgba(221, 221, 221, 0.4); }
  .programData_overview__2SpgP .programData_pipeChart__DrdJ4:first-child {
    padding-right: 160px; }

.programData_pipeChart__DrdJ4 {
  display: flex;
  padding: 20px 0;
  align-items: center; }
  .programData_pipeChart__DrdJ4:last-child {
    padding-right: 74px; }
  .programData_pipeChart__DrdJ4 .ant-divider-vertical {
    height: 84px;
    margin: auto 33px; }
  .programData_pipeChart__DrdJ4 .programData_description__2ZNL0 {
    margin-left: 24px; }
    .programData_pipeChart__DrdJ4 .programData_description__2ZNL0 .programData_progress__3TC5c {
      color: #0D2350;
      font-size: 24px;
      line-height: 38px; }
    .programData_pipeChart__DrdJ4 .programData_description__2ZNL0 .programData_text__fj1h4 {
      color: #8691A7;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.3px; }
  .programData_pipeChart__DrdJ4 .ant-progress-circle-path {
    stroke: #FF6B00 !important; }

.programData_nutrition__186xi, .programData_rejuvenation__21ojw {
  justify-content: space-between; }
  .programData_nutrition__186xi .programData_avatar__iEcXv, .programData_rejuvenation__21ojw .programData_avatar__iEcXv {
    margin-right: 100px; }
  .programData_nutrition__186xi .ant-divider-vertical, .programData_rejuvenation__21ojw .ant-divider-vertical {
    height: 84px;
    margin: auto 33px; }
  .programData_nutrition__186xi .ant-progress-circle-path, .programData_rejuvenation__21ojw .ant-progress-circle-path {
    stroke: #7FB800 !important; }

.programData_rejuvenation__21ojw .ant-progress-circle-path {
  stroke: #5C00D1 !important; }

.programData_emptyStatus__2zzrW {
  text-align: center;
  margin-top: 150px; }
  .programData_emptyStatus__2zzrW div {
    font-family: Heebo;
    margin: 25px;
    font-size: 14px;
    color: #79879B;
    opacity: 0.8;
    letter-spacing: 0.3px; }
  .programData_emptyStatus__2zzrW button {
    width: 156px;
    height: 40px; }

.programData_exerciseContainer__367SQ {
  margin-top: 27px; }
  .programData_exerciseContainer__367SQ .programData_title__TW2Am {
    margin-bottom: 15px;
    font-size: 12px;
    color: #52606C; }
  .programData_exerciseContainer__367SQ .programData_exerciseList__2pQuv {
    margin-top: 10px;
    display: flex;
    align-items: flex-start; }
    .programData_exerciseContainer__367SQ .programData_exerciseList__2pQuv.programData_todo__3-4E3 {
      flex-wrap: wrap; }
    .programData_exerciseContainer__367SQ .programData_exerciseList__2pQuv .programData_exerciseItem__347yD {
      min-width: 145px;
      width: 14.28%; }

.W9qDgenoFEZlKbXAUWRGH.email {
  padding: 0 40px; }
  .W9qDgenoFEZlKbXAUWRGH.email .modality-type-list {
    padding-right: 0;
    padding-left: 10px; }

.W9qDgenoFEZlKbXAUWRGH .modality-type-list {
  list-style: none;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  display: inline-block;
  min-width: 155px; }

.W9qDgenoFEZlKbXAUWRGH li {
  display: flex;
  justify-content: space-between; }
  .W9qDgenoFEZlKbXAUWRGH li span {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-right: 6px;
    vertical-align: middle; }
  .W9qDgenoFEZlKbXAUWRGH li svg {
    vertical-align: middle; }

._20R8ufYErNXUlSL41tkDPR.User .goal-detail-bg {
  margin-top: -66px;
  margin-left: -343px; }

._20R8ufYErNXUlSL41tkDPR .goal-detail-bg {
  margin-right: -44px;
  margin-top: -20px;
  margin-left: -314px; }

._20R8ufYErNXUlSL41tkDPR .core-metrics-top {
  display: flex;
  align-items: center; }
  ._20R8ufYErNXUlSL41tkDPR .core-metrics-top .today {
    font-family: Gilroy;
    font-size: 14px;
    line-height: 150%;
    color: #52606C;
    border: 1px solid #DEE2EC;
    border-radius: 12px;
    padding: 8px 15px;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 18px;
    margin-left: 8px;
    background: #ffffff; }
  ._20R8ufYErNXUlSL41tkDPR .core-metrics-top + .healthy-container {
    margin-top: 40px; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics-top + .healthy-container .healthy {
      border-radius: 12px 12px 0 0; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics-top + .healthy-container .healthy h3 {
        margin: 0 -20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 18px;
        border-bottom: 1px solid #DEE2EC; }
  ._20R8ufYErNXUlSL41tkDPR .core-metrics-top.healthy-top-container {
    margin-top: 0;
    justify-content: space-between; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics-top.healthy-top-container .ant-select-selector {
      border-radius: 12px; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics-top.healthy-top-container .ant-divider-vertical {
      height: 28px;
      border-color: #000000;
      opacity: 0.2;
      margin-left: 48px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics-top.healthy-top-container .ant-divider-vertical + div {
        display: flex; }

._20R8ufYErNXUlSL41tkDPR .core-metrics {
  margin-top: 40px; }
  ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card {
    padding: 18px 20px;
    padding-bottom: 36px;
    border-radius: 12px;
    background: #FFFFFF;
    border: 1px solid #DEE2EC;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 22px; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card h3 {
      padding-bottom: 18px;
      font-weight: bold;
      font-size: 17px;
      line-height: 124%;
      letter-spacing: 0.3px;
      border-bottom: 1px solid #E4EAEF;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card h3 .right-tag {
        color: #8596A4;
        font-size: 12px;
        line-height: 14px; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.nutrition h3, ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.rejuvenation h3 {
      color: #7BAD2D; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.nutrition .metric:not(.first-level) > div, ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.rejuvenation .metric:not(.first-level) > div {
      width: 100%; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.nutrition .metric:not(.first-level) > div .value, ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.rejuvenation .metric:not(.first-level) > div .value {
        justify-content: flex-start; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.exercise h3 {
      color: #FF6B00;
      border-bottom: 0;
      padding-top: 25px; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.rejuvenation h3 {
      color: #5C00D1; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.healthy h3 {
      color: #2B4B8F; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.weight {
      padding-bottom: 10px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.weight h3 {
        color: #FF6B00; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .null-status {
      padding-top: 77px;
      padding-bottom: 60px;
      text-align: center; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .null-status p {
        font-size: 14px;
        color: #8596A4;
        margin-top: 26px; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metrics-body {
      display: flex;
      justify-content: space-between; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metrics-body > div {
        width: 50%; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metrics-body > div.full-row {
          width: 100%;
          display: flex;
          flex-wrap: wrap; }
          ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metrics-body > div.full-row .metric.nutrition {
            width: 50%; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metrics-body .weight-chart-container {
        padding-right: 18px; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metrics-body .weight-chart-container .title-view {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px; }
          ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metrics-body .weight-chart-container .title-view .title-view-date {
            font-size: 12px;
            font-weight: 600;
            color: #8596A4; }
          ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metrics-body .weight-chart-container .title-view .title-view-unit {
            color: #97A0A7; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .rjn-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #E4EAEF;
      padding-bottom: 24px;
      padding-top: 24px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .rjn-item > div {
        width: 50%; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .rjn-item > div.full-row {
          width: 100%;
          display: flex;
          flex-wrap: wrap; }
          ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .rjn-item > div.full-row .metric.nutrition {
            width: 50%; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .rjn-item .resting .r-left {
        width: 420px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .rjn-item .resting .r-right .label {
        margin-left: 20px;
        margin-bottom: 0px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .rjn-item.sleep .s-left {
        width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-around; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .rjn-item.sleep .s-right {
        flex: 1 1; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .body {
      margin-top: 25px; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card.exercise .body {
      display: block; }
    ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      font-size: 18px;
      line-height: 124%;
      letter-spacing: 0.3px;
      color: #383E48; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric.gotobed {
        display: flex;
        flex-direction: column; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric.gotobed .label {
          margin-bottom: 0px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .clickable {
        color: #264382;
        cursor: pointer; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .clickable .anticon {
          font-size: 12px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric:last-child {
        margin: 0; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric.nutrition {
        margin-bottom: 20px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric.first-level .label {
        margin-bottom: 15px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric.first-level .value {
        justify-content: flex-start; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric.first-level .value span {
          color: #383E48;
          margin-right: 10px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric.first-level:nth-child(2), ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric.first-level:nth-child(3), ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric.first-level:nth-child(4) {
        border-top: 1px solid #E4EAEF;
        padding-top: 25px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric > div {
        width: 50%; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .label {
        font-size: 12px;
        color: #8596A4;
        line-height: 150%;
        margin-bottom: 15px; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .label .healthy-status {
          margin-left: 6px;
          text-transform: uppercase; }
          ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .label .healthy-status.over-allocated {
            color: #7BAD2D; }
          ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .label .healthy-status.on-track {
            color: #3561C0; }
          ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .label .healthy-status.under-allocated {
            color: #FF6B00; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .value {
        display: flex;
        justify-content: space-between;
        margin-top: 10px; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .value span {
          font-size: 12px;
          color: #8596A4; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .value > div:first-child {
          width: 178px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .l-number {
        font-size: 40px !important; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .l-number .no-data span {
          line-height: 12px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .more-week-avg {
        margin-top: 23px;
        color: #A5ABB2;
        font-size: 12px;
        font-weight: 500;
        line-height: 150%; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .more-week-avg-value {
        color: #323F4A;
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.3px;
        margin-top: 3px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .more-week-avg-nodata {
        color: #C0C6D1; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .calendar-container {
        display: flex;
        margin-top: 10px; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .calendar-container .date-item {
          margin-right: 8px; }
          ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .calendar-container .date-item > div {
            border: 2px solid #C9CDD2;
            border-radius: 15px;
            width: 30px;
            height: 30px;
            font-weight: bold;
            color: #8596A4;
            font-size: 14px;
            text-align: center;
            line-height: 26px;
            background: #FBFBFB; }
          ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .calendar-container .date-item .lighter {
            opacity: .6; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .no-data {
        color: #BAC3CE;
        margin-top: 10px; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .no-data span {
          display: inline-block;
          background: rgba(43, 75, 143, 0.2);
          width: 18px;
          height: 18px;
          border-radius: 100%;
          text-align: center;
          line-height: 15px;
          margin-left: 8px; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .chart {
        padding-right: 20%; }
      ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .sleep-tip {
        padding-top: 10px;
        display: flex;
        font-size: 12px;
        color: #60656D; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .sleep-tip div {
          margin-right: 12px; }
        ._20R8ufYErNXUlSL41tkDPR .core-metrics .metrics-card .metric .sleep-tip span {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          margin-right: 5px; }

._20R8ufYErNXUlSL41tkDPR .healthy-signal-top-labels {
  display: flex;
  position: absolute;
  right: 0;
  margin-top: 15px;
  color: #8596A4;
  font-size: 12px; }
  ._20R8ufYErNXUlSL41tkDPR .healthy-signal-top-labels > div {
    margin-left: 16px; }
  ._20R8ufYErNXUlSL41tkDPR .healthy-signal-top-labels svg {
    margin-right: 6px;
    vertical-align: middle; }

._20R8ufYErNXUlSL41tkDPR .healthy-container {
  margin: 0;
  border-bottom: 1px solid #DEE2EC; }
  ._20R8ufYErNXUlSL41tkDPR .healthy-container .healthy {
    margin: 0;
    padding-bottom: 22px;
    border: none;
    border-radius: 0; }
    ._20R8ufYErNXUlSL41tkDPR .healthy-container .healthy h3 {
      border-bottom: 0;
      padding-bottom: 0; }
    ._20R8ufYErNXUlSL41tkDPR .healthy-container .healthy .metric .l-number {
      font-size: 32px !important; }
    ._20R8ufYErNXUlSL41tkDPR .healthy-container .healthy.client-view {
      padding-top: 36px; }
      ._20R8ufYErNXUlSL41tkDPR .healthy-container .healthy.client-view h3 {
        margin: 0;
        border: none;
        padding: 0; }
      ._20R8ufYErNXUlSL41tkDPR .healthy-container .healthy.client-view .body .ant-row.metric {
        margin-top: -55px; }
        ._20R8ufYErNXUlSL41tkDPR .healthy-container .healthy.client-view .body .ant-row.metric > div:first-child {
          margin-top: 70px; }
  ._20R8ufYErNXUlSL41tkDPR .healthy-container:last-child .healthy {
    border-radius: 0 0 12px 12px; }

._1XYsPkhLaPRhhRpgJ7Idis {
  font-family: Gilroy;
  padding-bottom: 100px; }
  ._1XYsPkhLaPRhhRpgJ7Idis footer {
    position: fixed;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 17px 48px;
    height: 74px;
    width: 100%;
    max-width: 1440px;
    background: #FCFDFD;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #798187; }
    ._1XYsPkhLaPRhhRpgJ7Idis footer button {
      height: 40px;
      margin-left: 16px; }
  ._1XYsPkhLaPRhhRpgJ7Idis .top-opr {
    text-align: right; }
    ._1XYsPkhLaPRhhRpgJ7Idis .top-opr button {
      height: 40px; }
  ._1XYsPkhLaPRhhRpgJ7Idis .section {
    padding-bottom: 30px; }
    ._1XYsPkhLaPRhhRpgJ7Idis .section .program-title {
      padding-bottom: 16px;
      font-size: 24px;
      line-height: 150%;
      letter-spacing: 0.3px;
      color: #383E48; }
    ._1XYsPkhLaPRhhRpgJ7Idis .section .active-title {
      font-size: 24px; }
    ._1XYsPkhLaPRhhRpgJ7Idis .section .active-box {
      border-color: #7FB800;
      margin-right: 90px; }
  ._1XYsPkhLaPRhhRpgJ7Idis .pipe-chart {
    display: flex;
    padding: 20px 0;
    align-items: center; }
    ._1XYsPkhLaPRhhRpgJ7Idis .pipe-chart:last-child {
      padding-right: 50px; }
    ._1XYsPkhLaPRhhRpgJ7Idis .pipe-chart .ant-divider-vertical {
      height: 84px;
      margin: auto 33px; }
    ._1XYsPkhLaPRhhRpgJ7Idis .pipe-chart .description {
      margin-left: 24px; }
      ._1XYsPkhLaPRhhRpgJ7Idis .pipe-chart .description .progress {
        color: #0D2350;
        font-size: 24px;
        line-height: 38px;
        white-space: nowrap; }
      ._1XYsPkhLaPRhhRpgJ7Idis .pipe-chart .description .text {
        color: #8691A7;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.3px; }
    ._1XYsPkhLaPRhhRpgJ7Idis .pipe-chart .ant-progress-circle-path {
      stroke: #0D2350 !important; }
  ._1XYsPkhLaPRhhRpgJ7Idis .detail-top {
    display: flex;
    justify-content: space-between;
    align-items: baseline; }
    ._1XYsPkhLaPRhhRpgJ7Idis .detail-top .title {
      font-size: 36px;
      font-family: Gilroy;
      color: #4C6072; }
      ._1XYsPkhLaPRhhRpgJ7Idis .detail-top .title .ant-tag {
        border-radius: 15px;
        padding: 0 10px;
        margin-left: 10px;
        vertical-align: middle;
        text-transform: uppercase;
        font-size: 9px; }
      ._1XYsPkhLaPRhhRpgJ7Idis .detail-top .title:first-letter {
        text-transform: uppercase; }
      ._1XYsPkhLaPRhhRpgJ7Idis .detail-top .title .date-range {
        display: block;
        font-size: 14px;
        color: #798187; }
    ._1XYsPkhLaPRhhRpgJ7Idis .detail-top .top-opr {
      display: flex;
      justify-content: space-between;
      margin: 0;
      height: 40px; }
      ._1XYsPkhLaPRhhRpgJ7Idis .detail-top .top-opr .switch-date-range {
        margin-top: 0; }
      ._1XYsPkhLaPRhhRpgJ7Idis .detail-top .top-opr button {
        height: 40px;
        margin-left: 16px; }
  ._1XYsPkhLaPRhhRpgJ7Idis .right-opr {
    text-align: right; }
    ._1XYsPkhLaPRhhRpgJ7Idis .right-opr button {
      height: 40px; }
      ._1XYsPkhLaPRhhRpgJ7Idis .right-opr button:first-child {
        margin-right: 16px; }
  ._1XYsPkhLaPRhhRpgJ7Idis .detail-description {
    height: calc(100% - 30px);
    margin-top: 30px;
    padding: 22px 24px;
    border: 1px solid #DEE2EC;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05); }
    ._1XYsPkhLaPRhhRpgJ7Idis .detail-description.select-expert {
      text-align: center;
      min-width: 250px; }
      ._1XYsPkhLaPRhhRpgJ7Idis .detail-description.select-expert.draft {
        box-shadow: 4px 4px 20px rgba(255, 0, 0, 0.3); }
    ._1XYsPkhLaPRhhRpgJ7Idis .detail-description h4 {
      font-family: 'Gilroy-Bold';
      margin-bottom: 14px;
      font-size: 14px;
      color: #383E48;
      letter-spacing: 0.3px; }
      ._1XYsPkhLaPRhhRpgJ7Idis .detail-description h4 img {
        margin-right: 15px; }
      ._1XYsPkhLaPRhhRpgJ7Idis .detail-description h4 .anticon {
        color: #FF6B00;
        margin-right: 13px; }
    ._1XYsPkhLaPRhhRpgJ7Idis .detail-description pre {
      font-family: Gilroy;
      color: #70808E;
      font-size: 14px;
      margin-bottom: 0;
      white-space: pre-wrap; }
    ._1XYsPkhLaPRhhRpgJ7Idis .detail-description .ant-btn {
      margin-top: 12px;
      width: 100%;
      border-width: 2px;
      font-size: 14px;
      font-family: 'Gilroy-Bold'; }
  ._1XYsPkhLaPRhhRpgJ7Idis .date-top-bar {
    display: flex;
    justify-content: flex-end;
    margin-top: -110px;
    margin-bottom: 55px; }
    ._1XYsPkhLaPRhhRpgJ7Idis .date-top-bar .switch-date-range {
      margin-top: 0; }
  ._1XYsPkhLaPRhhRpgJ7Idis .section-top {
    margin: 37px 0 18px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    ._1XYsPkhLaPRhhRpgJ7Idis .section-top .title {
      font-size: 24px;
      line-height: 150%; }
    ._1XYsPkhLaPRhhRpgJ7Idis .section-top .exercise {
      color: #FF6B00; }
    ._1XYsPkhLaPRhhRpgJ7Idis .section-top .nutrition {
      color: #7FB800; }
    ._1XYsPkhLaPRhhRpgJ7Idis .section-top .rejuvenation {
      color: #5C00D1; }
    ._1XYsPkhLaPRhhRpgJ7Idis .section-top .right-opr {
      display: flex;
      align-items: center; }
      ._1XYsPkhLaPRhhRpgJ7Idis .section-top .right-opr .program-container {
        margin-right: 24px;
        background: rgba(38, 67, 130, 0.05);
        border-radius: 8px;
        padding: 12px 20px;
        color: #383E48; }
        ._1XYsPkhLaPRhhRpgJ7Idis .section-top .right-opr .program-container .ant-divider-vertical {
          height: 1.5em;
          background: rgba(0, 0, 0, 0.1);
          margin: 0 15px; }
        ._1XYsPkhLaPRhhRpgJ7Idis .section-top .right-opr .program-container .ant-progress-inner {
          margin-right: 12px; }
        ._1XYsPkhLaPRhhRpgJ7Idis .section-top .right-opr .program-container .ant-progress-circle-trail {
          stroke: rgba(0, 0, 0, 0.1) !important; }
        ._1XYsPkhLaPRhhRpgJ7Idis .section-top .right-opr .program-container strong {
          font-family: Gilroy-Bold;
          margin-right: 5px; }
  ._1XYsPkhLaPRhhRpgJ7Idis .goal-detail-bg {
    margin-left: -275px; }
    ._1XYsPkhLaPRhhRpgJ7Idis .goal-detail-bg .modal-detail-container .header > .title .ant-typography {
      display: inline-block; }

.LIDBA9SXiuFPEPJ-eRxh0 {
  font-size: 14px;
  line-height: 1.5;
  margin-right: 24px;
  background: rgba(38, 67, 130, 0.05);
  border-radius: 8px;
  padding: 12px 20px;
  color: #383E48;
  display: flex;
  justify-content: space-between; }
  .LIDBA9SXiuFPEPJ-eRxh0 .ant-divider-vertical {
    height: auto;
    background: rgba(0, 0, 0, 0.1);
    margin: 0 15px; }
  .LIDBA9SXiuFPEPJ-eRxh0 .ant-progress-inner {
    margin-right: 12px; }
  .LIDBA9SXiuFPEPJ-eRxh0 .ant-progress-circle-trail {
    stroke: rgba(0, 0, 0, 0.1) !important; }
  .LIDBA9SXiuFPEPJ-eRxh0 strong {
    font-family: Gilroy-Bold;
    margin-right: 5px; }
  .LIDBA9SXiuFPEPJ-eRxh0 .exercise-completed-container {
    display: inline-flex;
    align-items: center; }
    .LIDBA9SXiuFPEPJ-eRxh0 .exercise-completed-container div:last-child {
      margin-left: 16px; }
  .LIDBA9SXiuFPEPJ-eRxh0 > div > div {
    margin: 5px 0; }
  .LIDBA9SXiuFPEPJ-eRxh0 .align-bottom {
    align-self: flex-end; }
  .LIDBA9SXiuFPEPJ-eRxh0 .dot {
    padding-left: 20px; }
    .LIDBA9SXiuFPEPJ-eRxh0 .dot > div:first-child:before {
      content: '';
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 8px;
      margin-left: -18px;
      margin-right: 10px; }
    .LIDBA9SXiuFPEPJ-eRxh0 .dot .dot-red:before {
      background: #FF6F03; }
    .LIDBA9SXiuFPEPJ-eRxh0 .dot .dot-green:before {
      background: #7BAD2D; }
  .LIDBA9SXiuFPEPJ-eRxh0 .text-unit {
    color: #70808E; }

.F72skmpBFUfdeQdYS-nN1 {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  margin-top: 30px; }
  .F72skmpBFUfdeQdYS-nN1 .left-menu {
    width: 280px;
    border-right: 1px solid #f1f2f5; }
    .F72skmpBFUfdeQdYS-nN1 .left-menu ul {
      list-style: none;
      padding-left: 0;
      margin: 0; }
    .F72skmpBFUfdeQdYS-nN1 .left-menu li {
      height: 64px;
      padding-left: 25px;
      border-bottom: 1px solid #f1f2f5;
      display: flex;
      align-items: center; }
      .F72skmpBFUfdeQdYS-nN1 .left-menu li > div {
        max-width: 211px; }
      .F72skmpBFUfdeQdYS-nN1 .left-menu li:last-child {
        border: none; }
      .F72skmpBFUfdeQdYS-nN1 .left-menu li.active {
        background: #F6F7F9;
        border-left: 3px solid #223064; }
        .F72skmpBFUfdeQdYS-nN1 .left-menu li.active span {
          background: #223064; }
      .F72skmpBFUfdeQdYS-nN1 .left-menu li svg {
        margin-right: 16px; }
      .F72skmpBFUfdeQdYS-nN1 .left-menu li span {
        display: block;
        margin-right: 16px;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background: rgba(34, 48, 100, 0.2);
        font-size: 13px;
        color: #ffffff;
        line-height: 24px;
        text-align: center; }
      .F72skmpBFUfdeQdYS-nN1 .left-menu li .date {
        color: #60656D;
        font-size: 12px; }
  .F72skmpBFUfdeQdYS-nN1 .right-container {
    width: calc(100% - 280px);
    position: relative; }
    .F72skmpBFUfdeQdYS-nN1 .right-container .title {
      padding: 24px 32px;
      border-bottom: 1px solid #f1f2f5; }
      .F72skmpBFUfdeQdYS-nN1 .right-container .title span {
        font-size: 12px;
        color: #8D99A5;
        float: right; }
      .F72skmpBFUfdeQdYS-nN1 .right-container .title h3 {
        font-size: 16px;
        color: #383E48;
        font-family: Gilroy-Bold; }
      .F72skmpBFUfdeQdYS-nN1 .right-container .title .description {
        color: #60656D;
        font-size: 14px; }
    .F72skmpBFUfdeQdYS-nN1 .right-container .body {
      padding: 24px 32px; }
      .F72skmpBFUfdeQdYS-nN1 .right-container .body .common-items {
        padding-bottom: 60px; }
        .F72skmpBFUfdeQdYS-nN1 .right-container .body .common-items .html-container {
          display: inline-block; }
          .F72skmpBFUfdeQdYS-nN1 .right-container .body .common-items .html-container a {
            color: #52606C !important; }
          .F72skmpBFUfdeQdYS-nN1 .right-container .body .common-items .html-container p {
            margin: 0; }
          .F72skmpBFUfdeQdYS-nN1 .right-container .body .common-items .html-container + .anticon {
            margin-left: 10px;
            color: #264382; }
      .F72skmpBFUfdeQdYS-nN1 .right-container .body .opr {
        position: absolute;
        right: 25px;
        bottom: 25px; }
        .F72skmpBFUfdeQdYS-nN1 .right-container .body .opr .notes-icon {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          position: relative;
          background: rgba(117, 135, 157, 0.1);
          display: inline-block;
          margin-right: 16px;
          text-align: center;
          line-height: 40px;
          cursor: pointer; }
    .F72skmpBFUfdeQdYS-nN1 .right-container .add-item {
      display: inline-block;
      cursor: pointer;
      margin-top: 17px;
      color: #223064;
      font-size: 14px; }
  .F72skmpBFUfdeQdYS-nN1 .ant-table-content div, .F72skmpBFUfdeQdYS-nN1 .ant-table-content th, .F72skmpBFUfdeQdYS-nN1 .ant-table-content td {
    border-color: #f1f2f5 !important;
    color: #52606C; }
  .F72skmpBFUfdeQdYS-nN1 .ant-table-content th div {
    color: rgba(56, 62, 72, 0.5); }
  .F72skmpBFUfdeQdYS-nN1 .ant-table-content .ant-table-thead > tr > th {
    background: transparent;
    color: rgba(56, 62, 72, 0.5); }

._25rsVbi1paJHbKsAdU-i0n {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 16px;
  padding: 34px 26px 23px;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 147px; }
  ._25rsVbi1paJHbKsAdU-i0n::-webkit-scrollbar {
    width: 0 !important; }
  ._25rsVbi1paJHbKsAdU-i0n .arrow {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: 174px;
    display: flex;
    align-items: center;
    padding: 0 24px; }
    ._25rsVbi1paJHbKsAdU-i0n .arrow span {
      display: none;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background: #ffffff;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      text-align: center;
      line-height: 40px; }
    ._25rsVbi1paJHbKsAdU-i0n .arrow:hover span {
      display: inline-block; }
  ._25rsVbi1paJHbKsAdU-i0n .arrow-left {
    left: 0; }
    ._25rsVbi1paJHbKsAdU-i0n .arrow-left:hover {
      background: linear-gradient(90deg, #FFFFFF 11.13%, rgba(255, 255, 255, 0) 100%); }
  ._25rsVbi1paJHbKsAdU-i0n .arrow-right {
    right: 0;
    justify-content: flex-end; }
    ._25rsVbi1paJHbKsAdU-i0n .arrow-right:hover {
      background: linear-gradient(270deg, #FFFFFF 11.13%, rgba(255, 255, 255, 0) 100%); }
  ._25rsVbi1paJHbKsAdU-i0n .timeline-container {
    white-space: nowrap;
    position: relative; }
    ._25rsVbi1paJHbKsAdU-i0n .timeline-container .time-line-item {
      display: inline-block;
      vertical-align: top; }
      ._25rsVbi1paJHbKsAdU-i0n .timeline-container .time-line-item .icon img {
        width: 32px;
        margin-right: 2px; }
      ._25rsVbi1paJHbKsAdU-i0n .timeline-container .time-line-item .icon:after {
        content: '';
        display: inline-block;
        border: 1px solid #264382;
        opacity: 0.1;
        width: calc(100% - 32px - 4px);
        margin-right: 2px; }
      ._25rsVbi1paJHbKsAdU-i0n .timeline-container .time-line-item .name {
        min-width: 140px;
        padding-right: 20px;
        margin-top: 18px;
        font-size: 16px;
        color: #383E48;
        opacity: 0.4; }
      ._25rsVbi1paJHbKsAdU-i0n .timeline-container .time-line-item .date {
        font-size: 14px;
        color: #6D7D8C;
        line-height: 16px;
        opacity: 0.4; }
      ._25rsVbi1paJHbKsAdU-i0n .timeline-container .time-line-item.done .icon:after, ._25rsVbi1paJHbKsAdU-i0n .timeline-container .time-line-item.done .name, ._25rsVbi1paJHbKsAdU-i0n .timeline-container .time-line-item.done .date {
        opacity: 1; }
      ._25rsVbi1paJHbKsAdU-i0n .timeline-container .time-line-item:last-child .icon:after {
        border: none;
        width: 100px; }
      ._25rsVbi1paJHbKsAdU-i0n .timeline-container .time-line-item:last-child .name {
        min-width: auto;
        padding-right: 0; }

.SCW4zhfe1v5d9NizsJWjn {
  position: relative;
  font-family: Gilroy;
  letter-spacing: 0.3px; }
  .SCW4zhfe1v5d9NizsJWjn .type-list {
    display: flex;
    padding: 0;
    list-style: none;
    text-transform: uppercase;
    color: #9DAABA;
    letter-spacing: 0.8px;
    font-size: 13px; }
    .SCW4zhfe1v5d9NizsJWjn .type-list li {
      margin-right: 32px;
      cursor: pointer; }
      .SCW4zhfe1v5d9NizsJWjn .type-list li.active {
        color: #264382;
        border-bottom: 3px solid #264382; }
  .SCW4zhfe1v5d9NizsJWjn .section-title {
    font-weight: 600;
    font-size: 24px;
    color: #383E48;
    line-height: 150%;
    padding: 0; }
  .SCW4zhfe1v5d9NizsJWjn .title {
    font-size: 36px;
    font-weight: 500;
    margin-top: -10px; }
    .SCW4zhfe1v5d9NizsJWjn .title .avatar-container {
      width: 72px;
      height: 72px;
      border-radius: 100%;
      background: rgba(38, 67, 130, 0.1);
      margin-right: 17px; }
      .SCW4zhfe1v5d9NizsJWjn .title .avatar-container img {
        width: 72px;
        height: 72px; }
    .SCW4zhfe1v5d9NizsJWjn .title .challenge-link {
      font-size: 17px;
      color: #2B4B8F; }
      .SCW4zhfe1v5d9NizsJWjn .title .challenge-link img {
        margin-right: 11px; }
      .SCW4zhfe1v5d9NizsJWjn .title .challenge-link a {
        color: #2B4B8F;
        text-decoration: underline; }
  .SCW4zhfe1v5d9NizsJWjn h3 {
    font-size: 18px;
    line-height: 150%;
    color: #383E48; }
  .SCW4zhfe1v5d9NizsJWjn .info-content {
    display: flex;
    justify-content: space-between; }
    .SCW4zhfe1v5d9NizsJWjn .info-content .info-card {
      width: 49%;
      padding: 24px;
      margin-bottom: 20px;
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #DEE2EC;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05); }
      .SCW4zhfe1v5d9NizsJWjn .info-content .info-card h3 {
        margin-bottom: 20px; }
        .SCW4zhfe1v5d9NizsJWjn .info-content .info-card h3 button {
          float: right;
          width: 76px; }
    .SCW4zhfe1v5d9NizsJWjn .info-content .personal-info-list {
      list-style: none;
      padding: 0; }
      .SCW4zhfe1v5d9NizsJWjn .info-content .personal-info-list li {
        margin-bottom: 18px;
        font-size: 14px;
        color: #6D7D8C;
        font-weight: 500; }
        .SCW4zhfe1v5d9NizsJWjn .info-content .personal-info-list li span {
          display: inline-block;
          color: #44433D;
          width: 150px; }
  .SCW4zhfe1v5d9NizsJWjn .overview .divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 40px 0; }
    .SCW4zhfe1v5d9NizsJWjn .overview .divider span {
      padding-bottom: 3px;
      margin-top: -2px;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 2.3px;
      text-transform: uppercase;
      color: #264382;
      border-bottom: 3px solid #264382;
      font-family: Gilroy; }
  .SCW4zhfe1v5d9NizsJWjn .overview .section {
    background: #ffffff;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border: 1px solid #DEE2EC;
    padding: 24px;
    margin-bottom: 16px;
    width: 49%;
    font-size: 12px;
    color: #70808E;
    display: inline-block;
    vertical-align: top; }
    .SCW4zhfe1v5d9NizsJWjn .overview .section:first-child {
      margin-right: 2%; }
    .SCW4zhfe1v5d9NizsJWjn .overview .section .switch-month {
      width: 152px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      float: right;
      padding: 0 17px;
      border: 1px solid #DEE2EC;
      border-radius: 12px;
      font-size: 14px;
      color: #52606C;
      font-weight: 600; }
      .SCW4zhfe1v5d9NizsJWjn .overview .section .switch-month .anticon {
        color: #A4B9D2;
        cursor: pointer; }
    .SCW4zhfe1v5d9NizsJWjn .overview .section .edit-focus {
      float: right;
      cursor: pointer;
      color: #264382;
      font-size: 14px; }
    .SCW4zhfe1v5d9NizsJWjn .overview .section .aggregation {
      padding: 0;
      list-style: none;
      margin-top: 45px;
      display: flex;
      justify-content: space-between; }
      .SCW4zhfe1v5d9NizsJWjn .overview .section .aggregation li {
        vertical-align: top;
        border-right: 1px solid #E6E6E6;
        padding: 0 40px; }
        .SCW4zhfe1v5d9NizsJWjn .overview .section .aggregation li:first-child {
          padding-left: 0; }
        .SCW4zhfe1v5d9NizsJWjn .overview .section .aggregation li:last-child {
          padding-right: 0;
          border-right: none; }
        .SCW4zhfe1v5d9NizsJWjn .overview .section .aggregation li > div {
          width: 291px; }
        .SCW4zhfe1v5d9NizsJWjn .overview .section .aggregation li .item {
          font-weight: bold;
          font-size: 14px;
          color: #383E48;
          display: flex;
          justify-content: space-between; }
          .SCW4zhfe1v5d9NizsJWjn .overview .section .aggregation li .item span:first-child {
            font-size: 18px; }
            .SCW4zhfe1v5d9NizsJWjn .overview .section .aggregation li .item span:first-child.achieved {
              color: #44433D;
              opacity: .5;
              font-size: 14px;
              margin-right: 5px; }
        .SCW4zhfe1v5d9NizsJWjn .overview .section .aggregation li .ant-progress-success-bg, .SCW4zhfe1v5d9NizsJWjn .overview .section .aggregation li .ant-progress-bg {
          background-color: #7BAD2D; }

._3YqphGI69ziLzgy0ggNnSM .ant-modal-content {
  border-radius: 12px; }

._3YqphGI69ziLzgy0ggNnSM .ant-modal-confirm-body {
  font-family: 'Gilroy'; }
  ._3YqphGI69ziLzgy0ggNnSM .ant-modal-confirm-body .ant-modal-confirm-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.3px;
    color: #262626; }
  ._3YqphGI69ziLzgy0ggNnSM .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.3px;
    color: #60656D; }
    ._3YqphGI69ziLzgy0ggNnSM .ant-modal-confirm-body .ant-modal-confirm-content p {
      margin-bottom: 0px; }
    ._3YqphGI69ziLzgy0ggNnSM .ant-modal-confirm-body .ant-modal-confirm-content ul {
      -webkit-padding-start: 22px;
              padding-inline-start: 22px; }

._3YqphGI69ziLzgy0ggNnSM .ant-modal-confirm-btns .ant-btn {
  min-width: 80px;
  height: 40px;
  font-family: 'Lato';
  font-size: 13px; }

._21vwCslfsdzgF0J7Je3ofj {
  margin-top: 25px;
  padding-bottom: 22px; }
  ._21vwCslfsdzgF0J7Je3ofj .title {
    font: Gilroy;
    color: #4C6072;
    font-size: 24px;
    display: flex;
    letter-spacing: 0.3px;
    justify-content: space-between; }
  ._21vwCslfsdzgF0J7Je3ofj .box-view {
    background-color: white;
    border-radius: 8px;
    padding: 16px 30px 16px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    ._21vwCslfsdzgF0J7Je3ofj .box-view .box-title-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      ._21vwCslfsdzgF0J7Je3ofj .box-view .box-title-container .box-title {
        font: Gilroy;
        color: #4C6072;
        font-size: 18px;
        height: 41px; }
      ._21vwCslfsdzgF0J7Je3ofj .box-view .box-title-container .box-subtitle {
        font: Gilroy;
        color: #4C6072;
        font-size: 14px;
        line-height: 23px;
        letter-spacing: 0.006em; }
        ._21vwCslfsdzgF0J7Je3ofj .box-view .box-title-container .box-subtitle .box-point-text {
          font: Gilroy;
          color: #4C6072;
          font-size: 14px;
          font-weight: 500;
          line-height: 23px;
          letter-spacing: 0.006em; }
    ._21vwCslfsdzgF0J7Je3ofj .box-view .box-button {
      border: 1px solid #264382;
      box-sizing: border-box;
      border-radius: 26px;
      width: 140px;
      height: 30px; }
    ._21vwCslfsdzgF0J7Je3ofj .box-view .ant-switch-checked {
      background: #2B4B8F; }
  ._21vwCslfsdzgF0J7Je3ofj .has-items {
    margin-top: 15px;
    display: block; }
    ._21vwCslfsdzgF0J7Je3ofj .has-items .box-item {
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      border-top: 1px solid #E6E9EB;
      color: #4C6072; }
      ._21vwCslfsdzgF0J7Je3ofj .has-items .box-item:last-child {
        padding-bottom: 0; }
  ._21vwCslfsdzgF0J7Je3ofj .signed-forms {
    margin-top: 15px;
    height: 105px; }

._19GGeT1faW7T_a70pqa20J {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  background-color: white;
  border-radius: 8px; }
  ._19GGeT1faW7T_a70pqa20J .user-avatar-container {
    width: 226px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    ._19GGeT1faW7T_a70pqa20J .user-avatar-container .user-avatar {
      width: 140px;
      height: 140px;
      border-radius: 70px;
      margin-bottom: 18px; }
  ._19GGeT1faW7T_a70pqa20J .user-info-box {
    display: flex;
    flex-direction: column; }
    ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info {
      flex-grow: 1;
      margin-left: 13px;
      padding: 13px 21px 9px 0px;
      display: flex;
      flex-direction: column;
      min-width: 780px; }
      ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .top {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%; }
        ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .top .title {
          font-family: Gilroy;
          font-size: 14px;
          line-height: 29px;
          text-transform: uppercase;
          color: #4C6072; }
        ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .top .edit {
          font-family: Gilroy;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.3px;
          color: #264382;
          padding-top: 5px;
          cursor: pointer; }
      ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .bottom {
        margin-top: 15px; }
        ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .bottom .ant-legacy-form-item-required::before {
          content: ''; }
        ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .bottom .ant-input, ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .bottom .ant-select-selection, ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .bottom .ant-select-selector, ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .bottom .ant-picker {
          background-color: #F5F6F8;
          border: 1px solid rgba(38, 67, 130, 0.5); }
        ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .bottom .ant-select-disabled > .ant-select-selection, ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .bottom .ant-select-disabled > .ant-select-selector {
          border: 0px; }
        ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .bottom label {
          margin-left: -8px; }
      ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .formIsEditing .ant-input, ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .formIsEditing .ant-select-selection, ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .formIsEditing .ant-select-selector, ._19GGeT1faW7T_a70pqa20J .user-info-box .user-basic-info .formIsEditing .ant-picker {
        border: 0px; }

._3lnyt4oPSMExvtEJ4YrxFA {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px; }
  ._3lnyt4oPSMExvtEJ4YrxFA .logo {
    position: fixed;
    left: 30px;
    top: 20px;
    width: 150px; }
  ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container {
    background-color: white;
    width: 500px;
    min-height: 631px;
    border-radius: 18px;
    overflow: hidden;
    position: relative; }
    ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .back {
      margin: 24px 0 0 22px;
      position: absolute;
      cursor: pointer; }
      ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .back .back-title {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #264382;
        letter-spacing: 2.8px; }
    ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .white-box-container-title {
      text-align: center;
      font-family: Gilroy;
      font-size: 36px;
      letter-spacing: 0.3px;
      color: #A7B1BF;
      margin-top: 54px; }
    ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .white-box-container-notice {
      margin-top: 10px;
      font-family: Heebo;
      font-size: 14px;
      padding: 0 100px;
      line-height: 150%;
      color: rgba(92, 98, 106, 0.8);
      text-align: center; }
      ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .white-box-container-notice span {
        font-weight: bold; }
    ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .form-view-box {
      margin: 15px 22px 26px 20px;
      min-height: 430px;
      overflow: auto; }
      ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .form-view-box .form-view {
        background-color: #FCFDFD;
        border: 1px solid rgba(43, 75, 143, 0.3);
        border-radius: 12px;
        width: 457px;
        height: 100px;
        padding-left: 29px;
        padding-right: 34px;
        margin-top: 15px; }
        ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .form-view-box .form-view .form-view-container {
          font-family: Heebo;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .form-view-box .form-view .form-view-container .form-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 25px; }
          ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .form-view-box .form-view .form-view-container .form-title {
            font-size: 18px;
            color: #29303E;
            letter-spacing: 0.3px; }
          ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .form-view-box .form-view .form-view-container .form-view-icon-title {
            margin-top: 5px;
            color: #264382;
            letter-spacing: 0.3px;
            line-height: 100%; }
          ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .form-view-box .form-view .form-view-container .opr-icon {
            width: 20px;
            margin: 0 auto; }
      ._3lnyt4oPSMExvtEJ4YrxFA .white-box-container .form-view-box .add-more-tip {
        color: #3561C0;
        text-align: center;
        margin: 20px 0;
        cursor: pointer;
        font-family: Heebo;
        font-weight: 500;
        font-size: 12px; }

.Dql6L-8o8TPKo_XlvWIzo {
  font-family: Gilroy; }
  .Dql6L-8o8TPKo_XlvWIzo .ant-modal-footer {
    border: none;
    padding: 24px; }
    .Dql6L-8o8TPKo_XlvWIzo .ant-modal-footer .ant-btn {
      border: none; }
  .Dql6L-8o8TPKo_XlvWIzo h3.title {
    font-size: 24px;
    color: #4C6072;
    margin-bottom: 21px; }
  .Dql6L-8o8TPKo_XlvWIzo p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65); }
    .Dql6L-8o8TPKo_XlvWIzo p strong {
      font-family: 'Gilroy-bold';
      color: rgba(0, 0, 0, 0.9); }

.ant-table-thead>tr>th {
    color: #8D99A5 !important;
}

.session-pricing-header {
    margin: 40px 0px 20px 0px;
}

.editable-input{
    width: 120px !important;
}
.react-code-input input[type="number"]::-webkit-outer-spin-button,
.react-code-input input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.react-code-input input[type="number"] {
  -moz-appearance: textfield;
  font-family: Gilroy; }

.U0TpQ2bdxDHVrZk0AiLcN {
  font-family: Gilroy; }
  .U0TpQ2bdxDHVrZk0AiLcN .top-image {
    padding-top: 50px; }
  .U0TpQ2bdxDHVrZk0AiLcN .main-title {
    font-size: 24px;
    color: #545F6D;
    letter-spacing: 0.3px;
    text-align: center; }
  .U0TpQ2bdxDHVrZk0AiLcN .sub-title {
    font-family: Heebo;
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: #5C626A;
    opacity: 0.8; }
  .U0TpQ2bdxDHVrZk0AiLcN .main-button {
    width: 320px;
    height: 50px;
    font-size: 16px; }
  .U0TpQ2bdxDHVrZk0AiLcN .columnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .U0TpQ2bdxDHVrZk0AiLcN .twoFAContainer .enable-2fa-sub-title {
    padding-top: 16px;
    width: 334px;
    text-align: center;
    padding-bottom: 42px; }
  .U0TpQ2bdxDHVrZk0AiLcN .twoFAContainer .enable-2fa-main-title {
    width: 295px;
    padding-top: 40px; }
  .U0TpQ2bdxDHVrZk0AiLcN .twoFAContainer .enable-2fa-skip {
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #2B4B8F;
    width: 193px;
    height: 30px;
    padding-top: 28px;
    cursor: pointer; }
  .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .enter-phone-number-top-title {
    font-size: 12px;
    letter-spacing: 0.6px;
    padding-top: 58px;
    color: #A7B1BF;
    text-transform: uppercase;
    padding-bottom: 2px; }
  .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .enter-phone-number-desc {
    width: 333px;
    text-align: center;
    padding-top: 8px; }
  .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .form-container {
    margin-top: 15px;
    width: 320px;
    height: 54px; }
    .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .form-container .ant-legacy-form-item-required::before {
      content: ''; }
    .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .form-container .ant-input {
      background-color: #F5F6F8 !important;
      border: 0px;
      font-family: Heebo;
      font-size: 16px;
      font-weight: normal; }
    .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .form-container label {
      margin-left: -8px; }
    .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .form-container input:-webkit-autofill,
    .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .form-container input:-webkit-autofill:hover,
    .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .form-container input:-webkit-autofill:focus,
    .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .form-container input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 0 #F5F6F8 inset !important; }
  .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .enter-phone-main-button {
    padding-top: 40px; }
  .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .invalid-label {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    color: red;
    letter-spacing: 0.3px;
    width: 159px; }
  .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .resend-label {
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: 0.3px;
    padding-top: 60px; }
    .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .resend-label .resend-button {
      font-weight: bold;
      color: #2B4B8F;
      padding-left: 3px; }
    .U0TpQ2bdxDHVrZk0AiLcN .enterPhoneNumberContainer .resend-label .disable-resend-button {
      color: gray; }
  .U0TpQ2bdxDHVrZk0AiLcN .changePasswordContainer .main-title {
    color: #A7B1BF;
    font-size: 36px;
    letter-spacing: 0.3px;
    line-height: 40px;
    padding-top: 56px; }
  .U0TpQ2bdxDHVrZk0AiLcN .changePasswordContainer .sub-title {
    color: #5C626A;
    opacity: 0.8;
    font-family: Heebo;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    padding-top: 14px; }
  .U0TpQ2bdxDHVrZk0AiLcN .changePasswordContainer .form-container {
    padding-top: 29px;
    margin-top: 15px;
    width: 320px; }
    .U0TpQ2bdxDHVrZk0AiLcN .changePasswordContainer .form-container .ant-legacy-form-item-required::before {
      content: ''; }
    .U0TpQ2bdxDHVrZk0AiLcN .changePasswordContainer .form-container .ant-input {
      background-color: #ffffff !important;
      border: 0px;
      font-family: Heebo;
      font-size: 16px;
      font-weight: normal; }
    .U0TpQ2bdxDHVrZk0AiLcN .changePasswordContainer .form-container label {
      margin-left: -8px; }

._2Mcq6O2sbhQ1_a30nAfKHE .tips-content {
  background-color: rgba(152, 169, 188, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 61px;
  margin-top: 30px;
  padding-left: 21px; }
  ._2Mcq6O2sbhQ1_a30nAfKHE .tips-content.hascard {
    background-color: rgba(152, 169, 188, 0.1); }
  ._2Mcq6O2sbhQ1_a30nAfKHE .tips-content.error {
    background: rgba(255, 81, 81, 0.1); }
    ._2Mcq6O2sbhQ1_a30nAfKHE .tips-content.error p {
      color: #FF5151; }
  ._2Mcq6O2sbhQ1_a30nAfKHE .tips-content p {
    color: #98A9BC;
    font-size: 14px;
    letter-spacing: 0.6px;
    margin: 0px;
    padding-left: 8px;
    text-transform: uppercase; }

._2Mcq6O2sbhQ1_a30nAfKHE .bank-info {
  padding-top: 21px; }
  ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail {
    display: flex;
    align-items: center;
    margin-bottom: 41px; }
    ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail .bank-info-input {
      width: 388px; }
      ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail .bank-info-input .edit-button {
        color: #264382;
        font-size: 14px;
        font-weight: bold;
        margin-top: 14px;
        cursor: pointer; }
      ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail .bank-info-input .ant-legacy-form-item {
        margin-bottom: 7px; }
    ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail .normal-label-form .ant-legacy-form-item .ant-legacy-form-item-label {
      position: relative;
      background: rgba(0, 0, 0, 0);
      margin-top: 0px;
      margin-bottom: 10px;
      margin-left: -10px; }
      ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail .normal-label-form .ant-legacy-form-item .ant-legacy-form-item-label label {
        color: rgba(56, 67, 94, 0.5);
        font-weight: bold; }
      ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail .normal-label-form .ant-legacy-form-item .ant-legacy-form-item-label label::after {
        content: ''; }
      ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail .normal-label-form .ant-legacy-form-item .ant-legacy-form-item-label .ant-legacy-form-item-required::before {
        content: ''; }
    ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail .bank-info-card {
      box-shadow: 30px 15px 20px -10px #dedfe0;
      margin-left: 89px;
      width: 355px;
      height: 220px;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start; }
      ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail .bank-info-card .bank-info-card-logo {
        margin-top: 26px;
        margin-left: 28px; }
      ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail .bank-info-card .bank-info-user-card-info {
        color: #4C6072;
        font-size: 22px;
        font-family: Courier New, Courier, monospace;
        line-height: 150%;
        margin-left: 28px;
        margin-bottom: -7px; }
      ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-detail .bank-info-card .bank-info-card-support-banks {
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: #96A1B8;
        font-size: 12px;
        font-weight: bold;
        margin-right: 24px;
        margin-bottom: 14px; }
  ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-description {
    border-radius: 8px;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 18px 30px;
    padding-bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-description .secure-icon {
      position: absolute;
      top: 19px;
      right: 25px; }
    ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-description .bank-info-description-content p {
      letter-spacing: 0.3px; }
    ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-description .bank-info-description-content .bank-info-description-content-title {
      color: "#29303E";
      font-size: 12px;
      line-height: 100%; }
    ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-description .bank-info-description-content .bank-info-description-content-sub-title {
      font-size: 14px;
      line-height: 150%;
      color: #5E687D;
      margin-bottom: 24px; }
    ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-description .bank-info-description-content .bank-info-description-content-detail {
      color: "#5E687D";
      font-size: 12px;
      line-height: 150%;
      font-family: Lato;
      font-style: italic;
      margin-bottom: 8px;
      display: flex;
      align-items: flex-start; }
      ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-description .bank-info-description-content .bank-info-description-content-detail img {
        margin-top: 3px; }
      ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-description .bank-info-description-content .bank-info-description-content-detail span {
        padding-left: 10px;
        display: inline-block;
        width: calc(100% - 25px); }
      ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-description .bank-info-description-content .bank-info-description-content-detail .anticon {
        margin-right: 20px; }
    ._2Mcq6O2sbhQ1_a30nAfKHE .bank-info .bank-info-description .bank-info-description-content .bank-info-description-content-italic {
      padding-top: 15px;
      font-family: Lato;
      font-style: italic;
      font-size: 12px;
      color: #5E687D;
      margin-bottom: 22px; }

._2Mcq6O2sbhQ1_a30nAfKHE .privacy {
  padding-top: 30px;
  padding-bottom: 27px; }
  ._2Mcq6O2sbhQ1_a30nAfKHE .privacy p {
    font-style: italic;
    color: #5E687D;
    line-height: 150%;
    letter-spacing: 0.3px;
    font-size: 12px; }
  ._2Mcq6O2sbhQ1_a30nAfKHE .privacy .ant-checkbox-wrapper {
    margin-right: 11px; }
  ._2Mcq6O2sbhQ1_a30nAfKHE .privacy a {
    color: #264382;
    text-decoration: underline; }

._2Mcq6O2sbhQ1_a30nAfKHE .confirm {
  display: flex;
  justify-content: center;
  position: relative; }
  ._2Mcq6O2sbhQ1_a30nAfKHE .confirm .confirm-button {
    width: 186px;
    height: 48px;
    margin-top: 24px; }
  ._2Mcq6O2sbhQ1_a30nAfKHE .confirm .skip-button {
    color: #264382;
    font-size: 12px;
    letter-spacing: 2.8px;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 38px;
    cursor: pointer; }

._2Mcq6O2sbhQ1_a30nAfKHE .credit-card-name {
  background: #FBFBFB;
  border-radius: 8px; }

._2Mcq6O2sbhQ1_a30nAfKHE .stripe-element-input {
  background: #FBFBFB;
  height: 45px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 14px 11px; }
  ._2Mcq6O2sbhQ1_a30nAfKHE .stripe-element-input.prefill {
    line-height: 16px;
    margin-bottom: 8px;
    background-color: #E8ECF0; }
  ._2Mcq6O2sbhQ1_a30nAfKHE .stripe-element-input.invalid {
    background: #FEF8F8;
    border: 1px solid #FF5151; }

._2Mcq6O2sbhQ1_a30nAfKHE .invalid-message {
  color: #FF5151;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  line-height: 21px; }

._2Mcq6O2sbhQ1_a30nAfKHE .stripe-element-label {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  margin-top: 21px;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
  color: #38435E;
  opacity: 0.5;
  white-space: nowrap; }

._2Mcq6O2sbhQ1_a30nAfKHE .cvv-icon {
  margin-top: 52px; }

._2CKBPZRQdJbOmi2pZArjd4 {
  background: #7BAD2D;
  margin: -15px -20px;
  padding: 20px 16px;
  border-radius: 6px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-family: Gilroy;
  min-width: 711px; }
  ._2CKBPZRQdJbOmi2pZArjd4 .icon {
    display: flex; }
    ._2CKBPZRQdJbOmi2pZArjd4 .icon .anticon {
      padding-right: 5px; }
  ._2CKBPZRQdJbOmi2pZArjd4 h3 {
    color: #ffffff;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.3px; }
  ._2CKBPZRQdJbOmi2pZArjd4 p {
    margin: 0;
    font-size: 13px;
    line-height: 19px; }

._2wr_IEGZSpLkGGuDF1HHVO {
  position: fixed;
  bottom: 136px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  background: #383E48;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  width: 711px;
  padding: 20px 24px;
  color: #FFFFFF;
  font-family: Gilroy;
  z-index: 9; }
  ._2wr_IEGZSpLkGGuDF1HHVO > div:last-child {
    cursor: pointer; }

.bGDvosM-it6FboW9n6SsB {
  border-radius: 8px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  padding: 32px 24px; }
  .bGDvosM-it6FboW9n6SsB footer {
    text-align: right;
    margin-top: 40px; }
    .bGDvosM-it6FboW9n6SsB footer button {
      margin-left: 10px; }
    .bGDvosM-it6FboW9n6SsB footer .ant-btn-background-ghost {
      border: none;
      color: #4C6072;
      box-shadow: none; }

._32dTmg3B0h9DdpMgiCo2dm {
  max-width: 382px;
  width: calc(100% - 635px); }
  ._32dTmg3B0h9DdpMgiCo2dm .ant-tabs-nav-container {
    padding: 0; }
  ._32dTmg3B0h9DdpMgiCo2dm .ant-tabs-nav .ant-tabs-tab {
    font-size: 14px;
    font-family: Gilroy-Bold;
    padding-left: 0;
    padding-right: 0; }
  ._32dTmg3B0h9DdpMgiCo2dm .ant-tabs-tab-arrow-show {
    display: none; }
  ._32dTmg3B0h9DdpMgiCo2dm .recent-activity {
    min-width: 347px;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #DEE2EC;
    border-radius: 12px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05); }
  ._32dTmg3B0h9DdpMgiCo2dm h3 {
    font-size: 18px;
    font-weight: bold; }
  ._32dTmg3B0h9DdpMgiCo2dm .statistics-list {
    list-style: none;
    padding: 0; }
    ._32dTmg3B0h9DdpMgiCo2dm .statistics-list li {
      margin: 14px 0;
      display: flex;
      color: #323F4A;
      font-size: 13px; }
      ._32dTmg3B0h9DdpMgiCo2dm .statistics-list li div {
        width: 50%; }
        ._32dTmg3B0h9DdpMgiCo2dm .statistics-list li div:first-child {
          font-family: Gilroy-Bold; }
      ._32dTmg3B0h9DdpMgiCo2dm .statistics-list li .status-circle:before {
        content: '';
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border-radius: 8px;
        display: inline-block;
        background: #F7C137; }
      ._32dTmg3B0h9DdpMgiCo2dm .statistics-list li .Opened:before {
        background: #7BAD2D; }
  ._32dTmg3B0h9DdpMgiCo2dm .activity-list {
    list-style: none;
    padding: 0; }
    ._32dTmg3B0h9DdpMgiCo2dm .activity-list .item {
      margin: 10px 0;
      display: flex;
      color: #323F4A;
      letter-spacing: 0.3px;
      font-size: 13px; }
      ._32dTmg3B0h9DdpMgiCo2dm .activity-list .item .avatar {
        min-width: 32px;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 1px solid #e0e0e0;
        margin-right: 16px; }
      ._32dTmg3B0h9DdpMgiCo2dm .activity-list .item .avatar-placeholder {
        min-width: 32px;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        margin-right: 16px; }
      ._32dTmg3B0h9DdpMgiCo2dm .activity-list .item .bold {
        font-family: 'Gilroy-Bold';
        text-transform: capitalize; }
      ._32dTmg3B0h9DdpMgiCo2dm .activity-list .item .date {
        color: #97A0A7;
        font-size: 12px; }
      ._32dTmg3B0h9DdpMgiCo2dm .activity-list .item .undo {
        color: #2B4B8F;
        margin-left: 5px;
        cursor: pointer;
        font-family: 'Gilroy-Bold'; }
  ._32dTmg3B0h9DdpMgiCo2dm .show-all {
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    color: #264382;
    cursor: pointer; }

._2jVYSXh_Am1IKluBrYfUwl {
  font-family: Gilroy;
  letter-spacing: 0.3px; }
  ._2jVYSXh_Am1IKluBrYfUwl .ant-modal-content {
    border-radius: 8px; }
    ._2jVYSXh_Am1IKluBrYfUwl .ant-modal-content .ant-modal-header {
      padding-top: 40px;
      padding-left: 40px;
      border: none;
      border-radius: 8px 8px 0 0; }
      ._2jVYSXh_Am1IKluBrYfUwl .ant-modal-content .ant-modal-header .ant-modal-title {
        font-size: 30px;
        font-weight: 500; }
    ._2jVYSXh_Am1IKluBrYfUwl .ant-modal-content .ant-modal-body {
      padding: 40px;
      padding-top: 24px; }
      ._2jVYSXh_Am1IKluBrYfUwl .ant-modal-content .ant-modal-body > p {
        text-align: right;
        margin-top: -15px;
        position: relative;
        z-index: 2; }
        ._2jVYSXh_Am1IKluBrYfUwl .ant-modal-content .ant-modal-body > p span {
          color: #264382;
          cursor: pointer; }
      ._2jVYSXh_Am1IKluBrYfUwl .ant-modal-content .ant-modal-body footer {
        text-align: right; }
      ._2jVYSXh_Am1IKluBrYfUwl .ant-modal-content .ant-modal-body .tip-title {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.3px;
        color: rgba(0, 0, 0, 0.65); }
        ._2jVYSXh_Am1IKluBrYfUwl .ant-modal-content .ant-modal-body .tip-title p {
          color: #82909C; }

.XKgti69RJTnqfJjXB_B2s {
  font-family: 'Gilroy-bold';
  font-size: 12px;
  text-transform: uppercase;
  color: #264382;
  line-height: 1.5;
  position: relative; }
  .XKgti69RJTnqfJjXB_B2s > span {
    cursor: pointer; }
  .XKgti69RJTnqfJjXB_B2s .category-list, .XKgti69RJTnqfJjXB_B2s .subcategory-list, .XKgti69RJTnqfJjXB_B2s .title-list {
    position: absolute;
    min-width: 200px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    list-style: none;
    padding: 0;
    margin-left: -20px;
    margin-top: 15px;
    z-index: 99; }
  .XKgti69RJTnqfJjXB_B2s .subcategory-list, .XKgti69RJTnqfJjXB_B2s .title-list {
    min-width: 170px; }
  .XKgti69RJTnqfJjXB_B2s .sub-container, .XKgti69RJTnqfJjXB_B2s .title-container {
    padding: 0;
    height: 0; }
  .XKgti69RJTnqfJjXB_B2s .subcategory-list, .XKgti69RJTnqfJjXB_B2s .title-list {
    margin-left: 100%;
    top: 0; }
  .XKgti69RJTnqfJjXB_B2s li {
    white-space: nowrap;
    padding: 14px 20px;
    font-family: Gilroy;
    font-size: 15px;
    letter-spacing: 0.006em;
    text-transform: capitalize;
    color: #4C6072;
    display: flex;
    justify-content: space-between;
    cursor: pointer; }
    .XKgti69RJTnqfJjXB_B2s li.active, .XKgti69RJTnqfJjXB_B2s li:hover {
      background: rgba(38, 67, 130, 0.05); }

._3Dj890k5YHxERJ-4S9BHlG .information-editor {
  border: 2px solid #264382;
  border-radius: 8px; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-editor .ant-legacy-form-item {
    margin-bottom: 0;
    max-height: 194px; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-editor .ant-legacy-form-item-label {
    display: none; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-editor textarea {
    border: none;
    resize: none; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-editor textarea.ant-input:focus {
      box-shadow: none; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-editor .ant-divider {
    margin: 17px 0; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-editor .mark-down-support {
    display: block;
    padding: 14px 20px;
    background: #F8F8F8;
    color: #97A0A7;
    border: 1px solid #E8ECF0;
    margin: 0; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-editor .mark-down-support .header {
      display: flex;
      justify-content: space-between; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-editor .mark-down-support .direction-label {
      cursor: pointer;
      color: #264382; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-editor .mark-down-support span {
      color: #264382;
      cursor: pointer;
      border-bottom: 1px solid #264382; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-editor .mark-down-support .direction-content {
      border-top: 1px solid #E8ECF0;
      margin-top: 6px;
      padding-top: 16px;
      margin-bottom: 0; }
      ._3Dj890k5YHxERJ-4S9BHlG .information-editor .mark-down-support .direction-content p {
        margin-bottom: 0; }
      ._3Dj890k5YHxERJ-4S9BHlG .information-editor .mark-down-support .direction-content ul {
        padding-left: 20px; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-editor .ant-input {
    padding: 24px 15px; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-editor .editor-footer {
    letter-spacing: 0.3px;
    font-size: 12px;
    text-align: right;
    padding: 18px 20px; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-editor .editor-footer.templates {
      display: flex;
      justify-content: space-between; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-editor .editor-footer .buttons {
      text-align: right; }
      ._3Dj890k5YHxERJ-4S9BHlG .information-editor .editor-footer .buttons button {
        font-family: 'Gilroy-bold';
        border: none;
        box-shadow: none;
        margin-top: -10px;
        font-weight: bold; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-editor.text-only .ant-input {
    border-radius: 8px 8px 0 0;
    border: none;
    border-bottom: 2px solid;
    border-color: #E8ECF0 !important;
    box-shadow: none !important; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-editor.text-only .editor-footer {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-editor.text-only .limit {
    color: #97A0A7; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-editor.text-only .buttons button {
    margin: 0; }

._3Dj890k5YHxERJ-4S9BHlG .information-container {
  border-radius: 8px;
  padding: 11px 16px;
  color: #264382;
  letter-spacing: 0.3px;
  border: 2px dashed #264382;
  text-align: center;
  position: relative; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-container.Opened, ._3Dj890k5YHxERJ-4S9BHlG .information-container.Sent, ._3Dj890k5YHxERJ-4S9BHlG .information-container.Locked {
    background: rgba(244, 245, 247, 0.5);
    border: none;
    color: #383E48; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-container.Opened .content, ._3Dj890k5YHxERJ-4S9BHlG .information-container.Sent .content, ._3Dj890k5YHxERJ-4S9BHlG .information-container.Locked .content {
      min-height: auto; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-container.text-only {
    color: #383E48;
    text-align: left;
    font-family: 'Gilroy-Bold'; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-container.text-only .opr {
      padding: 5px 16px; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-container:hover .opr {
    display: flex; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-container.Sent:hover .opr, ._3Dj890k5YHxERJ-4S9BHlG .information-container.Opened:hover .opr, ._3Dj890k5YHxERJ-4S9BHlG .information-container.Locked:hover .opr {
    display: none; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-container .null-state, ._3Dj890k5YHxERJ-4S9BHlG .information-container .opr-button {
    cursor: pointer;
    text-transform: uppercase; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-container .opr {
    display: none;
    font-size: 32px;
    justify-content: flex-end;
    align-items: baseline;
    float: right;
    position: absolute;
    background-image: linear-gradient(to top right, rgba(255, 255, 255, 0.5) 100%, white 10%);
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    top: 0;
    left: 0;
    padding: 20px 16px; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-container .opr .opr-button {
      margin-left: 8px; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-container .null-state .anticon {
    margin-right: 16px; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-container .content {
    min-height: 50px;
    display: flex;
    text-align: left;
    font-family: Gilroy;
    font-size: 14px;
    color: #70808E; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-container .content .avatar {
      color: #479FEC;
      font-size: 16px;
      padding-right: 16px; }
    ._3Dj890k5YHxERJ-4S9BHlG .information-container .content strong {
      font-family: 'Gilroy-Bold'; }
  ._3Dj890k5YHxERJ-4S9BHlG .information-container + .done-status {
    margin-top: 8px;
    color: #97A0A7;
    letter-spacing: 0.3px;
    font-size: 12px; }

._39oIkg-HsascgBy4BcC3Oh {
  display: flex;
  border: 1px solid #E4EAEF;
  border-radius: 8px;
  padding: 14px 20px;
  font-size: 12px;
  color: #70808E;
  position: relative;
  line-height: 1.7; }
  ._39oIkg-HsascgBy4BcC3Oh.Draft {
    border: 2px dashed #264382; }
  ._39oIkg-HsascgBy4BcC3Oh .anticon {
    margin-right: 16px;
    margin-top: 3px;
    font-size: 16px; }
    ._39oIkg-HsascgBy4BcC3Oh .anticon + div {
      width: calc(100% - 32px);
      white-space: normal;
      word-break: break-word; }
  ._39oIkg-HsascgBy4BcC3Oh h4 {
    font-size: 14px;
    color: #383E48; }
  ._39oIkg-HsascgBy4BcC3Oh .null-state {
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    color: #264382;
    font-size: 14px;
    margin-left: -32px;
    width: calc(100% + 32px); }
  ._39oIkg-HsascgBy4BcC3Oh .editor-container {
    margin-left: -32px;
    width: calc(100% + 32px); }
    ._39oIkg-HsascgBy4BcC3Oh .editor-container .buttons {
      margin-top: 10px; }
    ._39oIkg-HsascgBy4BcC3Oh .editor-container .buttons {
      text-align: right; }
      ._39oIkg-HsascgBy4BcC3Oh .editor-container .buttons button {
        font-family: 'Gilroy-bold';
        border: none;
        box-shadow: none;
        font-weight: bold; }
  ._39oIkg-HsascgBy4BcC3Oh:hover .opr {
    display: flex; }
  ._39oIkg-HsascgBy4BcC3Oh .opr {
    display: none;
    font-size: 32px;
    justify-content: flex-end;
    align-items: baseline;
    float: right;
    position: absolute;
    background-image: linear-gradient(to top right, rgba(255, 255, 255, 0.5) 90%, white 10%);
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    top: 0;
    left: 0;
    padding: 20px 16px; }
    ._39oIkg-HsascgBy4BcC3Oh .opr .opr-button {
      margin-left: 8px; }
    ._39oIkg-HsascgBy4BcC3Oh .opr .anticon {
      font-size: 32px;
      margin-right: 0; }

._2vr4MEHpGTMktjgK0s2ac3 .goal-item {
  padding: 24px 0;
  border-color: #E4EAEF;
  border-style: solid;
  border-width: 0;
  position: relative; }
  ._2vr4MEHpGTMktjgK0s2ac3 .goal-item:after {
    content: '';
    width: 40px;
    height: 150px;
    position: absolute;
    right: -40px;
    top: 0; }
  ._2vr4MEHpGTMktjgK0s2ac3 .goal-item:hover .delete {
    display: block; }
  ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .delete {
    float: right;
    margin: -10px -40px 0 0;
    display: none;
    cursor: pointer;
    position: relative;
    z-index: 2; }
  ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .name {
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #383E48;
    padding-bottom: 10px; }
  ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content {
    margin: 10px 0;
    display: flex; }
    ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content > div, ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .days, ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .weeks {
      width: 50%; }
      ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content > div img, ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .days img, ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .weeks img {
        max-width: 100%; }
    ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .days, ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .weeks {
      display: inline-block;
      width: 50%;
      font-family: 'Gilroy-Bold';
      font-size: 30px; }
      ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .days span, ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .weeks span {
        font-family: Gilroy;
        font-size: 12px;
        color: #70808E;
        display: block;
        white-space: normal; }
      ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .days .change, ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .weeks .change {
        display: inline-block;
        margin-left: 5px; }
      ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .days .green, ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .weeks .green {
        color: #7BAD2D; }
      ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .days .red, ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .weeks .red {
        color: #FF6B00; }
    ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .days {
      white-space: nowrap;
      color: #383E48; }
    ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .weeks {
      color: #B7BFC6; }
    ._2vr4MEHpGTMktjgK0s2ac3 .goal-item .data-content .unit {
      display: inline-block !important;
      font-size: 12px;
      color: #70808E; }

.wrjugs_pYSvmr1RGybgfK {
  background: rgba(38, 67, 130, 0.03);
  border-radius: 8px;
  padding: 0 24px 1px 24px;
  border: 1px solid #E4EAEF; }

._2UG0vLP6-WKRAwHXBoipu9 .title {
  text-align: left;
  font-size: 24px; }

._2UG0vLP6-WKRAwHXBoipu9 .ant-modal-footer {
  border: none;
  padding: 0 24px;
  padding-bottom: 24px; }

._3hnn9jNcg2o9uUHupcY3w5 {
  padding-bottom: 150px;
  font-family: Gilroy; }
  ._3hnn9jNcg2o9uUHupcY3w5 .ant-alert.ant-alert-warning {
    color: #383E48; }
    ._3hnn9jNcg2o9uUHupcY3w5 .ant-alert.ant-alert-warning .ant-alert-icon, ._3hnn9jNcg2o9uUHupcY3w5 .ant-alert.ant-alert-warning .ant-alert-message {
      color: #383E48; }
  ._3hnn9jNcg2o9uUHupcY3w5.User .main-container, ._3hnn9jNcg2o9uUHupcY3w5.Service_Provider .main-container {
    width: 616px;
    padding-right: 32px;
    padding-left: 32px; }
  ._3hnn9jNcg2o9uUHupcY3w5.User .bg-image, ._3hnn9jNcg2o9uUHupcY3w5.Service_Provider .bg-image {
    width: calc(100% + 32px + 32px);
    margin-left: -32px; }
  ._3hnn9jNcg2o9uUHupcY3w5 .email-content {
    display: flex;
    justify-content: space-between; }
  ._3hnn9jNcg2o9uUHupcY3w5 img.delete-icon {
    width: 32px !important; }
  ._3hnn9jNcg2o9uUHupcY3w5 img.big-chart {
    width: 100% !important; }
  ._3hnn9jNcg2o9uUHupcY3w5 .switch-date-range {
    float: right;
    margin-top: -75px; }
  ._3hnn9jNcg2o9uUHupcY3w5 .extra-information {
    padding: 11px 16px;
    padding-bottom: 0;
    margin: 16px 0;
    border: 1px solid #E4EAEF;
    border-radius: 8px;
    font-size: 12px;
    line-height: 17px; }
    ._3hnn9jNcg2o9uUHupcY3w5 .extra-information h5 {
      font-size: 12px;
      font-family: Gilroy-Bold;
      color: #383E48; }
    ._3hnn9jNcg2o9uUHupcY3w5 .extra-information p {
      color: #70808E; }
  ._3hnn9jNcg2o9uUHupcY3w5 .profile-container {
    margin-top: 16px;
    padding-bottom: 16px; }
    ._3hnn9jNcg2o9uUHupcY3w5 .profile-container .profile-info {
      display: inline-block;
      vertical-align: top;
      width: 268px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .profile-container .profile-info:first-child {
        margin-right: 14px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .profile-container .profile-info:first-child:last-child {
        width: 100%;
        margin: 0; }
  ._3hnn9jNcg2o9uUHupcY3w5 .yes-no-chart {
    display: flex;
    justify-content: space-around;
    padding: 0 40px;
    padding-top: 20px; }
    ._3hnn9jNcg2o9uUHupcY3w5 .yes-no-chart .check-dot {
      text-align: center; }
    ._3hnn9jNcg2o9uUHupcY3w5 .yes-no-chart div {
      min-width: 0 !important; }
  ._3hnn9jNcg2o9uUHupcY3w5 footer {
    margin-left: -38px;
    height: 88px;
    background: #FFFFFF;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 64px;
    position: fixed;
    width: calc(1440px - 250px - 64px);
    max-width: calc(100% - 250px - 64px);
    z-index: 2; }
    ._3hnn9jNcg2o9uUHupcY3w5 footer span {
      font-size: 14px;
      color: #264382;
      letter-spacing: 0.3px;
      text-transform: uppercase; }
    ._3hnn9jNcg2o9uUHupcY3w5 footer button {
      width: 186px;
      height: 48px;
      margin-left: 24px; }
      ._3hnn9jNcg2o9uUHupcY3w5 footer button span {
        color: inherit; }
  ._3hnn9jNcg2o9uUHupcY3w5 .main-container {
    border-radius: 12px;
    padding: 0  59px 32px 24px;
    width: 635px;
    background: #FFFFFF;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid #DEE2EC;
    margin-top: 24px; }
  ._3hnn9jNcg2o9uUHupcY3w5 .subject-container {
    border-radius: 12px;
    padding: 0  59px 32px 24px;
    width: 635px;
    background: #FFFFFF;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid #DEE2EC; }
  ._3hnn9jNcg2o9uUHupcY3w5 .section-container {
    margin-top: 16px; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container:first-child {
      margin-top: 0; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container .sub-section {
      margin: 0 -28px;
      padding: 0 28px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .sub-section .delete, ._3hnn9jNcg2o9uUHupcY3w5 .section-container .sub-section .deletable-part-opr {
        float: right;
        margin-right: -40px;
        display: none;
        cursor: pointer; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .sub-section:hover .delete {
        display: block; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container .deletable-part {
      margin: 0 -40px;
      padding: 0 40px; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container .deletable-part:hover .deletable-part-opr {
      display: block;
      margin-top: 25px; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container .summary-date {
      font-family: Gilroy-Bold;
      font-size: 12px;
      color: #70808E;
      text-transform: uppercase;
      padding-top: 24px; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container > h3 {
      margin: 0;
      margin-bottom: 24px;
      padding: 14px 25px;
      font-size: 18px;
      font-family: Gilroy-Bold;
      color: #ffffff;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container > h3 svg {
        cursor: pointer;
        display: none; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container > h3:hover svg {
        display: block; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container > h3.Overview {
        color: #383E48;
        padding: 0;
        font-size: 24px;
        margin-bottom: 0; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container > h3.Rejuvenation {
        background: #5C00D1; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container > h3.Exercise {
        background: #FF6B00; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container > h3.Nutrition {
        background: #7BAD2D; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container > h3.Weight {
        background: #3194EB; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container .table-header {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      color: #70808E;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 100%;
      vertical-align: top; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .table-header div {
        display: inline-block; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .table-header div .anticon {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5); }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .table-header .days-7, ._3hnn9jNcg2o9uUHupcY3w5 .section-container .table-header .week-4 {
        text-align: right;
        width: 100px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .table-header .days-7 {
        margin-right: -40px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .table-header .change {
        margin-left: 30px;
        margin-right: 35px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .table-header .calendar {
        margin-right: -31px;
        display: flex;
        width: 140px;
        margin-right: 45px;
        justify-content: space-between;
        text-align: center; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container .ref-container img {
      max-width: 100%; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section {
      display: flex;
      margin-bottom: 15px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .yes-no-chart {
        padding-top: 5px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .left-title {
        width: 32px;
        text-transform: uppercase;
        border-radius: 8px 0px 0px 8px;
        display: flex;
        align-items: center; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .left-title.Rejuvenation {
          color: #5C00D1;
          background: #EFE6FA; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .left-title.Exercise {
          color: #FF6B00;
          background: #FFF0E6; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .left-title.Nutrition {
          color: #7BAD2D;
          background: #F2F7EA; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .left-title.Weight {
          color: #3194EB;
          background: #E2F2FF; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row {
        position: relative;
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(145, 159, 171, 0.1);
        margin-left: 16px;
        text-align: center;
        font-size: 12px;
        color: #383E48;
        letter-spacing: 0.5px;
        font-weight: bold;
        font-family: 'Gilroy-Bold';
        min-height: 72px; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row:last-child {
          border-bottom: 1px solid rgba(145, 159, 171, 0.1); }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row:hover .delete {
          display: block; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row .chart {
          width: 225px;
          text-align: right; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row .name {
          width: 115px;
          text-align: left;
          letter-spacing: 1px;
          font-weight: 800; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row .name span, ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row .name b {
            display: inline-block;
            width: 100px; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row .gray {
          color: #B7BFC6; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row .green {
          color: #7BAD2D; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row .red {
          color: #FF6B00; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row .normal {
          color: #70808E; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row .black {
          color: #383E48; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row div {
          min-width: 65px; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row img {
          margin-right: 20px;
          width: 150px;
          height: 50px; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .overview-section .right-content .row .delete {
          width: 0;
          position: absolute;
          right: -50px;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          display: none;
          cursor: pointer; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container .zone-list {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      padding-right: 10px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .zone-list li {
        min-width: 42px;
        color: #8596A4;
        font-size: 12px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .zone-list li:before {
        content: '';
        width: 6px;
        height: 6px;
        display: inline-block;
        border-radius: 6px;
        background: #c1c1c1;
        margin-right: 4px;
        vertical-align: middle; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .zone-list .z-5:before {
        background: #FF501A; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .zone-list .z-4:before {
        background: #FC7558; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .zone-list .z-3:before {
        background: #FB9043; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .zone-list .z-2:before {
        background: #F7AD69; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container h4.sub-title {
      margin-top: 27px;
      margin-bottom: 16px;
      font-family: "Gilroy-Bold";
      font-weight: 800;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #383E48; }
    ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart {
      margin: 20px 0;
      overflow: hidden; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-container {
        margin-top: -45px;
        display: flex;
        position: relative; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-container .label {
          position: absolute;
          top: 25%;
          color: #70808E;
          font-size: 12px;
          text-transform: uppercase;
          align-items: center;
          display: flex; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-container .label span {
            display: block;
            text-align: center;
            white-space: nowrap; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-container .left-axis {
          left: 0; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-container .left-axis span {
            -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
            height: 43px; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-container .right-axis {
          right: 0; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-container .right-axis span {
            -webkit-transform: rotate(90deg);
                    transform: rotate(90deg);
            margin-left: -30px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels {
        padding: 0 40px; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-type, ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-name, ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-label {
          display: flex;
          justify-content: space-between;
          align-items: center; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .name svg {
          vertical-align: middle; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-label {
          border-top: 2px solid #E4EAEF;
          padding: 10px 0; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .workout-summary {
          width: 202px;
          display: flex;
          justify-content: space-between; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-type {
          border-top: 2px solid #E4EAEF;
          padding-top: 16px;
          padding-bottom: 5px;
          font-family: 'Gilroy-bold'; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-type .name > span {
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 4px;
            margin-right: 8px;
            vertical-align: middle; }
            ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-type .name > span.Resistance {
              background: #58646B; }
            ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-type .name > span.Aerobic {
              background: #FF6B00; }
            ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-type .name > span.Activity {
              background: #BCCA70; }
            ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-type .name > span.Movement {
              background: #C29E7C; }
            ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-type .name > span.non-program {
              background: #C4C4C4; }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-name {
          padding-left: 22px;
          padding-bottom: 5px; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-name:last-child {
            padding-bottom: 15px; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .workout-labels .activity-name .name:before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-left: -14px; }
      ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 10px 16px;
        border-radius: 8px;
        background: rgba(38, 67, 130, 0.05); }
        ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip {
          color: #70808E;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          font-weight: bold;
          font-size: 12px; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip svg {
            vertical-align: middle; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip span:first-child, ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip i:first-child {
            margin-right: 8px;
            display: inline-block;
            vertical-align: middle;
            box-sizing: border-box; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip span {
            margin-top: -2px; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip .circle {
            border-radius: 25px;
            border: 2px solid #479FEC;
            width: 14px;
            height: 14px; }
          ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip .rect {
            border-radius: 4px;
            width: 14px;
            height: 14px; }
            ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip .rect.sleep-duration {
              background: #5C00D1; }
            ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip .rect.aerobic {
              background: #F3B555; }
            ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip .rect.resistance {
              background: #58646B; }
            ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip .rect.flexibility {
              background: #C9D48A; }
            ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip .rect.percentages {
              background: #FF6B00; }
            ._3hnn9jNcg2o9uUHupcY3w5 .section-container .big-chart .chart-label .tip .rect.cummulative {
              background: #479FEC; }

._2lBAXIvZ2thzyHgjrr6ZYJ {
  background: #7BAD2D;
  margin: -15px -20px;
  padding: 20px 16px;
  border-radius: 6px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-family: Gilroy;
  min-width: 711px; }
  ._2lBAXIvZ2thzyHgjrr6ZYJ .icon {
    display: flex; }
    ._2lBAXIvZ2thzyHgjrr6ZYJ .icon .anticon {
      padding-right: 5px; }
  ._2lBAXIvZ2thzyHgjrr6ZYJ h3 {
    color: #ffffff;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.3px; }
  ._2lBAXIvZ2thzyHgjrr6ZYJ p {
    margin: 0;
    font-size: 13px;
    line-height: 19px; }

._3MH5tuhH-lBUHF0uLD9QR_ {
  position: fixed;
  bottom: 136px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  background: #383E48;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  width: 711px;
  padding: 20px 24px;
  color: #FFFFFF;
  font-family: Gilroy; }
  ._3MH5tuhH-lBUHF0uLD9QR_ > div:last-child {
    cursor: pointer; }

._2VQ3YY3ReJuJt-VCYqQF78 {
  max-width: 347px;
  width: calc(100% - 670px); }
  ._2VQ3YY3ReJuJt-VCYqQF78 .ant-tabs-nav-container {
    padding: 0; }
  ._2VQ3YY3ReJuJt-VCYqQF78 .ant-tabs-nav .ant-tabs-tab {
    font-size: 14px;
    font-family: Gilroy-Bold;
    padding-left: 0;
    padding-right: 0; }
  ._2VQ3YY3ReJuJt-VCYqQF78 .ant-tabs-tab-arrow-show {
    display: none; }
  ._2VQ3YY3ReJuJt-VCYqQF78 .recent-activity {
    min-width: 347px;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #DEE2EC;
    border-radius: 12px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05); }
  ._2VQ3YY3ReJuJt-VCYqQF78 h3 {
    font-size: 18px;
    font-weight: bold; }
  ._2VQ3YY3ReJuJt-VCYqQF78 .statistics-list {
    list-style: none;
    padding: 0; }
    ._2VQ3YY3ReJuJt-VCYqQF78 .statistics-list li {
      margin: 14px 0;
      display: flex;
      color: #323F4A;
      font-size: 13px; }
      ._2VQ3YY3ReJuJt-VCYqQF78 .statistics-list li div {
        width: 50%; }
        ._2VQ3YY3ReJuJt-VCYqQF78 .statistics-list li div:first-child {
          font-family: Gilroy-Bold; }
      ._2VQ3YY3ReJuJt-VCYqQF78 .statistics-list li .status-circle:before {
        content: '';
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border-radius: 8px;
        display: inline-block;
        background: #F7C137; }
      ._2VQ3YY3ReJuJt-VCYqQF78 .statistics-list li .Opened:before {
        background: #7BAD2D; }
  ._2VQ3YY3ReJuJt-VCYqQF78 .activity-list {
    list-style: none;
    padding: 0; }
    ._2VQ3YY3ReJuJt-VCYqQF78 .activity-list .item {
      margin: 10px 0;
      display: flex;
      color: #323F4A;
      letter-spacing: 0.3px;
      font-size: 13px; }
      ._2VQ3YY3ReJuJt-VCYqQF78 .activity-list .item .avatar {
        min-width: 32px;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 1px solid #e0e0e0;
        margin-right: 16px; }
      ._2VQ3YY3ReJuJt-VCYqQF78 .activity-list .item .avatar-placeholder {
        min-width: 32px;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        margin-right: 16px; }
      ._2VQ3YY3ReJuJt-VCYqQF78 .activity-list .item .bold {
        font-family: 'Gilroy-Bold';
        text-transform: capitalize; }
      ._2VQ3YY3ReJuJt-VCYqQF78 .activity-list .item .date {
        color: #97A0A7;
        font-size: 12px; }
  ._2VQ3YY3ReJuJt-VCYqQF78 .show-all {
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    color: #264382;
    cursor: pointer; }

._1_O_uKSUHWMtPYKmoeLUX3 {
  padding-top: 30px; }
  ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item {
    margin: 0 -59px;
    padding: 0 59px; }
    ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item:hover .delete {
      display: block; }
    ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .delete {
      float: right;
      margin: -10px -40px 0 0;
      display: none;
      cursor: pointer; }
    ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .name {
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #383E48;
      padding-bottom: 10px; }
    ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content {
      margin: 10px 0;
      display: flex; }
      ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content > div, ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .days, ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .weeks {
        width: 50%; }
        ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content > div img, ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .days img, ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .weeks img {
          max-width: 100%; }
      ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .days, ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .weeks {
        display: inline-block;
        width: 50%;
        font-family: 'Gilroy-Bold';
        font-size: 30px; }
        ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .days span, ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .weeks span {
          font-family: Gilroy;
          font-size: 12px;
          color: #70808E;
          display: block;
          white-space: normal; }
        ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .days .change, ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .weeks .change {
          display: inline-block;
          margin-left: 5px; }
        ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .days .green, ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .weeks .green {
          color: #7BAD2D; }
        ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .days .red, ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .weeks .red {
          color: #FF6B00; }
      ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .days {
        white-space: nowrap;
        color: #383E48; }
      ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .weeks {
        color: #B7BFC6; }
      ._1_O_uKSUHWMtPYKmoeLUX3 .goal-item .data-content .unit {
        display: inline-block !important;
        font-size: 12px;
        color: #70808E; }

._1oTQ7ZjhZTpFxqV7tf4Rq1 {
  margin: 5px 24px;
  display: flex;
  align-items: stretch;
  flex-direction: column; }
  ._1oTQ7ZjhZTpFxqV7tf4Rq1 .zone-background {
    background: #EAEFF7;
    border-radius: 6px;
    overflow: hidden;
    height: 36px;
    display: flex;
    position: relative; }
    ._1oTQ7ZjhZTpFxqV7tf4Rq1 .zone-background .percentage {
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #383E48;
      position: absolute;
      top: 34%; }
    ._1oTQ7ZjhZTpFxqV7tf4Rq1 .zone-background .hit-ideal-line {
      background-color: #383E48;
      width: 2px;
      height: 36px;
      position: absolute; }
    ._1oTQ7ZjhZTpFxqV7tf4Rq1 .zone-background .left-border {
      border-radius: 6px 0px 0px 6px; }
    ._1oTQ7ZjhZTpFxqV7tf4Rq1 .zone-background .right-border {
      border-radius: 0px 6px 6px 0px; }
  ._1oTQ7ZjhZTpFxqV7tf4Rq1 .zone-target {
    margin-top: 2px;
    position: relative;
    height: 20px; }
    ._1oTQ7ZjhZTpFxqV7tf4Rq1 .zone-target .zone-target-label {
      position: absolute;
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 8px;
      line-height: 140%;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #70808E; }

.ivmA1qyIFny-0XySZLfY2 .zone-breakdown-container {
  margin-bottom: 16px;
  display: flex; }
  .ivmA1qyIFny-0XySZLfY2 .zone-breakdown-container .zone-background {
    background: #EAEFF7;
    border-radius: 6px;
    height: 32px; }
    .ivmA1qyIFny-0XySZLfY2 .zone-breakdown-container .zone-background .zone {
      border-radius: 6px;
      height: 32px; }
    .ivmA1qyIFny-0XySZLfY2 .zone-breakdown-container .zone-background .zone-minimum {
      display: inline;
      height: 32px;
      width: 2px;
      float: left; }
  .ivmA1qyIFny-0XySZLfY2 .zone-breakdown-container .zone-data-container {
    margin-left: 16px; }
    .ivmA1qyIFny-0XySZLfY2 .zone-breakdown-container .zone-data-container .zone-data svg {
      vertical-align: bottom; }
    .ivmA1qyIFny-0XySZLfY2 .zone-breakdown-container .zone-data-container .zone-desc svg {
      vertical-align: top; }

.ivmA1qyIFny-0XySZLfY2 .zone-chart-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 16px;
  border-radius: 8px;
  background: rgba(38, 67, 130, 0.05); }
  .ivmA1qyIFny-0XySZLfY2 .zone-chart-label svg {
    vertical-align: middle; }
  .ivmA1qyIFny-0XySZLfY2 .zone-chart-label .tip {
    color: #70808E;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: bold;
    font-size: 9px; }
    .ivmA1qyIFny-0XySZLfY2 .zone-chart-label .tip span:first-child, .ivmA1qyIFny-0XySZLfY2 .zone-chart-label .tip i:first-child {
      margin-right: 8px;
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box; }
    .ivmA1qyIFny-0XySZLfY2 .zone-chart-label .tip .rectangle {
      background: #EAEFF6;
      border: 2px solid #CFD7E2;
      box-sizing: border-box;
      border-radius: 4px;
      width: 14px;
      height: 14px; }
    .ivmA1qyIFny-0XySZLfY2 .zone-chart-label .tip .dot {
      background: black;
      width: 2px;
      height: 2px;
      display: inline-block;
      margin-right: 2px; }

.ivmA1qyIFny-0XySZLfY2 .list {
  width: 251px; }
  .ivmA1qyIFny-0XySZLfY2 .list > div {
    padding-left: 25px; }
    .ivmA1qyIFny-0XySZLfY2 .list > div:first-child {
      border-bottom: 1px solid #E4EAEF; }
    .ivmA1qyIFny-0XySZLfY2 .list > div span.icon {
      margin-left: -26px;
      margin-top: 8px;
      float: left;
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 4px; }
    .ivmA1qyIFny-0XySZLfY2 .list > div .Moderate {
      background: #F7AD69; }
    .ivmA1qyIFny-0XySZLfY2 .list > div .Intense {
      background: #FC7558; }
    .ivmA1qyIFny-0XySZLfY2 .list > div div {
      padding: 7px 0;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      border-bottom: 1px solid #E4EAEF; }
      .ivmA1qyIFny-0XySZLfY2 .list > div div span:first-child {
        text-transform: uppercase; }
      .ivmA1qyIFny-0XySZLfY2 .list > div div:last-child {
        border: none; }
    .ivmA1qyIFny-0XySZLfY2 .list > div .title {
      font-family: "Gilroy-Bold";
      letter-spacing: 1px;
      color: #383E48; }
    .ivmA1qyIFny-0XySZLfY2 .list > div .detail-item {
      letter-spacing: 0.5px;
      color: #70808E; }

.ivmA1qyIFny-0XySZLfY2 .pie {
  position: relative;
  margin-left: 60px;
  width: 200px; }
  .ivmA1qyIFny-0XySZLfY2 .pie img {
    width: 166px; }
  .ivmA1qyIFny-0XySZLfY2 .pie .pie-percentage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 24px;
    font-family: 'Gilroy-Bold';
    color: #383E48;
    text-align: center;
    line-height: 200px;
    letter-spacing: 0.3px; }

.hTbWOdEjdRWHBHv3CTsb0 {
  background: rgba(38, 67, 130, 0.03);
  border-radius: 8px;
  padding: 0 24px 1px 24px;
  border: 1px solid #E4EAEF; }

._3kCuOFa0eP-PjQ-XV2kaPE .title {
  text-align: left;
  font-size: 24px; }

._3kCuOFa0eP-PjQ-XV2kaPE .ant-modal-footer {
  border: none;
  padding: 0 24px;
  padding-bottom: 24px; }

._2Q5MR-Sj1jWE9zZ2-jWW_W {
  padding-bottom: 150px;
  font-family: Gilroy; }
  ._2Q5MR-Sj1jWE9zZ2-jWW_W .email-content {
    display: flex;
    justify-content: space-between; }
  ._2Q5MR-Sj1jWE9zZ2-jWW_W img.delete-icon {
    width: 32px !important; }
  ._2Q5MR-Sj1jWE9zZ2-jWW_W img.big-chart {
    width: 100% !important; }
  ._2Q5MR-Sj1jWE9zZ2-jWW_W .switch-date-range {
    float: right;
    margin-top: -75px; }
  ._2Q5MR-Sj1jWE9zZ2-jWW_W .extra-information {
    padding: 11px 16px;
    padding-bottom: 0;
    margin: 16px 0;
    border: 1px solid #E4EAEF;
    border-radius: 8px;
    font-size: 12px;
    line-height: 17px; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .extra-information h5 {
      font-size: 12px;
      font-family: Gilroy-Bold;
      color: #383E48; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .extra-information p {
      color: #70808E; }
  ._2Q5MR-Sj1jWE9zZ2-jWW_W .yes-no-chart {
    display: flex;
    justify-content: space-around;
    padding: 0 40px;
    padding-top: 20px; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .yes-no-chart .check-dot {
      text-align: center; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .yes-no-chart div {
      min-width: 0 !important; }
  ._2Q5MR-Sj1jWE9zZ2-jWW_W footer {
    margin-left: -38px;
    height: 88px;
    background: #FFFFFF;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 64px;
    position: fixed;
    width: calc(1440px - 250px - 64px);
    max-width: calc(100% - 250px - 64px); }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W footer span {
      font-size: 14px;
      color: #264382;
      letter-spacing: 0.3px;
      text-transform: uppercase; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W footer button {
      width: 186px;
      height: 48px;
      margin-left: 24px; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W footer button span {
        color: inherit; }
  ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container {
    margin-top: 16px;
    padding: 0 59px 0 32px;
    padding-bottom: 32px;
    width: 670px;
    background: #FFFFFF;
    border: 1px solid #DEE2EC;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container:first-child {
      margin-top: 0; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .sub-section {
      margin: 0 -28px;
      padding: 0 28px; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .sub-section .delete, ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .sub-section .deletable-part-opr {
        float: right;
        margin-right: -40px;
        display: none;
        cursor: pointer; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .sub-section:hover .delete {
        display: block; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .deletable-part {
      margin: 0 -40px;
      padding: 0 40px; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .deletable-part:hover .deletable-part-opr {
      display: block;
      margin-top: 25px; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .summary-date {
      font-family: Gilroy-Bold;
      font-size: 12px;
      color: #70808E;
      text-transform: uppercase;
      margin-top: 24px;
      margin-bottom: 10px; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container > h3 {
      margin: 0;
      margin-left: -32px;
      margin-right: -59px;
      padding: 18px 32px;
      font-size: 24px;
      font-family: Gilroy-Bold;
      color: #ffffff;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container > h3 svg {
        cursor: pointer;
        display: none; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container > h3:hover svg {
        display: block; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container > h3.Overview {
        color: #383E48;
        padding-bottom: 0;
        padding-top: 0; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container > h3.Rejuvenation {
        background: #5C00D1; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container > h3.Exercise {
        background: #FF6B00; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container > h3.Nutrition {
        background: #7BAD2D; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .bg-image {
      width: calc(100% + 32px + 59px);
      margin-left: -32px; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .table-header {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      color: #70808E;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 100%;
      vertical-align: top; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .table-header div {
        display: inline-block; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .table-header div .anticon {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5); }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .table-header .days-7, ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .table-header .week-4 {
        text-align: right;
        width: 100px; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .table-header .days-7 {
        margin-right: -40px; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .table-header .change {
        margin-left: 30px;
        margin-right: 35px; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .table-header .calendar {
        margin-right: -31px;
        display: flex;
        width: 140px;
        margin-right: 45px;
        justify-content: space-between;
        text-align: center; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section {
      display: flex;
      margin-bottom: 15px; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .yes-no-chart {
        padding-top: 5px; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .left-title {
        width: 32px;
        text-transform: uppercase;
        border-radius: 8px 0px 0px 8px;
        display: flex;
        align-items: center; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .left-title.Rejuvenation {
          color: #5C00D1;
          background: #EFE6FA; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .left-title.Exercise {
          color: #FF6B00;
          background: #FFF0E6; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .left-title.Nutrition {
          color: #7BAD2D;
          background: #F2F7EA; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .left-title.Weight {
          color: #3194EB;
          background: #E2F2FF; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row {
        position: relative;
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(145, 159, 171, 0.1);
        margin-left: 16px;
        text-align: center;
        font-size: 12px;
        color: #383E48;
        letter-spacing: 0.5px;
        font-weight: bold;
        font-family: 'Gilroy-Bold';
        min-height: 72px; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row:last-child {
          border-bottom: 1px solid rgba(145, 159, 171, 0.1); }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row:hover .delete {
          display: block; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row .chart {
          width: 225px;
          text-align: right; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row .name {
          width: 115px;
          text-align: left;
          letter-spacing: 1px;
          font-weight: 800; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row .name span, ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row .name b {
            display: inline-block;
            width: 100px; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row .gray {
          color: #B7BFC6; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row .green {
          color: #7BAD2D; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row .red {
          color: #FF6B00; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row .normal {
          color: #70808E; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row .black {
          color: #383E48; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row div {
          min-width: 65px; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row img {
          margin-right: 20px;
          width: 150px;
          height: 50px; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .overview-section .right-content .row .delete {
          width: 0;
          position: absolute;
          right: -50px;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          display: none;
          cursor: pointer; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .zone-list {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      padding-right: 10px; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .zone-list li {
        min-width: 42px;
        color: #8596A4;
        font-size: 12px; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .zone-list li:before {
        content: '';
        width: 6px;
        height: 6px;
        display: inline-block;
        border-radius: 6px;
        background: #c1c1c1;
        margin-right: 4px;
        vertical-align: middle; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .zone-list .z-5:before {
        background: #FF501A; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .zone-list .z-4:before {
        background: #FC7558; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .zone-list .z-3:before {
        background: #FB9043; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .zone-list .z-2:before {
        background: #F7AD69; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container h4.sub-title {
      margin-top: 27px;
      margin-bottom: 16px;
      font-family: "Gilroy-Bold";
      font-weight: 800;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #383E48; }
    ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart {
      margin: 20px 0;
      overflow: hidden; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-container {
        margin-top: -45px;
        display: flex;
        position: relative; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-container .label {
          position: absolute;
          top: 25%;
          color: #70808E;
          font-size: 12px;
          text-transform: uppercase;
          align-items: center;
          display: flex; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-container .label span {
            display: block;
            text-align: center;
            white-space: nowrap; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-container .left-axis {
          left: 0; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-container .left-axis span {
            -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
            height: 43px; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-container .right-axis {
          right: 0; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-container .right-axis span {
            -webkit-transform: rotate(90deg);
                    transform: rotate(90deg);
            margin-left: -30px; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels {
        padding: 0 40px; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-type, ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-name {
          display: flex;
          justify-content: space-between;
          align-items: center; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .name {
          display: flex;
          align-items: center; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-type {
          border-top: 2px solid #E4EAEF;
          padding-top: 16px;
          padding-bottom: 5px;
          font-family: 'Gilroy-bold'; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-type .name > span {
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 4px;
            margin-right: 8px;
            vertical-align: middle; }
            ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-type .name > span.Resistance {
              background: #58646B; }
            ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-type .name > span.Aerobic {
              background: #FF6B00; }
            ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-type .name > span.Activity {
              background: #BCCA70; }
            ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-type .name > span.Movement {
              background: #C29E7C; }
            ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-type .name > span.non-program {
              background: #C4C4C4; }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-name {
          padding-left: 22px;
          padding-bottom: 5px; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-name:last-child {
            padding-bottom: 15px; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .workout-labels .activity-name .name:before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-left: -14px; }
      ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 10px 16px;
        border-radius: 8px;
        background: rgba(38, 67, 130, 0.05); }
        ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip {
          color: #70808E;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          font-weight: bold;
          font-size: 12px; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip svg {
            vertical-align: middle; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip span:first-child, ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip i:first-child {
            margin-right: 8px;
            display: inline-block;
            vertical-align: middle;
            box-sizing: border-box; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip span {
            margin-top: -2px; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip .circle {
            border-radius: 25px;
            border: 2px solid #479FEC;
            width: 14px;
            height: 14px; }
          ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip .rect {
            border-radius: 4px;
            width: 14px;
            height: 14px; }
            ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip .rect.sleep-duration {
              background: #5C00D1; }
            ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip .rect.aerobic {
              background: #F3B555; }
            ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip .rect.resistance {
              background: #58646B; }
            ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip .rect.flexibility {
              background: #C9D48A; }
            ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip .rect.percentages {
              background: #FF6B00; }
            ._2Q5MR-Sj1jWE9zZ2-jWW_W .section-container .big-chart .chart-label .tip .rect.cummulative {
              background: #479FEC; }

._12g02Wk5x8JGzz2lMUsIX_ {
  font-family: Gilroy; }
  ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-thead {
    text-transform: uppercase;
    color: #5C626A;
    opacity: 0.8;
    font-size: 12px; }
    ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-thead > tr > th {
      background: transparent;
      border: none; }
      ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-thead > tr > th:last-child {
        text-align: right; }
  ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody {
    font-size: 14px; }
    ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody > tr > td {
      background: #FFFFFF;
      border-color: #F4F6F8;
      border-width: 2px; }
      ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody > tr > td:first-child {
        border-radius: 8px 0 0 8px; }
      ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody > tr > td:last-child {
        border-radius: 0 8px 8px 0; }
    ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody .bold {
      font-family: 'Gilroy-Bold'; }
    ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody .status-circle {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin-right: 8px; }
      ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody .status-circle.Draft {
        background: #F7C137; }
      ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody .status-circle.Opened {
        background: #7BAD2D; }
      ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody .status-circle.Sent {
        background: #7BAD2D; }
    ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody .actions {
      display: flex;
      justify-content: space-around; }
      ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody .actions.User {
        justify-content: flex-end; }
      ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody .actions .anticon, ._12g02Wk5x8JGzz2lMUsIX_ .ant-table-tbody .actions img {
        cursor: pointer;
        color: #264382;
        font-size: 32px; }

.date-text {
    color: '#4c6072';
    font-size: 16px;
}

.tooltip-container {
    display: flex;
    justify-content: space-between;
    /* width: 200px; */
}

.tooltip {
    position: absolute;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    font-size: 18px;
    z-index: 10;
    width: 20rem;
}

.tooltip.red {
    color: red;
}

.tooltip.green {
    color: green;
}


._3kR3Am8nCmmO71XGWgdtqy {
  padding: 46px 0;
  border-bottom: 1px solid #F4F6F8;
  text-align: center; }
  ._3kR3Am8nCmmO71XGWgdtqy h1 {
    color: #323F4A;
    font-size: 40px;
    font-family: 'Gilroy-bold'; }
  ._3kR3Am8nCmmO71XGWgdtqy .content {
    display: flex;
    justify-content: center; }
  ._3kR3Am8nCmmO71XGWgdtqy .info-list {
    width: 257px;
    padding: 0 35px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.3px; }
    ._3kR3Am8nCmmO71XGWgdtqy .info-list:first-child {
      border-right: 1px solid rgba(35, 48, 100, 0.1); }
  ._3kR3Am8nCmmO71XGWgdtqy .info-item, ._3kR3Am8nCmmO71XGWgdtqy .info-value {
    color: #94A0AA; }
    ._3kR3Am8nCmmO71XGWgdtqy .info-item div, ._3kR3Am8nCmmO71XGWgdtqy .info-value div {
      margin: 10px 0;
      text-align: left; }
  ._3kR3Am8nCmmO71XGWgdtqy .info-value {
    color: #4C6072; }
    ._3kR3Am8nCmmO71XGWgdtqy .info-value div::first-letter {
      text-transform: capitalize; }

.zCD1VJchfFRfGBAZD4sls {
  letter-spacing: 0.006em;
  color: #4C6072 !important; }

.ROEVP10bUCxKjsm2oT9sJ {
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  margin-bottom: 88px; }
  .ROEVP10bUCxKjsm2oT9sJ button {
    height: 48px;
    padding: 0 32px; }
  .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container {
    padding: 40px; }
    .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container.mobile > div:first-child {
      margin-bottom: 30px;
      padding-bottom: 30px; }
    .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container .edit-botton {
      position: absolute;
      margin-top: -100px;
      right: 0; }
      .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container .edit-botton .ant-btn {
        height: 40px;
        min-width: 160px; }
        .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container .edit-botton .ant-btn:first-child {
          margin-right: 24px; }
    .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container .compare-labels {
      text-align: center;
      margin-bottom: 40px;
      margin-top: -40px;
      text-transform: uppercase; }
      .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container .compare-labels div {
        color: #5A657C;
        margin: 0 8px;
        padding: 5px 20px;
        min-width: 113px;
        display: inline-block;
        border-radius: 19px;
        border: 1px solid rgba(33, 57, 89, 0.2);
        font-size: 14px;
        line-height: 130%;
        letter-spacing: 0.5px; }
        .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container .compare-labels div svg {
          margin-right: 6px; }
      .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container .compare-labels .current:before {
        background: #4C6072; }
      .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container .compare-labels .compare:before {
        background: #9CA1B4; }
      .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container .compare-labels.mobile {
        margin-top: 10px; }
        .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container .compare-labels.mobile div {
          font-size: 12px;
          -webkit-transform: scale(0.7);
                  transform: scale(0.7);
          margin: 5px; }
    .ROEVP10bUCxKjsm2oT9sJ .health-scroe-container .spider-graph-container svg {
      height: 0; }
  .ROEVP10bUCxKjsm2oT9sJ > footer {
    margin-left: -38px;
    height: 88px;
    background: #FFFFFF;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 64px;
    position: fixed;
    width: calc(1440px - 250px - 64px);
    max-width: calc(100% - 250px - 64px); }
    .ROEVP10bUCxKjsm2oT9sJ > footer button {
      min-width: 140px;
      text-transform: uppercase; }
      .ROEVP10bUCxKjsm2oT9sJ > footer button:last-child:nth-child(2) {
        margin-left: 16px; }

.ZpoqgJP4_n0YutvJB2ApV {
  background: #ffffff;
  margin-top: 24px;
  overflow: hidden;
  border: 1px solid #E3E6EA;
  border-radius: 4px;
  display: flex; }
  .ZpoqgJP4_n0YutvJB2ApV:first-child {
    margin-top: 0; }
  .ZpoqgJP4_n0YutvJB2ApV .score-left {
    padding-top: 5px;
    background: #FAFAFA;
    width: 33.5%;
    border-right: 1px solid #E3E6EA; }
    .ZpoqgJP4_n0YutvJB2ApV .score-left .title {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      margin: 0 24px;
      padding: 16px 0;
      border-bottom: 1px solid #E3E6EA;
      text-transform: capitalize; }
      .ZpoqgJP4_n0YutvJB2ApV .score-left .title .value {
        font-family: 'Gilroy-Bold'; }
  .ZpoqgJP4_n0YutvJB2ApV .score-right {
    padding: 24px;
    width: 66.5%;
    font-size: 12px; }
    .ZpoqgJP4_n0YutvJB2ApV .score-right .item-list {
      margin: 0 -24px;
      padding-top: 18px;
      display: flex;
      flex-wrap: wrap; }
      .ZpoqgJP4_n0YutvJB2ApV .score-right .item-list .item {
        display: flex;
        width: 50%;
        justify-content: space-between;
        padding: 0 24px;
        line-height: 29px; }
        .ZpoqgJP4_n0YutvJB2ApV .score-right .item-list .item:hover {
          color: #2B4B8F; }
        .ZpoqgJP4_n0YutvJB2ApV .score-right .item-list .item:nth-child(2n+1) {
          border-right: 1px solid rgba(135, 148, 161, 0.2); }
        .ZpoqgJP4_n0YutvJB2ApV .score-right .item-list .item .name {
          cursor: pointer; }
          .ZpoqgJP4_n0YutvJB2ApV .score-right .item-list .item .name img {
            width: 16px;
            margin-right: 8px; }
      .ZpoqgJP4_n0YutvJB2ApV .score-right .item-list .status {
        text-transform: uppercase;
        min-width: 48px;
        font-family: 'Gilroy-Bold';
        text-align: right; }
        .ZpoqgJP4_n0YutvJB2ApV .score-right .item-list .status.score-1 {
          color: #FF6B00; }
        .ZpoqgJP4_n0YutvJB2ApV .score-right .item-list .status.score-2 {
          color: #F5BA40; }
        .ZpoqgJP4_n0YutvJB2ApV .score-right .item-list .status.score-3 {
          color: #40C47C; }
    .ZpoqgJP4_n0YutvJB2ApV .score-right .more-opr {
      color: #2B4B8F;
      line-height: 12px;
      margin-top: 23px;
      font-family: 'Gilroy-Bold';
      cursor: pointer; }
  .ZpoqgJP4_n0YutvJB2ApV .score-right :first-child.item-list {
    padding-bottom: 12px;
    padding-top: 0px; }
  .ZpoqgJP4_n0YutvJB2ApV .pipe-container {
    padding-left: 10px;
    padding-top: 10px; }
    .ZpoqgJP4_n0YutvJB2ApV .pipe-container .pipe {
      width: 110px;
      display: inline-block;
      vertical-align: top; }
    .ZpoqgJP4_n0YutvJB2ApV .pipe-container .pipe-list {
      width: calc(100% - 110px);
      display: inline-block;
      list-style: none;
      font-size: 12px;
      padding-left: 10px;
      padding-top: 15px;
      padding-right: 24px; }
      .ZpoqgJP4_n0YutvJB2ApV .pipe-container .pipe-list li {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #4C6072;
        margin-bottom: 10px;
        letter-spacing: 0.3px;
        font-family: 'Gilroy-Bold'; }
        .ZpoqgJP4_n0YutvJB2ApV .pipe-container .pipe-list li .label-name {
          color: #A5AFB8;
          letter-spacing: 0.7px; }
          .ZpoqgJP4_n0YutvJB2ApV .pipe-container .pipe-list li .label-name span {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 8px;
            border-radius: 2px; }

.JTK6sIu2itCj14uCE0_s2 {
  font-family: Gilroy; }
  .JTK6sIu2itCj14uCE0_s2 .ant-modal-body {
    padding: 40px; }
  .JTK6sIu2itCj14uCE0_s2 .optimal, .JTK6sIu2itCj14uCE0_s2 .concern, .JTK6sIu2itCj14uCE0_s2 .at-risk, .JTK6sIu2itCj14uCE0_s2 .risk {
    font-size: 10px;
    letter-spacing: 0.3px;
    line-height: 150%;
    text-transform: uppercase;
    font-family: 'Gilroy-Bold'; }
  .JTK6sIu2itCj14uCE0_s2 .optimal {
    color: #40C47C;
    border-color: #40C47C; }
  .JTK6sIu2itCj14uCE0_s2 .concern {
    color: #F5BA40;
    border-color: #F5BA40; }
  .JTK6sIu2itCj14uCE0_s2 .at-risk, .JTK6sIu2itCj14uCE0_s2 .risk {
    color: #FF6B00;
    border-color: #FF6B00; }
  .JTK6sIu2itCj14uCE0_s2 .title {
    font-size: 24px;
    line-height: 29px;
    color: #4C6072;
    margin-bottom: 0; }
  .JTK6sIu2itCj14uCE0_s2 .status-group {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    margin-bottom: 0; }
    .JTK6sIu2itCj14uCE0_s2 .status-group li:before {
      content: '';
      display: inline-block;
      width: 9px;
      height: 9px;
      border: 2px solid;
      border-radius: 9px;
      margin-left: 17px;
      margin-right: 5px; }
  .JTK6sIu2itCj14uCE0_s2 .chart-container {
    margin: 0 -20px;
    position: relative;
    padding-left: 20px; }
    .JTK6sIu2itCj14uCE0_s2 .chart-container .chart-label {
      position: absolute;
      font-family: 'Gilroy-Bold';
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #70808E;
      height: 13px;
      left: 25px;
      top: 50%;
      margin-top: -20px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      .JTK6sIu2itCj14uCE0_s2 .chart-container .chart-label .label-text {
        -webkit-transform: translateX(-50%) rotate(-90deg) scale(0.72);
                transform: translateX(-50%) rotate(-90deg) scale(0.72); }
  .JTK6sIu2itCj14uCE0_s2 .choice-list {
    list-style: none;
    margin-bottom: 16;
    padding: 20px 28px;
    border: 1px solid #E2E5E9;
    border-radius: 8px;
    font-size: 13px;
    color: #70808E;
    line-height: 19px; }
    .JTK6sIu2itCj14uCE0_s2 .choice-list li {
      display: flex;
      margin-bottom: 12px; }
      .JTK6sIu2itCj14uCE0_s2 .choice-list li:last-child {
        margin-bottom: 0; }
      .JTK6sIu2itCj14uCE0_s2 .choice-list li > div:last-child {
        width: calc(100% - 40px); }
      .JTK6sIu2itCj14uCE0_s2 .choice-list li .score {
        width: 24px;
        height: 24px;
        border: 2px solid;
        line-height: 20px;
        text-align: center;
        border-radius: 24px;
        margin-right: 19px; }
      .JTK6sIu2itCj14uCE0_s2 .choice-list li .score-1 {
        border-color: #FF6B00; }
      .JTK6sIu2itCj14uCE0_s2 .choice-list li .score-2 {
        border-color: #F5BA40; }
      .JTK6sIu2itCj14uCE0_s2 .choice-list li .score-3 {
        border-color: #40C47C; }
  .JTK6sIu2itCj14uCE0_s2 .generalChart {
    padding: 0px; }
    .JTK6sIu2itCj14uCE0_s2 .generalChart .header {
      display: block; }
      .JTK6sIu2itCj14uCE0_s2 .generalChart .header .name {
        font-size: 24px;
        display: flex; }
        .JTK6sIu2itCj14uCE0_s2 .generalChart .header .name > span {
          line-height: 19px;
          margin-left: 8px; }
  .JTK6sIu2itCj14uCE0_s2 .info-container {
    display: flex; }
    .JTK6sIu2itCj14uCE0_s2 .info-container .info-list {
      background: rgba(38, 67, 130, 0.05);
      border-radius: 8px;
      width: 100%;
      list-style: none;
      padding: 24px;
      margin: 0; }
      .JTK6sIu2itCj14uCE0_s2 .info-container .info-list.has-icon {
        width: calc(100% - 284px); }
      .JTK6sIu2itCj14uCE0_s2 .info-container .info-list li {
        display: flex;
        color: #70808E;
        font-size: 13px;
        line-height: 19px;
        margin-bottom: 15px; }
        .JTK6sIu2itCj14uCE0_s2 .info-container .info-list li:last-child {
          margin-bottom: 0; }
        .JTK6sIu2itCj14uCE0_s2 .info-container .info-list li img {
          margin-right: 15px; }
        .JTK6sIu2itCj14uCE0_s2 .info-container .info-list li div:last-child {
          align-items: center;
          display: flex;
          flex-wrap: wrap; }
        .JTK6sIu2itCj14uCE0_s2 .info-container .info-list li a {
          color: #2B4B8F;
          text-decoration: underline;
          font-family: 'Gilroy-Bold'; }
    .JTK6sIu2itCj14uCE0_s2 .info-container .icons {
      width: 260px;
      border-radius: 8px;
      border: 1px solid #F4F6F9;
      margin-left: 24px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #4C6072;
      font-size: 13px; }
      .JTK6sIu2itCj14uCE0_s2 .info-container .icons > div {
        display: flex;
        padding: 0;
        width: 100%;
        align-items: center;
        justify-content: space-around; }
      .JTK6sIu2itCj14uCE0_s2 .info-container .icons img {
        margin-bottom: 12px; }
      .JTK6sIu2itCj14uCE0_s2 .info-container .icons div {
        min-width: 50%;
        text-align: center;
        padding: 5px 3px; }
  .JTK6sIu2itCj14uCE0_s2.mobile {
    background: white;
    padding-bottom: 40px; }
    .JTK6sIu2itCj14uCE0_s2.mobile .title {
      font-size: 17px;
      text-align: center;
      padding-top: 23px; }
      .JTK6sIu2itCj14uCE0_s2.mobile .title .anticon {
        float: left;
        margin-left: 20px;
        margin-top: 5px; }
      .JTK6sIu2itCj14uCE0_s2.mobile .title + div {
        text-align: center;
        padding-bottom: 13px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .JTK6sIu2itCj14uCE0_s2.mobile .chart-container {
      margin: 0; }
    .JTK6sIu2itCj14uCE0_s2.mobile .status-group {
      padding-right: 20px; }
    .JTK6sIu2itCj14uCE0_s2.mobile .choice-list {
      margin: 0 16px;
      text-align: left;
      padding: 20px; }
      .JTK6sIu2itCj14uCE0_s2.mobile .choice-list li .score {
        margin-right: 16px; }
    .JTK6sIu2itCj14uCE0_s2.mobile .info-container {
      flex-wrap: wrap; }
      .JTK6sIu2itCj14uCE0_s2.mobile .info-container .icons, .JTK6sIu2itCj14uCE0_s2.mobile .info-container .info-list {
        width: 100%;
        margin: 0 16px; }
      .JTK6sIu2itCj14uCE0_s2.mobile .info-container .icons {
        order: 1; }
      .JTK6sIu2itCj14uCE0_s2.mobile .info-container .info-list {
        order: 2;
        margin-top: 16px;
        text-align: left;
        padding: 20px; }

._3z1syxq18uVAcgIQwYitrb .send-btn {
  height: 40px;
  text-transform: uppercase;
  width: 191px;
  font-size: 13px;
  float: right;
  margin-top: -55px; }

._3z1syxq18uVAcgIQwYitrb .description {
  width: 780px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.3px;
  color: #52606C;
  margin-top: -20px; }

._3z1syxq18uVAcgIQwYitrb .nutrition-score-container {
  margin-top: 22px;
  background: #ffffff;
  border-radius: 12px 12px 0px 0px;
  overflow: hidden; }

._3z1syxq18uVAcgIQwYitrb .section-container {
  background: #ffffff;
  padding: 40px;
  padding-top: 24px;
  border-radius: 0px 0px 12px 12px; }

._3z1syxq18uVAcgIQwYitrb .information-editor .mark-down-support, ._3z1syxq18uVAcgIQwYitrb .information-editor .editor-footer {
  padding: 14px 20px;
  background: transparent;
  border: none;
  border-top: 1px solid #E8ECF0; }
  ._3z1syxq18uVAcgIQwYitrb .information-editor .mark-down-support .buttons button, ._3z1syxq18uVAcgIQwYitrb .information-editor .editor-footer .buttons button {
    height: auto;
    line-height: 25px; }

._3z1syxq18uVAcgIQwYitrb .information-editor .mark-down-support {
  float: left; }

._3z1syxq18uVAcgIQwYitrb img[alt="empty"] {
  width: 300px; }

.range-area-wrap {
    position: relative;
}

.ranges-container-wrap {
    position: relative;
    height: 40px;
    margin-top: 30px;
}

.progress-container-wrap {
    position: relative;
}

.hexagon-wrap {
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    margin-top: 24px;
}

.range-info-wrap {
    margin-top: 120px;
}

.grouped-container {
    margin-top: 20px;
}

.section-name {
    font-size: 24px;
    letter-spacing: 0.3px;
    color: rgba(82, 96, 108, 1);
}

.display-name {
    font-size: 20px;
    letter-spacing: 0.3px;
    color: rgba(82, 96, 108, 1);
}

.section-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.display-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.metric-container {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
}

.tab-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.tab-scroll {
    height: 200px;
    overflow-y: scroll;
    padding: 10px;
}

.tab-background {
    background: white;
}

.bar-wrap {
    flex: 2 1;
    padding: 50px 20px 0px 20px;
    border: 0.1px solid #e8e8e8;
    border-radius: 4px;
}

.tab-div {
    flex: 2 1;
    border: 0.1px solid #e8e8e8;
    padding: 10px;
    border-radius: 4px;
}

.action-btns {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-left: 20px;
}
.section-right img {
    height: 200px;
    width: 300px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.section-left {
    padding: 40px;
    max-width: calc(100% - 308px);
    line-height: 24px;
}

.resource-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    background-color: whitesmoke;
    margin: 80px 0 70px;
}

.resource-title {
    font-size: 18px;
    font-weight: 500;
    color: rgb(38, 67, 130);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.resource-description {
    margin-top: 8px;
    font-size: 15px;
    font-weight: 500;
    color: rgb(112, 128, 142);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.resource-link-wrapper {

    margin-top: 8px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.resource-link {
    margin-left: 8px;
    max-width: calc(100% - 24px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.UH-YI-_w0HVIL09KOs0IQ {
  position: relative;
  height: 100%; }
  .UH-YI-_w0HVIL09KOs0IQ .center {
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center; }
    .UH-YI-_w0HVIL09KOs0IQ .center .center-item {
      margin-top: 44px; }
      .UH-YI-_w0HVIL09KOs0IQ .center .center-item img {
        width: 422px; }
    .UH-YI-_w0HVIL09KOs0IQ .center .title {
      font-size: 36px;
      letter-spacing: 0.3px;
      line-height: 40px;
      color: #A7B1BF; }
    .UH-YI-_w0HVIL09KOs0IQ .center .description {
      font-size: 14px;
      line-height: 24px;
      color: #373A3C;
      margin-top: 40px;
      opacity: 0.8; }
    .UH-YI-_w0HVIL09KOs0IQ .center .refresh {
      font-size: 16px;
      color: #2B4B8F;
      cursor: pointer;
      letter-spacing: 0.3px; }

.card_cardContainer__2yfA0 {
  margin-top: 24px;
  background: #fff;
  padding: 16px;
  border: 1px solid #DEE2EC;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px; }
  .card_cardContainer__2yfA0 .card_title__aJT0q {
    display: flex;
    justify-content: space-between; }

.card_inActive__2TmPM {
  box-shadow: 4px 4px 20px rgba(255, 0, 0, 0.3); }

._2-gzX8gBuRTov07xwZJ889 {
  font-family: Gilroy;
  font-weight: 500; }
  ._2-gzX8gBuRTov07xwZJ889 .ant-modal-header {
    border: none;
    padding: 40px 46px;
    padding-bottom: 0; }
  ._2-gzX8gBuRTov07xwZJ889 .ant-modal-body {
    padding: 15px 46px;
    padding-bottom: 0; }
  ._2-gzX8gBuRTov07xwZJ889 .ant-modal-footer {
    border: none;
    padding: 15px 46px;
    padding-bottom: 40px; }
    ._2-gzX8gBuRTov07xwZJ889 .ant-modal-footer .upload-footer {
      background: #ffffff; }
      ._2-gzX8gBuRTov07xwZJ889 .ant-modal-footer .upload-footer button {
        height: 48px;
        min-width: 115px; }
  ._2-gzX8gBuRTov07xwZJ889 .ant-modal-title {
    font-size: 32px;
    color: #4C6072; }
  ._2-gzX8gBuRTov07xwZJ889 .ant-upload {
    width: 100%;
    padding: 5px; }
  ._2-gzX8gBuRTov07xwZJ889 .upload-container {
    background: #F4F6F8;
    padding: 11px;
    width: 100%;
    min-height: 73px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(129, 144, 156, 0.2);
    border-radius: 8px;
    letter-spacing: 0.3px;
    font-size: 16px; }
    ._2-gzX8gBuRTov07xwZJ889 .upload-container.empty {
      border: 2px dashed #E2E2E2; }
    ._2-gzX8gBuRTov07xwZJ889 .upload-container.has-error {
      border: 1px solid #f5222d; }
    ._2-gzX8gBuRTov07xwZJ889 .upload-container .opr {
      color: #264382;
      cursor: pointer; }
    ._2-gzX8gBuRTov07xwZJ889 .upload-container .select {
      text-align: center;
      width: 100%; }
    ._2-gzX8gBuRTov07xwZJ889 .upload-container .name {
      width: 80%;
      display: flex;
      color: #4C6072;
      font-size: 14px; }
      ._2-gzX8gBuRTov07xwZJ889 .upload-container .name .speed {
        color: #7FB800;
        margin-left: 20px;
        text-transform: lowercase; }
      ._2-gzX8gBuRTov07xwZJ889 .upload-container .name img {
        margin-right: 16px; }
      ._2-gzX8gBuRTov07xwZJ889 .upload-container .name > div {
        max-width: calc(100% - 45px); }
      ._2-gzX8gBuRTov07xwZJ889 .upload-container .name .ant-typography {
        margin: 0;
        font-size: 16px;
        font-family: Heebo;
        font-weight: 500;
        font-size: 16px; }
  ._2-gzX8gBuRTov07xwZJ889 .file-required {
    color: #f5222d;
    font-size: 14px; }
  ._2-gzX8gBuRTov07xwZJ889 .ant-progress-inner {
    margin-top: -30px; }
    ._2-gzX8gBuRTov07xwZJ889 .ant-progress-inner .ant-progress-bg {
      height: 4px !important; }

._2W2SIOo_1-roRpSYcTTIEG {
  margin: 40px 0;
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  letter-spacing: 0.3px; }
  ._2W2SIOo_1-roRpSYcTTIEG .ant-spin.ant-spin-spinning {
    text-align: center;
    padding: 20px;
    width: 100%; }
    ._2W2SIOo_1-roRpSYcTTIEG .ant-spin.ant-spin-spinning .ant-spin-dot i {
      background: #81909c; }
  ._2W2SIOo_1-roRpSYcTTIEG .title {
    font-size: 24px;
    line-height: 150%;
    color: #4C6072;
    display: flex;
    justify-content: space-between; }
  ._2W2SIOo_1-roRpSYcTTIEG .empty-container {
    padding: 40px 0;
    margin-top: 24px;
    background: #FFFFFF;
    border-radius: 8px; }
  ._2W2SIOo_1-roRpSYcTTIEG .file-container {
    margin: 12px 0;
    background: #FFFFFF;
    border-radius: 8px;
    color: #4C6072; }
    ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info {
      border-radius: 8px;
      padding: 20px;
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
      font-size: 14px; }
      ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info .uppercase {
        text-transform: uppercase; }
      ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info .name {
        width: 55%;
        display: flex; }
        ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info .name img {
          margin-right: 16px; }
        ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info .name > div {
          max-width: calc(100% - 45px); }
        ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info .name .ant-typography {
          margin: 0;
          font-size: 16px;
          font-family: Heebo;
          font-weight: 500;
          color: #4C6072; }
      ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info .left-border {
        border-left: 1px solid #E6E9EB;
        padding-left: 16px; }
        ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info .left-border span {
          font-size: 12px; }
      ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info .opr {
        width: 112px;
        display: flex;
        justify-content: flex-end; }
        ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info .opr a:last-child, ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info .opr img:last-child {
          margin-left: 16px; }
        ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-info .opr img {
          height: 40px;
          vertical-align: middle; }
    ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-title {
      font-size: 18px;
      padding-top: 10px; }
      ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-title:first-letter {
        text-transform: uppercase; }
    ._2W2SIOo_1-roRpSYcTTIEG .file-container .file-description {
      width: 500px;
      padding: 20px;
      padding-top: 10px;
      margin-left: 62px;
      font-size: 13px;
      font-family: Gilroy;
      white-space: normal;
      color: #52606C; }
  ._2W2SIOo_1-roRpSYcTTIEG .load-more {
    text-align: center;
    color: #264382;
    cursor: pointer; }

    .title {
        font-weight: 500;
        font-size: 36px;
        line-height: 120%;
        -webkit-letter-spacing: 0.3px;
        -moz-letter-spacing: 0.3px;
        -ms-letter-spacing: 0.3px;
        letter-spacing: 0.3px;
        color: #3c4e5f;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .transaction-container {
        display: flex;
        grid-gap: 20px;
        gap: 20px;
        margin-bottom: 30px;
    }

    .total-sessions-container {
        background: #FFFFFF;
        width: 226px;
        height: 120px;
        padding: 14px;
        text-align: left;
    }

    .sessions-remaining-container {
        background: #FFFFFF;
        width: 226px;
        height: 120px;
        padding: 14px 14px 22px 14px;
        text-align: left;
    }

    .session-header {
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;
        letter-spacing: 0.41313132643699646px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
                text-decoration-skip-ink: none;
    }

    .sessions-value {
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0.41313132643699646px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
                text-decoration-skip-ink: none;
        color: #264382;
    }

    .credit-filter {
        color: #009606 !important;
        border: 1px solid #009606 !important;

    }

    .debit-filter {
        color: #FF6161 !important;
        border: 1px solid #FF6161 !important;
    }

    .show-all-filter {
        color: #264382 !important;
        border: 1px solid #264382 !important;
    }

    .credit-value {
        background-color: #009606 !important;
        color: #FFFFFF !important;
    }

    .debit-value {
        background-color: #FF6161 !important;
        color: #FFFFFF !important;
    }

    .option {
        border-radius: 10px !important;
        margin-left: 10px !important;
    }

    .title-header-text {
        color: #a5abb2 !important;
    }

    .actions-container{
        display: flex;
        grid-gap: 10px;
        gap: 10px;
        margin: auto;
        justify-content: center;
    }

    .create-modal-title {
        font-family: 'Gilroy', sans-serif;
        font-weight: 600;
        letter-spacing: 0.366666704416275px;
        text-align: left;
        text-underline-position: from-font;
        -webkit-text-decoration-skip-ink: none;
                text-decoration-skip-ink: none;
        color: #4C4C4C;
    }

    .filter-panel {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        gap: 16px;
        margin-bottom: 20px;
    }

    .transaction-type {
        display: flex;
        grid-gap: 20px;
        gap: 20px;
        margin-bottom: 24px;
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
        grid-gap: 10px;
        gap: 10px;
    }

    .ant-modal-content .ant-modal-header,
    .ant-modal-content .ant-modal-footer {
        padding: 40px 20px 0px 20px !important;
        border: none;
    }

    .ant-form-item-label>label {
        font-family: Gilroy !important;
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 7.76px !important;
        color: #8D99A5 !important;
        letter-spacing: 0.8px !important;
        text-align: left !important;
    }

    .admin-buttons{
        display: flex;
        grid-gap: 16px;
        gap: 16px;
        align-items: center;
    }

    .ant-modal-content{
        padding-bottom: 30px !important;
    }
._2cx9oB3jZpAMScSXVDMueN {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px; }
  ._2cx9oB3jZpAMScSXVDMueN .list {
    list-style: none;
    padding: 0; }
  ._2cx9oB3jZpAMScSXVDMueN .list-item {
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
    font-size: 12px;
    opacity: 0.8; }
    ._2cx9oB3jZpAMScSXVDMueN .list-item .item-container {
      cursor: pointer;
      border-radius: 0 20.5px 20.5px 0;
      padding: 10px;
      padding-left: 25px; }
      ._2cx9oB3jZpAMScSXVDMueN .list-item .item-container span {
        font-size: 14px; }
    ._2cx9oB3jZpAMScSXVDMueN .list-item.active .item-container {
      background: rgba(135, 152, 173, 0.1); }
    ._2cx9oB3jZpAMScSXVDMueN .list-item a {
      color: #3F4752; }
    ._2cx9oB3jZpAMScSXVDMueN .list-item .sub-list-container {
      height: auto;
      font-weight: bold;
      line-height: 21px;
      font-size: 14px;
      transition: height 1s; }
      ._2cx9oB3jZpAMScSXVDMueN .list-item .sub-list-container .sub-list {
        list-style: initial;
        margin-left: 20px;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(134, 140, 148, 0.2); }
        ._2cx9oB3jZpAMScSXVDMueN .list-item .sub-list-container .sub-list .sub-list-item {
          margin: 15px 0;
          color: rgba(63, 71, 82, 0.6);
          cursor: pointer; }
          ._2cx9oB3jZpAMScSXVDMueN .list-item .sub-list-container .sub-list .sub-list-item.active {
            color: #4C6072; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-inline {
    width: calc(100% + 23px); }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu {
    background: transparent;
    border: none;
    color: rgba(76, 96, 114, 0.8);
    font-size: 14px;
    font-family: Gilroy;
    letter-spacing: 0.3px; }
    ._2cx9oB3jZpAMScSXVDMueN .ant-menu .ant-menu-submenu:last-child {
      padding-bottom: 75px; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu-selected {
    color: rgba(76, 96, 114, 0.8); }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu > .ant-menu {
    background: transparent;
    border: none; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu .ant-menu-submenu-title:hover {
    color: rgba(38, 67, 130, 0.8); }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item-active, ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item:hover {
    color: rgba(38, 67, 130, 0.8); }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: linear-gradient(to right, rgba(38, 67, 130, 0.8), rgba(38, 67, 130, 0.8)); }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu-open > .ant-menu-submenu-title {
    background: #E8ECF0;
    color: rgba(38, 67, 130, 0.8);
    border: none; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item {
    position: relative; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item::after {
    display: none; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-sub > .ant-menu-item:before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 100%;
    background: rgba(38, 67, 130, 0.8);
    margin-right: 13px;
    background: #6B7C8B;
    opacity: 0.3; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-sub > .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0 !important; }
    ._2cx9oB3jZpAMScSXVDMueN .ant-menu-sub > .ant-menu-item.ant-menu-item-selected {
      background: transparent; }
      ._2cx9oB3jZpAMScSXVDMueN .ant-menu-sub > .ant-menu-item.ant-menu-item-selected > a {
        color: #6A7EA9; }
      ._2cx9oB3jZpAMScSXVDMueN .ant-menu-sub > .ant-menu-item.ant-menu-item-selected:before {
        background: #6A7EA9;
        opacity: 1;
        width: 4px;
        margin-left: -1px; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item a, ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item span {
    color: rgba(76, 96, 114, 0.8); }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item.ant-badge {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item a:hover {
    color: rgba(38, 67, 130, 0.8); }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item a::before {
    content: none; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item span:hover {
    color: rgba(38, 67, 130, 0.8); }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item .ant-scroll-number-only-unit.current {
    color: #ffffff; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu .ant-menu-sub .ant-menu-title-content {
    margin-left: 13px; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-submenu-inline .ant-menu-submenu-arrow {
    right: 24px; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item.assessment {
    font-size: 14px;
    line-height: 150%;
    color: rgba(50, 63, 74, 0.8) !important;
    border-color: #E8ECF0 !important;
    font-weight: bold;
    border-top: 1px solid;
    height: 50px;
    padding-left: 22px !important; }
    ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item.assessment .ant-menu-title-content {
      padding-top: 27px; }
  ._2cx9oB3jZpAMScSXVDMueN .ant-menu-item.home {
    font-size: 14px;
    color: rgba(50, 63, 74, 0.8) !important;
    font-weight: bold;
    padding-left: 22px !important; }
  ._2cx9oB3jZpAMScSXVDMueN.User .ant-menu-inline, ._2cx9oB3jZpAMScSXVDMueN.Service_Provider .ant-menu-inline {
    width: calc(100% + 10px); }

._19T7Cn0CQvvJjrSVV-wTkG .draft-report header {
  height: 66px;
  padding: 20px 15px;
  font-size: 14px;
  background: #FAFCFD;
  border: 1px solid #EAEDF3;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  text-align: right;
  font-weight: bold; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report header .action-item {
    cursor: pointer;
    margin: 0 20px;
    color: #70808E;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 14px; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report header .close {
    font-size: 20px;
    color: #E5E8ED;
    cursor: pointer; }

._19T7Cn0CQvvJjrSVV-wTkG .draft-report .sub-header {
  padding: 10px 30px;
  color: #FAFCFD;
  text-transform: uppercase;
  letter-spacing: 0.366667px;
  font-size: 14px;
  font-weight: bold;
  line-height: 27px;
  background: #70808E; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .sub-header .anticon {
    margin-right: 5px; }

._19T7Cn0CQvvJjrSVV-wTkG .draft-report .report-banner {
  position: relative;
  padding: 55px 38px;
  background: #E5E8ED;
  color: #4C6072;
  height: 188px; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .report-banner.has-bg {
    color: #ffffff; }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .report-banner.has-bg h1 {
      color: #ffffff; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .report-banner .ant-progress {
    position: absolute;
    top: -8px;
    left: 0;
    width: 100%; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .report-banner h1 {
    font-size: 32px;
    line-height: 29px;
    font-weight: normal; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .report-banner p {
    font-size: 24px;
    line-height: 29px;
    opacity: 0.5;
    margin: 0; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .report-banner button {
    position: absolute;
    bottom: 19px;
    right: 26px;
    height: 48px;
    width: 200px;
    background: #FFFFFF;
    color: #75879D;
    text-transform: uppercase;
    letter-spacing: 0.366667px;
    border: none; }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .report-banner button .anticon {
      vertical-align: bottom; }

._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container {
  padding: 17px 0; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .left, ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .right {
    display: inline-block;
    vertical-align: top; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .left {
    width: 185px;
    margin-right: 30px; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .right {
    width: calc(100% - 185px - 55px); }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .right .section-title {
      text-transform: capitalize; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-borderless {
    background: transparent; }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-borderless .ant-collapse-item {
      background: #ffffff;
      padding-bottom: 15px; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-header {
    margin: 0 10px;
    margin-top: 15px;
    padding-left: 16px;
    text-transform: uppercase;
    font-size: 24px; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-header .ant-collapse-arrow {
    width: 40px;
    height: 40px;
    right: 6px;
    border-radius: 100%;
    top: 20px;
    left: auto;
    background: #F1F3F6;
    color: #75879D; }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-header .ant-collapse-arrow svg {
      margin-top: -2px; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-extra {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 3px;
    padding: 8px;
    border-radius: 100%;
    right: 75px;
    text-align: center;
    background: #F1F3F6;
    color: #75879D; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box {
    padding: 4px 26px 20px 26px; }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .ant-divider.full {
      margin: 24px -26px;
      width: calc(100% + 52px); }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box p, ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box pre, ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box textarea {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 27px;
      letter-spacing: 0.366667px;
      color: #3F4651;
      opacity: 0.8;
      cursor: pointer;
      white-space: inherit;
      margin: 0; }
      ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box p .placeholder, ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box pre .placeholder, ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box textarea .placeholder {
        opacity: 0.3; }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .ant-upload-drag {
      margin-top: 25px;
      height: 272px;
      border-color: rgba(117, 135, 157, 0.3);
      border-width: 2px;
      background-color: rgba(241, 243, 245, 0.3); }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .upload-btn {
      margin-left: 35px; }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .files-card {
      padding: 10px;
      color: #4C6072;
      border-radius: 8px; }
      ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .files-card:hover {
        background: rgba(196, 196, 196, 0.2); }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .card-cover {
      overflow: hidden;
      height: 134px;
      border-radius: 8px; }
      ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .card-cover > img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .card-cover .video-thum, ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .card-cover .file-content {
        position: relative;
        width: 100%;
        height: 100%; }
        ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .card-cover .video-thum > img, ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .card-cover .file-content > img {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
      ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .card-cover .video-thum {
        background: rgba(0, 0, 0, 0.8); }
      ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .card-cover .file-content {
        background: #264382; }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .ant-card-body {
      padding: 0; }
      ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .ant-card-body .card-content {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .ant-card-body .card-content .file-info {
          width: 85%;
          font-size: 14px;
          letter-spacing: 0.366667px;
          text-transform: capitalize; }
          ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .ant-collapse-content-box .report-files .ant-card-body .card-content .file-info .ant-typography {
            margin: 0; }
  ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .report-blood-test-result {
    margin: 0 -26px;
    padding: 0 26px; }
    ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .report-blood-test-result .biomarkers-cotainer {
      margin: 0 -26px; }
      ._19T7Cn0CQvvJjrSVV-wTkG .draft-report .draft-report-container .report-blood-test-result .biomarkers-cotainer .biomarker-item {
        padding: 0 26px; }

.preview-report {
  width: 1440px;
  margin: 0 auto;
  background: #ffffff; }
  .preview-report img {
    max-width: 100%; }
  .preview-report .text-section, .preview-report pre {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0.366667px;
    text-transform: capitalize;
    color: #3F4651;
    opacity: 0.8;
    white-space: inherit;
    margin: 0; }
  .preview-report .report-section {
    margin-left: 156px;
    margin-right: 195px;
    padding: 55px 0; }
    .preview-report .report-section .section-title {
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #4C6072;
      font-weight: 500; }
    .preview-report .report-section .section-sub-title {
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.3px;
      text-transform: capitalize;
      color: #4C6072; }
  .preview-report .preview-banner {
    background: #E5E8ED;
    color: #4C6072;
    height: 188px;
    position: relative; }
    .preview-report .preview-banner img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .preview-report .preview-banner h1 {
      position: relative;
      z-index: 1;
      margin-left: 156px;
      padding-top: 68px;
      font-size: 32px;
      line-height: 29px;
      font-weight: normal; }
    .preview-report .preview-banner p {
      padding-left: 156px;
      font-size: 24px;
      line-height: 29px;
      opacity: 0.5;
      margin: 0; }
    .preview-report .preview-banner img + h1 {
      color: #ffffff; }
      .preview-report .preview-banner img + h1 + p {
        color: #ffffff; }
  .preview-report .report-blood-test-result {
    margin-left: -165px;
    margin-right: -195px;
    padding-left: 165px;
    padding-right: 195px; }
    .preview-report .report-blood-test-result .biomarkers-cotainer {
      margin-left: -165px;
      margin-right: -195px; }
      .preview-report .report-blood-test-result .biomarkers-cotainer .biomarker-item {
        padding-left: 165px;
        padding-right: 195px; }

@media screen and (max-width: 768px) {
  .preview-report {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    bottom: 0;
    -webkit-overflow-scrolling: touch;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    width: 100vw;
    max-width: 750px;
    margin: 0 auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    .preview-report img {
      max-width: 100%; }
    .preview-report .preview-banner {
      padding: 20px;
      height: 234px; }
      .preview-report .preview-banner h1, .preview-report .preview-banner p {
        margin: 0;
        padding: 0; }
      .preview-report .preview-banner h1 {
        margin-top: 90px;
        font-size: 24px;
        line-height: 29px;
        color: #FAFCFD; }
      .preview-report .preview-banner p {
        margin-top: 15px;
        font-size: 18px; }
    .preview-report .report-section {
      margin: 0;
      padding: 20px; }
      .preview-report .report-section > img {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
        max-width: calc(100% + 40px); }
      .preview-report .report-section .section-sub-title {
        font-size: 21px; }
      .preview-report .report-section .ant-col-6 {
        width: 50% !important; }
      .preview-report .report-section .report-blood-chats {
        height: auto; }
        .preview-report .report-section .report-blood-chats .summary {
          display: block;
          height: auto;
          padding-top: 15px; }
          .preview-report .report-section .report-blood-chats .summary .pie {
            margin: 10px;
            margin-right: 0;
            float: left; }
          .preview-report .report-section .report-blood-chats .summary .pie-number {
            display: inline-block;
            margin-left: 35px; }
            .preview-report .report-section .report-blood-chats .summary .pie-number svg {
              width: 100px; }
              .preview-report .report-section .report-blood-chats .summary .pie-number svg text[text-anchor="start"] {
                font-size: 28px; }
        .preview-report .report-section .report-blood-chats .bars {
          height: auto; }
          .preview-report .report-section .report-blood-chats .bars .VictoryContainer {
            height: auto; }
            .preview-report .report-section .report-blood-chats .bars .VictoryContainer svg {
              height: auto;
              max-width: none; }
      .preview-report .report-section .report-blood-test-result .category .header {
        display: block;
        margin-bottom: 0; }
        .preview-report .report-section .report-blood-test-result .category .header .right {
          margin-top: 15px;
          background: rgba(196, 196, 196, 0.1); }
          .preview-report .report-section .report-blood-test-result .category .header .right span {
            margin: 15px; }
      .preview-report .report-section .report-blood-test-result .category .biomarkers-cotainer {
        margin: 0 -20px; }
        .preview-report .report-section .report-blood-test-result .category .biomarkers-cotainer .biomarker-item {
          padding: 20px;
          padding-bottom: 40px;
          display: block;
          height: auto; }
          .preview-report .report-section .report-blood-test-result .category .biomarkers-cotainer .biomarker-item > div {
            width: auto;
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 10px; }
          .preview-report .report-section .report-blood-test-result .category .biomarkers-cotainer .biomarker-item .range-bar {
            width: 100%;
            margin: 0; }
            .preview-report .report-section .report-blood-test-result .category .biomarkers-cotainer .biomarker-item .range-bar .range-item {
              width: 20%; }
              .preview-report .report-section .report-blood-test-result .category .biomarkers-cotainer .biomarker-item .range-bar .range-item .item-bar {
                width: 100%; } }

._2UhZiqpGskAzobX_JnRDjK {
  background: #ffffff; }
  ._2UhZiqpGskAzobX_JnRDjK .sub-header .ant-tabs .ant-tabs-top-content, ._2UhZiqpGskAzobX_JnRDjK .sub-header .ant-tabs-ink-bar {
    display: none !important; }
  ._2UhZiqpGskAzobX_JnRDjK .admin-container {
    padding: 20px;
    margin-bottom: 20px; }
    ._2UhZiqpGskAzobX_JnRDjK .admin-container .admin-tabs {
      overflow: hidden;
      margin: 20px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 16px;
      line-height: 25px;
      text-transform: capitalize;
      position: relative;
      padding: 12px; }
      ._2UhZiqpGskAzobX_JnRDjK .admin-container .admin-tabs .admin-tabs-container {
        position: absolute;
        white-space: nowrap;
        top: 0;
        left: 0; }
      ._2UhZiqpGskAzobX_JnRDjK .admin-container .admin-tabs .icon-container-left, ._2UhZiqpGskAzobX_JnRDjK .admin-container .admin-tabs .icon-container-right {
        position: absolute;
        top: 0;
        padding: 0 10px;
        background: #ffffff;
        z-index: 1;
        cursor: pointer; }
      ._2UhZiqpGskAzobX_JnRDjK .admin-container .admin-tabs .icon-container-left {
        left: 0; }
      ._2UhZiqpGskAzobX_JnRDjK .admin-container .admin-tabs .icon-container-right {
        right: 0; }
      ._2UhZiqpGskAzobX_JnRDjK .admin-container .admin-tabs span {
        margin-right: 68px;
        cursor: pointer; }
        ._2UhZiqpGskAzobX_JnRDjK .admin-container .admin-tabs span.active {
          font-weight: bold;
          border-bottom: 2px solid rgba(0, 0, 0, 0.5); }
  ._2UhZiqpGskAzobX_JnRDjK .category {
    margin: 20px 0;
    padding: 20px 40px;
    background: #E4F1FD;
    color: #297FCA;
    border-radius: 10px;
    overflow: auto; }
    ._2UhZiqpGskAzobX_JnRDjK .category .sub-category {
      display: flex;
      align-items: flex-start; }
      ._2UhZiqpGskAzobX_JnRDjK .category .sub-category > div {
        padding: 10px; }
    ._2UhZiqpGskAzobX_JnRDjK .category .white-btn {
      background: #ffffff !important; }
    ._2UhZiqpGskAzobX_JnRDjK .category h5 {
      font-weight: bold;
      font-size: 24px;
      padding-bottom: 10px;
      color: #297FCA; }
      ._2UhZiqpGskAzobX_JnRDjK .category h5 button {
        margin-left: 10px;
        vertical-align: middle; }
    ._2UhZiqpGskAzobX_JnRDjK .category > .biomarkers {
      -webkit-columns: 4;
              columns: 4; }
    ._2UhZiqpGskAzobX_JnRDjK .category .biomarkers.item-inner-list {
      width: 300px;
      display: inline-block;
      vertical-align: top; }
      ._2UhZiqpGskAzobX_JnRDjK .category .biomarkers.item-inner-list .biomarker-item {
        min-width: 200px; }
    ._2UhZiqpGskAzobX_JnRDjK .category .biomarkers .biomarker-item {
      margin: 10px 0;
      min-width: 180px;
      display: inline-block;
      font-size: 16px;
      line-height: 32px;
      border-radius: 2px;
      padding: 5px 10px;
      cursor: pointer; }
      ._2UhZiqpGskAzobX_JnRDjK .category .biomarkers .biomarker-item .ant-typography {
        margin-bottom: 0; }
      ._2UhZiqpGskAzobX_JnRDjK .category .biomarkers .biomarker-item .anticon {
        margin-left: 30px;
        float: right;
        margin-top: 7px; }

.preview {
  padding: 20px;
  display: flex;
  flex-wrap: wrap; }
  .preview .category {
    margin: 15px;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    box-shadow: -9px 10px 11px 0 rgba(87, 106, 128, 0.1);
    overflow: auto; }
    .preview .category .sub-category {
      display: flex; }
      .preview .category .sub-category > div {
        margin: 0 10px; }
    .preview .category .biomarker-tag {
      display: block; }
    .preview .category h5 {
      font-weight: bold;
      font-size: 24px;
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 10px; }
      .preview .category h5 button {
        margin-left: 10px;
        vertical-align: middle; }
    .preview .category .item-inner-list .ant-btn-group {
      margin: 10px 0;
      margin-right: 10px; }

.logo {
  height: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  color: #ffffff;
  text-align: center;
  font-size: 20px; }

.btn-back {
  margin-left: 20px; }

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s; }
  .trigger:hover {
    color: #1890ff; }

.edit-container {
  max-width: 800px;
  position: relative; }
  .edit-container .value-type {
    margin-bottom: 20px;
    padding-left: 10px; }
    .edit-container .value-type > span {
      color: rgba(76, 76, 76, 0.5);
      margin-right: 30px; }
  .edit-container .ranges-container .range-sec-title {
    font-weight: bold; }
  .edit-container .ranges-container .ant-btn-group {
    margin-right: 5px;
    margin-bottom: 30px; }
  .edit-container .ranges-container .range-btn.show-range {
    border-color: green;
    color: green; }
  .edit-container .ranges-container .optimal-judge {
    margin-bottom: 30px;
    padding-left: 10px; }
  .edit-container .btn-publish {
    margin-right: 10px; }

.preview-container .preview-doc {
  max-width: 800px; }
  .preview-container .preview-doc .title {
    text-align: center;
    font-size: 34px; }
  .preview-container .preview-doc .sub-title {
    margin-top: 40px;
    font-size: 26px; }
  .preview-container .preview-doc .third-title {
    font-size: 22px; }

.page404-container {
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.set-up-screen {
    padding: 0 80px
}

.set-up-screen .header {
    height: 81px;
    text-align: center;
}

.set-up-screen .header img {
    width: 122px;
    margin-top: 35px;
    margin-right: 8px;
}

.set-up-screen .header .log-out {
    position: fixed;
    right: 34px;
    top: 43px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #264382;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.set-up-screen .header .log-out .anticon {
    margin-right: 8px;
}

.set-up-screen .landing {
    background: #FAFCFD;
    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding-bottom: 90;
    border: none;
    display: flex;
    justify-content: space-between;
    margin: 0px 80px;
    padding: 114px 64px 90px 64px;
}

.set-up-screen .landing .title {
    color: #29303e;
    letter-spacing: .3px;
    font-size: 36px;
    line-height: 1.2;
}

.set-up-screen .landing .img-container {
    text-align: center;
    padding-right: 20px;
}

.set-up-screen .landing .img-container img {
    width: 368px;
}

.set-up-screen .ready-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.set-up-screen .ready {
    width: 965px;
    background: #FAFCFD;
    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    background-image: url(/static/media/ready-pg.e12917ab.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    padding: 60px 0;
    padding-bottom: 137px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.set-up-screen .ready .title {
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 120%;
    text-align: center;
    letter-spacing: .0187rem;
    color: #323F4A;
}

.set-up-screen .ready .desc {
    width: 420px;
    margin-top: 36px;
    margin-bottom: 36px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    color: #52606C;
    opacity: 0.6;
    margin-bottom: 36px;
}

.set-up-screen .ready .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 310px;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #52606C;
    margin-bottom: 24px;
}

.set-up-screen .ready .item .circle {
    border: 2px solid #98A9BC;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-right: 12px;
}

.set-up-screen .ready .item .min {
    padding: 4px 8px;
    background: #E6E9F2;
    border-radius: 22px;
    font-weight: 500;
    font-size: 12px;
    line-height: 116%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.3px;
    color: #52606C;
    margin-left: 12px;
}

.set-up-screen .cta {
    margin-top: 50px;
    height: 48px;
    padding: 0px 32px;
}

.set-up-screen .flow {
    display: flex;
    background: #FAFCFD;
    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.05);
    border: 1px solid #F1F3F6;
    border-radius: 12px;
    height: 600px;
    margin-top: 30px;
    background-image: url(/static/media/doc.fd525a22.svg);
    background-repeat: no-repeat;
    background-position: 95%;
    width: 1285px;
    min-width: 1285px;
}

.set-up-screen .flow.sign-image {
    background-image: url(/static/media/onboarding-sign-image.81b7bfb6.svg);
}

.set-up-screen .flow .left {
    border-right: 1px solid #E5E9F2;
    width: 400px;
    min-width: 400px;
    padding-top: 15px;
}

.set-up-screen .flow .left .item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E5E9F2;
    padding: 12px;
    padding-right: 24px;
}

.set-up-screen .flow .left .item .wrap {
    display: flex;
    align-items: center;
}


.set-up-screen .flow .left .item .circle {
    border: 1px solid #98A9BC;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #98A9BC;
    margin-left: 12px;
    margin-right: 12px;
}

.set-up-screen .flow .left .item .circle.active {
    background: #264382;
    width: 30px;
    height: 30px;
    color: #FAFCFD;
}

.set-up-screen .flow .left .item .circle.completed {
    border: 2px solid #05B800;
    background: #05B800;
}

.set-up-screen .flow .left .item .min {
    background: #E6E9F2;
    border-radius: 22px;
    padding: 0px 8px;
    height: 22px;
}

.set-up-screen .flow .right {
    padding: 60px 48px;
}

.set-up-screen .flow .right .title {
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.3px;
    color: #29303E;
}

.set-up-screen .flow .right .question {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #323F4A;
    margin-top: 48px;
    margin-bottom: 21px;
    width: 373px;
}

.set-up-screen .flow .right .answer {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.3px;
    color: #52606C;
    width: 373px;
}

.set-up-screen .flow-container .btn-box {
    text-align: center;
}

.set-up-screen .flow-container .btn-box .btn-back {
    margin-right: 36px;
    border: 1px solid #264382;
    color: #264382;
}
._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container {
  display: flex;
  flex-wrap: nowrap; }
  ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .biomarker-info {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: inline-block;
    width: calc(558px + 45px);
    margin-left: 20px;
    position: relative;
    min-height: 344px; }
    ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .biomarker-info .tabs {
      display: flex; }
      ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .biomarker-info .tabs > ul {
        list-style: none;
        padding: 0;
        text-align: center;
        border-bottom: 1px solid #EAEDF3;
        width: 100%; }
        ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .biomarker-info .tabs > ul li {
          display: inline-block;
          width: 50%;
          border-right: 1px solid #EAEDF3;
          background: #FAFAFA;
          padding: 31px 0;
          font-size: 12px;
          font-weight: bold; }
          ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .biomarker-info .tabs > ul li:last-child {
            border: none; }
          ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .biomarker-info .tabs > ul li.active {
            color: #3F4752;
            background: #ffffff; }
    ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .biomarker-info .information-container {
      padding: 20px;
      font-family: Heebo; }
      ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .biomarker-info .information-container .information {
        position: absolute;
        width: calc(100% - 40px);
        height: calc(100% - 104px - 40px);
        overflow-y: scroll; }
  ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: inline-block;
    width: 767px; }
    ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info .header {
      padding: 15px 20px;
      height: 88px;
      border-bottom: 1px solid #EAEDF3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 29px; }
      ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info .header h1 {
        margin: 0;
        font-size: 18px; }
      ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info .header .date {
        font-size: 12px;
        line-height: 29px;
        color: #B1B1B1; }
        ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info .header .date img {
          margin-right: 10px; }
      ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info .header .show-options {
        text-transform: uppercase;
        text-align: right;
        margin-top: -45px; }
    ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info .ranges-area-container {
      width: 100%;
      min-height: 100px;
      display: inline-block;
      padding: 20px;
      vertical-align: top;
      padding-bottom: 0; }
    ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info .ranges-area-container .ranges-area:last-child {
      margin-top: 20px; }
    ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info .ranges-area-container .options-container {
      display: flex;
      justify-content: space-between; }
      ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info .ranges-area-container .options-container div > .label {
        font-size: 12px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #4C6072; }
      ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info .ranges-area-container .options-container > div {
        font-size: 24px;
        line-height: 36px;
        text-transform: capitalize; }
      ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info .ranges-area-container .options-container .value {
        text-align: right; }

._1jQxMCtiSBzR4Ji065NNfY .trend-graph {
  background: #ffffff;
  margin-top: 20px;
  border-radius: 4px;
  padding-bottom: 20px;
  width: 100%; }
  ._1jQxMCtiSBzR4Ji065NNfY .trend-graph h1 {
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid #EAEDF3;
    font-size: 18px;
    line-height: 29px; }
  ._1jQxMCtiSBzR4Ji065NNfY .trend-graph .VictoryContainer {
    margin: 0 20px; }

@media screen and (max-width: 768px) {
  ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container {
    display: block; }
    ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .ranges-info {
      width: 100%;
      border-radius: 0; }
    ._1jQxMCtiSBzR4Ji065NNfY .client-biomarker-container .biomarker-info {
      width: 100%;
      margin: 0; } }

._1xvETd2-q8xBc1rzw6PhnP {
  font-family: Gilroy;
  padding-right: 50px;
  padding-top: 40px;
  padding-bottom: 50px; }
  ._1xvETd2-q8xBc1rzw6PhnP .title {
    display: flex;
    justify-content: space-between; }
    ._1xvETd2-q8xBc1rzw6PhnP .title .sort {
      font-size: 18px; }
  ._1xvETd2-q8xBc1rzw6PhnP .select-container {
    color: #8D99A5;
    letter-spacing: 0.4px;
    font-size: 12px; }
    ._1xvETd2-q8xBc1rzw6PhnP .select-container .sub-title {
      font-weight: 600;
      font-size: 24px;
      color: #383E48; }
    ._1xvETd2-q8xBc1rzw6PhnP .select-container .ant-select {
      margin-left: 15px; }
    ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list {
      margin-top: 16px; }
      ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item {
        display: inline-block;
        margin-bottom: 24px; }
        ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item .ant-card-body, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item .ant-card-body {
          padding: 13px 16px 16px; }
        ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item .item-type, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item .item-type {
          font-size: 12px;
          line-height: 150%;
          letter-spacing: 0.3px;
          font-weight: 600;
          margin-bottom: 8px; }
          ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item .item-type.nutrition, ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item .item-type.Nutrition, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item .item-type.nutrition, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item .item-type.Nutrition {
            color: #7BAD2D; }
          ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item .item-type.exercise, ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item .item-type.Exercise, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item .item-type.exercise, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item .item-type.Exercise {
            color: #FF6B00; }
          ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item .item-type.rejuvenation, ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item .item-type.Rejuvenation, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item .item-type.rejuvenation, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item .item-type.Rejuvenation {
            color: #5C00D1; }
        ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item .ant-card-meta-title, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item .ant-card-meta-title {
          color: #323F4A;
          font-size: 14px;
          line-height: 120%; }
        ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item .ant-card-meta-description, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item .ant-card-meta-description {
          color: #A5ABB1;
          font-size: 12px;
          line-height: 23px; }
        ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .content-item .item-more, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .content-item .item-more {
          margin-top: 30px;
          font-size: 14px;
          font-family: Heebo;
          color: #264382; }
      ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .resource-item, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .resource-item {
        display: inline-block;
        margin-bottom: 24px; }
        ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .resource-item .ant-card-body, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .resource-item .ant-card-body {
          margin-top: -85px;
          padding: 20px 16px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0.16%, rgba(0, 13, 40, 0.6) 100%); }
          ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .resource-item .ant-card-body .ant-card-meta-title, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .resource-item .ant-card-body .ant-card-meta-title {
            color: #ffffff;
            font-size: 20px;
            line-height: 22px; }
          ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .resource-item .ant-card-body .ant-card-meta-description, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .resource-item .ant-card-body .ant-card-meta-description {
            color: #ffffff; }
        ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .resource-item .ant-card-cover img, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .resource-item .ant-card-cover img {
          border-radius: 4px; }
      ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .category-item, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .category-item {
        width: 100%; }
        ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .category-item .ant-card-meta-title, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .category-item .ant-card-meta-title {
          white-space: normal; }
        ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .category-item .ant-card-cover, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .category-item .ant-card-cover {
          height: 114px;
          object-fit: cover;
          overflow: hidden; }
        ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .category-item .item-tip, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .category-item .item-tip {
          color: #A5ABB1;
          font-size: 12px;
          margin-top: 19px; }
        ._1xvETd2-q8xBc1rzw6PhnP .select-container .content-list .category-item .item-type, ._1xvETd2-q8xBc1rzw6PhnP .select-container .resource-list .category-item .item-type {
          text-transform: capitalize; }

._11BmMI1AB5_sx1ormVAKn- {
  padding-top: 40px;
  padding-bottom: 60px;
  padding-right: 64px; }
  ._11BmMI1AB5_sx1ormVAKn- .title {
    font-size: 36px;
    font-weight: 500; }
  ._11BmMI1AB5_sx1ormVAKn-.backup .information-container.Opened {
    border: 1px solid #70808E; }

._2YFRGqG3OTIHqBPsV9ObCA {
  padding-top: 40px;
  padding-right: 60px; }
  ._2YFRGqG3OTIHqBPsV9ObCA .description {
    margin-top: 0; }
  ._2YFRGqG3OTIHqBPsV9ObCA.User .information-container.Opened {
    border: none; }
  ._2YFRGqG3OTIHqBPsV9ObCA.User .nutrition-score-container .information-container.Opened .content {
    color: #E7E9EC; }
  ._2YFRGqG3OTIHqBPsV9ObCA.User .section-container .information-container.Opened {
    background: rgba(38, 67, 130, 0.05); }

._2lxnoE9jr6elP7SdsSnneH {
  padding-right: 50px; }
  ._2lxnoE9jr6elP7SdsSnneH .container .ant-btn.box-button {
    color: #264382; }
  ._2lxnoE9jr6elP7SdsSnneH .container .back-title {
    margin-left: 12px;
    font: Gilroy;
    color: #264382;
    font-size: 14px; }

._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content {
  display: flex;
  justify-content: center;
  position: relative; }
  ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .left, ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .right {
    display: inline-block;
    vertical-align: top; }
  ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .left {
    width: 280px;
    margin-right: 14px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2; }
    ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .left.close {
      position: absolute;
      left: 0;
      width: 0px; }
    ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .left .swift-left-btn {
      position: absolute;
      top: 0;
      right: -60px; }
      ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .left .swift-left-btn.close {
        left: 0; }
  ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .close > div:first-child {
    padding-right: 0px; }
  ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .right {
    width: calc(100% - 280px - 14px);
    min-height: calc(100vh - 71px);
    background: #F4F6F8;
    margin-top: -35px;
    padding-top: 35px;
    padding-left: 50px;
    padding-bottom: 60px;
    position: relative; }
    ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .right .profile-contant {
      padding-right: 44px;
      padding-top: 40px; }
    ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .right .question-con {
      padding-right: 54px;
      padding-top: 40px;
      padding-bottom: 60px;
      position: relative; }
      ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .right .question-con .page-title {
        padding-bottom: 1em; }
      ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .right .question-con .btn-group {
        position: absolute;
        right: 54px;
        top: 40px;
        font-family: Gilroy;
        font-size: 16px;
        color: #264382; }
      ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .right .question-con .btn-edit-answer {
        display: inline-block;
        margin-left: 20px;
        width: 202px;
        height: 44px;
        border-radius: 38px;
        border: 2px solid #264382;
        text-align: center;
        line-height: 40px;
        cursor: pointer; }
    ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .right.detail-right {
      width: 100%;
      padding: 0 25px; }
      ._1emJwQLW1amxHi8Ni5Ytl6 .client-view-main-content .right.detail-right .page-title img {
        margin-right: 5px; }

.pricing-table .ant-table {
    border-radius: 20px;
    overflow: hidden;
}

.pricing-table .ant-table-thead>tr>th,
.pricing-table .ant-table-tbody>tr>td {
    border: none;
}


.pricing-table .ant-table-tbody>tr>td {
    padding: 16px;
}

.column-head {
    text-align: left;
    padding-left: 10px;
    padding-top: 20px;
}

.column-service {
    text-align: center;
    font-size: 20px;
}

.pricing-table {
    margin: 40px 0px 20px 0px;
    border-radius: 16px;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.05)
}

.insights-text {
    font-family: Gilroy-Bold;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #9FB8C7;
}

.bronze-text {
    font-family: Gilroy-Bold;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #B17A6A;
}

.silver-text {
    font-family: Gilroy-Bold;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #B6B6B9;
}

.gold-text {
    font-family: Gilroy-Bold;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #F9A72A;
}

.remote-text {
    font-family: Gilroy-Bold;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #6598b9;
}

.membership-price-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #36383A;
}

.session-info{
    margin: 40px 0px 40px 0px;

}
.table-sub-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
}

.service-list-container{
    text-align: left;
    padding-right: 2rem;
}

.selected {
    background-color: #264382 !important;
    color: #ffff !important;
    border: 1px solid #264382 !important;
    letter-spacing: 2.8px;
    font-size: 14px;
    margin-top: 16px;
}
.no-hover:hover {
    background-color: transparent !important;
}

.ant-table td {
    border: none !important;
}

.ant-table th {
    border: none !important;
    border-bottom: 1px solid #f0f0f0 !important;
}

td.highlighted-column {
    background-color: #E2F2FF !important;
    border-left: 2px solid #264382 !important;
    border-right: 2px solid #264382 !important;
    position: relative;
}

th.ant-table-cell.highlighted-column {
    border-top: 2px solid #264382 !important;
    border-left: 2px solid #264382 !important;
    border-right: 2px solid #264382 !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background-color: #E2F2FF !important;
}

.ant-table-tbody>tr:last-child td.highlighted-column {
    border-bottom: 2px solid #264382 !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.selected-btn {
    background: #264382 !important;
    color: #ffffff;
}

.ant-table-thead>tr>th {
    padding-bottom: 40px !important;
}



.ant-table-container {
    border: none !important;
}
.tip-text {
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 30px;
}

.contact-email {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    font-size: 18px;
    font-weight: 400;
}

.contact-email-text {
    margin-right: 6px;
}

.contact-email a {
    text-decoration: underline;
}

.table-wrapper .ant-table {
    border-radius: 20px;
    overflow: hidden;
}

.table-wrapper .ant-table-thead>tr>th:first-child {
    border-top-left-radius: 20px;
}

.table-wrapper .ant-table-thead>tr>th:last-child {
    border-top-right-radius: 20px;
}

.table-wrapper .ant-table-tbody>tr:last-child>td:first-child {
    border-bottom-left-radius: 20px;
}

.table-wrapper .ant-table-tbody>tr:last-child>td:last-child {
    border-bottom-right-radius: 20px;
}

.ant-table-thead>tr>th {
    background-color: white !important;
    border-bottom: none;
}

.ant-table-tbody tr:last-child td:nth-child(2) {
    vertical-align: top !important;
}

.ant-table-tbody tr:last-child td:nth-child(3) {
    vertical-align: top !important;
}

.ant-table-thead th {
    padding: 44px 16px !important;
    text-align: center;
    vertical-align: middle;
}

.sub-text-price {
    letter-spacing: 1px;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
}
._2CMEEYB_X4V1WDDzFmJ31J {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px; }
  ._2CMEEYB_X4V1WDDzFmJ31J .logo {
    position: fixed;
    left: 30px;
    top: 20px;
    width: 150px; }
  ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container {
    background-color: white;
    width: 500px;
    height: 631px;
    border-radius: 18px;
    overflow: hidden;
    position: relative; }
    ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .back {
      margin: 24px 0 0 22px;
      position: absolute; }
      ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .back .back-title {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #264382;
        letter-spacing: 2.8px; }
    ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .white-box-container-title {
      text-align: center;
      font-family: Gilroy;
      font-size: 36px;
      letter-spacing: 0.3px;
      color: #A7B1BF;
      margin-top: 54px; }
    ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .white-box-container-notice {
      margin-top: 10px;
      font-family: Heebo;
      font-size: 14px;
      padding: 0 100px;
      line-height: 150%;
      color: rgba(92, 98, 106, 0.8);
      text-align: center; }
      ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .white-box-container-notice span {
        font-weight: bold; }
    ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .form-view-box {
      margin: 15px 22px 26px 20px;
      height: 430px;
      overflow: auto; }
      ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .form-view-box .form-view {
        background-color: #FCFDFD;
        border: 1px solid rgba(43, 75, 143, 0.3);
        border-radius: 12px;
        width: 457px;
        height: 100px;
        padding-left: 29px;
        padding-right: 34px;
        margin-top: 15px; }
        ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .form-view-box .form-view .form-view-container {
          font-family: Heebo;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .form-view-box .form-view .form-view-container .form-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 25px; }
          ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .form-view-box .form-view .form-view-container .form-title {
            font-size: 18px;
            color: #29303E;
            letter-spacing: 0.3px; }
          ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .form-view-box .form-view .form-view-container .form-view-icon-title {
            margin-top: 5px;
            color: #264382;
            letter-spacing: 0.3px;
            line-height: 100%; }
      ._2CMEEYB_X4V1WDDzFmJ31J .white-box-container .form-view-box .add-more-tip {
        color: #3561C0;
        text-align: center;
        margin: 20px 0;
        cursor: pointer;
        font-family: Heebo;
        font-weight: 500;
        font-size: 12px; }

._3VZAMY5-RH45EJB-oxdNl6 {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-around; }
  ._3VZAMY5-RH45EJB-oxdNl6 > div {
    background: #ffffff; }
  ._3VZAMY5-RH45EJB-oxdNl6 .choose-container {
    width: 500px;
    border-radius: 18px; }
    ._3VZAMY5-RH45EJB-oxdNl6 .choose-container .back {
      padding: 24px 22px;
      padding-bottom: 14px;
      color: #264382;
      text-transform: uppercase;
      letter-spacing: 2.8px;
      font-size: 12px;
      font-weight: bold;
      cursor: pointer; }
    ._3VZAMY5-RH45EJB-oxdNl6 .choose-container .title {
      width: 363px;
      margin: 0 auto;
      margin-bottom: 25px;
      text-align: center;
      font-size: 36px;
      font-family: Gilroy;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #A7B1BF; }
    ._3VZAMY5-RH45EJB-oxdNl6 .choose-container .choose-option {
      display: flex;
      justify-content: space-between;
      padding: 17px 0 23px 44px;
      font-size: 14px;
      font-family: Heebo;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #323F4A;
      border-top: 1px solid #E8ECF0; }
      ._3VZAMY5-RH45EJB-oxdNl6 .choose-container .choose-option div {
        max-width: 247px; }
      ._3VZAMY5-RH45EJB-oxdNl6 .choose-container .choose-option .option-title {
        font-family: Gilroy;
        font-size: 18px;
        line-height: 150%;
        color: #52606C;
        margin-bottom: 11px; }
      ._3VZAMY5-RH45EJB-oxdNl6 .choose-container .choose-option .btn-icon {
        text-align: center;
        width: 130px;
        align-items: center;
        padding: 16px;
        color: #264382;
        cursor: pointer; }
        ._3VZAMY5-RH45EJB-oxdNl6 .choose-container .choose-option .btn-icon .anticon {
          margin-bottom: 5px; }
      ._3VZAMY5-RH45EJB-oxdNl6 .choose-container .choose-option p {
        margin-bottom: 0; }
  ._3VZAMY5-RH45EJB-oxdNl6 .customize-container {
    width: 854px;
    border-radius: 8px;
    padding: 40px;
    padding-left: 55px;
    padding-right: 73px; }
    ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .title {
      font-family: Gilroy;
      font-size: 36px;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #323F4A; }
      ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .title .close {
        float: right;
        cursor: pointer;
        font-size: 16px;
        margin-right: -43px;
        margin-top: -26px; }
    ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .body-box label {
      font-family: Heebo;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      color: #737E88;
      opacity: 0.8; }
    ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .body-box input {
      background: rgba(232, 236, 240, 0.2);
      border: 1px solid rgba(82, 96, 108, 0.5);
      border-radius: 4px;
      margin-top: 15px;
      margin-bottom: 25px;
      height: 50px;
      font-family: Gilroy;
      font-size: 16px;
      color: #323F4A; }
    ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .body-box .ant-divider-horizontal.ant-divider-with-text-left::before {
      width: 0; }
    ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .body-box .ant-divider-inner-text {
      font-family: Gilroy;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #52606C;
      padding-left: 0; }
    ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .body-box .ant-checkbox-group {
      padding-top: 30px;
      width: 100%; }
      ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .body-box .ant-checkbox-group .ant-checkbox-wrapper {
        width: 50%;
        font-family: Gilroy;
        font-size: 16px;
        line-height: 150%;
        color: #ECEFF5;
        letter-spacing: 0.3px;
        margin: 0;
        margin-bottom: 36px; }
        ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .body-box .ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
          margin-right: 42px; }
      ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .body-box .ant-checkbox-group .ant-checkbox-wrapper-checked {
        color: #323F4A; }
    ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .bottom-opr {
      text-align: right;
      font-family: Gilroy;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.3px; }
      ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .bottom-opr .ant-btn-primary {
        padding: 10px 38px;
        height: 44px;
        margin-left: 25px; }
      ._3VZAMY5-RH45EJB-oxdNl6 .customize-container .bottom-opr .cancel {
        padding: 10px 15px;
        color: #264382;
        cursor: pointer; }
  ._3VZAMY5-RH45EJB-oxdNl6 .succeed-container {
    width: 478px;
    padding: 40px 80px;
    text-align: center;
    border-radius: 8px; }
    ._3VZAMY5-RH45EJB-oxdNl6 .succeed-container .title {
      font-family: Gilroy;
      font-size: 36px;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #323F4A;
      margin-top: 5px;
      margin-bottom: 8px; }
    ._3VZAMY5-RH45EJB-oxdNl6 .succeed-container .body-box {
      padding: 60px 0 50px; }
      ._3VZAMY5-RH45EJB-oxdNl6 .succeed-container .body-box p {
        font-family: Gilroy;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.3px;
        color: #545F6D;
        margin-top: 30px; }
    ._3VZAMY5-RH45EJB-oxdNl6 .succeed-container .ant-btn-primary {
      width: 320px;
      height: 50px;
      font-family: Gilroy;
      font-size: 16px;
      line-height: 42px;
      letter-spacing: 0.3px; }
    ._3VZAMY5-RH45EJB-oxdNl6 .succeed-container .do-it-later {
      cursor: pointer;
      margin-top: 10px;
      font-size: 16px; }

._3B-o3CokT0MMXGvwej5MO7 .ant-empty {
  padding-top: 50px; }

._3B-o3CokT0MMXGvwej5MO7 .category-container .root-category {
  padding: 20px 20px 20px 0; }
  ._3B-o3CokT0MMXGvwej5MO7 .category-container .root-category > .root-category {
    padding: 0 !important; }
  ._3B-o3CokT0MMXGvwej5MO7 .category-container .root-category .ant-divider:before {
    width: 0; }
  ._3B-o3CokT0MMXGvwej5MO7 .category-container .root-category .ant-divider .ant-divider-inner-text {
    font-size: 18px;
    padding-left: 0; }
  ._3B-o3CokT0MMXGvwej5MO7 .category-container .root-category .card-list .metric-container {
    margin-bottom: 30px;
    width: 100%; }
  ._3B-o3CokT0MMXGvwej5MO7 .category-container .root-category .sec-no-data {
    text-align: center;
    color: #bac3ce;
    opacity: .8;
    font-size: 12px;
    font-weight: 500;
    font-family: Heebo;
    letter-spacing: .6px;
    text-transform: uppercase; }
  ._3B-o3CokT0MMXGvwej5MO7 .category-container .root-category .sub-category-container .description {
    padding: 0;
    padding-bottom: 10px; }
  ._3B-o3CokT0MMXGvwej5MO7 .category-container .root-category .image-content img {
    max-width: 100%; }

._3B-o3CokT0MMXGvwej5MO7 .section-title {
  margin: 24px 0px 7px 21px;
  font-size: 24px;
  letter-spacing: 0.3px;
  color: #383E48;
  display: inline-block; }

._3B-o3CokT0MMXGvwej5MO7 .separator {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0px 21px; }

._19ach25jqCzpxfz-la6h2m .header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  background: #fff;
  padding: 20px; }
  ._19ach25jqCzpxfz-la6h2m .header h1 {
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #4C6072;
    font-weight: bold; }

._19ach25jqCzpxfz-la6h2m .menu {
  height: 50px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  background: #F9FAFB;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }
  ._19ach25jqCzpxfz-la6h2m .menu img {
    width: 25px; }
  ._19ach25jqCzpxfz-la6h2m .menu .page-title {
    padding: 0 25px;
    font-size: 14px;
    color: #3F4752;
    font-weight: normal; }

._19ach25jqCzpxfz-la6h2m .trend-graph {
  margin-top: -8px !important;
  padding-bottom: 0; }
  ._19ach25jqCzpxfz-la6h2m .trend-graph .trend-title {
    font-family: Gilroy-Bold;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.3px;
    text-transform: capitalize;
    color: #383E48;
    font-weight: 700;
    margin-bottom: 25px; }
  ._19ach25jqCzpxfz-la6h2m .trend-graph .chat-axis-title {
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: #4C6072;
    opacity: 0.8;
    font-size: 12px;
    font-weight: bold;
    font-family: Heebo; }
    ._19ach25jqCzpxfz-la6h2m .trend-graph .chat-axis-title .left, ._19ach25jqCzpxfz-la6h2m .trend-graph .chat-axis-title .right {
      display: inline-block;
      width: 50%; }
    ._19ach25jqCzpxfz-la6h2m .trend-graph .chat-axis-title .right {
      text-align: right; }
  ._19ach25jqCzpxfz-la6h2m .trend-graph > div {
    min-width: calc(100vw); }
  ._19ach25jqCzpxfz-la6h2m .trend-graph .VictoryContainer {
    margin: 0; }

._19ach25jqCzpxfz-la6h2m .am-drawer .am-drawer-draghandle {
  width: 0; }

._19ach25jqCzpxfz-la6h2m .am-drawer-overlay {
  background: #ffffff; }

._19ach25jqCzpxfz-la6h2m .am-drawer-sidebar .am-list-body::after {
  content: none; }

._19ach25jqCzpxfz-la6h2m .am-drawer-sidebar .am-list-item {
  padding-left: 47px; }
  ._19ach25jqCzpxfz-la6h2m .am-drawer-sidebar .am-list-item.am-list-item-active {
    background: transparent; }
  ._19ach25jqCzpxfz-la6h2m .am-drawer-sidebar .am-list-item .am-list-line {
    padding: 0; }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-sidebar .am-list-item .am-list-line .am-accordion-content-box .menu-item {
      font-size: 14px;
      line-height: 200%;
      letter-spacing: 0.3px;
      margin: 10px 0; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-sidebar .am-list-item .am-list-line .am-accordion-content-box .menu-item:first-child {
        padding-top: 10px; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-sidebar .am-list-item .am-list-line .am-accordion-content-box .menu-item:last-child {
        padding-bottom: 20px;
        margin-bottom: 0; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-sidebar .am-list-item .am-list-line .am-accordion-content-box .menu-item:before {
        content: '';
        display: inline-block;
        width: 9px;
        height: 9px;
        background: #4C6072;
        margin-right: 18px;
        border-radius: 100%; }

._19ach25jqCzpxfz-la6h2m .am-drawer-open.am-drawer.am-drawer-left .am-drawer-sidebar {
  width: 100%; }

._19ach25jqCzpxfz-la6h2m .am-accordion .am-accordion-item .am-accordion-header {
  padding: 3px 0;
  border: none; }
  ._19ach25jqCzpxfz-la6h2m .am-accordion .am-accordion-item .am-accordion-header .arrow {
    display: none; }

._19ach25jqCzpxfz-la6h2m .am-drawer-content {
  text-align: left !important; }
  ._19ach25jqCzpxfz-la6h2m .am-drawer-content .is-momentumScrollable {
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    bottom: 0;
    -webkit-overflow-scrolling: touch;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0); }
  ._19ach25jqCzpxfz-la6h2m .am-drawer-content .trend-graph {
    margin-top: 0;
    padding-bottom: 0; }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-content .trend-graph .chat-axis-title {
      text-transform: uppercase;
      letter-spacing: 0.6px;
      color: #4C6072;
      opacity: 0.8;
      font-family: Heebo;
      font-weight: bold;
      font-size: 12px;
      line-height: 19px; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-content .trend-graph .chat-axis-title .left, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .trend-graph .chat-axis-title .right {
        display: inline-block;
        width: 50%; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-content .trend-graph .chat-axis-title .right {
        text-align: right; }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-content .trend-graph > div {
      min-width: calc(100vw); }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-content .trend-graph .VictoryContainer {
      margin: 0; }
  ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container {
    background: #F9FAFB;
    padding: 0; }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .root-category, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .root-category {
      padding-left: 17px;
      padding-top: 10px;
      padding-bottom: 10px;
      background: #ffffff;
      margin-top: 0 !important; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .root-category .attachment-container, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .root-category .attachment-container {
        margin: 0;
        padding: 0; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .root-category.extra, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .root-category.extra {
        padding-top: 10px; }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .left, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .left {
      background: #ffffff;
      padding: 17px; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .left .name > div, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .left .name > div {
        display: inline; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .left .show-more-text, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .left .show-more-text {
        color: #3D3D3D;
        opacity: 0.8;
        text-decoration: underline;
        cursor: pointer; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .left .description.mobile-show .ant-typography-expand, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .left .description.mobile-show .ant-typography-expand {
        display: inline-block;
        color: transparent;
        position: relative; }
        ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .left .description.mobile-show .ant-typography-expand:before, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .left .description.mobile-show .ant-typography-expand:before {
          content: 'Read more';
          position: absolute;
          color: #3D3D3D;
          opacity: 0.8;
          text-decoration: underline;
          width: 70px; }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .right, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .right {
      padding: 17px; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .right .biomarker-container, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .right .biomarker-container {
        margin: 0;
        margin-bottom: 17px;
        box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.05);
        border-radius: 4px; }
        ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .right .biomarker-container:first-child, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .right .biomarker-container:first-child {
          border: none; }
        ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .right .biomarker-container .range > div, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .right .biomarker-container .range > div {
          height: 84px; }
        ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .right .biomarker-container .personal-value-container, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .right .biomarker-container .personal-value-container {
          height: auto; }
          ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .right .biomarker-container .personal-value-container .name, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .right .biomarker-container .personal-value-container .name {
            padding-bottom: 0; }
          ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .sub-category-container.permanent-category .right .biomarker-container .personal-value-container .value, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .sub-category-container.permanent-category .right .biomarker-container .personal-value-container .value {
            display: flex;
            justify-content: space-between;
            align-items: center; }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-content .category-container .ant-divider:after, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .ant-divider:after {
      border: none; }
  ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .root-category {
    background: #F9FAFB !important;
    padding: 0; }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .root-category .left .description {
      margin: 0;
      margin-top: 20px; }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .root-category .attachment-name {
      background: #ffffff;
      margin-bottom: 0;
      padding: 20px; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-content .mobile-categories-container .root-category .attachment-name + img {
        margin: 0; }
  ._19ach25jqCzpxfz-la6h2m .am-drawer-content .detail-title {
    padding: 20px 17px;
    margin: 0;
    font-size: 24px;
    line-height: 29px; }
  ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container {
    background: #ffffff; }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info {
      margin-bottom: 0 !important;
      box-shadow: none; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container {
        padding-bottom: 0; }
        ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container .options-container {
          margin-bottom: 20px; }
        ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container .ranges-area {
          position: relative;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
          ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container .ranges-area .range-title, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container .ranges-area .range-level {
            display: inline-block;
            font-size: 12px;
            color: rgba(76, 96, 114, 0.8);
            line-height: 1.4; }
            ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container .ranges-area .range-title span:last-child, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container .ranges-area .range-level span:last-child {
              display: none; }
            ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container .ranges-area .range-title span.name, ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container .ranges-area .range-level span.name {
              display: inline-block; }
          ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container .ranges-area .range-level {
            color: #383E48;
            display: block;
            width: 100%;
            font-size: 18px;
            letter-spacing: 0.3px;
            color: #4C6072;
            text-align: left; }
          ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container .ranges-area .range-value {
            letter-spacing: 0.6px;
            font-size: 24px;
            line-height: 36px;
            text-transform: capitalize;
            font-family: Gilroy-Bold;
            font-weight: bold; }
          ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .ranges-info .ranges-area-container .ranges-area .range-info {
            text-transform: uppercase !important; }
    ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .biomarker-info {
      box-shadow: none;
      border-top: 1px solid #EAEDF3; }
      ._19ach25jqCzpxfz-la6h2m .am-drawer-content .client-biomarker-container .biomarker-info .information {
        color: #44433D;
        width: 100%;
        position: relative; }

._1HqikR_JGtlq455gp9kXuK .main-content .left, ._1HqikR_JGtlq455gp9kXuK .main-content .right {
  display: inline-block;
  vertical-align: top; }

._1HqikR_JGtlq455gp9kXuK .main-content .left {
  width: 280px;
  margin-right: 78px; }

._1HqikR_JGtlq455gp9kXuK .main-content .right {
  width: calc(100% - 280px - 78px);
  padding-right: 44px; }
  ._1HqikR_JGtlq455gp9kXuK .main-content .right.detail-right {
    width: 100%;
    padding: 0 25px; }
    ._1HqikR_JGtlq455gp9kXuK .main-content .right.detail-right .page-title img {
      margin-right: 5px; }

@media screen and (max-width: 768px) {
  ._1HqikR_JGtlq455gp9kXuK {
    /* detail mobile style*/ }
    ._1HqikR_JGtlq455gp9kXuK .main-content .left {
      margin-right: 0; }
    ._1HqikR_JGtlq455gp9kXuK .main-content.ios .right .mobile-show > div {
      margin-top: 0; }
    ._1HqikR_JGtlq455gp9kXuK .main-content .right {
      width: 100%;
      padding: 0; }
      ._1HqikR_JGtlq455gp9kXuK .main-content .right .mobile-show > div {
        margin-top: -20px; }
      ._1HqikR_JGtlq455gp9kXuK .main-content .right.detail-right {
        padding: 0; }
        ._1HqikR_JGtlq455gp9kXuK .main-content .right.detail-right .mobile-show > div {
          margin-top: 0; } }

._13tNfs45qKmkBTiyrsm6aR {
  width: 100vw !important;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #ffffff; }
  ._13tNfs45qKmkBTiyrsm6aR .cover-header {
    position: relative;
    margin: 0 auto; }
    ._13tNfs45qKmkBTiyrsm6aR .cover-header header {
      position: absolute;
      padding: 28px 5px;
      left: 10px;
      height: 83px;
      background: #ffffff; }
    ._13tNfs45qKmkBTiyrsm6aR .cover-header.back {
      position: fixed;
      top: 0;
      width: 100%;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
      ._13tNfs45qKmkBTiyrsm6aR .cover-header.back header {
        position: static;
        text-align: center; }
        ._13tNfs45qKmkBTiyrsm6aR .cover-header.back header .anticon {
          font-size: 26px;
          float: left;
          color: #4C6072; }
  ._13tNfs45qKmkBTiyrsm6aR iframe {
    width: 100%;
    height: 100%;
    border: none; }
  @media (min-width: 576px) {
    ._13tNfs45qKmkBTiyrsm6aR .cover-header {
      max-width: 510px; }
      ._13tNfs45qKmkBTiyrsm6aR .cover-header header {
        left: 0; } }
  @media (min-width: 768px) {
    ._13tNfs45qKmkBTiyrsm6aR .cover-header {
      max-width: 690px; } }
  @media (min-width: 992px) {
    ._13tNfs45qKmkBTiyrsm6aR .cover-header {
      max-width: 930px; } }
  @media (min-width: 1200px) {
    ._13tNfs45qKmkBTiyrsm6aR .cover-header {
      max-width: 1053px; } }

._1dcQdkbRrAEtzj4dks8WY {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw !important; }
  ._1dcQdkbRrAEtzj4dks8WY header {
    background: #ffffff;
    height: 83px;
    line-height: 83px;
    text-align: center; }
  ._1dcQdkbRrAEtzj4dks8WY .content {
    width: 100% !important;
    margin: 0 auto;
    padding-top: 105px;
    max-width: 800px; }
    ._1dcQdkbRrAEtzj4dks8WY .content h1 {
      font-family: Gilroy;
      font-weight: 500;
      font-size: 36px;
      color: #4C6072;
      letter-spacing: 0.3px;
      margin-bottom: 30px;
      padding: 0 10px; }
    ._1dcQdkbRrAEtzj4dks8WY .content p {
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 24px;
      color: #52606C;
      opacity: 0.8;
      padding: 0 10px; }
    ._1dcQdkbRrAEtzj4dks8WY .content button {
      min-width: 150px;
      height: 42px;
      font-size: 16px; }
    ._1dcQdkbRrAEtzj4dks8WY .content .form-btn {
      text-align: right; }
    ._1dcQdkbRrAEtzj4dks8WY .content .ant-form {
      margin: 0 auto;
      text-align: left; }
      ._1dcQdkbRrAEtzj4dks8WY .content .ant-form .ant-input-affix-wrapper {
        border: 1px solid #d9d9d9; }
      ._1dcQdkbRrAEtzj4dks8WY .content .ant-form .ant-input {
        background: #ffffff !important;
        border: 1px solid #d9d9d9; }
  ._1dcQdkbRrAEtzj4dks8WY .succeed {
    text-align: center; }
    ._1dcQdkbRrAEtzj4dks8WY .succeed img {
      width: 80px; }
    ._1dcQdkbRrAEtzj4dks8WY .succeed p {
      margin-top: 46px;
      margin-bottom: 53px;
      opacity: 1; }
    ._1dcQdkbRrAEtzj4dks8WY .succeed button {
      width: 320px; }
  @media screen and (max-width: 1025px) {
    ._1dcQdkbRrAEtzj4dks8WY header {
      width: 100vw; }
    ._1dcQdkbRrAEtzj4dks8WY .content {
      width: 100vw !important;
      height: calc(100vh - 83px);
      max-width: 100vw;
      margin: 0;
      padding-top: 66px;
      background: #ffffff;
      padding-left: 20px;
      padding-right: 20px; }
      ._1dcQdkbRrAEtzj4dks8WY .content h1 {
        font-size: 24px; }
      ._1dcQdkbRrAEtzj4dks8WY .content p {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 17px; }
      ._1dcQdkbRrAEtzj4dks8WY .content .form-btn {
        text-align: center; }
    ._1dcQdkbRrAEtzj4dks8WY .succeed p {
      border: none;
      padding: 0; } }
  @media screen and (max-width: 767px) {
    ._1dcQdkbRrAEtzj4dks8WY .content {
      padding-top: 15px; } }

@-webkit-keyframes logoPosition {
  0% {
    width: 34px;
    top: 50px;
    margin-left: -87px;
    margin-top: 0; }
  100% {
    width: 80px;
    top: 50%;
    margin-top: -190px;
    margin-left: -35px; } }

@keyframes logoPosition {
  0% {
    width: 34px;
    top: 50px;
    margin-left: -87px;
    margin-top: 0; }
  100% {
    width: 80px;
    top: 50%;
    margin-top: -190px;
    margin-left: -35px; } }

@-webkit-keyframes avatarPosition {
  0% {
    margin-left: -45px; }
  100% {
    margin-left: 48px; } }

@keyframes avatarPosition {
  0% {
    margin-left: -45px; }
  100% {
    margin-left: 48px; } }

.ant-select-dropdown.select-facility .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  height: 40px;
  line-height: 32px;
  text-align: center;
  border-bottom: 1px solid #BAC3CE;
  font-family: Gilroy;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #4C6072; }
  .ant-select-dropdown.select-facility .ant-select-dropdown-menu .ant-select-dropdown-menu-item:hover {
    background: #ffffff; }

.ant-select-dropdown.select-facility .ant-select-dropdown-menu .ant-select-dropdown-menu-item-active {
  background: #ffffff; }

@media screen and (orientation: landscape) {
  .welcome .footer-location, .expert .footer-location {
    display: none; }
  .welcome-text {
    margin-top: 0 !important; } }

._1RXSeMOzy-3w_p7fCMpas5 {
  background: #223064;
  color: #ffffff;
  position: fixed;
  top: 0;
  bottom: 0;
  max-width: 100vw;
  font-family: Gilroy;
  overflow-y: auto; }
  ._1RXSeMOzy-3w_p7fCMpas5 > div {
    padding-bottom: 100px; }
  ._1RXSeMOzy-3w_p7fCMpas5 header {
    padding: 50px 40px; }
    ._1RXSeMOzy-3w_p7fCMpas5 header .return-icon {
      font-size: 26px;
      left: 40px;
      position: absolute; }
    ._1RXSeMOzy-3w_p7fCMpas5 header .center {
      text-align: center; }
      ._1RXSeMOzy-3w_p7fCMpas5 header .center .square-logo {
        width: 34px;
        margin-right: 22px; }
  ._1RXSeMOzy-3w_p7fCMpas5 .footer-location {
    text-align: center;
    font-size: 14px;
    position: fixed;
    width: 100%;
    bottom: 55px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .footer-location .anticon {
      vertical-align: sub;
      margin-right: 10px; }
  ._1RXSeMOzy-3w_p7fCMpas5 .center-section, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    letter-spacing: 0.3px;
    text-align: center; }
    ._1RXSeMOzy-3w_p7fCMpas5 .center-section img, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section img {
      margin-bottom: 24px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .center-section p, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section p {
      font-size: 24px;
      line-height: 28px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .center-section .ant-btn, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .ant-btn {
      width: 260px;
      height: 48px;
      font-size: 16px;
      line-height: 42px;
      color: #2B4B8F;
      background: #FFFFFF;
      box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 89px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities {
      width: 453px;
      height: 52px;
      border: 2px solid #FFFFFF;
      border-radius: 5px;
      margin-top: 42px; }
      ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select-open .ant-select-selection, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select-open .ant-select-selection {
        border-color: #ffffff;
        box-shadow: none; }
      ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select, ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select-selector, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select-selector {
        width: 100%;
        height: 100%; }
        ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select .ant-select-selection, ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select .ant-select-selection__rendered, ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select .ant-select-selection-selected-value, ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select .ant-select-selection-item, ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select-selector .ant-select-selection, ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select-selector .ant-select-selection__rendered, ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select-selector .ant-select-selection-selected-value, ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select-selector .ant-select-selection-item, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select .ant-select-selection, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select .ant-select-selection__rendered, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select .ant-select-selection-selected-value, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select .ant-select-selection-item, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select-selector .ant-select-selection, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select-selector .ant-select-selection__rendered, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select-selector .ant-select-selection-selected-value, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select-selector .ant-select-selection-item {
          width: 100%;
          height: 100%;
          background: transparent;
          color: #ffffff;
          text-align: center;
          line-height: 42px;
          font-family: Gilroy;
          font-size: 16px;
          letter-spacing: 0.3px; }
        ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select .ant-select-arrow, ._1RXSeMOzy-3w_p7fCMpas5 .center-section .check-facilities .ant-select-selector .ant-select-arrow, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select .ant-select-arrow, ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .check-facilities .ant-select-selector .ant-select-arrow {
          color: #ffffff; }
  ._1RXSeMOzy-3w_p7fCMpas5 .normal-section {
    top: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 70px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .normal-section p {
      font-size: 18px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .top-section {
      margin-bottom: 110px; }
      ._1RXSeMOzy-3w_p7fCMpas5 .normal-section .top-section > p {
        font-size: 24px; }
  ._1RXSeMOzy-3w_p7fCMpas5 .location {
    width: 257px;
    margin: 0 auto;
    margin-bottom: 25px;
    padding: 10px 0;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    text-align: center;
    font-size: 14px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .location .anticon {
      vertical-align: sub;
      margin-right: 10px; }
  ._1RXSeMOzy-3w_p7fCMpas5 .welcome-text {
    margin-top: 53px;
    padding-top: 10px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.3px; }
  ._1RXSeMOzy-3w_p7fCMpas5 .check-title {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.3px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .check-title .first-name {
      text-transform: uppercase; }
  ._1RXSeMOzy-3w_p7fCMpas5 button {
    margin: 20px auto;
    width: 260px;
    height: 48px;
    font-size: 16px;
    line-height: 42px;
    color: #2B4B8F; }
  ._1RXSeMOzy-3w_p7fCMpas5 .confirm .confirm-btn-group {
    margin-top: 16px;
    text-align: center; }
    ._1RXSeMOzy-3w_p7fCMpas5 .confirm .confirm-btn-group button {
      width: 260px;
      height: 48px;
      border: 2px solid #FFFFFF;
      box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 89px;
      color: #ffffff; }
      ._1RXSeMOzy-3w_p7fCMpas5 .confirm .confirm-btn-group button:first-child {
        margin-right: 53px; }
  ._1RXSeMOzy-3w_p7fCMpas5 .confirm .confirm-form {
    width: 522px;
    margin: 0 auto; }
  ._1RXSeMOzy-3w_p7fCMpas5 .confirm .confirm-check {
    text-align: center;
    font-size: 12px;
    line-height: 24px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .confirm .confirm-check a {
      color: #ffffff;
      text-decoration: underline;
      font-weight: bold; }
    ._1RXSeMOzy-3w_p7fCMpas5 .confirm .confirm-check .ant-checkbox-wrapper {
      margin-right: 10px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .confirm .confirm-check button {
      margin-top: 66px;
      width: 260px;
      height: 48px;
      font-size: 16px;
      line-height: 42px;
      color: #2B4B8F; }
    ._1RXSeMOzy-3w_p7fCMpas5 .confirm .confirm-check .button-group {
      margin-left: -30px;
      margin-right: -30px; }
      ._1RXSeMOzy-3w_p7fCMpas5 .confirm .confirm-check .button-group button {
        color: #ffffff; }
  ._1RXSeMOzy-3w_p7fCMpas5 .welcome {
    text-align: center; }
    ._1RXSeMOzy-3w_p7fCMpas5 .welcome .check-in-avatar {
      margin-bottom: 20px;
      width: 81px;
      height: 81px;
      border-radius: 100%;
      object-fit: cover; }
    ._1RXSeMOzy-3w_p7fCMpas5 .welcome .check-list {
      width: 622px;
      padding: 0;
      list-style: none;
      margin: 0 auto; }
      ._1RXSeMOzy-3w_p7fCMpas5 .welcome .check-list li {
        display: inline-block;
        width: 182px;
        border: 2px solid #FFFFFF;
        box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 89px;
        height: 48px;
        margin: 0 15px;
        margin-top: 36px;
        font-size: 16px;
        line-height: 42px;
        text-align: center; }
        ._1RXSeMOzy-3w_p7fCMpas5 .welcome .check-list li:nth-child(3n) {
          margin-right: 0; }
        ._1RXSeMOzy-3w_p7fCMpas5 .welcome .check-list li.selected {
          background: #E8ECF0;
          border: 2px solid #FFFFFF;
          color: #3561C0; }
  ._1RXSeMOzy-3w_p7fCMpas5 .expert {
    text-align: center; }
    ._1RXSeMOzy-3w_p7fCMpas5 .expert .expert-list {
      width: 600px;
      margin: 0 auto;
      list-style: none;
      padding: 0;
      text-align: left; }
      ._1RXSeMOzy-3w_p7fCMpas5 .expert .expert-list li {
        display: inline-block;
        margin-bottom: 50px;
        width: 33.33%; }
        ._1RXSeMOzy-3w_p7fCMpas5 .expert .expert-list li .avatar-content {
          width: 82px;
          height: 82px;
          border: 4px solid #FFFFFF;
          border-radius: 100%;
          position: relative;
          margin: 0 auto; }
          ._1RXSeMOzy-3w_p7fCMpas5 .expert .expert-list li .avatar-content img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover; }
          ._1RXSeMOzy-3w_p7fCMpas5 .expert .expert-list li .avatar-content .selected {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(27, 185, 52, 0.5);
            z-index: 1;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: 25px; }
        ._1RXSeMOzy-3w_p7fCMpas5 .expert .expert-list li .expert-name {
          text-align: center;
          margin-top: 10px;
          font-size: 12px;
          text-transform: capitalize; }
  ._1RXSeMOzy-3w_p7fCMpas5 .avatar {
    text-align: center; }
    ._1RXSeMOzy-3w_p7fCMpas5 .avatar .avatar-box {
      width: 328px;
      height: 328px;
      display: inline-block;
      border-radius: 100%;
      border: 1px solid #ffffff;
      overflow: hidden; }
      ._1RXSeMOzy-3w_p7fCMpas5 .avatar .avatar-box img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    ._1RXSeMOzy-3w_p7fCMpas5 .avatar .btn-group button {
      color: #ffffff;
      border-width: 2px; }
      ._1RXSeMOzy-3w_p7fCMpas5 .avatar .btn-group button:last-child {
        margin-left: 50px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .avatar .cancel-take-photo {
      position: fixed;
      z-index: 99;
      left: 40px;
      top: 50px;
      font-size: 26px;
      color: #ffffff; }
    ._1RXSeMOzy-3w_p7fCMpas5 .avatar .react-html5-camera-photo {
      text-align: center;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center; }
  ._1RXSeMOzy-3w_p7fCMpas5 .finish {
    padding: 50px 0;
    text-align: center;
    height: 50%;
    position: relative; }
    ._1RXSeMOzy-3w_p7fCMpas5 .finish .square-logo {
      width: 34px;
      margin-right: 22px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .finish .check-title {
      margin-top: 10px; }
      ._1RXSeMOzy-3w_p7fCMpas5 .finish .check-title.large {
        font-size: 24px;
        padding: 0;
        margin-top: 30px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .finish .avatar-content {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      border: 4px solid #223064;
      display: inline-block;
      margin-left: -45px;
      background: #fff;
      overflow: hidden; }
      ._1RXSeMOzy-3w_p7fCMpas5 .finish .avatar-content img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    ._1RXSeMOzy-3w_p7fCMpas5 .finish > .square-logo {
      width: 34px;
      vertical-align: top;
      position: fixed;
      top: 50px;
      margin-left: -87px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .finish .center {
      position: absolute;
      width: 100%;
      bottom: 0;
      -webkit-transform: translateY(50%);
              transform: translateY(50%); }
      ._1RXSeMOzy-3w_p7fCMpas5 .finish .center button {
        color: #ffffff;
        border-width: 2px; }
    ._1RXSeMOzy-3w_p7fCMpas5 .finish.align {
      display: flex;
      justify-content: center;
      align-items: center; }
      ._1RXSeMOzy-3w_p7fCMpas5 .finish.align .header {
        display: none; }
      ._1RXSeMOzy-3w_p7fCMpas5 .finish.align > .square-logo {
        -webkit-animation: logoPosition 3s;
                animation: logoPosition 3s;
        width: 80px;
        top: 50%;
        margin-left: -35px; }
      ._1RXSeMOzy-3w_p7fCMpas5 .finish.align .avatar-content {
        -webkit-animation: avatarPosition 3s;
                animation: avatarPosition 3s;
        margin-left: 48px; }

.facilities-expert-modal {
  width: 745px !important; }
  .facilities-expert-modal .ant-modal-body {
    padding-top: 43px; }
    .facilities-expert-modal .ant-modal-body .title {
      text-align: left;
      font-family: Gilroy;
      font-size: 32px;
      line-height: 29px; }
    .facilities-expert-modal .ant-modal-body .ant-divider.ant-divider-with-text-left {
      font-family: Gilroy;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: #979797; }
      .facilities-expert-modal .ant-modal-body .ant-divider.ant-divider-with-text-left:before {
        width: 0%; }
    .facilities-expert-modal .ant-modal-body .facility-name {
      font-family: Gilroy;
      padding-left: 10px;
      color: #323F4A;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.3px; }
      .facilities-expert-modal .ant-modal-body .facility-name span {
        margin-right: 15px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: #979797; }
    .facilities-expert-modal .ant-modal-body .available-list {
      padding: 0;
      list-style: none;
      margin: 0 -5px; }
      .facilities-expert-modal .ant-modal-body .available-list li {
        display: inline-block;
        margin: 8px;
        padding: 10px 15px;
        border-radius: 4px;
        background: #EFF0F1;
        font-family: Gilroy;
        font-size: 14px;
        color: #4C6072;
        cursor: pointer; }
        .facilities-expert-modal .ant-modal-body .available-list li.selected {
          background: #3561C0;
          color: #ffffff; }
  .facilities-expert-modal .ant-modal-footer {
    border: none;
    padding-bottom: 34px; }

.experts-modal .ant-checkbox-wrapper {
  display: flex;
  margin: 10px 0;
  align-items: center; }

.experts-modal .experts-list {
  padding-top: 20px; }
  .experts-modal .experts-list .avatar, .experts-modal .experts-list .name {
    display: inline-block;
    vertical-align: middle; }
  .experts-modal .experts-list .avatar {
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    margin-right: 16px;
    overflow: hidden; }
    .experts-modal .experts-list .avatar img {
      max-width: 100%; }

._2Yw8R1CqfZ5dbU4G6Wwwme {
  font-family: Gilroy;
  letter-spacing: 0.3px; }
  ._2Yw8R1CqfZ5dbU4G6Wwwme .time-area {
    font-size: 12px;
    text-transform: capitalize;
    color: #4C6072;
    opacity: 0.8;
    padding: 10px 0; }
  ._2Yw8R1CqfZ5dbU4G6Wwwme .address {
    font-size: 14px;
    color: #52606C;
    padding: 20px 0; }
    ._2Yw8R1CqfZ5dbU4G6Wwwme .address .anticon {
      color: #264382;
      margin-right: 10px; }
  ._2Yw8R1CqfZ5dbU4G6Wwwme .facility {
    background: #ffffff;
    border: 1px solid #E8ECF0;
    border-radius: 4px;
    padding: 15px;
    padding-bottom: 25px;
    margin: 20px 0; }
    ._2Yw8R1CqfZ5dbU4G6Wwwme .facility .title {
      font-size: 18px;
      color: #52606C;
      display: flex;
      justify-content: space-between; }
      ._2Yw8R1CqfZ5dbU4G6Wwwme .facility .title .arrow {
        cursor: pointer; }
    ._2Yw8R1CqfZ5dbU4G6Wwwme .facility .ant-divider-with-text-left {
      color: #BAC3CE;
      font-size: 12px;
      margin: 0 -10px; }
      ._2Yw8R1CqfZ5dbU4G6Wwwme .facility .ant-divider-with-text-left:before {
        width: 0 !important; }
    ._2Yw8R1CqfZ5dbU4G6Wwwme .facility .services {
      display: flex;
      justify-content: space-between;
      padding-top: 20px; }
      ._2Yw8R1CqfZ5dbU4G6Wwwme .facility .services .list {
        padding: 0;
        list-style: none; }
        ._2Yw8R1CqfZ5dbU4G6Wwwme .facility .services .list li {
          display: inline-block;
          padding: 5px 10px;
          text-align: center;
          min-width: 100px;
          margin-right: 10px;
          background: #E8ECF0;
          border-radius: 33px;
          font-size: 12px;
          color: #323F4A; }
          ._2Yw8R1CqfZ5dbU4G6Wwwme .facility .services .list li:last-child {
            margin-right: 0; }
      ._2Yw8R1CqfZ5dbU4G6Wwwme .facility .services .others {
        color: #264382;
        padding: 5px; }
  ._2Yw8R1CqfZ5dbU4G6Wwwme .service .service-empty {
    font-family: Lato;
    font-weight: bold;
    font-size: 14px;
    line-height: 29px;
    text-transform: capitalize;
    color: #323F4A;
    letter-spacing: 0.3px;
    margin-top: 17px; }
  ._2Yw8R1CqfZ5dbU4G6Wwwme .service .facility-info {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #BAC3CE; }
    ._2Yw8R1CqfZ5dbU4G6Wwwme .service .facility-info .time-area, ._2Yw8R1CqfZ5dbU4G6Wwwme .service .facility-info .address {
      display: inline-block;
      margin-right: 50px; }
    ._2Yw8R1CqfZ5dbU4G6Wwwme .service .facility-info .opr {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 29px;
      height: 61px;
      color: #264382;
      font-weight: bold;
      cursor: pointer; }
      ._2Yw8R1CqfZ5dbU4G6Wwwme .service .facility-info .opr .del {
        color: #FF0000;
        margin-right: 30px; }
  ._2Yw8R1CqfZ5dbU4G6Wwwme .service .service-item {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-top: 10px solid #BAC3CE;
    padding: 10px 20px;
    margin-top: 20px;
    padding-bottom: 20px; }
    ._2Yw8R1CqfZ5dbU4G6Wwwme .service .service-item .title {
      line-height: 29px;
      text-transform: capitalize;
      color: #52606C;
      font-weight: bold;
      display: flex;
      justify-content: space-between; }
      ._2Yw8R1CqfZ5dbU4G6Wwwme .service .service-item .title span {
        padding: 10px 0;
        font-size: 14px; }
      ._2Yw8R1CqfZ5dbU4G6Wwwme .service .service-item .title .anticon {
        color: #264382;
        vertical-align: bottom;
        font-size: 18px;
        margin: 0 7px;
        cursor: pointer; }
    ._2Yw8R1CqfZ5dbU4G6Wwwme .service .service-item .duration {
      color: #BAC3CE;
      font-size: 12px;
      margin-bottom: 20px; }
    ._2Yw8R1CqfZ5dbU4G6Wwwme .service .service-item .expert {
      display: flex;
      margin: 10px 0; }
      ._2Yw8R1CqfZ5dbU4G6Wwwme .service .service-item .expert .avatar {
        width: 32px;
        height: 32px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 100%;
        margin-right: 16px;
        overflow: hidden; }
        ._2Yw8R1CqfZ5dbU4G6Wwwme .service .service-item .expert .avatar img {
          max-width: 100%; }
      ._2Yw8R1CqfZ5dbU4G6Wwwme .service .service-item .expert .name {
        font-size: 12px;
        line-height: 29px;
        text-transform: capitalize;
        color: #000000; }

.recommened-content {
  font-family: Gilroy; }
  .recommened-content .catergory-filter span.tag {
    font-size: 12px;
    color: #8D99A5;
    margin: 8px 14px 0 0; }
  .recommened-content .catergory-filter .catergory-sel {
    width: 134px;
    height: 29px;
    border-radius: 4px; }
    .recommened-content .catergory-filter .catergory-sel .ant-select-selection {
      border: none; }
  .recommened-content .content-cards {
    margin: 41px -17px 0;
    min-width: 1034px;
    display: flex;
    flex-wrap: wrap; }
    .recommened-content .content-cards .add-new-card {
      width: 237px;
      height: 244px;
      background: #E8ECF0;
      border-radius: 4px;
      position: relative;
      margin: 0 8px;
      cursor: pointer; }
      .recommened-content .content-cards .add-new-card img, .recommened-content .content-cards .add-new-card span {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .recommened-content .content-cards .add-new-card span {
        top: 72%;
        width: 100%;
        text-align: center;
        color: #52606C; }

.modal-recommend {
  width: 890px;
  height: 438px; }
  .modal-recommend .ant-form .ant-form-item {
    height: 53px; }
  .modal-recommend .ant-modal-content {
    border-radius: 8px;
    font-family: Gilroy;
    font-weight: 500; }
    .modal-recommend .ant-modal-content .ant-modal-body {
      padding: 51px;
      position: relative; }
      .modal-recommend .ant-modal-content .ant-modal-body .ant-legacy-form input#title, .modal-recommend .ant-modal-content .ant-modal-body .ant-legacy-form input#description {
        padding-right: 145px; }
      .modal-recommend .ant-modal-content .ant-modal-body .body-title {
        margin-bottom: 55px;
        font-size: 32px;
        line-height: 29px;
        color: #4C6072; }
      .modal-recommend .ant-modal-content .ant-modal-body .min-tip {
        position: absolute;
        top: 223px;
        right: 67px;
        color: #BAC3CE;
        font-size: 16px;
        line-height: 23px; }
      .modal-recommend .ant-modal-content .ant-modal-body .character-tip {
        position: absolute;
        top: 378px;
        right: 65px;
        color: #BAC3CE;
        font-size: 16px;
        line-height: 23px; }
        .modal-recommend .ant-modal-content .ant-modal-body .character-tip.description-tip {
          top: 455px; }
        .modal-recommend .ant-modal-content .ant-modal-body .character-tip.copy-tip {
          top: 298px;
          color: #3561C0;
          cursor: pointer;
          background: #fff;
          padding-left: 10px;
          display: inline-block; }
      .modal-recommend .ant-modal-content .ant-modal-body .over-view-con .title {
        color: #8D99A5;
        font-size: 12px;
        padding-left: 10px; }
      .modal-recommend .ant-modal-content .ant-modal-body .over-view-con .card-infos {
        display: flex; }
        .modal-recommend .ant-modal-content .ant-modal-body .over-view-con .card-infos .right-info {
          padding: 50px 38px; }
          .modal-recommend .ant-modal-content .ant-modal-body .over-view-con .card-infos .right-info .media-switch {
            width: 208px;
            height: 59px;
            border-radius: 4px;
            border: 2px solid #E8ECF0;
            display: flex;
            position: relative;
            justify-content: space-between;
            align-items: center;
            padding: 6px 18px; }
            .modal-recommend .ant-modal-content .ant-modal-body .over-view-con .card-infos .right-info .media-switch .out-title {
              position: absolute;
              background: #ffffff;
              color: #8D99A5;
              top: -12px;
              left: 22px;
              font-size: 12px; }
            .modal-recommend .ant-modal-content .ant-modal-body .over-view-con .card-infos .right-info .media-switch div {
              color: #E8ECF0;
              font-size: 14px;
              line-height: 47px;
              width: 66px;
              text-align: center;
              flex-shrink: 0;
              margin: 0;
              cursor: pointer; }
              .modal-recommend .ant-modal-content .ant-modal-body .over-view-con .card-infos .right-info .media-switch div.active {
                color: #3561C0; }
            .modal-recommend .ant-modal-content .ant-modal-body .over-view-con .card-infos .right-info .media-switch i {
              width: 0;
              height: 26px;
              border-right: 2px solid #E8ECF0; }
          .modal-recommend .ant-modal-content .ant-modal-body .over-view-con .card-infos .right-info .change-cover {
            margin-top: 30px;
            color: #3561C0;
            font-size: 16px;
            cursor: pointer; }
            .modal-recommend .ant-modal-content .ant-modal-body .over-view-con .card-infos .right-info .change-cover span {
              padding: 8px 12px; }
          .modal-recommend .ant-modal-content .ant-modal-body .over-view-con .card-infos .right-info .img-tip {
            color: #52606C;
            font-size: 14px;
            margin-top: 14px; }
    .modal-recommend .ant-modal-content .ant-modal-footer {
      border: none;
      padding: 20px 53px 44px; }
      .modal-recommend .ant-modal-content .ant-modal-footer .ant-btn {
        width: 155px;
        height: 48px;
        border: none;
        color: #4C6072;
        box-shadow: none; }
      .modal-recommend .ant-modal-content .ant-modal-footer .ant-btn-primary {
        color: #ffffff; }

.modal-recommend .content-card, .recommened-content .content-card {
  width: 237px;
  margin: 0 8px 17px; }
  .modal-recommend .content-card .card-info, .recommened-content .content-card .card-info {
    position: relative;
    padding-top: 114px;
    background: #ffffff;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0px 2px 8px #E8ECF0; }
    .modal-recommend .content-card .card-info img, .recommened-content .content-card .card-info img {
      display: block;
      width: 100%;
      height: auto;
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      left: 50%;
      top: 23%; }
    .modal-recommend .content-card .card-info .category, .recommened-content .content-card .card-info .category {
      position: absolute;
      left: 18px;
      top: 14px;
      text-align: center;
      background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);
      border-radius: 44px;
      padding: 0 13px;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 1.5; }
      .modal-recommend .content-card .card-info .category.nutrition, .recommened-content .content-card .card-info .category.nutrition {
        color: #7BAD2D; }
      .modal-recommend .content-card .card-info .category.rejuvenation, .recommened-content .content-card .card-info .category.rejuvenation {
        color: #3561C0; }
      .modal-recommend .content-card .card-info .category.exercise, .recommened-content .content-card .card-info .category.exercise {
        color: #FF6B00; }
    .modal-recommend .content-card .card-info .sub-con, .recommened-content .content-card .card-info .sub-con {
      background: #ffffff;
      padding: 12px 18px 15px;
      position: relative;
      height: 146px;
      z-index: 2;
      letter-spacing: 0.3px;
      border-top: 1px solid #E8ECF0; }
      .modal-recommend .content-card .card-info .sub-con .sub-category, .recommened-content .content-card .card-info .sub-con .sub-category {
        letter-spacing: 0.3px;
        font-size: 12px;
        line-height: 150%;
        text-transform: capitalize; }
        .modal-recommend .content-card .card-info .sub-con .sub-category.nutrition, .recommened-content .content-card .card-info .sub-con .sub-category.nutrition {
          color: #7BAD2D; }
        .modal-recommend .content-card .card-info .sub-con .sub-category.rejuvenation, .recommened-content .content-card .card-info .sub-con .sub-category.rejuvenation {
          color: #3561C0; }
        .modal-recommend .content-card .card-info .sub-con .sub-category.exercise, .recommened-content .content-card .card-info .sub-con .sub-category.exercise {
          color: #FF6B00; }
      .modal-recommend .content-card .card-info .sub-con .title, .recommened-content .content-card .card-info .sub-con .title {
        color: #383E48;
        font-size: 14px;
        max-height: 48px;
        line-height: 1.2;
        overflow: hidden; }
      .modal-recommend .content-card .card-info .sub-con .description, .recommened-content .content-card .card-info .sub-con .description {
        margin-top: 7px;
        color: #52606C;
        font-size: 12px;
        max-height: 42px;
        line-height: 1.2;
        overflow: hidden; }
      .modal-recommend .content-card .card-info .sub-con .minutes, .recommened-content .content-card .card-info .sub-con .minutes {
        position: absolute;
        bottom: 15px;
        left: 18px;
        color: #BAC3CE;
        font-size: 12px;
        line-height: 1;
        margin-top: 7px; }
  .modal-recommend .content-card .to-remove, .recommened-content .content-card .to-remove {
    background: #FF6B00;
    color: #ffffff;
    border-radius: 4px;
    height: 37px;
    margin-top: 5px;
    line-height: 35px;
    text-align: center;
    cursor: pointer; }

.status-drop-down {
  width: 151px !important; }
  .status-drop-down .status-circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 8px;
    display: inline-block;
    background: gray; }
    .status-drop-down .status-circle.draft {
      background: #F7C137; }
    .status-drop-down .status-circle.done {
      background: #7BAD2D; }

._2r3Cbqq993BeUzm1jg7hbc {
  font-family: Gilroy;
  margin-top: 40px;
  padding-bottom: 20px; }
  ._2r3Cbqq993BeUzm1jg7hbc .ant-table-thead > tr > th {
    padding-left: 8px;
    font-size: 12px;
    background: transparent;
    color: #5C626A;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    opacity: 0.8;
    border-bottom: none; }
    ._2r3Cbqq993BeUzm1jg7hbc .ant-table-thead > tr > th:first-child {
      padding-left: 24px; }
    ._2r3Cbqq993BeUzm1jg7hbc .ant-table-thead > tr > th:last-child {
      text-align: right;
      padding-right: 24px; }
  ._2r3Cbqq993BeUzm1jg7hbc .ant-table-tbody > tr > td {
    padding: 16px 8px;
    word-wrap: break-word;
    word-break: break-word;
    border-bottom-color: #F4F6F8;
    font-size: 14px;
    color: #4C6072;
    background: #ffffff; }
    ._2r3Cbqq993BeUzm1jg7hbc .ant-table-tbody > tr > td:first-child {
      border-radius: 12px 0 0 12px;
      color: #323F4A;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      font-family: 'Gilroy-bold';
      padding-left: 24px; }
    ._2r3Cbqq993BeUzm1jg7hbc .ant-table-tbody > tr > td:last-child {
      border-radius: 0 12px 12px 0;
      text-align: right;
      padding-right: 24px; }
    ._2r3Cbqq993BeUzm1jg7hbc .ant-table-tbody > tr > td .name-container {
      width: 180px;
      cursor: pointer;
      word-break: break-word; }
      ._2r3Cbqq993BeUzm1jg7hbc .ant-table-tbody > tr > td .name-container:hover {
        color: #1890ff; }
  ._2r3Cbqq993BeUzm1jg7hbc .status-circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 8px;
    display: inline-block;
    background: gray; }
    ._2r3Cbqq993BeUzm1jg7hbc .status-circle.Sent, ._2r3Cbqq993BeUzm1jg7hbc .status-circle.Draft, ._2r3Cbqq993BeUzm1jg7hbc .status-circle.draft {
      background: #F7C137; }
    ._2r3Cbqq993BeUzm1jg7hbc .status-circle.Opened, ._2r3Cbqq993BeUzm1jg7hbc .status-circle.done {
      background: #7BAD2D; }
  ._2r3Cbqq993BeUzm1jg7hbc .ant-select .ant-select-selection__rendered {
    margin-left: 0; }
  ._2r3Cbqq993BeUzm1jg7hbc .ant-select .ant-select-selection {
    border: none;
    background-color: transparent; }
  ._2r3Cbqq993BeUzm1jg7hbc .ant-select .ant-select-focused .ant-select-selection, ._2r3Cbqq993BeUzm1jg7hbc .ant-select .ant-select-selection:focus, ._2r3Cbqq993BeUzm1jg7hbc .ant-select .ant-select-selection:active {
    border-color: transparent;
    box-shadow: none; }
  ._2r3Cbqq993BeUzm1jg7hbc .ant-select .ant-select-arrow {
    color: #4C6072; }

._2UdY31vW79H2mH5ek62hdD {
  position: relative;
  margin-top: -30px;
  font-family: Gilroy;
  letter-spacing: 0.3px;
  min-height: calc(100vh - 145px); }
  @media screen and (max-width: 1280px) {
    ._2UdY31vW79H2mH5ek62hdD {
      margin-left: -20px;
      margin-right: -30px; } }
  ._2UdY31vW79H2mH5ek62hdD .date-range {
    display: flex;
    justify-content: flex-end; }
  ._2UdY31vW79H2mH5ek62hdD .dashboard-container {
    margin-top: 40px;
    background: #FFFFFF;
    border: 1px solid #DEE2EC;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 32px;
    padding-right: 0;
    display: flex;
    justify-content: space-between; }
    ._2UdY31vW79H2mH5ek62hdD .dashboard-container .dashboard-info {
      width: 100%;
      color: #383E48; }
      ._2UdY31vW79H2mH5ek62hdD .dashboard-container .dashboard-info span {
        margin-bottom: 10px;
        color: #60656D;
        font-size: 12px;
        line-height: 150%;
        display: block;
        font-weight: normal;
        font-family: Gilroy; }
      ._2UdY31vW79H2mH5ek62hdD .dashboard-container .dashboard-info .large-text {
        font-size: 40px;
        line-height: 124%;
        font-family: 'Gilroy-bold';
        width: 20%;
        display: inline-block; }
      ._2UdY31vW79H2mH5ek62hdD .dashboard-container .dashboard-info .normal-text {
        font-size: 18px;
        line-height: 124%;
        font-family: 'Gilroy-bold'; }
    ._2UdY31vW79H2mH5ek62hdD .dashboard-container .chart {
      width: 56%; }
  ._2UdY31vW79H2mH5ek62hdD .chart-tips {
    text-align: right;
    font-size: 12px;
    padding-right: 32px; }
    ._2UdY31vW79H2mH5ek62hdD .chart-tips > span {
      margin-left: 20px; }
    ._2UdY31vW79H2mH5ek62hdD .chart-tips .tip {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      margin-right: 4px; }
    ._2UdY31vW79H2mH5ek62hdD .chart-tips .sent {
      background: #264382; }
    ._2UdY31vW79H2mH5ek62hdD .chart-tips .opened {
      background: #7BAD2D; }
  ._2UdY31vW79H2mH5ek62hdD .notes-modal-bg {
    margin-right: -44px;
    margin-top: -72px;
    margin-left: -344px; }

._3URd85slGeIJia5KYpv6Cx {
  font-family: Gilroy; }
  ._3URd85slGeIJia5KYpv6Cx .ant-modal-content {
    border-radius: 8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1); }
  ._3URd85slGeIJia5KYpv6Cx .ant-modal-body {
    padding: 32px; }
  ._3URd85slGeIJia5KYpv6Cx .title {
    font-size: 24px;
    color: #4C6072;
    padding-bottom: 40px; }
  ._3URd85slGeIJia5KYpv6Cx .ant-modal-footer {
    border: none;
    padding-bottom: 32px; }
    ._3URd85slGeIJia5KYpv6Cx .ant-modal-footer footer.flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px; }
    ._3URd85slGeIJia5KYpv6Cx .ant-modal-footer .ant-btn-background-ghost {
      border: none;
      box-shadow: none; }

._19HNK8TmSp5XaIxkYRcmIv .ant-select {
  text-transform: capitalize; }

._19HNK8TmSp5XaIxkYRcmIv .catergory-filter {
  margin-bottom: 30px; }
  ._19HNK8TmSp5XaIxkYRcmIv .catergory-filter .tag {
    font-size: 12px;
    color: #8D99A5;
    margin: 8px 14px 0 0; }
  ._19HNK8TmSp5XaIxkYRcmIv .catergory-filter .catergory-sel {
    width: 134px;
    height: 29px;
    border-radius: 4px; }

._19HNK8TmSp5XaIxkYRcmIv .template-card {
  min-height: 244px;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 2px 8px #E8ECF0; }
  ._19HNK8TmSp5XaIxkYRcmIv .template-card.add {
    border: 2px dashed #000000;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #52606C; }
    ._19HNK8TmSp5XaIxkYRcmIv .template-card.add .ant-card-body {
      text-align: center;
      width: 100%; }
      ._19HNK8TmSp5XaIxkYRcmIv .template-card.add .ant-card-body div {
        margin-top: 5px; }
  ._19HNK8TmSp5XaIxkYRcmIv .template-card .ant-card-body {
    padding: 16px; }
  ._19HNK8TmSp5XaIxkYRcmIv .template-card .category {
    font-size: 9px;
    line-height: 140%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #70808E; }
  ._19HNK8TmSp5XaIxkYRcmIv .template-card .sub-category {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.3px;
    color: #323F4A;
    padding: 10px 0;
    text-transform: capitalize; }
  ._19HNK8TmSp5XaIxkYRcmIv .template-card .template {
    font-size: 12px;
    color: #70808E;
    line-height: 17px; }

.table-filter-container {
    display: flex;
    flex-direction: column;
}

.filter-scrollable-area {
    flex: 1 1;
    overflow-y: scroll;
    padding: 8px;
}

.select-all-option {
    margin-bottom: 6px !important;
    font-weight: bold;
}

.filter-values{
    margin-bottom: 4;
}

.filter-footer {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-top: 1px solid #f0f0f0;
}

.reset-button {
    width: 48%;
    border: none !important;
}

.submit-button {
    width: 40%;
}
._1X5Hc1yhWa7z-TQ3WI7efR .left, ._1X5Hc1yhWa7z-TQ3WI7efR .right {
  display: inline-block;
  vertical-align: top;
  text-align: left; }

._1X5Hc1yhWa7z-TQ3WI7efR .left {
  width: 280px;
  margin-right: 26px; }
  ._1X5Hc1yhWa7z-TQ3WI7efR .left > div {
    height: auto; }

._1X5Hc1yhWa7z-TQ3WI7efR .right {
  overflow: auto;
  width: calc(100% - 280px - 26px);
  min-height: calc(100vh - 71px);
  padding-right: 44px;
  padding-left: 38px;
  padding-top: 45px;
  margin-top: -37px;
  background: #F4F6F8;
  box-shadow: -1px 4px 20px rgba(0, 0, 0, 0.03); }
  ._1X5Hc1yhWa7z-TQ3WI7efR .right .page-title {
    font-family: Gilroy;
    line-height: 120%;
    font-size: 32px;
    color: #4C6072;
    padding-bottom: 20px; }

._1ZVf7WOhAM1qrIBjheo4uh .information-container.Opened {
  border: none; }

._1ZVf7WOhAM1qrIBjheo4uh .nutrition-score-container {
  margin-top: 0;
  border-radius: 0; }
  ._1ZVf7WOhAM1qrIBjheo4uh .nutrition-score-container img {
    width: 240%;
    right: -50%; }
  ._1ZVf7WOhAM1qrIBjheo4uh .nutrition-score-container .chart-container {
    width: calc(50% + 100px + 300px);
    margin-left: -350px; }
  ._1ZVf7WOhAM1qrIBjheo4uh .nutrition-score-container .information-container.Opened .content {
    color: #E7E9EC; }

._1ZVf7WOhAM1qrIBjheo4uh .section-container {
  padding: 20px; }
  ._1ZVf7WOhAM1qrIBjheo4uh .section-container section {
    display: block; }
  ._1ZVf7WOhAM1qrIBjheo4uh .section-container .score-left {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #E3E6EA; }
  ._1ZVf7WOhAM1qrIBjheo4uh .section-container .score-right {
    width: 100%; }
  ._1ZVf7WOhAM1qrIBjheo4uh .section-container .item-list .item {
    width: 100%;
    border-right: none; }
  ._1ZVf7WOhAM1qrIBjheo4uh .section-container .information-container.Opened {
    background: rgba(38, 67, 130, 0.05); }

._1ZVf7WOhAM1qrIBjheo4uh img[alt="empty"] {
  max-width: 55%; }

._2MEBMEUGiTtoDwA92cZYbW {
  background: #ffffff;
  padding-top: 30px; }
  ._2MEBMEUGiTtoDwA92cZYbW .health-scroe-container {
    padding: 0px; }
    ._2MEBMEUGiTtoDwA92cZYbW .health-scroe-container .compare-labels {
      margin-top: -20px; }
    ._2MEBMEUGiTtoDwA92cZYbW .health-scroe-container .health-card-conatiner {
      padding-left: 20px;
      padding-right: 20px; }
      ._2MEBMEUGiTtoDwA92cZYbW .health-scroe-container .health-card-conatiner .ant-card-meta-description {
        padding: 0;
        margin: 0; }
    ._2MEBMEUGiTtoDwA92cZYbW .health-scroe-container .spider-graph-container {
      width: 100%; }
    ._2MEBMEUGiTtoDwA92cZYbW .health-scroe-container .info-list {
      padding: 0 25px; }

._1ZwGRw3fNqYFXYSWyqps_p {
  font-family: Gilroy;
  background: #F4F6F8; }
  ._1ZwGRw3fNqYFXYSWyqps_p.backup-style .section-container > h3 {
    margin: 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -23px;
    padding: 18px 32px;
    font-size: 24px; }
  ._1ZwGRw3fNqYFXYSWyqps_p.backup-style .information-container.Opened {
    border: 1px solid #70808E;
    margin: 16px 0 32px 0; }
  ._1ZwGRw3fNqYFXYSWyqps_p img {
    max-width: 100%;
    width: 100%; }
  ._1ZwGRw3fNqYFXYSWyqps_p .section-container {
    background: #ffffff;
    padding: 20px; }
    ._1ZwGRw3fNqYFXYSWyqps_p .section-container > h3 {
      margin: 0;
      margin-bottom: 24px;
      padding: 14px 25px;
      font-size: 18px;
      font-family: Gilroy-Bold;
      color: #ffffff;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      ._1ZwGRw3fNqYFXYSWyqps_p .section-container > h3 svg {
        cursor: pointer;
        display: none; }
      ._1ZwGRw3fNqYFXYSWyqps_p .section-container > h3:hover svg {
        display: block; }
      ._1ZwGRw3fNqYFXYSWyqps_p .section-container > h3.Overview {
        color: #383E48;
        padding: 0;
        margin: 0; }
      ._1ZwGRw3fNqYFXYSWyqps_p .section-container > h3.Rejuvenation {
        background: #5C00D1; }
      ._1ZwGRw3fNqYFXYSWyqps_p .section-container > h3.Exercise {
        background: #FF6B00; }
      ._1ZwGRw3fNqYFXYSWyqps_p .section-container > h3.Nutrition {
        background: #7BAD2D; }
      ._1ZwGRw3fNqYFXYSWyqps_p .section-container > h3.Weight {
        background: #3194EB; }
    ._1ZwGRw3fNqYFXYSWyqps_p .section-container h4.sub-title {
      margin-top: 27px;
      margin-bottom: 16px;
      font-family: "Gilroy-Bold";
      font-weight: 800;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #383E48; }
  ._1ZwGRw3fNqYFXYSWyqps_p .goal-name {
    font-family: Gilroy-Bold;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #383e48;
    padding-bottom: 10px; }
  ._1ZwGRw3fNqYFXYSWyqps_p .extra-information {
    padding: 11px 16px;
    padding-bottom: 0;
    margin: 16px 0;
    border: 1px solid #E4EAEF;
    border-radius: 8px;
    font-size: 12px;
    line-height: 17px; }
    ._1ZwGRw3fNqYFXYSWyqps_p .extra-information h5 {
      font-size: 12px;
      font-family: Gilroy-Bold;
      color: #383E48; }
    ._1ZwGRw3fNqYFXYSWyqps_p .extra-information p {
      color: #70808E; }
    ._1ZwGRw3fNqYFXYSWyqps_p .extra-information .information-container {
      padding: 5px 0;
      background: transparent; }
  ._1ZwGRw3fNqYFXYSWyqps_p .summary-date {
    font-family: Gilroy-Bold;
    font-size: 12px;
    color: #70808E;
    text-transform: uppercase;
    margin-bottom: 10px; }
  ._1ZwGRw3fNqYFXYSWyqps_p .title {
    color: #383e48;
    padding-bottom: 0;
    padding-top: 0; }
  ._1ZwGRw3fNqYFXYSWyqps_p .extra-info {
    margin-top: 10px;
    background: rgba(244, 245, 247, 0.5);
    border: 1px solid #70808e;
    color: #383e48;
    min-height: 50px;
    display: flex;
    text-align: left;
    font-family: Gilroy;
    font-size: 14px;
    color: #70808e; }
    ._1ZwGRw3fNqYFXYSWyqps_p .extra-info .avatar {
      color: #479fec;
      font-size: 16px;
      padding-right: 16px; }
  ._1ZwGRw3fNqYFXYSWyqps_p .profile-container {
    margin-top: 16px;
    padding-bottom: 0; }
    ._1ZwGRw3fNqYFXYSWyqps_p .profile-container .profile-info {
      width: 100%; }
    ._1ZwGRw3fNqYFXYSWyqps_p .profile-container .Opened {
      width: 100%; }
      ._1ZwGRw3fNqYFXYSWyqps_p .profile-container .Opened:first-child {
        margin-bottom: 16px; }

.Q09q7fsVRjUvgHuqf95JN {
  max-width: 750px !important;
  margin: 0 auto; }

@counter-style brackets-latin {
  prefix: '(';
  suffix: ')';
  system: extends lower-latin; }

@counter-style brackets-roman {
  prefix: '(';
  suffix: ')';
  system: extends lower-roman; }

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #F1F3F6;
  height: -webkit-fill-available; }

#root, .App {
  min-height: 100%;
  width: 100%;
  margin: 0 auto;
  font-family: Gilroy,Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style: normal;
  font-weight: 500;
  background: #F1F3F6; }

.App {
  max-width: 1440px; }
  .App > div {
    width: 100%;
    min-height: 100vh; }
    .App > div > div {
      width: 1440px;
      max-width: 100%; }

/* Attention!!! Don't change this attribute above, will effect window scroll(react-scroll) feature : height, overflow, min-height */
.mobile-show {
  display: none !important; }

.desktop-show {
  display: block !important; }

.footer {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  color: #5B616B;
  opacity: 0.4; }

button > img {
  margin-right: 10px; }

.x-hellosign-embedded.x-hellosign-embedded--in-modal {
  z-index: 99; }

.header-opr .ant-dropdown-menu-item {
  font-family: Gilroy;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.3px;
  font-weight: 500;
  color: #264382; }
  .header-opr .ant-dropdown-menu-item .anticon {
    margin-right: 15px; }

.ant-tooltip-inner {
  font-family: Gilroy;
  font-size: 14px; }
  .ant-tooltip-inner h3 {
    font-family: 'Gilroy-bold';
    color: #ffffff; }

.ant-tabs-nav-list .ant-tabs-tab {
  padding: 12px 16px;
  margin: 0 16px 0 0; }
  .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    color: rgba(0, 0, 0, 0.65); }

.ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #264382; }

.ant-tabs-nav-list .ant-tabs-ink-bar {
  background-color: #264382; }

@media screen and (max-width: 750px) {
  #root,
  .App {
    max-width: 100%;
    min-width: auto;
    overflow: hidden; }
  .App > div {
    min-height: 100vh; }
    .App > div > div {
      width: 100vw; }
  .desktop-show {
    display: none !important; }
  .mobile-show {
    display: block !important; }
  .modal-report-file-detail.has-mobile-view {
    min-width: unset;
    width: 100% !important; }
    .modal-report-file-detail.has-mobile-view video, .modal-report-file-detail.has-mobile-view img {
      width: 100%; } }

.phs-footer {
  background: #ffffff;
  height: 62px;
  line-height: 62px;
  text-align: center;
  color: #98A9BC;
  font-size: 14px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid #EAEDF3; }

.phs-pointer {
  cursor: pointer; }

.ant-layout.ant-layout-has-sider {
  height: 100%; }
  .ant-layout.ant-layout-has-sider .ant-layout-sider {
    overflow: auto; }
  .ant-layout.ant-layout-has-sider .ant-layout-content {
    overflow-y: auto; }

.video-modal .ant-modal-body {
  padding: 0; }
  .video-modal .ant-modal-body video {
    width: 100%;
    display: block; }

.video-modal .ant-modal-footer {
  display: none; }

.table-action-split {
  margin: 0 10px; }

span.as-link {
  color: #264382;
  cursor: pointer;
  padding: 3px; }
  span.as-link.disabled {
    opacity: 0.25;
    cursor: not-allowed; }

.ant-modal-content .ant-modal-header, .ant-modal-content .ant-modal-footer {
  border: none;
  padding: 20px 40px; }

.ant-modal-content .ant-modal-header {
  padding-top: 40px; }
  .ant-modal-content .ant-modal-header .ant-modal-title {
    font-size: 24px; }

.ant-modal-content .ant-modal-footer {
  padding-bottom: 40px; }

.modal-report-file-detail {
  width: auto !important;
  min-width: 520px;
  text-align: center;
  display: inline-block;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%; }
  .modal-report-file-detail img, .modal-report-file-detail video {
    max-width: 1220px; }

.modal-only-read .ant-modal-footer {
  display: none; }

.page-loading {
  text-align: center;
  font-size: 5em; }

.phs-section {
  background: #ffffff;
  border: 1px solid #EAEDF3;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 30px; }
  .phs-section .phs-section-header {
    display: flex;
    border-bottom: 1px solid #EAEDF3;
    padding: 25px 30px;
    height: 106px; }
    .phs-section .phs-section-header .title {
      color: #B1B1B1;
      font-size: 12px;
      line-height: 29px; }
      .phs-section .phs-section-header .title h2 {
        color: #3F4752;
        font-size: 18px;
        line-height: 29px;
        font-weight: bold; }
      .phs-section .phs-section-header .title p {
        text-transform: uppercase; }
  .phs-section .phs-section-body {
    overflow: auto;
    padding: 25px 30px; }

.avatar-small {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: rgba(38, 67, 130, 0.1); }

.phs-button-outline {
  width: 120px;
  height: 47px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #264382;
  border: 2px solid #264382;
  border-radius: 36px; }

.page-loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 2; }
  .page-loading-modal .anticon-loading {
    color: #ffffff;
    position: absolute;
    top: 50%;
    margin-top: -35px; }

.ant-btn-primary {
  background: #2B4B8F;
  border-color: transparent; }
  .ant-btn-primary.ant-btn-background-ghost {
    color: #2B4B8F;
    border-color: #2B4B8F; }

.ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: #45609b;
  background: #45609b; }

.ant-btn-none {
  border: none;
  box-shadow: none; }

.ant-legacy-form, .ant-legacy-form {
  padding: 0 5px; }
  .ant-legacy-form .gray-bg, .ant-legacy-form .gray-bg {
    background: rgba(229, 231, 234, 0.3);
    color: rgba(76, 96, 114, 0.5);
    padding: 2px 20px;
    border-radius: 4px;
    height: 44px; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important; }

.ant-legacy-form-item, .ant-select-disabled .ant-select-selection, .ant-select-disabled .ant-select-selector {
  background: transparent; }
  .ant-legacy-form-item .ant-input[disabled], .ant-select-disabled .ant-select-selection .ant-input[disabled], .ant-select-disabled .ant-select-selector .ant-input[disabled] {
    background: transparent; }

.ant-input {
  font-weight: 500; }

.ant-picker-input > input {
  font-weight: 500; }

.ant-select-selection-item {
  font-weight: 500; }

.ant-input-number-input {
  font-weight: 500; }

.ant-input-number {
  box-sizing: content-box; }

.ant-legacy-form-item, .ant-form-item {
  position: relative;
  text-align: left; }
  .ant-legacy-form-item .ant-legacy-form-item-label, .ant-form-item .ant-legacy-form-item-label {
    position: absolute;
    z-index: 1;
    background: #ffffff;
    top: 0;
    left: 10px;
    margin-top: -18px;
    height: 30px; }
    .ant-legacy-form-item .ant-legacy-form-item-label label, .ant-form-item .ant-legacy-form-item-label label {
      color: #8D99A5;
      font-size: 12px;
      letter-spacing: 0.4px;
      text-transform: uppercase; }
  .ant-legacy-form-item .has-error .ant-input-number, .ant-form-item .has-error .ant-input-number {
    box-sizing: content-box; }
  .ant-legacy-form-item .has-error .ant-input-affix-wrapper .ant-input, .ant-legacy-form-item .has-error .multi-tag-wapper, .ant-form-item .has-error .ant-input-affix-wrapper .ant-input, .ant-form-item .has-error .multi-tag-wapper {
    border-color: #FF0000;
    color: #FF0000; }
  .ant-legacy-form-item .ant-legacy-form-item-control.has-error, .ant-form-item .ant-legacy-form-item-control.has-error {
    margin-bottom: 20px; }
  .ant-legacy-form-item .ant-legacy-form-item-control-wrapper input, .ant-form-item .ant-legacy-form-item-control-wrapper input {
    height: 45px; }
  .ant-legacy-form-item .ant-input-affix-wrapper, .ant-form-item .ant-input-affix-wrapper {
    height: 45px;
    top: -8px; }
    .ant-legacy-form-item .ant-input-affix-wrapper input, .ant-form-item .ant-input-affix-wrapper input {
      height: 39px; }
    .ant-legacy-form-item .ant-input-affix-wrapper .ant-input-suffix, .ant-form-item .ant-input-affix-wrapper .ant-input-suffix {
      margin-top: 5px; }
  .ant-legacy-form-item .ant-select-selection, .ant-legacy-form-item .ant-picker, .ant-form-item .ant-select-selection, .ant-form-item .ant-picker {
    height: 45px; }
  .ant-legacy-form-item .ant-radio-group, .ant-form-item .ant-radio-group {
    height: 40px;
    display: flex;
    align-items: flex-end; }
  .ant-legacy-form-item .ant-input-group-addon, .ant-form-item .ant-input-group-addon {
    border: 0px;
    background-color: #fafafa;
    color: inherit; }
  .ant-legacy-form-item .ant-select .ant-select-selector, .ant-form-item .ant-select .ant-select-selector {
    height: 45px;
    align-items: center; }
  .ant-legacy-form-item .ant-select .ant-select-selection--multiple, .ant-legacy-form-item .ant-select .ant-select-selector--multiple, .ant-form-item .ant-select .ant-select-selection--multiple, .ant-form-item .ant-select .ant-select-selector--multiple {
    height: auto;
    min-height: 45px;
    padding-top: 10px; }
    .ant-legacy-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__rendered, .ant-legacy-form-item .ant-select .ant-select-selection--multiple .ant-select-selector__rendered, .ant-legacy-form-item .ant-select .ant-select-selector--multiple .ant-select-selection__rendered, .ant-legacy-form-item .ant-select .ant-select-selector--multiple .ant-select-selector__rendered, .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__rendered, .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selector__rendered, .ant-form-item .ant-select .ant-select-selector--multiple .ant-select-selection__rendered, .ant-form-item .ant-select .ant-select-selector--multiple .ant-select-selector__rendered {
      line-height: 32px; }
  .ant-legacy-form-item .ant-select.ant-select-multiple .ant-select-selector, .ant-form-item .ant-select.ant-select-multiple .ant-select-selector {
    height: auto;
    min-height: 45px;
    padding: 0px 4px; }
    .ant-legacy-form-item .ant-select.ant-select-multiple .ant-select-selector .ant-select-selection-overflow, .ant-form-item .ant-select.ant-select-multiple .ant-select-selector .ant-select-selection-overflow {
      padding-top: 10px; }
      .ant-legacy-form-item .ant-select.ant-select-multiple .ant-select-selector .ant-select-selection-overflow .ant-select-selection-search-input, .ant-form-item .ant-select.ant-select-multiple .ant-select-selector .ant-select-selection-overflow .ant-select-selection-search-input {
        height: 32px; }
  .ant-legacy-form-item .ant-input-prefix + input, .ant-form-item .ant-input-prefix + input {
    padding-left: 5px; }
  .ant-legacy-form-item textarea, .ant-form-item textarea {
    padding: 10px 11px 4px; }
  .ant-legacy-form-item .select-color-container .ant-select .ant-select-selector:before, .ant-form-item .select-color-container .ant-select .ant-select-selector:before {
    content: '';
    display: inline-block;
    width: 50px;
    height: 25px;
    background: #d2d2d2;
    float: left;
    margin-right: 10px; }
  .ant-legacy-form-item .select-color-container[datacolor="red"] .ant-select .ant-select-selector:before, .ant-form-item .select-color-container[datacolor="red"] .ant-select .ant-select-selector:before {
    background: #DF0726; }
  .ant-legacy-form-item .select-color-container[datacolor="green"] .ant-select .ant-select-selector:before, .ant-form-item .select-color-container[datacolor="green"] .ant-select .ant-select-selector:before {
    background: #7BAD2D; }
  .ant-legacy-form-item .select-color-container[datacolor="blue"] .ant-select .ant-select-selector:before, .ant-form-item .select-color-container[datacolor="blue"] .ant-select .ant-select-selector:before {
    background: #86C2D8; }
  .ant-legacy-form-item .select-color-container[datacolor="yellow"] .ant-select .ant-select-selector:before, .ant-form-item .select-color-container[datacolor="yellow"] .ant-select .ant-select-selector:before {
    background: #FFB800; }
  .ant-legacy-form-item .select-color-container[datacolor="orange"] .ant-select .ant-select-selector:before, .ant-form-item .select-color-container[datacolor="orange"] .ant-select .ant-select-selector:before {
    background: #FF6B00; }
  .ant-legacy-form-item .select-color-container[datacolor="emerald"] .ant-select .ant-select-selector:before, .ant-form-item .select-color-container[datacolor="emerald"] .ant-select .ant-select-selector:before {
    background: #40C47C; }
  .ant-legacy-form-item .ant-select .ant-select-selection__rendered, .ant-legacy-form-item .ant-select .ant-select-selector, .ant-form-item .ant-select .ant-select-selection__rendered, .ant-form-item .ant-select .ant-select-selector {
    line-height: 45px; }

.ant-checkbox-indeterminate .ant-checkbox-inner::after,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #05B800;
  border-color: #05B800; }

.ant-switch {
  background-color: #C9D0E0; }

.ant-switch-checked {
  background: #05B800; }

.ant-btn {
  border-radius: 30px;
  font-weight: 500; }
  .ant-btn.phs-btn-primary {
    background-color: #264382; }
    .ant-btn.phs-btn-primary.btn-text {
      color: #264382;
      background-color: transparent; }
  .ant-btn.phs-btn-success {
    background-color: #05B800;
    color: #ffffff; }
  .ant-btn.ant-btn-success {
    color: #7FB800;
    border-color: #7FB800; }
  .ant-btn.ant-btn-highlight {
    color: #1890ff;
    border-color: #1890ff; }

.personal-value-edit.error .ant-input-affix-wrapper {
  border-bottom: 2px solid #f5222d; }

.personal-value-edit .ant-input-affix-wrapper {
  width: 100%;
  border: none;
  border-bottom: 2px solid #264382;
  border-radius: 0;
  height: 34px;
  padding: 0;
  margin-right: 10px;
  margin-bottom: 15px; }
  .personal-value-edit .ant-input-affix-wrapper input {
    border: none; }

.personal-value-edit .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #264382; }

.personal-value-edit .ant-input-affix-wrapper:focus, .personal-value-edit .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 1px rgba(24, 14, 255, 0.2); }

.personal-value-edit button {
  padding: 0 15px; }
  .personal-value-edit button:last-child {
    margin-left: 15px; }

/* modal style */
.phs-modal .title {
  font-weight: 300;
  line-height: 24px;
  font-size: 36px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #4C4C4C;
  margin-bottom: 40px; }

.phs-modal .sub-title {
  font-weight: normal;
  line-height: 27px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.366667px;
  text-transform: capitalize;
  color: #3F4651;
  margin-top: 40px; }

.phs-modal .submit-button {
  border-radius: 89px;
  text-transform: uppercase; }

.phs-info-modal .ant-modal-content {
  border-radius: 12px; }

.client-creation {
  width: 750px; }
  .client-creation .title {
    text-align: left;
    font-weight: normal;
    font-size: 32px;
    margin: 20px 0 40px 0; }
  .client-creation .upload-avatar {
    margin-bottom: 35px;
    padding-left: 10px;
    display: flex;
    align-items: center; }
    .client-creation .upload-avatar .edit-avatar {
      position: absolute;
      margin-top: -100px;
      margin-left: 72px; }
  .client-creation .ant-modal-footer {
    border: none;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 10px; }
    .client-creation .ant-modal-footer button {
      min-width: 120px; }
    .client-creation .ant-modal-footer .ant-btn-dangerous:not(.ant-btn-text) {
      background: #FBEEE8; }
  .client-creation .ant-collapse {
    background-color: transparent;
    border: unset; }
    .client-creation .ant-collapse > .ant-collapse-item {
      border-bottom: unset; }
    .client-creation .ant-collapse .ant-collapse-header {
      padding: 12px 0;
      border-top: 1px solid #E6E9F2; }
      .client-creation .ant-collapse .ant-collapse-header .ant-collapse-header-text {
        font-weight: 500;
        font-size: 24px;
        letter-spacing: 0.3px;
        color: #323F4A; }
      .client-creation .ant-collapse .ant-collapse-header .ant-collapse-expand-icon > span {
        right: 0;
        font-size: 16px; }
    .client-creation .ant-collapse .ant-collapse-content {
      border: unset; }
    .client-creation .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
      padding: 16px 0px; }

.upload-footer {
  background: rgba(178, 181, 186, 0.1);
  margin: -10px -16px;
  padding: 10px 16px; }
  .upload-footer button {
    margin-left: 10px; }

.upload-progress {
  text-align: right;
  font-weight: 500;
  letter-spacing: 0.4px;
  font-size: 14px;
  text-transform: uppercase; }
  .upload-progress .uploading {
    color: #264382; }
  .upload-progress .success {
    color: #05B800; }

.title-center {
  color: #4C6072;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  margin-bottom: 32px; }

.phs-empty {
  margin-top: 200px; }
  .phs-empty .empty-description {
    display: inline-block;
    font-size: 24px;
    color: #4C6072;
    line-height: 29px;
    margin: 15px 0; }

.ant-radio-wrapper.blood-test-item {
  margin-bottom: 15px;
  border: 1px solid #d9d9d9;
  padding: 5px 10px;
  border-radius: 30px; }
  .ant-radio-wrapper.blood-test-item.ant-radio-wrapper-checked {
    border-color: #05B800; }

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #05B800; }
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner:after {
    background-color: #05B800; }

.ipad-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 999;
  touch-action: none; }
  .ipad-menu .menu-mask {
    content: ' ';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 79; }
    .ipad-menu .menu-mask .foo-menu {
      background-color: #ffffff;
      width: 46%;
      display: inline-block;
      height: 100%;
      overflow-y: scroll; }

.popover-no-arrow {
  min-width: 347px; }
  .popover-no-arrow .ant-popover-content {
    overflow: visible; }
  .popover-no-arrow .ant-popover-arrow {
    display: none; }
  .popover-no-arrow .ant-popover-inner {
    padding: 22px 24px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px; }
  .popover-no-arrow .ant-popover-title {
    padding: 0;
    padding-bottom: 13px; }
  .popover-no-arrow.popover-for-HSD {
    min-width: 184px; }
    .popover-no-arrow.popover-for-HSD .ant-popover-inner {
      padding: 16px;
      border: 1px solid #e3e3e3;
      box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      background: #ffffff; }

.popover-hide-arrow .ant-popover-arrow {
  display: none; }

