 :local(.supportStyle) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw !important;
  header {
    background: #ffffff;
    height: 83px;
    line-height: 83px;
    text-align: center; }
  .content {
    width: 100% !important;
    margin: 0 auto;
    padding-top: 105px;
    max-width: 800px;
    h1 {
      font-family: Gilroy;
      font-weight: 500;
      font-size: 36px;
      color: #4C6072;
      letter-spacing: 0.3px;
      margin-bottom: 30px;
      padding: 0 10px; }
    p {
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 24px;
      color: #52606C;
      opacity: 0.8;
      padding: 0 10px; }
    button {
      min-width: 150px;
      height: 42px;
      font-size: 16px; }
    .form-btn {
      text-align: right; }
    .ant-form {
      margin: 0 auto;
      text-align: left;
      .ant-input-affix-wrapper {
        border: 1px solid #d9d9d9; }
      .ant-input {
        background: #ffffff !important;
        border: 1px solid #d9d9d9; } } }

  .succeed {
    //  margin-top: 105px
    text-align: center;
    img {
      width: 80px; }
    p {
      margin-top: 46px;
      margin-bottom: 53px;
      opacity: 1; }
    button {
      width: 320px; } }

  @media screen and (max-width: 1025px) {
    header {
      width: 100vw; }
    .content {
      width: 100vw !important;
      height: calc(100vh - 83px);
      max-width: 100vw;
      margin: 0;
      padding-top: 66px;
      background: #ffffff;
      padding-left: 20px;
      padding-right: 20px;
      h1 {
        font-size: 24px; }
      p {
        border-bottom: 1px solid rgba(0,0,0,.1);
        padding-bottom: 17px; }
      .form-btn {
        text-align: center; } }
    .succeed {
      p {
        border: none;
        padding: 0; } } }
  @media screen and (max-width: 767px) {
    .content {
      padding-top: 15px; } } }
