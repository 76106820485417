 :local(.modalStyle) {
  position: absolute;
  z-index: 9;
  background: rgba(46, 48, 51, 0.5);
  top: 0;
  left: 0;
  right: 0;
  min-height: calc(100% + 200px);
  display: flex;
  font-family: Gilroy;
  &.right-position {
    justify-content: flex-end; }
  .container-body {
    background: #fff;
    width: 716px; }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-height: 80px;
    .operate {
      display: flex;
      align-items: center;
      margin-right: -18px;
      &> * {
        margin: 18px;
        cursor: pointer; } }

    .title {
      color: #383E48;
      font-weight: bold;
      font-size: 18px;
      line-height: 29px;
      max-width: 525px;
      margin-bottom: 0;
      margin-top: 0;
      font-size: 18px;
      &>div:first-child {
        display: flex;
        align-items: center; }
      .anticon:first-child {
        margin-right: 13px; }
      .ant-typography {
        margin: 0;
        &:first-letter {
          text-transform: uppercase; } }
      .sub-title {
        font-size: 14px;
        letter-spacing: 0.3px;
        color: #4C6072;
        padding-left: 30px; } } }

  .main-container {
    padding: 25px; } }
