.cardContainer {
  margin-top: 24px;
  background: #fff;
  padding: 16px;
  border: 1px solid #DEE2EC;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  .title {
    display: flex;
    justify-content: space-between; } }

.inActive {
  box-shadow: 4px 4px 20px rgba(255, 0, 0, 0.30); }
