.recommened-content {
    font-family: Gilroy;
    .catergory-filter {
        span.tag {
            font-size: 12px;
            color: #8D99A5;
            margin: 8px 14px 0 0; }
        .catergory-sel {
            width: 134px;
            height: 29px;
            border-radius: 4px;
            .ant-select-selection {
                border: none; } } }
    .content-cards {
        margin: 41px -17px 0;
        min-width: 1034px;
        // max-height: 1000px
        // overflow-y: auto
        display: flex;
        flex-wrap: wrap;
        .add-new-card {
            width: 237px;
            height: 244px;
            background: #E8ECF0;
            border-radius: 4px;
            position: relative;
            margin: 0 8px;
            cursor: pointer;
            img,span {
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%); }
            span {
                top: 72%;
                width: 100%;
                text-align: center;
                color: #52606C; } } } }

.modal-recommend {
    width: 890px;
    height: 438px;
    .ant-form .ant-form-item {
        height: 53px; }
    .ant-modal-content {
        border-radius: 8px;
        font-family: Gilroy;
        font-weight: 500;
        .ant-modal-body {
            padding: 51px;
            position: relative;
            .ant-legacy-form {
                input#title, input#description {
                    padding-right: 145px; } }
            .body-title {
                margin-bottom: 55px;
                font-size: 32px;
                line-height: 29px;
                color: #4C6072; }
            .min-tip {
                position: absolute;
                top: 223px;
                right: 67px;
                color: #BAC3CE;
                font-size: 16px;
                line-height: 23px; }
            .character-tip {
                position: absolute;
                top: 378px;
                right: 65px;
                color: #BAC3CE;
                font-size: 16px;
                line-height: 23px;
                &.description-tip {
                    top: 455px; }
                &.copy-tip {
                    top: 298px;
                    color: #3561C0;
                    cursor: pointer;
                    background: #fff;
                    padding-left: 10px;
                    display: inline-block; } }
            .over-view-con {
                .title {
                    color: #8D99A5;
                    font-size: 12px;
                    padding-left: 10px; }
                .card-infos {
                    display: flex;
                    .right-info {
                        padding: 50px 38px;
                        .media-switch {
                            width: 208px;
                            height: 59px;
                            border-radius: 4px;
                            border: 2px solid #E8ECF0;
                            display: flex;
                            position: relative;
                            justify-content: space-between;
                            align-items: center;
                            padding: 6px 18px;
                            .out-title {
                                position: absolute;
                                background: #ffffff;
                                color: #8D99A5;
                                top: -12px;
                                left: 22px;
                                font-size: 12px; }
                            div {
                                color: #E8ECF0;
                                font-size: 14px;
                                line-height: 47px;
                                width: 66px;
                                text-align: center;
                                flex-shrink: 0;
                                margin: 0;
                                cursor: pointer;
                                &.active {
                                    color: #3561C0; } }
                            i {
                                width: 0;
                                height: 26px;
                                border-right: 2px solid #E8ECF0; } }
                        .change-cover {
                            margin-top: 30px;
                            color: #3561C0;
                            font-size: 16px;
                            cursor: pointer;
                            span {
                                padding: 8px 12px; } }
                        .img-tip {
                            color: #52606C;
                            font-size: 14px;
                            margin-top: 14px; } } } } }
        .ant-modal-footer {
            border: none;
            padding: 20px 53px 44px;
            .ant-btn {
                width: 155px;
                height: 48px;
                border: none;
                color: #4C6072;
                box-shadow: none; }
            .ant-btn-primary {
                color: #ffffff; } } } }

.modal-recommend,.recommened-content {
    .content-card {
        width: 237px;
        margin: 0 8px 17px;
        .card-info {
            position: relative;
            padding-top: 114px;
            background: #ffffff;
            overflow: hidden;
            border-radius: 4px;
            box-shadow: 0px 2px 8px #E8ECF0;
            img {
                display: block;
                width: 100%;
                height: auto;
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 23%; }
            .category {
                position: absolute;
                left: 18px;
                top: 14px;
                text-align: center;
                background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);
                border-radius: 44px;
                padding: 0 13px;
                height: 20px;
                font-size: 10px;
                text-transform: uppercase;
                line-height: 1.5;
                &.nutrition {
                    color: #7BAD2D; }
                &.rejuvenation {
                    color: #3561C0; }
                &.exercise {
                    color: #FF6B00; } }

            .sub-con {
                background: #ffffff;
                padding: 12px 18px 15px;
                position: relative;
                height: 146px;
                z-index: 2;
                letter-spacing: 0.3px;
                border-top: 1px solid #E8ECF0;
                .sub-category {
                    letter-spacing: 0.3px;
                    font-size: 12px;
                    line-height: 150%;
                    text-transform: capitalize;
                    &.nutrition {
                        color: #7BAD2D; }
                    &.rejuvenation {
                        color: #3561C0; }
                    &.exercise {
                        color: #FF6B00; } }
                .title {
                    color: #383E48;
                    font-size: 14px;
                    max-height: 48px;
                    line-height: 1.2;
                    overflow: hidden; }
                .description {
                    margin-top: 7px;
                    color: #52606C;
                    font-size: 12px;
                    max-height: 42px;
                    line-height: 1.2;
                    overflow: hidden;
                    // text-overflow: ellipsis
 }                    // white-space: nowrap
                .minutes {
                    position: absolute;
                    bottom: 15px;
                    left: 18px;
                    color: #BAC3CE;
                    font-size: 12px;
                    line-height: 1;
                    margin-top: 7px; } } }
        .to-remove {
            background: #FF6B00;
            color: #ffffff;
            border-radius: 4px;
            height: 37px;
            margin-top: 5px;
            line-height: 35px;
            text-align: center;
            cursor: pointer; } } }
