 :local(.detailStyle) {
  .select-bar {
    margin-right: 0;
    border-bottom: none; }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8.5px 22px;

    .title {
      color: #383E48;
      font-weight: bold;
      font-size: 18px;
      line-height: 29px;
      max-width: 525px;
      margin-bottom: 0;
      margin-top: 0;

      // padding-top: 18px
      div.ant-typography {
        margin: 0;
        text-align: left; }

      .anticon.anticon-left {
        line-height: 32px; } }

    .operate {
      margin-right: -15px;

      img {
        margin: 18px;
        cursor: pointer; }

      .anticon {
        margin: 15px;
        vertical-align: middle;
        cursor: pointer; } } }

  .date-range {
    display: flex;
    justify-content: flex-end;
    padding-top: 48px; }

  .select-bar .switch-date-range {
    .arrow {
      cursor: pointer;

      &:first-child {
        margin-right: 15px; }

      &:last-child {
        margin-left: 15px; } } }

  .rate {
    padding: 0;

    & > div {
      padding-top: 50px;
      position: relative;
      font-size: 15px;

      span {
        font-size: 18px;
        position: absolute;
        top: 30px;
        left: 0; } } }

  .input-type {
    padding-left: 130px;
    font-size: 12px;
    color: #000000;

    span {
      display: inline-block;
      width: 95px; } }

  .section-name {
    font-size: 12px;
    color: #383E48;
    opacity: 0.5;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    padding-bottom: 10px; }

  .text-info {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #44433D; }

  .goal-category-tracking-view {
    .container > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.3px;
      color: #383E48;

      // &>div:last-child
      & > div {
        text-transform: lowercase;

        .source {
          display: inline-block; }

        &:first-letter, .source:first-letter {
          text-transform: uppercase; } }

      .device {
        padding: 3px 10px;
        background: #264382;
        border-radius: 6px;
        color: #ffffff;
        display: block;
        text-align: center;
        text-transform: capitalize; }

      .device-auto {
        img {
          width: 37px;
          height: 37px;
          border-radius: 7px;
          border: 1px solid #E5E7EA;
          margin-right: 10px; }

        .source {
          display: inline-block; } }

      .week-day {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        margin-right: 10px;
        background: #264382;
        display: inline-block;
        color: #ffffff;
        text-align: center;
        line-height: 35px; } } }

  .date-item {
    font-weight: bold;
    font-size: 12px;
    line-height: 42px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #383E48;
    padding-bottom: 20px;

    .number-input:nth-child(3) {
      margin-left: 10px; }

    & > div {
      display: inline-block;
      vertical-align: middle; }

    &.sunday .date-text {
      color: #297FCA; }

    &.today .date-text {
      position: relative;

      &:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        background: #264382;
        border: 2px solid #FFFFFF;
        border-radius: 10px; } }

    &.text-item {
      padding-bottom: 15px;
      height: 100%; }

    .date-text {
      font-family: 'Gilroy-bold';
      display: inline-block;
      width: 130px;
      position: relative;
      letter-spacing: 0.3px;

      .is-manual {
        position: absolute;
        display: inline-block;
        font-size: 12px;
        left: 0;
        top: 22px;
        opacity: 0.5; }

      .activity {
        cursor: pointer;
        color: #7BAD2D;
        border-bottom: 2px dotted #7BAD2D;
        padding-bottom: 2px; } }

    .input-circle {
      margin-right: 16px;
      height: 30px;
      cursor: pointer;

      &:nth-child(2n+1) {
        border: 2px dotted transparent; }

      &:last-child {
        margin-right: 0; }

      &.activity {
        border: 2px dotted #7BAD2D;
        border-radius: 100%;
        padding: 2px;
        height: 38px; }
      &.not-allowed {
        cursor: not-allowed; } }

    .number-input, .text-input {
      display: inline-block;
      width: 86px;

      &.has-error {
        position: relative;

        input {
          border: 1px solid #FF0000; }

        span {
          position: absolute;
          top: 28px;
          color: #FF0000;
          letter-spacing: 0.3px;
          font-size: 12px;
          text-transform: capitalize;
          width: 100px; } }

      input, textarea {
        text-align: right;
        background: #f5f5f5;
        border: none;
        border-radius: 4px;
        resize: none; } }

    .text-input {
      width: 300px;
      height: calc(100% - 30px);

      textarea {
        text-align: left;
        min-height: 100% !important; } }

    .number-input {
      // &:last-child
      //   margin-left: 6px
      .number-input-formater-con {
        .fmt-h, .fmt-m {
          sub {
            text-transform: none;
            bottom: 0; } }

        .fmt-t {
          background-color: #f5f5f5;
          border-radius: 4px;
          height: 32px;
          line-height: 32px;
          display: inherit;
          text-align: right;
          padding-right: 10px; } } } }

  .null-status {
    margin-top: 112px;
    text-align: center;
    margin-left: -78px;

    img {
      width: 287px;
      margin-bottom: 30px;
      max-width: 100%; }

    p {
      font-family: Heebo;
      font-size: 14px;
      line-height: 150%;
      color: #79879B;
      opacity: 0.8; } } }

.attach-item-container {
  width: 256px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top; }

.attach-item {
  background: #E8ECF0;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .thumb {
    width: 47px;
    height: 47px;
    object-fit: cover;

    // background: gray
    color: #E8ECF0;
    text-align: center;
    overflow: hidden;

    &.link {
      font-size: 30px; }

    &.text {
      line-height: 47px;
      text-transform: uppercase; } }

  .name {
    font-family: Heebo;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.703548px;
    color: rgba(186, 195, 206, 0.7);
    width: 180px;

    .title {
      font-family: Gilroy;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.3px;
      height: 18px;
      color: #52606C;
      margin: 0; }

    .ant-typography {
      margin: 0;
      text-align: left; } }

  .anticon-close {
    cursor: pointer; } }
