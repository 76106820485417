 :local(.containerStyle) {
  .send-btn {
    height: 40px;
    // line-height: 40px
    text-transform: uppercase;
    width: 191px;
    font-size: 13px;
    float: right;
    margin-top: -55px; }
  .description {
    width: 780px;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.3px;
    color: #52606C;
    margin-top: -20px; }

  .nutrition-score-container {
    margin-top: 22px;
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    overflow: hidden; }
  .section-container {
    background: #ffffff;
    padding: 40px;
    padding-top: 24px;
    border-radius: 0px 0px 12px 12px; }

  .information-editor {
    .mark-down-support, .editor-footer {
      padding: 14px 20px;
      background: transparent;
      border: none;
      border-top: 1px solid #E8ECF0;
      .buttons button {
        height: auto;
        line-height: 25px; } }
    .mark-down-support {
      float: left; } }

  img[alt="empty"] {
    width: 300px; } }
