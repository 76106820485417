 :local(.rectangular) {
    margin-top: 15px;
    .rectangular-container {
        width: 103px;
        min-height: 32px;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px; }
    .rectangular-text {
        color: white;
        // font-family: Lato
        font-family: Heebo;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 100%;
        text-align: center; } }
