 :local(.heartRateBarsStyle) {
  &.email {
    padding: 0 40px;
    .total-heart-rate {
      padding-right: 0;
      padding-left: 10px; }
    .zoon-container {
      &:nth-child(2n) {
        padding-left: 20px; }
      &:nth-child(2n+1) {
        padding-right: 20px; } } } }
