.ant-popover-content {
  box-shadow: none;
  border-radius: 4px;
  overflow: hidden;
  .ant-popover-arrow {
    border-color: #E8ECF0 !important;
    box-shadow: none !important; }
  .ant-popover-inner-content {
    padding: 0 !important;
    .popove-tool {
      padding: 0;
      background: #E8ECF0;
      div {
        padding: 5px 16px;
        padding-right: 30px;
        font-family: Gilroy;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.3px;
        color: #52606C;
        cursor: pointer;
        &:first-child {
          border-bottom: 1px solid #BAC3CE; }
        .anticon {
          color: #BAC3CE;
          margin-right: 8px; } } } } }

 :local(.attachStyle) {
  .add-icon {
    color: #3561C0;
    display: inline-block;
    .anticon {
      margin-right: 5px; }
    div {
      display: inline-block; } }
  .attach-list {
    margin-bottom: 22px;
    margin-top: 29px;
    .attach-item, .file-item, .link-item {
      background: #E8ECF0;
      border-radius: 6px;
      margin-bottom: 10px;
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .thumb {
        width: 47px;
        height: 47px;
        object-fit: cover;
        // background: gray
        color: #E8ECF0;
        text-align: center;
        overflow: hidden;
        &.link {
          font-size: 30px; }
        &.text {
          line-height: 47px;
          text-transform: uppercase; } }
      .name {
        font-family: Heebo;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.703548px;
        color: rgba(186,195,206,.7);
        max-width: 100px;
        .title {
          font-family: Gilroy;
          font-size: 12px;
          line-height: 150%;
          letter-spacing: 0.3px;
          height: 18px;
          color: #52606C; }
        .ant-typography {
          margin: 0; } }
      .anticon-close {
        cursor: pointer; } }
    .file-item, .link-item {
      padding: 10px;
      .name {
        max-width: 300px;
        width: 300px; }
      .anticon-close {
        color:#3561C0 {} }
      .rate {
        font-family: Gilroy;
        font-size: 14px;
        letter-spacing: 0.3px;
        color: #323F4A;
        &.finish {
          color: #7BAD2D; } } }
    .link-item .name {
      max-width: 425px;
      width: 425px;
      margin-right: 55px; } }
  .upload-text .ant-upload {
    font-family: Gilroy;
    font-size: 12px;
    letter-spacing: 0.3px;
    color: #3561C0;
    cursor: pointer;
    .anticon {
      margin-right: 5px;
      & + div {
        display: inline-block; } } }
  .ant-upload-list {
    display: none; }
  .ant-legacy-form-item-link-container {
    display: flex;
    align-items: center;
    .ant-legacy-form-item-label > label {
      text-transform: none; }
    .ant-legacy-form-item-label > label::after {
      content: ''; }
    .control {
      flex-grow: 1; }
    .add {
      width: 84px;
      height: 38px;
      line-height: 35px;
      text-align: center;
      font-family: Heebo;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.3px;
      color: #264382;
      cursor: pointer;
      border-radius: 19px;
      border: 2px solid #264382;
      margin-left: 16px; }
    .unclickable {
      opacity: 0.5; } }
  .link-input {
    position: relative;
    .ant-input {
      padding-right: 60px; } }
  .separator {
    border: 0.5px solid #E8ECF0; }
  .ant-modal-header, .ant-modal-footer {
    border: none;
    .ant-modal-title {
      font-family: Gilroy;
      font-size: 24px;
      letter-spacing: 0.3px;
      color: #4C6072; } } }

 :local(.linkModalStyle) {
  .ant-modal-footer {
    padding: 0px 0px; }
  //   .ant-btn
  //     font-family: Gilroy
  //     font-size: 16px
  //     color: #4C6072
  //     border: 0px
  //     box-shadow: none
  //   .ant-btn-primary
  //     margin-left: 30px
  //     width: 155px
  //     height: 48px
  //     color: #F1F3F6 !important
  //     box-shadow: 10px 10px 30px rgba(38, 67, 130, 0.1)
  //   .ant-btn-primary[disabled]
 }  //     background-color: #BAC3CE
