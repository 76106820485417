.set-up-screen {
    padding: 0 80px
}

.set-up-screen .header {
    height: 81px;
    text-align: center;
}

.set-up-screen .header img {
    width: 122px;
    margin-top: 35px;
    margin-right: 8px;
}

.set-up-screen .header .log-out {
    position: fixed;
    right: 34px;
    top: 43px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #264382;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.set-up-screen .header .log-out .anticon {
    margin-right: 8px;
}

.set-up-screen .landing {
    background: #FAFCFD;
    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding-bottom: 90;
    border: none;
    display: flex;
    justify-content: space-between;
    margin: 0px 80px;
    padding: 114px 64px 90px 64px;
}

.set-up-screen .landing .title {
    color: #29303e;
    letter-spacing: .3px;
    font-size: 36px;
    line-height: 1.2;
}

.set-up-screen .landing .img-container {
    text-align: center;
    padding-right: 20px;
}

.set-up-screen .landing .img-container img {
    width: 368px;
}

.set-up-screen .ready-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.set-up-screen .ready {
    width: 965px;
    background: #FAFCFD;
    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    background-image: url(../../../asserts/images/ready-pg.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    padding: 60px 0;
    padding-bottom: 137px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.set-up-screen .ready .title {
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 120%;
    text-align: center;
    letter-spacing: .0187rem;
    color: #323F4A;
}

.set-up-screen .ready .desc {
    width: 420px;
    margin-top: 36px;
    margin-bottom: 36px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    color: #52606C;
    opacity: 0.6;
    margin-bottom: 36px;
}

.set-up-screen .ready .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 310px;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #52606C;
    margin-bottom: 24px;
}

.set-up-screen .ready .item .circle {
    border: 2px solid #98A9BC;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-right: 12px;
}

.set-up-screen .ready .item .min {
    padding: 4px 8px;
    background: #E6E9F2;
    border-radius: 22px;
    font-weight: 500;
    font-size: 12px;
    line-height: 116%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.3px;
    color: #52606C;
    margin-left: 12px;
}

.set-up-screen .cta {
    margin-top: 50px;
    height: 48px;
    padding: 0px 32px;
}

.set-up-screen .flow {
    display: flex;
    background: #FAFCFD;
    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.05);
    border: 1px solid #F1F3F6;
    border-radius: 12px;
    height: 600px;
    margin-top: 30px;
    background-image: url(../../../asserts/images/doc.svg);
    background-repeat: no-repeat;
    background-position: 95%;
    width: 1285px;
    min-width: 1285px;
}

.set-up-screen .flow.sign-image {
    background-image: url(../../../asserts/images/onboarding-sign-image.svg);
}

.set-up-screen .flow .left {
    border-right: 1px solid #E5E9F2;
    width: 400px;
    min-width: 400px;
    padding-top: 15px;
}

.set-up-screen .flow .left .item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E5E9F2;
    padding: 12px;
    padding-right: 24px;
}

.set-up-screen .flow .left .item .wrap {
    display: flex;
    align-items: center;
}


.set-up-screen .flow .left .item .circle {
    border: 1px solid #98A9BC;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #98A9BC;
    margin-left: 12px;
    margin-right: 12px;
}

.set-up-screen .flow .left .item .circle.active {
    background: #264382;
    width: 30px;
    height: 30px;
    color: #FAFCFD;
}

.set-up-screen .flow .left .item .circle.completed {
    border: 2px solid #05B800;
    background: #05B800;
}

.set-up-screen .flow .left .item .min {
    background: #E6E9F2;
    border-radius: 22px;
    padding: 0px 8px;
    height: 22px;
}

.set-up-screen .flow .right {
    padding: 60px 48px;
}

.set-up-screen .flow .right .title {
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.3px;
    color: #29303E;
}

.set-up-screen .flow .right .question {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #323F4A;
    margin-top: 48px;
    margin-bottom: 21px;
    width: 373px;
}

.set-up-screen .flow .right .answer {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.3px;
    color: #52606C;
    width: 373px;
}

.set-up-screen .flow-container .btn-box {
    text-align: center;
}

.set-up-screen .flow-container .btn-box .btn-back {
    margin-right: 36px;
    border: 1px solid #264382;
    color: #264382;
}