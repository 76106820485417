 :local(.listStyle) {
  font-family: Gilroy;
  .ant-table-thead {
    text-transform: uppercase;
    color: #5C626A;
    opacity: 0.8;
    font-size: 12px;
    & > tr > th {
      background: transparent;
      border: none;
      &:last-child {
        text-align: right; } } }
  .ant-table-tbody {
    font-size: 14px;
    & >tr > td {
      background: #FFFFFF;
      border-color: #F4F6F8;
      border-width: 2px;
      &:first-child {
        border-radius: 8px 0 0 8px; }
      &:last-child {
        &:first-child {}
        border-radius: 0 8px 8px 0; } }
    .bold {
      font-family: 'Gilroy-Bold'; }
    .status-circle {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin-right: 8px;
      &.Draft {
        background: #F7C137; }
      &.Opened {
        background: #7BAD2D; }
      &.Sent {
        background: #7BAD2D; } }
    .actions {
      display: flex;
      justify-content: space-around;
      &.User {
        justify-content: flex-end; }
      .anticon, img {
        cursor: pointer;
        color: #264382;
        font-size: 32px; } } } }
