 :local(.modalityListStyle) {

  &.email {
    padding: 0 40px;
    .modality-type-list {
      padding-right: 0;
      padding-left: 10px; } }
  .modality-type-list {
    list-style: none;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    // padding-right: 20%
    display: inline-block;
    min-width: 155px; }
  li {
    display: flex;
    justify-content: space-between;
    span {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      margin-right: 6px;
      vertical-align: middle; }
    svg {
      vertical-align: middle; } } }
