 :local(.clientCategoriesStyle) {
  .ant-empty {
    padding-top: 50px; }
  .category-container {
    .root-category {
      padding: 20px 20px 20px 0;
      &>.root-category {
        padding: 0 !important; }
      .ant-divider {
        &:before {
          width: 0; }
        .ant-divider-inner-text {
          font-size: 18px;
          padding-left: 0; } }
      .card-list {
        .metric-container {
          margin-bottom: 30px;
          width: 100%; } }
      .sec-no-data {
        text-align: center;
        color: #bac3ce;
        opacity: .8;
        font-size: 12px;
        font-weight: 500;
        font-family: Heebo;
        letter-spacing: .6px;
        text-transform: uppercase; }

      .sub-category-container {
        .description {
          padding: 0;
          padding-bottom: 10px; } }
      .image-content {
        img {
          max-width: 100%; } } } }
  .section-title {
    margin: 24px 0px 7px 21px;
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #383E48;
    display: inline-block; }
  .separator {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 0px 21px; } }
