 :local(.whiteBoxView) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  .logo {
    position: fixed;
    left: 30px;
    top: 20px;
    width: 150px; }
  .white-box-container {
    background-color: white;
    width: 500px;
    height: 631px;
    border-radius: 18px;
    // overflow-y: auto
    overflow: hidden;
    position: relative;
    .back {
      margin: 24px 0 0 22px;
      position: absolute;
      .back-title {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #264382;
        letter-spacing: 2.8px; } }
    .white-box-container-title {
      text-align: center;
      font-family: Gilroy;
      font-size: 36px;
      letter-spacing: 0.3px;
      color: #A7B1BF;
      margin-top: 54px; }
    .white-box-container-notice {
      margin-top: 10px;
      font-family: Heebo;
      font-size: 14px;
      padding: 0 100px;
      line-height: 150%;
      color: rgba(92,98,106,.8);
      text-align: center;
      span {
        font-weight: bold; } }

    .form-view-box {
      margin: 15px 22px 26px 20px;
      height: 430px;
      overflow: auto;
      .form-view {
        background-color: #FCFDFD;
        border: 1px solid rgba(43, 75, 143, 0.3);
        border-radius: 12px;
        width: 457px;
        height: 100px;
        padding-left: 29px;
        padding-right: 34px;
        margin-top: 15px;
        .form-view-container {
          font-family: Heebo;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .form-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 25px; }
          .form-title {
            font-size: 18px;
            color: #29303E;
            letter-spacing: 0.3px; }
          .form-view-icon-title {
            margin-top: 5px;
            color: #264382;
            letter-spacing: 0.3px;
            line-height: 100%; } } }
      .add-more-tip {
        color: #3561C0;
        text-align: center;
        margin: 20px 0;
        cursor: pointer;
        font-family: Heebo;
        font-weight: 500;
        font-size: 12px; } } } }
