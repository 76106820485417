 :local(.containerStyle) {
  position: absolute;
  z-index: 5;
  background: rgba(46, 48, 51, 0.5);
  top: 0;
  left: 0;
  right: 0;
  // bottom: -100px
  margin-left: -359px;
  display: flex;
  justify-content: flex-end;
  min-height: calc(100% + 200px);

  .modal-detail-container {
    // position: absolute
    background: #fff;
    width: 716px;

    .container-body > div {
      padding: 20px 22px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &:last-child {
        border: none; } }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8.5px 22px;

      .title {
        color: #383E48;
        font-weight: bold;
        font-size: 18px;
        line-height: 29px;
        max-width: 525px;
        margin-bottom: 0;
        margin-top: 0;

        // padding-top: 18px
        div.ant-typography {
          margin: 0;
          text-align: left; }

        .anticon.anticon-left {
          line-height: 32px; } }

      // .operate
 } } }      //   margin-right: -15px
