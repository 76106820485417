.title {
  padding-bottom: 20px;
  font-family: Gilroy;
  font-size: 36px;
  letter-spacing: 0.3px;
  color: #4C6072; }
.footer {
  display: flex;
  justify-content: space-between;
  button {
    color: #4C6072;
    font-size: 16px;
    height: 48px;
    font-weight: 400;
    &:first-child {
      margin-right: 10px;
      color: #4C6072; } }

   :global(.ant-btn-primary) {
    min-width: 155px;
    color: #ffffff; }
   :global(.ant-btn-background-ghost) {
    border: none;
    box-shadow: none; } }
.item, .itemActive {
  border-radius: 6px;
  padding: 15px;
  margin: 20px 0;
   :global(.anticon) {
    margin-right: 20px;
    font-size: 20px;
    vertical-align: middle; } }
.item {
  background: #F1F3F6;
  color: rgba(56,62,72,.9);
  margin: 20px 0; }
.itemActive {
  background: rgba(38, 67, 130, 0.02);
  border: 2px solid #264382;
  color: rgba(38,67,130,.9); }

.divider {
  background: transparent;
   :global(.ant-divider) {
    margin-top: -20px !important; }
   :global(.ant-divider-inner-text) {
    font-size: 14px;
    color: #8D99A5;
    opacity: 0.9;
    position: relative;
    top: 10px;
    font-family: Gilroy;
    padding: 0 10px; } }
