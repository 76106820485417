 :local(.sharaLinkStyle) {
  // padding-top: 25px
  // padding-bottom: 30px
  .main-content {
    .left,.right {
      display: inline-block;
      vertical-align: top; }
    .left {
      width: 280px;
      margin-right: 78px; }
    .right {
      width: calc(100% - 280px - 78px);
      padding-right: 44px;
      &.detail-right {
        width: 100%;
        padding: 0 25px;
        .page-title img {
          margin-right: 5px; } } } }
  @media screen and (max-width: 768px) {
    .main-content {
      .left {
        margin-right: 0; }
      &.ios {
        .right .mobile-show > div {
          margin-top: 0; } }
      .right {
        width: 100%;
        padding: 0;
        .mobile-show > div {
          margin-top: -20px; }
        &.detail-right {
          padding: 0;
          .mobile-show > div {
            margin-top: 0; } } } }
    /* detail mobile style*/ } }
