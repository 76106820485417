 :local(.mobileHealthScore) {
  background: #ffffff;
  padding-top: 30px;
  .health-scroe-container {
    padding: 0px;
    .compare-labels {
      margin-top: -20px; }
    .health-card-conatiner {
      padding-left: 20px;
      padding-right: 20px;
      .ant-card-meta-description {
        padding: 0;
        margin: 0; } }
    .spider-graph-container {
      width: 100%; }
    .info-list {
      padding: 0 25px; } } }
