 :local(.rangeAreaStyle) {
  // display: flex
  .ranges-container {
    display: flex;
 }    // background: rgba(196,196,196,.1)
  .range-area {
    display: inline-block;
    position: relative;
    vertical-align: top;
    .polygon-value {
      position: absolute;
      margin-top: 10px; } }
  .range-level {
    text-transform: capitalize; }
  .range-title, .range-level {
    display: flex;
    justify-content: space-between;
    color: #4C6072; }
  .range-title {
    text-transform: uppercase;
    font-family: Heebo;
    font-size: 12px;
    letter-spacing: 0.6px;
    font-weight: bold;
    line-height: 19px;
    color: #4C6072; }
  .range-level {
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.3px;
    margin: 10px 0;
    font-family: Gilroy-Bold;
    font-weight: bold; }
  .progress-container {
    padding: 40px 0;
    background: #ffffff; }
  .progress-bar {
    background: #D2D2D2;
    height: 6px;
    margin: 0 2px; }
  .range-info {
    color: rgba(76,96,114,.8);
    font-size: 12px;
    padding: 12px 8px;
    font-weight: bold;
    font-family: Heebo;
    text-align: center;
    .name {
      text-transform: uppercase;
      font-family: Heebo;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.6px;
      color: #323A46;
      opacity: 0.8; }
    .area {
      font-size: 16px;
      font-family: Heebo;
      font-weight: bold;
      line-height: 21px;
      display: block;
      min-height: 21px; } }
  .range-info-container {
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
    background-color: rgba(196, 196, 196, 0.1);
    display: flex;
    flex-wrap: wrap;
    .long {
      width: 100%;
      .range-info {
        text-align: left;
        padding: 12px 0;
        display: flex;
        align-items: center; }
      .area {
        display: inline-block;
        margin-right: 10px;
        min-width: 116px; }
      .name {
        max-width: calc(100% - 116px - 15px);
        padding-right: 10px; } } } }
