 :local(.optionStyle) {
  letter-spacing: 0.006em;
  color: #4C6072 !important; }

 :local(.healthStyle) {
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  margin-bottom: 88px;

  button {
    height: 48px;
    padding: 0 32px; }

  .health-scroe-container {
    padding: 40px;
    &.mobile>div:first-child {
      margin-bottom: 30px;
      padding-bottom: 30px; }
    .edit-botton {
      position: absolute;
      // text-align: right
      // width: 100%
      margin-top: -100px;
      right: 0;
      .ant-btn {
        height: 40px;
        min-width: 160px;
        &:first-child {
          margin-right: 24px; } } }

    .compare-labels {
      text-align: center;
      margin-bottom: 40px;
      margin-top: -40px;
      text-transform: uppercase;
      div {
        color: #5A657C;
        margin: 0 8px;
        padding: 5px 20px;
        min-width: 113px;
        display: inline-block;
        border-radius: 19px;
        border: 1px solid rgba(33,57,89,.2);
        font-size: 14px;
        line-height: 130%;
        letter-spacing: 0.5px;
        svg {
          margin-right: 6px; }
        // &:before
        //   content: ''
        //   display: inline-block
        //   width: 10px
        //   height: 10px
        //   border-radius: 10px
        //   background: gray
        //   margin-right: 6px
 }        //   vertical-align: middle
      .current:before {
        background: #4C6072; }
      .compare:before {
        background: #9CA1B4; }
      &.mobile {
        margin-top: 10px;
        div {
          font-size: 12px;
          transform: scale(0.7);
          margin: 5px; } } }

    .spider-graph-container {
      svg {
        height: 0; } } }

  &>footer {
    margin-left: -38px;
    height: 88px;
    background: #FFFFFF;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 64px;
    position: fixed;
    width: calc(1440px - 250px - 64px);
    max-width: calc(100% - 250px - 64px);
    button {
      min-width: 140px;
      text-transform: uppercase;
      &:last-child:nth-child(2) {
        margin-left: 16px; } } } }
