 :local(.detailStyle) {
  .client-biomarker-container {
    display: flex;
    flex-wrap: nowrap;
    .biomarker-info {
      background: #ffffff;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      display: inline-block;
      width: calc(558px + 45px);
      margin-left: 20px;
      position: relative;
      min-height: 344px;
      .tabs {
        display: flex;
        &>ul {
          list-style: none;
          padding: 0;
          text-align: center;
          border-bottom:  1px solid #EAEDF3;
          width: 100%;
          li {
            display: inline-block;
            // width: 33.3%
            width: 50%;
            border-right: 1px solid #EAEDF3;
            background: #FAFAFA;
            padding: 31px 0;
            font-size: 12px;
            font-weight: bold;
            &:last-child {
              border: none; }
            &.active {
              color: #3F4752;
              background: #ffffff; } } } }
      .information-container {
        padding: 20px;
        font-family: Heebo;
        .information {
          position: absolute;
          width: calc(100% - 40px);
          height: calc(100% - 104px - 40px);
          overflow-y: scroll; } } }
    .ranges-info {
      background: #ffffff;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      // overflow: hidden
      display: inline-block;
      width: 767px;
      .header {
        padding: 15px 20px;
        height: 88px;
        border-bottom:  1px solid #EAEDF3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        line-height: 29px;
        h1 {
          margin: 0;
          font-size: 18px; }
        .date {
          font-size: 12px;
          line-height: 29px;
          color: #B1B1B1;
          img {
            margin-right: 10px; } }
        .show-options {
          text-transform: uppercase;
          text-align: right;
          margin-top: -45px; } }
      .ranges-area-container {
        width: 100%;
        min-height: 100px;
        display: inline-block;
        padding: 20px;
        vertical-align: top;
        padding-bottom: 0; }
      .ranges-area-container {
        .ranges-area:last-child {
          margin-top: 20px; }
        .options-container {
          display: flex;
          justify-content: space-between;
          div>.label {
            font-size: 12px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            color: #4C6072; }
          &>div {
            font-size: 24px;
            line-height: 36px;
            text-transform: capitalize; }
          .value {
            text-align: right; } } } } }
  .trend-graph {
    background: #ffffff;
    margin-top: 20px;
    border-radius: 4px;
    padding-bottom: 20px;
    width: 100%;
    h1 {
      margin: 0;
      padding: 20px;
      border-bottom: 1px solid #EAEDF3;
      font-size: 18px;
      line-height: 29px; }
    .VictoryContainer {
      margin: 0 20px; } }

  @media screen and (max-width: 768px) {
    .client-biomarker-container {
      display: block;
      .ranges-info {
        width: 100%;
        border-radius: 0;
        // .range-title
 }        //// display: none !important
      .biomarker-info {
        width: 100%;
        margin: 0; } } } }
