    .title {
        font-weight: 500;
        font-size: 36px;
        line-height: 120%;
        -webkit-letter-spacing: 0.3px;
        -moz-letter-spacing: 0.3px;
        -ms-letter-spacing: 0.3px;
        letter-spacing: 0.3px;
        color: #3c4e5f;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .transaction-container {
        display: flex;
        gap: 20px;
        margin-bottom: 30px;
    }

    .total-sessions-container {
        background: #FFFFFF;
        width: 226px;
        height: 120px;
        padding: 14px;
        text-align: left;
    }

    .sessions-remaining-container {
        background: #FFFFFF;
        width: 226px;
        height: 120px;
        padding: 14px 14px 22px 14px;
        text-align: left;
    }

    .session-header {
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;
        letter-spacing: 0.41313132643699646px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

    .sessions-value {
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0.41313132643699646px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #264382;
    }

    .credit-filter {
        color: #009606 !important;
        border: 1px solid #009606 !important;

    }

    .debit-filter {
        color: #FF6161 !important;
        border: 1px solid #FF6161 !important;
    }

    .show-all-filter {
        color: #264382 !important;
        border: 1px solid #264382 !important;
    }

    .credit-value {
        background-color: #009606 !important;
        color: #FFFFFF !important;
    }

    .debit-value {
        background-color: #FF6161 !important;
        color: #FFFFFF !important;
    }

    .option {
        border-radius: 10px !important;
        margin-left: 10px !important;
    }

    .title-header-text {
        color: #a5abb2 !important;
    }

    .actions-container{
        display: flex;
        gap: 10px;
        margin: auto;
        justify-content: center;
    }

    .create-modal-title {
        font-family: 'Gilroy', sans-serif;
        font-weight: 600;
        letter-spacing: 0.366666704416275px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #4C4C4C;
    }

    .filter-panel {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 20px;
    }

    .transaction-type {
        display: flex;
        gap: 20px;
        margin-bottom: 24px;
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }

    .ant-modal-content .ant-modal-header,
    .ant-modal-content .ant-modal-footer {
        padding: 40px 20px 0px 20px !important;
        border: none;
    }

    .ant-form-item-label>label {
        font-family: Gilroy !important;
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 7.76px !important;
        color: #8D99A5 !important;
        letter-spacing: 0.8px !important;
        text-align: left !important;
    }

    .admin-buttons{
        display: flex;
        gap: 16px;
        align-items: center;
    }

    .ant-modal-content{
        padding-bottom: 30px !important;
    }