 :local(.daysOfWeekStyle) {
  padding-top: 15px;
  color: #4C6072;
  font-size: 12px;
  .days-item {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin-right: 7px;
    background: #F4F5F6;
    border-radius: 100%;
    cursor: pointer;
    &:last-child {
      margin-right: 0; }
    &.selected {
      background: #264382;
      color: #ffffff; } } }
