 :local(.styles) {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  // margin-right: -30px
  padding-right: 10px;
  .list {
    list-style: none;
    padding: 0; }
  .list-item {
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
    font-size: 12px;
    opacity: 0.8;
    // height: 33px
    // line-height: 33px
    .item-container {
      cursor: pointer;
      border-radius: 0 20.5px 20.5px 0;
      padding: 10px;
      padding-left: 25px;
      span {
        font-size: 14px; } }
    &.active .item-container {
      background: rgba(135,152,173,.1); }
    a {
      color: #3F4752; }
    .sub-list-container {
      height: auto;
      font-weight: bold;
      line-height: 21px;
      font-size: 14px;
      transition: height 1s;
      .sub-list {
        list-style: initial;
        margin-left: 20px;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(134,140,148,.2);
        .sub-list-item {
          margin: 15px 0;
          color: rgba(63,71,82, .6);
          cursor: pointer;
          &.active {
            color: #4C6072; } } } } }

  .ant-menu-inline {
    width: calc(100% + 23px); }
  .ant-menu {
    background: transparent;
    border: none;
    color: rgba(76,96,114,.8);
    font-size: 14px;
    font-family: Gilroy;
    letter-spacing: 0.3px;
    .ant-menu-submenu:last-child {
      padding-bottom: 75px; } }
  .ant-menu-submenu-selected {
    color: rgba(76,96,114,.8); }
  .ant-menu-submenu > .ant-menu {
    background: transparent;
    border: none; }
  .ant-menu-submenu {
    .ant-menu-submenu-title:hover {
      color: rgba(38,67,130, .8); } }
  .ant-menu-item-active, .ant-menu-item:hover {
    color: rgba(38,67,130, .8); }
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: linear-gradient(to right,rgba(38,67,130, .8),rgba(38,67,130, .8)); }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-submenu-open > .ant-menu-submenu-title {
    background: #E8ECF0;
    color: rgba(38,67,130, .8);
    border: none; }
  .ant-menu-item {
    position: relative; }
  .ant-menu-item::after {
    display: none; }
  .ant-menu-sub > .ant-menu-item:before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 100%;
    background: rgba(38,67,130, .8);
    margin-right: 13px;
    background: #6B7C8B;
    opacity: 0.3; }
  .ant-menu-sub > .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0 !important;
    &.ant-menu-item-selected {
      background: transparent;
      & > a {
        color: #6A7EA9; }
      &:before {
        background: #6A7EA9;
        opacity: 1;
        width: 4px;
        margin-left: -1px; } } }
  .ant-menu-item {
    & a, & span {
      color: rgba(76, 96, 114, 0.8); }
    &.ant-badge {
      top: 50%;
      transform: translateY(-50%); }
    & a:hover {
      color: rgba(38,67,130,.8); }
    & a::before {
      content: none; }
    & span:hover {
      color: rgba(38,67,130,.8); }
    .ant-scroll-number-only-unit.current {
      color: #ffffff; } }
  .ant-menu-submenu {
    .ant-menu-sub {
      .ant-menu-title-content {
        margin-left: 13px; } } }
  .ant-menu-submenu-inline {
    .ant-menu-submenu-arrow {
      right: 24px; } }

  .ant-menu-item.assessment {
    font-size: 14px;
    line-height: 150%;
    color: rgba(50,63,74,.8) !important;
    border-color: #E8ECF0 !important;
    font-weight: bold;
    border-top: 1px solid;
    height: 50px;
    padding-left: 22px !important;
    .ant-menu-title-content {
      padding-top: 27px; } }
  .ant-menu-item.home {
    font-size: 14px;
    color: rgba(50,63,74,.8) !important;
    font-weight: bold;
    padding-left: 22px !important; }

  &.User, &.Service_Provider {
    .ant-menu-inline {
      width: calc(100% + 10px); } } }
