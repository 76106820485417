.ant-table-thead>tr>th {
    color: #8D99A5 !important;
}

.session-pricing-header {
    margin: 40px 0px 20px 0px;
}

.editable-input{
    width: 120px !important;
}