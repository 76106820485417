 :local(.valueStyle) {
  .personal-value {
    display: inline-block;
    line-height: 17px;
    font-size: 24px;
    color: #323A46;
    padding: 5px 0;
    img, .text {
      display: inline-block;
      margin: 10px 0;
      margin-left: 10px; }
    .text {
      margin-left: 0;
      line-height: 1; }
    button {
      padding: 0 20px;
      margin: 10px 0;
      margin-left: 10px; }
    &.disabled {
      cursor: not-allowed;
      img {
        opacity: .5; } } }
  .error-message {
    color: #f5222d;
    font-size: 12px;
    margin-top: -15px; } }
