 :local(.modalContainer) {
  &.select-modal {
    width: 595px !important;
    border-radius: 12px;
    .ant-modal-header, .ant-modal-body, .ant-modal-footer {
      padding-left: 30px;
      padding-right: 30px; }
    .select-type-icon {
      width: 260px;
      height: 260px;
      display: inline-block;
      text-align: center;
      padding-top: 90px;
      font-size: 18px;
      cursor: pointer;
      &.selected {
        background: #F1F3F6;
        border-radius: 12px; }
      img {
        margin-bottom: 20px;
        width: 36px; } } }
  &.delete-confirm {
    font-weight: bold;
    color: #383E48;
    .ant-modal-close-x {
      font-size: 22px; }
    .confirm {
      margin-top: 35px;
      margin-bottom: 30px;
      font-size: 18px; }
    .name {
      font-size: 24px;
      margin-bottom: 90px; }
    .footer-opr {
      text-align: right;
      color: #264382;
      font-size: 18px;
      font-weight: 500;
      span {
        margin-left: 50px;
        cursor: pointer; } } }
  .ant-modal-close {
    top: 10px;
    right: 10px; }
  .ant-modal-header {
    padding: 0 50px;
    padding-top: 50px;
    border: none;
    .ant-modal-title {
      color: #8D99A5;
      font-size: 12px;
      line-height: 29px;
      letter-spacing: 0.3px;
      text-transform: capitalize; } }
  .ant-modal-body {
    padding: 24px 40px;
    padding-top: 0;
    .body-title {
      font-family: Gilroy;
      font-size: 36px;
      color: #4C6072; } }
  .ant-modal-footer {
    border: none;
    padding: 16px 50px;
    padding-bottom: 50px;
    .ant-btn {
      text-transform: uppercase;
      padding: 5px 15px;
      height: 35px; } } }
