.table-filter-container {
    display: flex;
    flex-direction: column;
}

.filter-scrollable-area {
    flex: 1;
    overflow-y: scroll;
    padding: 8px;
}

.select-all-option {
    margin-bottom: 6px !important;
    font-weight: bold;
}

.filter-values{
    margin-bottom: 4;
}

.filter-footer {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-top: 1px solid #f0f0f0;
}

.reset-button {
    width: 48%;
    border: none !important;
}

.submit-button {
    width: 40%;
}