 :local(.uploadModalStyle) {
  font-family: Gilroy;
  font-weight: 500;
  text-align: left;
  .ant-modal-header {
    border: none;
    padding: 40px 46px;
    padding-bottom: 0; }
  .ant-modal-body {
    padding: 15px 46px;
    padding-bottom: 0;
    .title-center {
      padding-top: 10px;
      margin-bottom: 10px; } }
  .ant-modal-footer {
    border: none;
    padding: 15px 46px;
    padding-bottom: 40px;
    .upload-footer {
      background: #ffffff;
      margin-top: 20px;
      position: relative;
      top: 15px;
      button {
        height: 48px;
        min-width: 115px; } } }

  .ant-modal-title {
    font-size: 32px;
    color: #4C6072; }

  .ant-upload {
    width: 100%;
    padding: 5px;
 }    // margin-bottom: 20px
  .upload-container {
    background: #F8F8F8;
    // height: 73px
    padding: 11px;
    width: 100%;
    min-height: 73px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(129, 144, 156, 0.2);
    border-radius: 8px;
    letter-spacing: 0.3px;
    font-size: 16px;
    &.empty {
      border: 2px dashed #E2E2E2; }
    &.has-error {
      border: 1px solid #f5222d; }
    .opr {
      color: #264382;
      cursor: pointer; }
    .select {
      text-align: center;
      width: 100%; }
    .name {
      width: 80%;
      display: flex;
      color: #4C6072;
      font-size: 14px;
      .speed {
        color: #7FB800;
        margin-left: 20px;
        text-transform: lowercase; }
      img {
        margin-right: 16px; }
      &>div {
        max-width: calc(100% - 45px); }
      .ant-typography {
        margin: 0;
        font-size: 16px;
        font-family: Heebo;
        font-weight: 500;
        font-size: 16px; } } }
  .file-required {
    color: #f5222d;
    font-size: 14px; }
  .ant-progress-inner {
    margin-top: -30px;
    .ant-progress-bg {
      height: 4px !important; } }
  .upload-progress span {
    display: inline-block;
    margin-bottom: 10px; }
  .title-normal {
    font-size: 24px; }
  .noti-container {
    font-size: 16px; } }


