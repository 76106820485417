 :local(.nutritionStyle) {
  // padding-top: 30px
  .goal-item {
    // margin: 0 -59px
    padding: 24px 0;
    border-color:  #E4EAEF;
    border-style: solid;
    border-width: 0;
    position: relative;
    &:after {
      content: '';
      width: 40px;
      height: 150px;
      position: absolute;
      right: -40px;
      top: 0;
      // background: red
 }      // opacity: .1
    &:hover .delete {
      display: block; }
    .delete {
      float: right;
      margin: -10px -40px 0 0;
      display: none;
      cursor: pointer;
      position: relative;
      z-index: 2; }
    .name {
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #383E48;
      padding-bottom: 10px; }
    .data-content {
      margin: 10px 0;
      display: flex;
      &>div, .days, .weeks {
        width: 50%;
        img {
          max-width: 100%; } }
      .days, .weeks {
        display: inline-block;
        width: 50%;
        font-family: 'Gilroy-Bold';
        font-size: 30px;
        span {
          font-family: Gilroy;
          font-size: 12px;
          color: #70808E;
          display: block;
          white-space: normal; }
        .change {
          display: inline-block;
          margin-left: 5px; }
        .green {
          color: #7BAD2D; }
        .red {
          color: #FF6B00; } }
      .days {
        white-space: nowrap;
        color: #383E48; }
      .weeks {
        color: #B7BFC6; }
      .unit {
        display: inline-block !important;
        font-size: 12px;
        // font-family: Gilroy
        color: #70808E;
 } } } }        // margin-left: 10px
