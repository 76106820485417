 :local(.paymentStyle) {
  .tips-content {
    background-color: rgba(152,169,188,.1);
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 61px;
    margin-top: 30px;
    padding-left: 21px;
    &.hascard {
      background-color: rgba(#98A9BC, 0.1 ); }
    &.error {
      background: rgba(255,81,81,.1);
      p {
        color: #FF5151; } }
    p {
      color: #98A9BC;
      font-size: 14px;
      letter-spacing: 0.6px;
      margin: 0px;
      padding-left: 8px;
      text-transform: uppercase; } }
  .bank-info {
    padding-top: 21px;
    .bank-info-detail {
      display: flex;
      align-items: center;
      margin-bottom: 41px;
      .bank-info-input {
        width: 388px;
        .edit-button {
          color: #264382;
          font-size: 14px;
          font-weight: bold;
          margin-top: 14px;
          cursor: pointer; }
        .ant-legacy-form-item {
          margin-bottom: 7px; } }
      .normal-label-form {
        .ant-legacy-form-item {
          .ant-legacy-form-item-label {
            position: relative;
            background: rgba(0, 0, 0, 0);
            margin-top: 0px;
            margin-bottom: 10px;
            margin-left: -10px;
            label {
              color: rgba(56, 67, 94, 0.5);
              font-weight: bold; }
            label::after {
              content: ''; }
            .ant-legacy-form-item-required::before {
              content: ''; } } } }
      .bank-info-card {
        box-shadow: 30px 15px 20px -10px rgb(222, 223, 224);
        margin-left: 89px;
        width: 355px;
        height: 220px;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .bank-info-card-logo {
          margin-top: 26px;
          margin-left: 28px; }
        .bank-info-user-card-info {
          color: #4C6072;
          font-size: 22px;
          font-family: Courier New, Courier, monospace;
          line-height: 150%;
          margin-left: 28px;
          margin-bottom: -7px; }
        .bank-info-card-support-banks {
          align-self: flex-end;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          color: #96A1B8;
          font-size: 12px;
          font-weight: bold;
          margin-right: 24px;
          margin-bottom: 14px; } } }
    .bank-info-description {
      border-radius: 8px;
      display: flex;
      position: relative;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 18px 30px;
      padding-bottom: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      .secure-icon {
        position: absolute;
        top: 19px;
        right: 25px; }
      // height: 148px
      // padding-left: 27px
      .bank-info-description-content {
        p {
          letter-spacing: 0.3px; }
        .bank-info-description-content-title {
          color: "#29303E";
          font-size: 12px;
          line-height: 100%; }
        .bank-info-description-content-sub-title {
          font-size: 14px;
          line-height: 150%;
          color: #5E687D;
          margin-bottom: 24px; }
        .bank-info-description-content-detail {
          color: "#5E687D";
          font-size: 12px;
          line-height: 150%;
          font-family: Lato;
          font-style: italic;
          margin-bottom: 8px;
          display: flex;
          align-items: flex-start;
          img {
            margin-top: 3px; }
          span {
            padding-left: 10px;
            display: inline-block;
            width: calc(100% - 25px); }
          .anticon {
            margin-right: 20px; } }
        .bank-info-description-content-italic {
          padding-top: 15px;
          font-family: Lato;
          font-style: italic;
          font-size: 12px;
          color: #5E687D;
          margin-bottom: 22px; } } } }
  .privacy {
    padding-top: 30px;
    padding-bottom: 27px;
    p {
      font-style: italic;
      color: #5E687D;
      line-height: 150%;
      letter-spacing: 0.3px;
      font-size: 12px; }
    .ant-checkbox-wrapper {
      margin-right: 11px; }
    a {
      color: #264382;
      text-decoration: underline; } }

  .confirm {
    display: flex;
    justify-content: center;
    position: relative;
    .confirm-button {
      width: 186px;
      height: 48px;
      margin-top: 24px; }
    .skip-button {
      color: #264382;
      font-size: 12px;
      letter-spacing: 2.8px;
      font-weight: bold;
      position: absolute;
      right: 0;
      top: 38px;
      cursor: pointer; } }
  .credit-card-name {
    background:  #FBFBFB;
    border-radius: 8px; }
  .stripe-element-input {
    background:  #FBFBFB;
    height: 45px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 14px 11px;
    &.prefill {
      line-height: 16px;
      margin-bottom: 8px;
      background-color: #E8ECF0; }
    &.invalid {
      background: #FEF8F8;
      border: 1px solid #FF5151; } }
  .invalid-message {
    color: #FF5151;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    line-height: 21px; }
  .stripe-element-label {
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    margin-top: 21px;
    margin-bottom: 10px;
    display: block;
    font-weight: bold;
    color: #38435E;
    opacity: 0.5;
    white-space: nowrap; }

  .cvv-icon {
    margin-top: 52px; } }
