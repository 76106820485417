 :local(.workoutDetailStyle) {
  padding-right: 0 !important;
  font-family: Gilroy;
  .workout-list {
    list-style: none;
    padding: 0 24px;
    margin: 0 -22px;
    margin-top: -15px;
    border-top: 1px solid rgba(0,0,0,0.1);
    li {
      display: block;
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      color: #383E48;
      letter-spacing: 0.3px;
      font-size: 13px;
      font-family: 'Gilroy-bold';
      label {
        font-size: 12px;
        color: #60656D;
        font-family: 'Gilroy'; }
      .name {
        padding-right: 10px; }
      .clickable {
        color: #264382;
        cursor: pointer;
        &>span {
          border-bottom: 2px dashed #264382; } }
      .date span {
         text-transform: none; } }
    .activity-toggle-container .ant-tabs-nav .ant-tabs-tab {
      margin-right: 0; } } }
