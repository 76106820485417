 :local(.Style) {
  min-height: 51px;
  color: rgba(117,135,157,.8);
  .date-set-tab {
    margin-top: 10px;
    margin-bottom: 15px;
    margin-right: 12px;
    min-width: 137px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,.1);
    background: rgba(117,135,157,.1);
    text-align: center;
    font-size: 14px;
    // font-weight: bold
    letter-spacing: 0.366667px;
    text-transform: uppercase;
    color: #3F4752;
    cursor: pointer;
    svg {
      margin-left: 10px;
      color:  #75879D; }
    &.active {
      background: #75879D;
      color: #ffffff;
      svg {
        color: #ffffff; } } }
  .data-set-action-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 10px;
    padding-right: 160px;
    margin-bottom: 40px; }

  @media screen and (max-width: 1024px) {
    .data-set-action-container {
      padding-left: 25px; } } }
