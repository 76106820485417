 :local(.resultScoreSectionStyle) {
  background: #ffffff;
  margin-top: 24px;
  overflow: hidden;
  border: 1px solid #E3E6EA;
  border-radius: 8px;
  display: flex;
  &:first-child {
    margin-top: 0; }

  .score-left {
    background: #FAFAFA;
    width: 50%;
    border-right: 1px solid #E3E6EA;
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      height: 57px;
      align-items: center;
      margin: 0 24px;
      border-bottom: 1px solid #E3E6EA;
      text-transform: capitalize;
      font-family: 'Gilroy-Bold';
      .value {
        font-size: 20px;
        span {
          color: rgb(163, 175, 185); } } } }

  .score-right {
    padding: 0px 24px;
    width: 50%;
    font-size: 12px;
    .title {
      display: flex;
      height: 57px;
      align-items: center;
      font-size: 14px;
      color: #4C6072;
      letter-spacing: 0.3px;
      border-bottom: 1px solid #E3E6EA;
      text-transform: capitalize; }
    .item-list {
      margin: 0 -24px;
      padding-top: 18px;
      display: flex;
      flex-wrap: wrap;
      .item {
        display: flex;
        width: 50%;
        justify-content: space-between;
        padding: 0 24px;
        line-height: 29px;
        &:hover {
          color: #2B4B8F; }
        // text-transform: capitalize
        &:nth-child(2n+1) {
          border-right: 1px solid rgba(135,148,161,0.2); }
        .name {
          cursor: pointer; } }
      .status {
        text-transform: uppercase;
        min-width: 48px;
        font-family: 'Gilroy-Bold';
        text-align: right;
        &.RISK {
          color: #FF6B00; }
        &.CONCERN {
          color: #F5BA40; }
        &.OPTIMAL {
          color: #40C47C; } } }
    .more-opr {
      color: #2B4B8F;
      // font-size: 12px
      line-height: 12px;
      margin-top: 23px;
      font-family: 'Gilroy-Bold';
      cursor: pointer; } }

  .pipe-container {
    padding-left: 10px;
    padding-top: 10px;
    .pipe {
      width: 110px;
      display: inline-block;
      vertical-align: top; }
    .pipe-list {
      width: calc(100% - 110px);
      display: inline-block;
      list-style: none;
      font-size: 12px;
      padding-left: 10px;
      padding-top: 15px;
      padding-right: 24px;
      li {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #4C6072;
        margin-bottom: 10px;
        letter-spacing: 0.3px;
        font-family: 'Gilroy-Bold';
        .label-name {
          color: #A5AFB8;
          letter-spacing: 0.7px;
          span {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 8px;
            border-radius: 2px; } } } } }
  .entries-container {
    padding-top: 19px;
    .entry {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // height: 29px
      .name {
        font-family: Gilroy;
        font-size: 12px;
        line-height: 29px;
        letter-spacing: 0.3px;
        text-transform: capitalize;
        color: #4C6072; }
      .status {
        text-transform: uppercase;
        min-width: 48px;
        font-family: 'Gilroy-Bold';
        text-align: right;
        &.RISK {
          color: #FF6B00; }
        &.CONCERN {
          color: #F5BA40; }
        &.OPTIMAL {
          color: #40C47C; } } } }
  .more-opr {
    color: #2B4B8F;
    line-height: 12px;
    margin-top: 10px;
    margin-bottom: 28px;
    font-family: 'Gilroy-Bold';
    cursor: pointer; } }
