 :local(.resultContainerStyle) {
  width: 880px;
  background: #fff;
  box-shadow: 10px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 48px 40px 40px 40px;
  .result-group:last-child {
    margin-bottom: 0px; }
  .result-group {
    margin-bottom: 48px;
    .group-title {
      font-family: Gilroy;
      font-size: 28px;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #323F4A; }
    .group-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.3px;
      color: #52606C;
      margin-top: 8px;
      .highlight {
        font-family: Gilroy-Bold; } }
    .target-container {
      padding: 0px 24px 0px 24px;
      background: rgba(38, 67, 130, 0.05);
      border-radius: 6px;
      .target-group-container {
        padding-top: 34px;
        padding-bottom: 25px;
        p {
          font-family: Gilroy-Bold;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.3px;
          color: #4C6072; }
        .ranked-goals-container {
          font-family: Gilroy;
          font-size: 14px;
          line-height: 34px;
          letter-spacing: 0.3px;
          color: #4C6072;
          .ranked-goal {
            display: flex;
            align-items: center;
            .ranked-goal-title {
              margin-left: 12px; } }
          .ranked-block {
            border-radius: 2px;
            margin-top: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
            .rank-index-container {
              font-family: Gilroy-Bold;
              font-size: 20px;
              line-height: 31px;
              color: #46515A;
              text-align: center;
              .super-index {
                vertical-align: text-bottom;
                display: inline-block; } } }
          .ST {
            background: #F5CA5C;
            height: 70px; }
          .ND {
            background: #F5A55C;
            height: 50px; }
          .RD {
            background: #CCCCCC;
            height: 26px; } }
        .future-goals-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .future-goal {
            display: flex;
            flex-grow: 1;
            align-items: center;
            padding-top: 9px;
            .future-goal-title {
              padding-left: 12px;
              font-family: Gilroy;
              font-size: 14px;
              letter-spacing: 0.3px;
              color: #4C6072; } } } }
      .bottom-line {
        border-bottom: 1px solid rgba(152,169,188,.2); } }
    .resilience-scale-container {
      margin-top: 18px;
      height: 57px;
      .level-line-container {
        display: flex;
        align-items: center;
        height: 34px;
        position: relative;
        .level-line {
          background: #E3E6EA;
          opacity: 0.5;
          border-radius: 13px;
          height: 10px;
          flex-grow: 1; }
        .point {
          float: left;
          position: absolute;
          left: 66.6%;
          transform: translateX(-50%); } }
      .level-title-container {
        margin-top: 7px;
        position: relative;
        .level-title {
          position: absolute;
          font-family: Gilroy;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.3px;
          color: #4C6072;
          transform: translateX(-50%);
          &:first-child {
            left: 0%;
            transform: none; }
          &:last-child {
            right: 0%;
            transform: none; }
          &:nth-child(2) {
            left: 33.3%; }
          &:nth-child(3) {
            left: 66.6%; } } } }
    .recommend-program-container {
      padding: 24px;
      margin-top: 16px;
      .title {
        font-family: Gilroy-Bold;
        font-size: 20px;
        line-height: 124%;
        letter-spacing: 0.3px;
        color: #FFFFFF; }
      .desc {
        margin-top: 16px;
        font-family: Gilroy;
        font-size: 14px;
        line-height: 140%;
        color: #FFFFFF;
        opacity: 0.7; }
      .view-program-container {
        margin-top: 8px;
        background: #FFFFFF;
        box-shadow: 10px 10px 30px rgba(38, 67, 130, 0.1);
        border-radius: 30px;
        padding: 15px 23px 12px 23px;
        display: inline-block;
        .label {
          font-family: Gilroy-Bold;
          font-size: 13px;
          line-height: 100%;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #264382; } } } }
  img[alt="empty"] {
    width: 300px; }
  .send-btn {
    height: 40px;
    // line-height: 40px
    text-transform: uppercase;
    width: 191px;
    font-size: 13px;
    float: right;
    margin-top: -55px; } }
