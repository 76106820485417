.heartRateTitle {
  font-family: 'Gilroy-bold';
  font-weight: 600;
  font-size: 14px;
  .anticon {
    color: #2B4B8F;
    margin-left: 8px; }
  & + .activityHeartRateZone {
    margin-top: 15px; } }
.activityHeartRateZoneContainer {
    // .activityHeartRatePregress
    //   margin-bottom: 24px
    //   padding-bottom: 24px
    //   font-size: 12px
    //   .progress-title
    //     margin-top: 15px
    //     color: #383E48
    //     font-weight: bold
    //   .moderate .ant-progress-bg
    //     background: #FFB570
    //   .intense .ant-progress-bg
    //     background: #FF7759
    //   .info
    //     color: #A6B0B9
    .activityHeartRateZone {
      margin-bottom: 15px;
      display: flex;
      // justify-content: space-between
      height: 48px;

      .activityHeartRateInfoContainer {
        display: flex;
        flex-direction: column;
        // align-items: flex-end
        padding-top: 5px;
        margin-left: 12px;
        .time {
          font-family: Gilroy-Bold;
          font-size: 12px;
          line-height: 150%;
          color: #383E48; }
        .subContent {
          font-size: 10px;
          line-height: 150%;
          color: #A6B0B9;
 } } } }          // text-align: right
