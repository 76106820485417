 :local(.root) {
  position: relative;
  height: 100%;
  .center {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    .center-item {
      margin-top: 44px;
      img {
        width: 422px; } }
    .title {
      font-size: 36px;
      letter-spacing: 0.3px;
      line-height: 40px;
      color: #A7B1BF; }
    .description {
      font-size: 14px;
      line-height: 24px;
      color: #373A3C;
      margin-top: 40px;
      opacity: 0.8; }
    .refresh {
      font-size: 16px;
      color: #2B4B8F;
      cursor: pointer;
      letter-spacing: 0.3px; } }
  // .footer
  //   position: absolute
  //   bottom: 0
  //   text-align: center
  //   font-size: 14px
  //   opacity: 0.4
 }  //   width: 100%
