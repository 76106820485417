 :local(.profileAndSettingStyle) {
  padding-right: 50px;
  .container {
    // width: 1030px
    // margin: 0px 207px
    .ant-btn.box-button {
      color: #264382; } //!important
    .back-title {
      margin-left: 12px;
      font: Gilroy;
      color: #264382;
      font-size: 14px; } } }
