 :local(.DateRageStyle) {
  .switch-date-range {
    display: flex;
    width: 270px;
    font-size: 14px;
    font-family: Gilroy;
    font-weight: 500;
    border-radius: 12px;
    padding: 4px 10px;
    // margin-top: -24px // remove program -----
    // margin-left: 346px
    justify-content: space-between;
    align-items: center;
    color: #52606C;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    background: #ffffff;
    border: 1px solid #DEE2EC;
    .arrow {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      text-align: center;
      line-height: 30px;
      &.hide {
        visibility: hidden; } } } }
