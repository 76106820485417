 :local(.timelineStyle) {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  margin-top: 30px;
  .left-menu {
    width: 280px;
    border-right: 1px solid #f1f2f5;
    ul {
      list-style: none;
      padding-left: 0;
      margin: 0; }
    li {
      height: 64px;
      padding-left: 25px;
      border-bottom: 1px solid #f1f2f5;
      display: flex;
      align-items: center;
      &>div {
       max-width: 211px; }
      &:last-child {
        border: none; }
      &.active {
        background: #F6F7F9;
        border-left: 3px solid #223064;
        span {
          background: #223064; } }
      svg {
        margin-right: 16px; }
      span {
        display: block;
        margin-right: 16px;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background: rgba(34, 48, 100, 0.2);
        font-size: 13px;
        color: #ffffff;
        line-height: 24px;
        text-align: center; }
      .date {
        color: #60656D;
        font-size: 12px; } } }


  .right-container {
    width: calc(100% - 280px);
    position: relative;
    .title {
      padding: 24px 32px;
      border-bottom: 1px solid #f1f2f5;
      span {
        font-size: 12px;
        color: #8D99A5;
        float: right; }
      h3 {
        font-size: 16px;
        color: #383E48;
        font-family: Gilroy-Bold; }
      .description {
        color: #60656D;
        font-size: 14px; } }
    .body {
      padding: 24px 32px;
      .common-items {
        padding-bottom: 60px;
        .html-container {
          display: inline-block;
          a {
            color: #52606C !important; }
          p {
            margin: 0; }
          & + .anticon {
            margin-left: 10px;
            color: #264382; } } }
      .opr {
        position: absolute;
        right: 25px;
        bottom: 25px;
        .notes-icon {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          position: relative;
          background: rgba(117,135,157,.1);
          display: inline-block;
          margin-right: 16px;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
          // &:after
          //   content: ''
          //   width: 8px
          //   height: 8px
          //   border-radius: 8px
          //   display: block
          //   position: absolute
          //   right: 0
          //   top: 0
 } } }          //   background: #264382


    .add-item {
      display: inline-block;
      cursor: pointer;
      margin-top: 17px;
      color: #223064;
      font-size: 14px; } }

  .ant-table-content {
    div, th, td {
      border-color: #f1f2f5 !important;
      color: #52606C; }
    th div {
      color: rgba(56,62,72,.5); }
    .ant-table-thead > tr > th {
      background: transparent;
      color: rgba(56,62,72,.5); } } }


 :local(.timelineMiniStyle) {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 16px;
  padding: 34px 26px 23px;
  position: relative;
  // overflow-x: auto
  overflow-x: hidden;
  overflow-y: hidden;
  height: 147px;
  // cursor: all-scroll
  &::-webkit-scrollbar {
    width: 0 !important; }
  .arrow {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: 174px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    span {
      display: none;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background: #ffffff;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      text-align: center;
      line-height: 40px; }
    &:hover {
      span {
        display: inline-block; } } }
  .arrow-left {
    left: 0;
    &:hover {
      background: linear-gradient(90deg, #FFFFFF 11.13%, rgba(255, 255, 255, 0) 100%); } }
  .arrow-right {
    right: 0;
    justify-content: flex-end;
    &:hover {
      background: linear-gradient(270deg, #FFFFFF 11.13%, rgba(255, 255, 255, 0) 100%); } }

  .timeline-container {
    white-space: nowrap;
    position: relative;
    .time-line-item {
      display: inline-block;
      vertical-align: top;
      .icon {
        img {
          width: 32px;
          margin-right: 2px; }
        &:after {
          content: '';
          display: inline-block;
          border: 1px solid #264382;
          opacity: 0.1;
          width: calc(100% - 32px - 4px);
          margin-right: 2px; } }
      .name {
        min-width: 140px;
        padding-right: 20px;
        margin-top: 18px;
        font-size: 16px;
        color: #383E48;
        // font-family: Gilroy-Bold
        opacity: 0.4; }
      .date {
        font-size: 14px;
        color: #6D7D8C;
        line-height: 16px;
        opacity: 0.4; }
      &.done {
        .icon:after, .name, .date {
          opacity: 1; } }
      &:last-child {
        .icon:after {
          border: none;
          width: 100px; }
        .name {
          min-width: auto;
          padding-right: 0; } } } } }


