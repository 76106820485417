 :local(.Style) {
  .information-editor {
    border: 2px solid #264382;
    border-radius: 8px;
    // max-height: 340px
    .ant-legacy-form-item {
      margin-bottom: 0;
      max-height: 194px; }
    .ant-legacy-form-item-label {
      display: none; }
    textarea {
      border: none;
      resize: none;
      &.ant-input:focus {
        box-shadow: none; } }
    .ant-divider {
      margin: 17px 0; }
    .mark-down-support {
      display: block;
      padding: 14px 20px;
      background: #F8F8F8;
      color: #97A0A7;
      border: 1px solid #E8ECF0;
      margin: 0;
      .header {
        display: flex;
        justify-content: space-between; }
      .direction-label {
        cursor: pointer;
        color: #264382; }
      span {
        color: #264382;
        cursor: pointer;
        border-bottom: 1px solid #264382; }
      .direction-content {
        border-top: 1px solid #E8ECF0;
        margin-top: 6px;
        padding-top: 16px;
        margin-bottom: 0;
        p {
          margin-bottom: 0; }
        ul {
          padding-left: 20px; } } }

    .ant-input {
      padding: 24px 15px; }

    .editor-footer {
      letter-spacing: 0.3px;
      font-size: 12px;
      text-align: right;
      padding: 18px 20px;
      &.templates {
        display: flex;
        justify-content: space-between; }
      .buttons {
        text-align: right;
        button {
          font-family: 'Gilroy-bold';
          border: none;
          box-shadow: none;
          margin-top: -10px;
          font-weight: bold; } } }

    &.text-only {
      .ant-input {
        border-radius: 8px 8px 0 0;
        border: none;
        border-bottom: 2px solid;
        border-color: #E8ECF0 !important;
        box-shadow: none !important; }
      .editor-footer {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .limit {
        color: #97A0A7; }
      .buttons button {
        margin: 0; } } }

  .information-container {
    // background: rgba(38, 67, 130, 0.05)
    border-radius: 8px;
    padding: 11px 16px;
    color: #264382;
    letter-spacing: 0.3px;
    border: 2px dashed #264382;
    text-align: center;
    // font-weight: 600
    position: relative;
    &.Opened, &.Sent, &.Locked {
      background: rgba(244, 245, 247, 0.5);
      border: none;
      color: #383E48;
      .content {
        min-height: auto; } }
    &.text-only {
      color: #383E48;
      text-align: left;
      font-family: 'Gilroy-Bold';
      .opr {
        padding: 5px 16px; } }

    &:hover .opr {
      display: flex; }
    &.Sent:hover .opr, &.Opened:hover .opr, &.Locked:hover .opr {
      display: none; }
    .null-state, .opr-button {
      cursor: pointer;
      text-transform: uppercase; }
    .opr {
      display: none;
      font-size: 32px;
      justify-content: flex-end;
      align-items: baseline;
      float: right;
      position: absolute;
      background-image: linear-gradient(to top right, rgba(255,255,255,.5) 100%, white 10%);
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      top: 0;
      left: 0;
      padding: 20px 16px;
      .opr-button {
        margin-left: 8px; } }
    .null-state .anticon {
      margin-right: 16px; }
    .content {
      min-height: 50px;
      display: flex;
      text-align: left;
      font-family: Gilroy;
      font-size: 14px;
      color: #70808E;
      .avatar {
        color: #479FEC;
        font-size: 16px;
        padding-right: 16px; }
      strong {
        font-family: 'Gilroy-Bold'; } }

    & + .done-status {
      margin-top: 8px;
      color: #97A0A7;
      letter-spacing: 0.3px;
      font-size: 12px; } } }
