 :local(.userInfoStyle) {
  padding: 46px 0;
  border-bottom: 1px solid #F4F6F8;
  text-align: center;
  h1 {
    color: #323F4A;
    font-size: 40px;
    font-family: 'Gilroy-bold'; }
  .content {
    display: flex;
    justify-content: center; }
  .info-list {
    width: 257px;
    padding: 0 35px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.3px;
    &:first-child {
      border-right: 1px solid rgba(35,48,100,.1); } }
  .info-item,  .info-value {
    color: #94A0AA;
    div {
      margin: 10px 0;
      text-align: left; } }
  .info-value {
    color: #4C6072;
    div::first-letter {
      text-transform: capitalize; } } }


