.common {
  .ant-input-number {
    width: 100%; }
  .ant-form-item {
    .ant-input-group-addon, .ant-input-group-addon + .ant-input {
      border: none; }
    .ant-select-lg, .ant-input-lg, .ant-picker-large .ant-picker-input > input {
      font-size: 14px; }
    .ant-form-item-label > label {
      text-transform: uppercase;
      font-size: 12px;
      height: auto !important; }
    .ant-input-affix-wrapper-lg {
      max-height: 45px; }
    .ant-picker, .ant-input-number-lg input {
      height: 45px; } }
  .ant-select-selector, .ant-input-lg, .ant-input-group-wrapper-lg .ant-input-lg,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 45px;
    .ant-select-selection-item {
      line-height: 45px; } }
  .ant-input-affix-wrapper > input.ant-input {
    height: 32px; }
  .ant-form-item {
    min-height: 40px;
    .ant-radio-group {
      padding: 0 10px; } } }

 :local(.phsForm) {
  @extend .common;
  .ant-form-item {
    position: relative;
    text-align: left;
    .ant-form-item-margin-offset {
      margin-bottom: 0 !important; }
    .ant-form-item-label {
      position: absolute;
      z-index: 2;
      background: #fff;
      top: 8px;
      left: 10px;
      margin-top: -18px;
      padding: 0 3px;
      overflow: visible;
      & > label {
        color: #8d99a5;
        letter-spacing: .4px;
        background: #fff;
        white-space: nowrap; } }
    .ant-input-prefix + input {
      padding-left: 5px; }
    .ant-select-disabled .ant-select-selector, .ant-input[disabled], .ant-picker.ant-picker-disabled, .ant-input-number-disabled {
      background: #ffffff !important; } } }

 :local(.normalForm) {
  @extend .common;
  .ant-form-item-label {
    height: 30px;
    letter-spacing: .3px;
    padding-bottom: 4px;
    padding-top: 4px;
    &>label {
      opacity: .8;
      color: #5c626a; } }
  .ant-input, .ant-picker, .ant-select-selector, .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f5f6f8 !important;
    border: 1px solid rgba(38,67,130,.5); }
  .ant-form-item-control {
    .ant-input-disabled, .ant-picker-disabled, .ant-select-disabled .ant-select-selector, .ant-input-affix-wrapper {
      border: none; } } }
