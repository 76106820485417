 :local(.select) {
  position: relative;
  &.disabled {
    cursor: not-allowed;
    .anticon {
      opacity: .5;
      cursor: not-allowed; } }
  .list-container {
    position: absolute; }
  .current-value {
    height: 50px;
    line-height: 50px;
    display: inline-block;
    border-bottom: 1px solid #264382;
    color: #4C6072;
    font-size: 24px;
    // white-space: nowrap
    .anticon {
      margin-left: 20px;
      font-size: 16px;
      cursor: pointer; } }
  .options-list {
    position: relative;
    // top: 53px
    list-style: none;
    padding: 0;
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    transition: height 1s ease;
    z-index: 4;
    &.open {
      height: auto; }
    &.close {
      height: 0; }
    .option-item {
      font-size: 14px;
      min-height: 45px;
      padding: 12px;
      padding-right: 60px;
      margin: 0;
      cursor: pointer;
      &.active {
        background: #eaeeee; }
      &:hover {
        background: #EAEDF3; } } } }
