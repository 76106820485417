 :local(.goalsDataStyle) {
  &.goals-container {
    padding-top: 40px; }
  .goals-category-container {
    margin: 30px 0;
    margin-right: 57px;
    .title-container {
      display: flex;
      align-items: center;
      // justify-content: space-between
      &>div {
        width: calc(100% - 160px - 40px); }
      .title {
        color: #383E48;
        font-size: 24px;
        font-weight: 600;
        padding-left: 20px;
        margin-bottom: 5px;
        display: inline-block; }
      .empty {
        padding-left: 20px;
        color: #383E48;
        font-size: 14px;
        opacity: .5; }
      .rate {
        padding: 0 20px;
        span {
          margin-left: 5px; } } }
    .category-body .empty {
      padding: 30px 0;
      color: #383E48;
      letter-spacing: 0.3px;
      opacity: .5;
      padding-left: 20px;
      img {
        margin-right: 10px;
        vertical-align: bottom; } }
    .list-container {
      width: 100%;
      color: #8D99A5; } }
  .subtitle {
    font-weight: bold;
    font-size: 18px;
    color: #4C6072;
    letter-spacing: 0.3px; }
  .subtitle-number {
    color: #C1C7CD;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.3px;
    margin-left: 8px; }
  .separator-line {
    border: 1px solid #000000;
    opacity: 0.1;
    margin-top: 30px; }

  .fix-separator-line {
    flex-grow: 1; }
  .add-goal-button {
    // width: 160px
    // height: 48px
    font-size: 16px;
    background: #264382;
    color: white;
    .anticon {
      margin-right: 5px; } } }

 :local(.archivedGoalsStyle) {
  padding-top: 21px;
  .goals-category-container {
    border-top: 1px solid rgba(0,0,0,.1);
    padding-top: 25px;
    padding-bottom: 46px; }
  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
    .icon-img {
      border: 4px solid rgba(166,176,185,.2);
      border-radius: 100%;
      padding: 3px;
      margin-right: 22px;
      img {
        width: 51px;
        height: 51px;
        border-radius: 100%; } }
    .title {
      font-family: Gilroy;
      font-size: 18px;
      line-height: 150%;
      color: #4C6072;
      font-weight: 500; } }
  .card {
    width: 329px;
    margin-right: 13px;
    display: inline-block;
    background: #EAEDF3;
    border-radius: 12px;
    padding: 10px 18px;
    position: relative;
    font-size: 12px;
    margin-bottom: 30px;
    vertical-align: top;
    .title {
      font-size: 14px;
      color: #383E48;
      font-weight: bold;
      margin-bottom: 15px; }
    .date {
      color: rgba(112,128,142,.8);
      letter-spacing: 0.006em; }
    .opr {
      position: absolute;
      width: 100%;
      text-align: right;
      bottom: 0;
      right: 18px;
      padding-bottom: 10px;
      color: rgba(38,67,130,.8);
      font-weight: bold;
      span {
        cursor: pointer;
        margin-left: 10px;
        img {
          margin-right: 5px;
          vertical-align: middle; } } } } }

