 :local(.admin) {
  background: #ffffff;
  .sub-header {
    .ant-tabs .ant-tabs-top-content, .ant-tabs-ink-bar {
      display: none !important; } }
  .admin-container {
    padding: 20px;
    margin-bottom: 20px;
    .admin-tabs {
      overflow: hidden;
      margin: 20px 0;
      border-bottom: 1px solid rgba(0,0,0,.1);
      font-size: 16px;
      line-height: 25px;
      text-transform: capitalize;
      position: relative;
      padding: 12px;
      .admin-tabs-container {
        position: absolute;
        white-space: nowrap;
        top: 0;
        left: 0; }
      .icon-container-left, .icon-container-right {
        position: absolute;
        top: 0;
        padding: 0 10px;
        background: #ffffff;
        z-index: 1;
        cursor: pointer; }
      .icon-container-left {
        left: 0; }
      .icon-container-right {
        right: 0; }
      span {
        margin-right: 68px;
        cursor: pointer;
        &.active {
          font-weight: bold;
          border-bottom: 2px solid rgba(0,0,0,.5); } } } }
  .category {
    margin: 20px 0;
    padding: 20px 40px;
    background: #E4F1FD;
    color: #297FCA;
    border-radius: 10px;
    overflow: auto;
    .sub-category {
      display: flex;
      align-items: flex-start;
      &>div {
        padding: 10px; } }
    .white-btn {
      background: #ffffff !important; }
    h5 {
      font-weight: bold;
      font-size: 24px;
      padding-bottom: 10px;
      color: #297FCA;
      button {
        margin-left: 10px;
        vertical-align: middle; } }
    &>.biomarkers {
      columns: 4; }
    .biomarkers {
      &.item-inner-list {
        width: 300px;
        display: inline-block;
        vertical-align: top;
        .biomarker-item {
          min-width: 200px; } }

      .biomarker-item {
        margin: 10px 0;
        min-width: 180px;
        // background: #ffffff
        display: inline-block;
        font-size: 16px;
        line-height: 32px;
        border-radius: 2px;
        padding: 5px 10px;
        cursor: pointer;
        span {
 }          // width: calc(100% - 45px )
        .ant-typography {
          margin-bottom: 0; }
        .anticon {
          margin-left: 30px;
          float: right;
          margin-top: 7px; } } } } }
