.date-text {
    color: '#4c6072';
    font-size: 16px;
}

.tooltip-container {
    display: flex;
    justify-content: space-between;
    /* width: 200px; */
}

.tooltip {
    position: absolute;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    font-size: 18px;
    z-index: 10;
    width: 20rem;
}

.tooltip.red {
    color: red;
}

.tooltip.green {
    color: green;
}

