.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-family: Gilroy;
  font-size: 14px;
  color: #4C6072;
  background: #FFFFFF;
  border: 1px solid #DEE2EC;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  overflow: hidden;
  .contentBox {
    border-left: 6px solid #C4C4C4;
    padding: 20px;
    .title {
      color: #383E48;
      font-size: 16px;
      padding-bottom: 5px;
      &:first-letter {
        text-transform: uppercase; }
      & + div {
        margin: 10px 0; } }
     :global(.anticon) {
      margin-right: 12px; }
    .activeTitle {
      font-size: 24px; } }
  .activeBox {
    border-color: #0D2350; } }

 :global(.active.noItems) {
  display: inline-block; }

.exerciseCard {
  overflow: hidden;
  border: 1px solid #DEE2EC;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background: #ffffff;
  width: 163px;
  margin-right: 10px;
  margin-bottom: 12px;
  .cardBox {
    border-left: 4px solid #C4C4C4;
    padding: 6px 13px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    // cursor: pointer
    .cardTagContainer {
      display: flex;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      .cardTag {
        min-width: 49px;
        color: #ffffff;
        padding: 3px 5px;
        border-radius: 18px;
        font-size: 8px;
        text-transform: uppercase;
        letter-spacing: 0; } } }
  .cardTitle, .cardBody, .cardWorkoutTitle {
    width: 100%;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    letter-spacing: 0.3px;
    color: #383E48;
    font-family: 'Gilroy-Bold';
    span:first-letter {
      text-transform: uppercase; } }
  .cardBody {
     align-items: flex-end; }
  .cardWorkoutTitle {
    margin: 0;
    maargin-top: -5px; }
  .cardSubTitle {
    font-family: Gilroy;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #A6B0B9; }
  .duration {
    font-size: 10px;
    margin-left: 1px; } }

.inActive {
  box-shadow: 4px 4px 20px rgba(255, 0, 0, 0.30); }
