 :local(.fileStyle) {
  margin: 40px 0;
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  letter-spacing: 0.3px;
  .ant-spin.ant-spin-spinning {
    text-align: center;
    padding: 20px;
    width: 100%;
    // color: rgba(129, 144, 156, 0.2)
    .ant-spin-dot i {
      background: rgb(129, 144, 156); } }
  .title {
    font-size: 24px;
    line-height: 150%;
    color: #4C6072;
    display: flex;
    justify-content: space-between; }
  .empty-container {
    padding: 40px 0;
    margin-top: 24px;
    background: #FFFFFF;
    border-radius: 8px; }
  .file-container {
    // padding: 24px
    // padding-bottom: 16px
    margin: 12px 0;
    background: #FFFFFF;
    border-radius: 8px;
    color: #4C6072;
    .file-info {
      // border: 1px solid rgba(129, 144, 156, 0.2)
      border-radius: 8px;
      padding: 20px;
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      .uppercase {
        text-transform: uppercase; }
      .name {
        width: 55%;
        display: flex;
        img {
          margin-right: 16px; }
        &>div {
          max-width: calc(100% - 45px); }
        .ant-typography {
          margin: 0;
          font-size: 16px;
          font-family: Heebo;
          font-weight: 500;
          color: #4C6072; } }
      .left-border {
        border-left: 1px solid #E6E9EB;
        padding-left: 16px;
        span {
          font-size: 12px; } }
      .opr {
        width: 112px;
        display: flex;
        justify-content: flex-end;
        a:last-child, img:last-child {
          margin-left: 16px; }
        img {
          // width: 40px
          height: 40px;
          vertical-align: middle; } } }
    .file-title {
      font-size: 18px;
      padding-top: 10px;
      &:first-letter {
        text-transform: uppercase; } }
    .file-description {
      width: 500px;
      padding: 20px;
      padding-top: 10px;
      margin-left: 62px;
      font-size: 13px;
      font-family: Gilroy;
      white-space: normal;
      color: #52606C; } }
  .load-more {
    text-align: center;
    color: #264382;
    cursor: pointer; } }
