 :local(.filesStyle) {
  .show-all {
    color: #264382;
    cursor: pointer; }
  .ant-typography a {
    color: #3A64C1; }
  .text-primary {
    color: #415A92; }
  .text-complete {
    color: #05B800; }
  .upload-btn {
    cursor: pointer; }
  .phs-section-body {
    padding: 25px 22px;
    .title {
      font-family: Gilroy;
      font-weight: bold;
      font-size: 18px;
      color: #4C6072;
      letter-spacing: 0.3px; } }

  h2 .right-opr {
    font-size: 14px;
    float: right;
    .oprate {
      color: #3F4752;
      text-transform: uppercase;
      letter-spacing: 0.366667px;
      font-size: 14px;
      font-weight: bold;
      line-height: 27px;
      margin-right: 20px;
      cursor: pointer;
      .anticon {
        color: #97AAC6;
        margin-right: 10px; } } }
  .tray-file-container {
    border-top: 1px solid rgba(0,0,0,.1);
    margin: 25px -30px -25px;
    padding: 0 30px;
    .ant-col-6 {
      padding: 15px;
      border-right: 1px solid rgba(0,0,0,.1);
      &:first-child, &:nth-child(4n+1) {
        padding-left: 0; }
      &:last-child, &:nth-child(4n) {
        border: none; } }
    .tray-file-item {
      display: flex;
      align-items: center;
      img {
        width: 21px;
        margin-right: 10px; }
      .file-name {
        width: 63%;
        .ant-typography {
          margin-bottom: 0; } }
      .anticon {
        margin: 0 10px; } } }
  .files-null-state, .files-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.006em;
    // border-top: 1px solid #EAEDF3
    color: #3F4752;
    // &:nth-child(2)
    //   border: none
    .files-item-col {
      border-right: 1px solid #EAEDF3;
      padding-right: 50px;
      & + div {
        padding-left: 50px; } }
    .item-label {
      line-height: 17px;
      font-size: 14px;
      color: rgba(117,135,157,.8);
      margin-bottom: 8px;
      font-weight: 500;
      letter-spacing: 0.3px;
      line-height: 150%; }
    .item-label-value {
      font-size: 14px;
      font-family: Heebo;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.3px; }
    .file-icon, .empty-icon {
      width: 8%; }
    .file-icon {
      position: relative;
      span {
        position: absolute;
        color: #FFFFFF;
        font-size: 24px;
        line-height: 17px;
        top: 30px;
        left: 25px;
        transform: translate(-50%, -50%); } }
    .name {
      width: 20%;
      overflow: hidden;
      &.no-test-date {
        width: 30%; }
 }      // padding-right: 50px
    .operator {
      width: 30%; }
    .date {
      width: 35%;
      display: flex;
      justify-content: space-between;
      &>div:first-child:last-child {
        margin: 0 auto; } }
    .opr {
      width: 12%;
      min-width: 125px;
      text-align: right;
      img {
        margin-left: 22px; } }
    .empty-icon {
      &>span {
        background: #3A64C1;
        font-size: 18px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        border-radius: 100%;
        display: inline-block;
        color: #ffffff;
        text-align: center;
        margin-left: -15px; }
      &>img {
        vertical-align: baseline; } } }
  .files-null-state {
    display: block;
    text-align: center;
    p {
      font-size: 18px;
      line-height: 29px;
      color: #4C6072;
      opacity: 0.5;
      margin: 20px 0;
      text-transform: none;
      font-weight: normal; } }
  // @media screen and (max-width: 768px)
  @media screen and (max-width: 1024px) {
    .files-list-item {
      .files-item-col {
        padding-right: 30px;
        & + div {
          padding-left: 30px; } }
      .date {
        width: 40%; }
      .operator {
        width: auto; } } } }

 :local(.mobileFilesStyle) {
  background-color: white;
  font-family: Gilroy;
  font-style: normal;
  .show-all {
    color: #264382;
    cursor: pointer;
    padding: 16px 22px 24px; }
  .section-title {
    margin: 24px 0px 7px 21px;
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #383E48;
    display: inline-block; }
  .section-separator {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1); }
  .file-list-container {
    .file-separator {
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      margin: 0px 21px; }
    .file-image {
      margin: 30px 16px 29px 22px;
      width: 32px; }
    .file-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .file-share-image {
        margin-right: 21px;
        margin-left: 21px; }
      .file-info-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        .file-name {
          font-weight: bold;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.3px;
          color: #383E48;
          word-break: break-word; }
        .file-test-date {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.3px;
          color: #44433d;
          opacity: 0.8; } } } } }

.modal-container {
  text-align: center;
  color: #4C6072;
  .upload-file-btn {
    background: #264382;
    padding: 0 20px;
    font-size: 14px; }
  &>img {
    margin-bottom: 20px; }
  .file-uploaded {
    text-align: left; } }
