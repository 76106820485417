 :local(.style) {
  // .sub-container
  margin-top: 25px;
  padding-bottom: 22px;
  .title {
    font: Gilroy;
    color: #4C6072;
    font-size: 24px;
    display: flex;
    letter-spacing: 0.3px;
    justify-content: space-between; }

  .box-view {
    background-color: white;
    border-radius: 8px;
    padding: 16px 30px 16px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box-title-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .box-title {
        font: Gilroy;
        color: #4C6072;
        font-size: 18px;
        height: 41px; }
      .box-subtitle {
        font: Gilroy;
        color: #4C6072;
        font-size: 14px;
        line-height: 23px;
        letter-spacing: 0.006em;
        .box-point-text {
          font: Gilroy;
          color: #4C6072;
          font-size: 14px;
          font-weight: 500;
          line-height: 23px;
          letter-spacing: 0.006em; } } }
    .box-button {
      border: 1px solid #264382;
      box-sizing: border-box;
      border-radius: 26px;
      width: 140px;
      height: 30px; }
    .ant-switch-checked {
      background: #2B4B8F; } }

  .has-items {
    margin-top: 15px;
    display: block;
    .box-item {
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      border-top: 1px solid #E6E9EB;
      color: #4C6072;
      &:last-child {
        padding-bottom: 0; } } }

  .signed-forms {
    margin-top: 15px;
    height: 105px; } }

 :local(.userInfoStyle) {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  background-color: white;
  border-radius: 8px;
  .user-avatar-container {
    width: 226px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .user-avatar {
      width: 140px;
      height: 140px;
      border-radius: 70px;
      margin-bottom: 18px; } }
  .user-info-box {
    display: flex;
    flex-direction: column;

    .user-basic-info {
      flex-grow: 1;
      margin-left: 13px;
      padding: 13px 21px 9px 0px;
      display: flex;
      flex-direction: column;
      min-width: 780px;
      .top {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        .title {
          font-family: Gilroy;
          font-size: 14px;
          line-height: 29px;
          text-transform: uppercase;
          color: #4C6072; }
        .edit {
          font-family: Gilroy;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.3px;
          color: #264382;
          padding-top: 5px;
          cursor: pointer; } }
      .bottom {
        margin-top: 15px;
        .ant-legacy-form-item-required::before {
          content: ''; }
        .ant-input, .ant-select-selection, .ant-select-selector, .ant-picker {
          background-color: #F5F6F8;
          border: 1px solid rgba(38, 67, 130, 0.5); }
        .ant-select-disabled > .ant-select-selection , .ant-select-disabled >  .ant-select-selector {
          border: 0px; }
        label {
          margin-left: -8px; } }
      .formIsEditing {
        .ant-input, .ant-select-selection, .ant-select-selector, .ant-picker {
          border: 0px; } } } } }

 :local(.settingStyle) {}

 :local(.whiteBoxView) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  .logo {
    position: fixed;
    left: 30px;
    top: 20px;
    width: 150px; }
  .white-box-container {
    background-color: white;
    width: 500px;
    min-height: 631px;
    border-radius: 18px;
    // overflow-y: auto
    overflow: hidden;
    position: relative;
    .back {
      margin: 24px 0 0 22px;
      position: absolute;
      cursor: pointer;
      .back-title {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #264382;
        letter-spacing: 2.8px; } }
    .white-box-container-title {
      text-align: center;
      font-family: Gilroy;
      font-size: 36px;
      letter-spacing: 0.3px;
      color: #A7B1BF;
      margin-top: 54px; }
    .white-box-container-notice {
      margin-top: 10px;
      font-family: Heebo;
      font-size: 14px;
      padding: 0 100px;
      line-height: 150%;
      color: rgba(92,98,106,.8);
      text-align: center;
      span {
        font-weight: bold; } }

    .form-view-box {
      margin: 15px 22px 26px 20px;
      min-height: 430px;
      overflow: auto;
      .form-view {
        background-color: #FCFDFD;
        border: 1px solid rgba(43, 75, 143, 0.3);
        border-radius: 12px;
        width: 457px;
        height: 100px;
        padding-left: 29px;
        padding-right: 34px;
        margin-top: 15px;
        .form-view-container {
          font-family: Heebo;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .form-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 25px; }
          .form-title {
            font-size: 18px;
            color: #29303E;
            letter-spacing: 0.3px; }
          .form-view-icon-title {
            margin-top: 5px;
            color: #264382;
            letter-spacing: 0.3px;
            line-height: 100%; }
          .opr-icon {
            width: 20px;
            margin: 0 auto; } } }
      .add-more-tip {
        color: #3561C0;
        text-align: center;
        margin: 20px 0;
        cursor: pointer;
        font-family: Heebo;
        font-weight: 500;
        font-size: 12px; } } } }

 :local(.confirmPopupStyle) {
  font-family: Gilroy;
  .ant-modal-footer {
    border: none;
    padding: 24px;
    .ant-btn {
      border: none; } }
  h3.title {
    font-size: 24px;
    color: #4C6072;
    margin-bottom: 21px; }
  p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    strong {
      font-family: 'Gilroy-bold';
      color: rgba(0, 0, 0, 0.9); } } }
