 :local(.acsmBreakDownStyle) {
  .zone-breakdown-container {
    margin-bottom: 16px;
    display: flex;
    .zone-background {
      background: #EAEFF7;
      border-radius: 6px;
      height: 32px;
      .zone {
        border-radius: 6px;
        height: 32px; }
      .zone-minimum {
        display: inline;
        height: 32px;
        width: 2px;
        float: left; } }
    .zone-data-container {
      margin-left: 16px;
      .zone-data svg {
        vertical-align: bottom; }
      .zone-desc svg {
        vertical-align: top; } } }
  .zone-chart-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 10px 16px;
    border-radius: 8px;
    background: rgba(38, 67, 130, 0.05);
    svg {
      vertical-align: middle; }
    .tip {
      color: #70808E;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: bold;
      font-size: 9px;
      span:first-child, i:first-child {
        margin-right: 8px;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box; }
      .rectangle {
        background: #EAEFF6;
        border: 2px solid #CFD7E2;
        box-sizing: border-box;
        border-radius: 4px;
        width: 14px;
        height: 14px; }
      .dot {
        background: black;
        width: 2px;
        height: 2px;
        display: inline-block;
        margin-right: 2px; } } }
  .list {
    width: 251px;
    &>div {
      padding-left: 25px;
      &:first-child {
        border-bottom: 1px solid #E4EAEF; }
      span.icon {
        margin-left: -26px;
        margin-top: 8px;
        float: left;
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 4px; }
      .Moderate {
        background: #F7AD69; }
      .Intense {
        background: #FC7558; }
      div {
        padding: 7px 0;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        border-bottom: 1px solid #E4EAEF;
        span:first-child {
          text-transform: uppercase; }
        &:last-child {
          border: none; } }
      .title {
        font-family: "Gilroy-Bold";
        letter-spacing: 1px;
        color: #383E48; }
      .detail-item {
        letter-spacing: 0.5px;
        color: #70808E; } } }
  .pie {
    position: relative;
    margin-left: 60px;
    width: 200px;
    img {
      width: 166px; }
    .pie-percentage {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 24px;
      font-family: 'Gilroy-Bold';
      color: #383E48;
      text-align: center;
      line-height: 200px;

      // top: 50%
      // left: 50%
      // transform: translate(-50%, -50%)
      letter-spacing: 0.3px; } } }
