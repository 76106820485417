.section-right img {
    height: 200px;
    width: 300px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.section-left {
    padding: 40px;
    max-width: calc(100% - 308px);
    line-height: 24px;
}

.resource-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    background-color: whitesmoke;
    margin: 80px 0 70px;
}

.resource-title {
    font-size: 18px;
    font-weight: 500;
    color: rgb(38, 67, 130);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.resource-description {
    margin-top: 8px;
    font-size: 15px;
    font-weight: 500;
    color: rgb(112, 128, 142);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.resource-link-wrapper {

    margin-top: 8px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.resource-link {
    margin-left: 8px;
    max-width: calc(100% - 24px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}