 :local(.confirmStyle) {
  border-radius: 8px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  padding: 32px 24px;
  footer {
    text-align: right;
    margin-top: 40px;
    button {
      margin-left: 10px; }
    .ant-btn-background-ghost {
      border: none;
      color: #4C6072;
      box-shadow: none; } } }
