.report-blood-chats {
  margin: 30px 0;
  height: 630px;
  border: 1px solid #EAEDF3;
  .summary {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 196px;
    .pie {
      margin-left: 42px;
      width: 141px;
      height: 141px; }
    .pie-number {
      // margin-top: 53px
      margin-left: 53px; } }
  .line {
    border-top: 1px solid #EAEDF3;
    height: 0px;
    width: 100%; }

  .bars {
    position: relative;
    overflow: hidden;
    display: flex;
    height: 434px;
    justify-content: flex-start;
    padding-bottom: 20px;
    .VictoryContainer {
      svg {
        width: auto !important;
        max-width: 100%; } } } }

.report-files {
  .upload-btn {
    margin-left: 35px; }
  .files-card {
    padding: 10px;
    color: #4C6072;
    border-radius: 8px;
    &:hover {
      background: rgba(196,196,196,.2); } }
  .card-cover {
    overflow: hidden;
    height: 134px;
    border-radius: 8px;
    &>img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .video-thum, .file-content {
      position: relative;
      width: 100%;
      height: 100%;
      &>img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
    .video-thum {
      background: rgba(0, 0, 0, 0.8); }
    .file-content {
      background: #264382; } }
  .ant-card-body {
    padding: 0;
    .card-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .file-info {
        width: 85%;
        font-size: 14px;
        letter-spacing: 0.366667px;
        text-transform: capitalize;
        .ant-typography {
          margin: 0; } } } } }
.report-blood-test-result {
  margin-bottom: 95px;
  margin-top: 45px;
  .category {
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: flex-end;
      .left {
        width: auto !important;
        color: #4C6072;
        font-size: 21px;
        font-weight: bold;
        line-height: 29px;
        span {
          color: #323A46;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 2px;
          opacity: 0.5; } }
      .right {
        width: auto !important;
        span {
          color: rgba(50,58,70, .8);
          text-transform: uppercase;
          letter-spacing: 0.3px;
          line-height: 29px;
          font-size: 12px;
          display: inline-block;
          margin: 0 35px;
          &:before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 5px; }
          &.optimized:before {
            background: #40C47C; }
          &.concern:before {
            background: #FFB800; }
          &.risk:before {
            background: #FF6B00; } } } } }
  .biomarkers-cotainer {
    .biomarker-item {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #4C6072;
      font-size: 14px;
      text-transform: uppercase;
      &:nth-child(2n) {
        background: rgba(241,243,245,.3); }
      .name {
        width: calc(100% - 200px - 119*5px); }
      .ranage {
        width: 100px;
        text-align: center; }
      .unit {
        text-align: center;
        width: 100px;
        color: rgba(76,96,114,.6); }
      .range-bar {
        .range-item {
          display: inline-block;
          position: relative;
          .item-bar {
            width: 117px;
            height: 8px;
            margin: 0 1px; }
          svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%); } } } } } }

 :local(.singleFileStyle) {
  &.single-file {
    min-height: 100px;
    .cover-photo {
      position: relative;
      &>img {
        width: 100%;
        margin: 0;
        object-fit: cover; }
      .tool-bar {
        padding: 10px 0;
        height: 61px;
        background: #75879D;
        text-align: right;
        width: 100%;
        color: #ffffff;
        .upload-progress {
          position: absolute;
          width: 100%;
          left: 0;
          top: 45px; }
        &>.anticon {
          font-size: 1.3em;
          margin: 0 20px;
          vertical-align: middle; }
        .cancel {
          margin: 0 20px; }
        .ant-btn.white {
          color: #ffffff;
          border-color: #ffffff; } } } } }
