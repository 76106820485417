 :local(.messageStyle) {
  letter-spacing: 0.3px;
  .ant-input {
    min-height: 44px;
    border: 1px solid #DEE2EC;
    border-radius: 4px;
    font-size: 14px;
    color: #323A46;
    margin-bottom: 16px;
    padding: 10px; }
  .submit-button {
    // text-align: right
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    button {
      width: 94px; }
    .upload-icon .anticon {
      color: #264382;
      font-size: 18px;
      cursor: pointer;
      &.disabled {
        color: rgba(0, 0, 0, 0.25); } } }
  .message-list {}
  .message-item {
    display: flex;
    margin-bottom: 20px;
    font-family: 'Gilroy';
    img {
      max-width: 100%; }
    &:hover .message-tag .opr {
      display: block; }
    .avatar {
      margin-right: 16px; }
    .message-content {
      width: calc(100% - 56px); }
    .message-tag {
      font-size: 12px;
      color: #97A0A7;
      display: flex;
      justify-content: space-between;
      strong {
        color: #323F4A;
        font-size: 14px;
        margin-right: 5px;
        font-family: 'Gilroy-Bold'; }
      .opr {
        display: none;
        font-size: 14px;
        color: #264382;
        cursor: pointer; } }
    .message-media-info {
      position: relative;
      display: inline-block;
      margin-top: 10px;
      border: 1px solid #DEE2EC;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      overflow: hidden;
      img {
        max-width: 100%; }
      .page-loading, .error {
        position: absolute;
        width: 100%;
        vertical-align: middle;
        top: 0;
        bottom: 0;
        background: rgba(255,255,255, .9);
        display: flex;
        align-items: center;
        justify-content: center; }

      .error {
        font-size: 18px;
        .try-aggin {
          cursor: pointer;
          color: #2B4B8F; } } }

    .message-info {
      position: relative;
      border: 1px solid #DEE2EC;
      border-radius: 4px;
      font-size: 14px;
      color: #323A46;
      opacity: 0.8;
      width: 100%;
      padding: 12px;
      line-height: 1.75;
      margin-top: 10px;
      word-break: break-word;
      pre {
        margin-bottom: 0;
        white-space: pre-wrap;
        font-family: Gilroy; }
      .is-edited {
        color: #97a0a7; }
      p:last-child {
        margin-bottom: 0; } }

    .btns {
      margin-top: 10px;
      text-align: right;
      button:first-child {
        margin: 0 5px; } } }
  .empty-container {
    padding-top: 40px;
    &>div {
      margin: 0 auto; }
    .team-member {
      background: #FFFFFF;
      box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      width: 360px;
      padding: 13px 16px;
      margin-bottom: 16px; }
    .empty-info {
      width: 281px;
      margin-top: 32px;
      padding-bottom: 34px;
      color: #909399;
      font-size: 14px;
      text-align: center; } } }
