.react-code-input {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  input[type="number"] {
    -moz-appearance: textfield;
    font-family: Gilroy; } }

 :local(.settingContainer) {
  font-family: Gilroy;
  .top-image {
    padding-top: 50px; }
  .main-title {
    font-size: 24px;
    color: #545F6D;
    letter-spacing: 0.3px;
    text-align: center; }
  .sub-title {
    font-family: Heebo;
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: #5C626A;
    opacity: 0.8; }
  .main-button {
    width: 320px;
    height: 50px;
    font-size: 16px; }
  .columnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .twoFAContainer {
    .enable-2fa-sub-title {
      padding-top: 16px;
      width: 334px;
      text-align: center;
      padding-bottom: 42px; }
    .enable-2fa-main-title {
      width: 295px;
      padding-top: 40px; }
    .enable-2fa-skip {
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #2B4B8F;
      width: 193px;
      height: 30px;
      padding-top: 28px;
      cursor: pointer; } }
  .enterPhoneNumberContainer {
    .enter-phone-number-top-title {
      font-size: 12px;
      letter-spacing: 0.6px;
      padding-top: 58px;
      color: #A7B1BF;
      text-transform: uppercase;
      padding-bottom: 2px; }
    .enter-phone-number-desc {
      width: 333px;
      text-align: center;
      padding-top: 8px; }
    .form-container {
      margin-top: 15px;
      width: 320px;
      height: 54px;
      .ant-legacy-form-item-required::before {
        content: ''; }
      .ant-input {
        background-color: #F5F6F8 !important;
        border: 0px;
        font-family: Heebo;
        font-size: 16px;
        font-weight: normal; }
      label {
        margin-left: -8px; }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 0 #F5F6F8 inset !important; } }
    .enter-phone-main-button {
      padding-top: 40px; }
    .invalid-label {
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 21px;
      color: red;
      letter-spacing: 0.3px;
      width: 159px; }
    .resend-label {
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.5);
      letter-spacing: 0.3px;
      padding-top: 60px;
      .resend-button {
        font-weight: bold;
        color: #2B4B8F;
        padding-left: 3px; }
      .disable-resend-button {
        color: gray; } } }
  .changePasswordContainer {
    .main-title {
      color: #A7B1BF;
      font-size: 36px;
      letter-spacing: 0.3px;
      line-height: 40px;
      padding-top: 56px; }
    .sub-title {
      color: #5C626A;
      opacity: 0.8;
      font-family: Heebo;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.3px;
      padding-top: 14px; }
    .form-container {
      padding-top: 29px;
      margin-top: 15px;
      width: 320px;
      .ant-legacy-form-item-required::before {
        content: ''; }
      .ant-input {
        background-color: #ffffff !important;
        border: 0px;
        font-family: Heebo;
        font-size: 16px;
        font-weight: normal; }

      label {
        margin-left: -8px; } } } }
