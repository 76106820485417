 :local(.templateStyle) {
  font-family: 'Gilroy-bold';
  font-size: 12px;
  text-transform: uppercase;
  color: #264382;
  line-height: 1.5;
  position: relative;
  &>span {
    cursor: pointer; }
  .category-list, .subcategory-list, .title-list {
    position: absolute;
    min-width: 200px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    list-style: none;
    padding: 0;
    margin-left: -20px;
    margin-top: 15px;
    z-index: 99; }
  .subcategory-list, .title-list {
    min-width: 170px; }
  .sub-container, .title-container {
    padding: 0;
    height: 0;
 }    // position: relative
  .subcategory-list, .title-list {
    // margin-left: 280px
    margin-left: 100%;
    top: 0;

    // // margin-left: 170px
 }    // top: 0
  li {
    white-space: nowrap;
    padding: 14px 20px;
    font-family: Gilroy;
    font-size: 15px;
    // line-height: 35px
    letter-spacing: 0.006em;
    text-transform: capitalize;
    color: #4C6072;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &.active, &:hover {
      background: rgba(38,67,130,.05); } } }



