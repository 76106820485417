.rpeContainer {
  padding-top: 24px;
  letter-spacing: 0.3px;
  .title {
    font-family: Gilroy-bold;
    font-size: 17px;
    color: #383E48;
    margin: 0;
    display: block;
    .anticon {
      color: #2B4B8F;
      margin-left: 8px;
      cursor: pointer; } }
  // .explainInfo
  //   font-size: 12px
  //   color: #264382
  //   background: rgba(38,67,130,.15)
  //   padding: 12px 24px
  //   border-radius: 12px
  //   margin: 16px 0
  // .manual-input
  //   padding-bottom: 24px
  //   .ant-checkbox-wrapper
  //     margin-right: 12px

  .score, .explainText {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #60656D;
    &>.bold {
      margin-left: 0; }
    .bold {
      font-family: Gilroy;
      margin-left: 9px; } }
  .explainText {
    padding-top: 10px; }
  .extraInfo {
    margin-top: 17px;
    font-size: 12px;
    line-height: 17px;
    color: #70808E;
    padding: 12px 15px;
    border: 1px solid #E4EAEF;
    box-sizing: border-box;
    border-radius: 8px;
    h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 124%;
      letter-spacing: 0.3px;
      color: #383E48; } }
  .rateLevel {
    font-size: 14px;
    color: #383E48; }
  .sliderContainer {
    padding: 17px 0;
    position: relative;
    .sliderBg {
      position: absolute;
      top: 0;
      width: calc(100% - 12px);
      margin: 40px 6px;
      padding: 4px 0;
      margin-bottom: 10px;
      span {
        height: 3px;
        display: inline-block;
        width: 11.11%;
        background: rgba(196,196,196,.4);
        border-right: 3px solid #2B4B8F; }
      &.disabled span {
        border-color: rgba(43,75,143,.4); } }
     :global(.ant-slider) {
      position: relative;
      z-index: 1;
       :global(.ant-slider-rail) {
        background-color: transparent; }
       :global(.ant-slider-track) {
        background-color: #2B4B8F; }
       :global(.ant-slider-handle) {
        background-color: #2B4B8F;
        border-color: #ffffff !important;
        border-width: 4px;
        width: 20px;
        height: 20px;
        margin-top: -8px;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2); } }
     :global(.ant-slider-disabled) {
       :global(.ant-slider-track) {
        background-color: #C7CFDF !important; }
       :global(.ant-slider-handle) {
        background-color: #C7CFDF; } } } }

.rpeValueUpdateInfoBox {
  margin-top: 16px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Gilroy;
  font-size: 12px;
  .infoLabel {
    color: #70808E; }
  .undo {
    color: #2B4B8F;
    cursor: pointer; } }
.topBottomLine {
  border-top: 1px solid rgba(0,0,0,.1);
  border-bottom: 1px solid rgba(0,0,0,.1); }
