@keyframes logoPosition {
  0% {
    width: 34px;
    top: 50px;
    margin-left: -87px;
    margin-top: 0; }
  100% {
    width: 80px;
    top: 50%;
    margin-top: -190px;
    margin-left: -35px; } }

@keyframes avatarPosition {
  0% {
    margin-left: -45px; }
  100% {
    margin-left: 48px; } }

.ant-select-dropdown.select-facility {
  .ant-select-dropdown-menu { // ant-select-dropdown-menu-root ant-select-dropdown-menu-vertical
    .ant-select-dropdown-menu-item {
      height: 40px;
      line-height: 32px;
      text-align: center;
      border-bottom: 1px solid #BAC3CE;
      font-family: Gilroy;
      font-size: 16px;
      letter-spacing: 0.3px;
      color: #4C6072;
      &:hover {
        background: #ffffff; } }
    .ant-select-dropdown-menu-item-active {
      background: #ffffff; } } }
@media screen and (orientation:landscape) {
  .welcome, .expert {
    .footer-location {
      display: none; } }
  .welcome-text {
    margin-top: 0 !important; } }
 :local(.CheckStyle) {
  background: #223064;
  color: #ffffff;
  position: fixed;
  top: 0;
  // left: 0
  // right: 0
  bottom: 0;
  max-width: 100vw;
  font-family: Gilroy;
  overflow-y: auto;
  &>div {
    padding-bottom: 100px; }
  header {
    padding: 50px 40px;
    .return-icon {
      font-size: 26px;
      left: 40px;
      position: absolute; }
    .center {
      text-align: center;
      .square-logo {
        width: 34px;
        margin-right: 22px; } } }
  .footer-location {
    text-align: center;
    font-size: 14px;
    position: fixed;
    width: 100%;
    bottom: 55px;
    .anticon {
      vertical-align: sub;
      margin-right: 10px; } }

  .center-section, .normal-section {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    letter-spacing: 0.3px;
    text-align: center;
    img {
      margin-bottom: 24px; }
    p {
      font-size: 24px;
      line-height: 28px; }
    .ant-btn {
      width: 260px;
      height: 48px;
      font-size: 16px;
      line-height: 42px;
      color: #2B4B8F;
      background: #FFFFFF;
      box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 89px; }
    .check-facilities {
      width: 453px;
      height: 52px;
      border: 2px solid #FFFFFF;
      border-radius: 5px;
      margin-top: 42px;
      .ant-select-open .ant-select-selection {
        border-color: #ffffff;
        box-shadow: none; }
      .ant-select, .ant-select-selector {
        width: 100%;
        height: 100%;
        .ant-select-selection, .ant-select-selection__rendered, .ant-select-selection-selected-value, .ant-select-selection-item {
          width: 100%;
          height: 100%;
          background: transparent;
          color: #ffffff;
          text-align: center;
          line-height: 42px;
          font-family: Gilroy;
          font-size: 16px;
          letter-spacing: 0.3px; }
        .ant-select-arrow {
          color: #ffffff; } } } }
  .normal-section {
    top: 0;
    transform: translateX(-50%);
    padding: 70px;
    p {
      font-size: 18px; }
    .top-section {
      margin-bottom: 110px;
      &>p {
        font-size: 24px; } } }
  .location {
    width: 257px;
    margin: 0 auto;
    margin-bottom: 25px;
    padding: 10px 0;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;

    // width: 100%
    .anticon {
      vertical-align: sub;
      margin-right: 10px; } }
  .welcome-text {
    margin-top: 53px;
    padding-top: 10px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.3px; }
  .check-title {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.3px;
    // margin-top: 73px
    // padding-bottom: 50px
    .first-name {
      text-transform: uppercase; } }
  button {
    margin: 20px auto;
    width: 260px;
    height: 48px;
    font-size: 16px;
    line-height: 42px;
    color: #2B4B8F; }
  .confirm {
    .confirm-btn-group {
      margin-top: 16px;
      text-align: center;
      button {
        width: 260px;
        height: 48px;
        border: 2px solid #FFFFFF;
        box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 89px;
        color: #ffffff;
        &:first-child {
          margin-right: 53px; } } }
    .confirm-form {
      width: 522px;
      margin: 0 auto; }
    .confirm-check {
      text-align: center;
      font-size: 12px;
      line-height: 24px;
      a {
        color: #ffffff;
        text-decoration: underline;
        font-weight: bold; }
      .ant-checkbox-wrapper {
        margin-right: 10px; }
      button {
        margin-top: 66px;
        width: 260px;
        height: 48px;
        font-size: 16px;
        line-height: 42px;
        color: #2B4B8F; }
      .button-group {
        margin-left: -30px;
        margin-right: -30px;
        button {
          color: #ffffff; } } } }
  .welcome {
    text-align: center;
    .check-in-avatar {
      margin-bottom: 20px;
      width: 81px;
      height: 81px;
      border-radius: 100%;
      object-fit: cover; }
    .check-list {
      width: 622px;
      padding: 0;
      list-style: none;
      margin: 0 auto;
      li {
        display: inline-block;
        width: 182px;
        border: 2px solid #FFFFFF;
        box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 89px;
        height: 48px;
        margin: 0 15px;
        margin-top: 36px;
        // margin-right: 30px
        font-size: 16px;
        line-height: 42px;
        text-align: center;
        &:nth-child(3n) {
          margin-right: 0; }
        &.selected {
          background: #E8ECF0;
          border: 2px solid #FFFFFF;
          color: #3561C0; } } } }
  .expert {
    text-align: center;
    .expert-list {
      width: 600px;
      margin: 0 auto;
      list-style: none;
      padding: 0;
      text-align: left;
      li {
        display: inline-block;
        margin-bottom: 50px;
        width: 33.33%;
        // &:nth-child(3n+2)
        //   margin: 0 124px
        .avatar-content {
          width: 82px;
          height: 82px;
          border: 4px solid #FFFFFF;
          border-radius: 100%;
          position: relative;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover; }
          .selected {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(27,185,52,.5);
            z-index: 1;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: 25px; } }
        .expert-name {
          text-align: center;
          margin-top: 10px;
          font-size: 12px;
          text-transform: capitalize; } } } }
  .avatar {
    text-align: center;
    .avatar-box {
      width: 328px;
      height: 328px;
      display: inline-block;
      border-radius: 100%;
      border: 1px solid #ffffff;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
    .btn-group {
      button {
        color: #ffffff;
        border-width: 2px;
        &:last-child {
          margin-left: 50px; } } }
    .cancel-take-photo {
      position: fixed;
      z-index: 99;
      left: 40px;
      top: 50px;
      font-size: 26px;
      color: #ffffff; }
    .react-html5-camera-photo {
      text-align: center;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,.5);
      display: flex;
      justify-content: center; } }
  .finish {
    padding: 50px 0;
    text-align: center;
    height: 50%;
    position: relative;
    .square-logo {
      width: 34px;
      margin-right: 22px; }
    .check-title {
      margin-top: 10px;
      &.large {
        font-size: 24px;
        padding: 0;
        margin-top: 30px; } }
    .avatar-content {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      border: 4px solid #223064;
      display: inline-block;
      margin-left: -45px;
      background: #fff;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
    &>.square-logo {
      width: 34px;
      vertical-align: top;
      position: fixed;
      top: 50px;
      margin-left: -87px; }
    .center {
      position: absolute;
      width: 100%;
      bottom: 0;
      transform: translateY(50%);
      button {
        color: #ffffff;
        border-width: 2px; } }

    &.align {
      display: flex;
      justify-content: center;
      align-items: center;
      .header {
        display: none; }
      &>.square-logo {
        animation: logoPosition 3s;
        width: 80px;
        top: 50%;
        // margin-top: -190px
        margin-left: -35px; }
      .avatar-content {
        animation: avatarPosition 3s;
        margin-left: 48px; } } } }
