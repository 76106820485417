 :local(.style) {
  // border: 1px solid #d9d9d9
  font-family: Gilroy;
  font-weight: 500;
  .toolbar {
    margin-bottom: 0;
    // border-radius: 4px 4px 0 0
    border-color: #d9d9d9; }
  .toolbar {
    & + .editor {
      border-color: #d9d9d9;
      border-top: none;
 } }      // border-radius: 0 0 4px 4px
  .editor {
    border: 1px solid #d9d9d9;
    // border-radius: 4px
    // border-top: none
    padding: 5px;
    .public-DraftStyleDefault-block {
      margin: 2px 0;
      line-height: 1.5;
      padding-bottom: 5px; } } }
