 :local(.SettingStyle) {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-around;
  &>div {
    background: #ffffff; }
  .choose-container {
    width: 500px;
    border-radius: 18px;
    .back {
      padding: 24px 22px;
      padding-bottom: 14px;
      color: #264382;
      text-transform: uppercase;
      letter-spacing: 2.8px;
      font-size: 12px;
      font-weight: bold;
      cursor: pointer; }
    .title {
      width: 363px;
      margin: 0 auto;
      margin-bottom: 25px;
      text-align: center;
      font-size: 36px;
      font-family: Gilroy;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #A7B1BF; }
    .choose-option {
      display: flex;
      justify-content: space-between;
      padding: 17px 0 23px 44px;
      font-size: 14px;
      font-family: Heebo;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #323F4A;
      border-top: 1px solid #E8ECF0;
      div {
        max-width: 247px; }
      .option-title {
        font-family: Gilroy;
        font-size: 18px;
        line-height: 150%;
        color: #52606C;
        margin-bottom: 11px; }
      .btn-icon {
        text-align: center;
        width: 130px;
        align-items: center;
        padding: 16px;
        color: #264382;
        cursor: pointer;
        .anticon {
          margin-bottom: 5px; } }
      p {
        margin-bottom: 0; } } }
  .customize-container {
    width: 854px;
    border-radius: 8px;
    padding: 40px;
    padding-left: 55px;
    padding-right: 73px;
    .title {
      font-family: Gilroy;
      font-size: 36px;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #323F4A;
      .close {
        float: right;
        cursor: pointer;
        font-size: 16px;
        margin-right: -43px;
        margin-top: -26px; } }
    .body-box {
      label {
        font-family: Heebo;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        color: #737E88;
        opacity: 0.8; }
      input {
        background: rgba(232, 236, 240, 0.2);
        border: 1px solid rgba(82, 96, 108, 0.5);
        border-radius: 4px;
        margin-top: 15px;
        margin-bottom: 25px;
        height: 50px;
        font-family: Gilroy;
        font-size: 16px;
        color: #323F4A; }
      .ant-divider-horizontal.ant-divider-with-text-left::before {
        width: 0; }
      .ant-divider-inner-text {
        font-family: Gilroy;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #52606C;
        padding-left: 0; }
      .ant-checkbox-group {
        padding-top: 30px;
        width: 100%;
        .ant-checkbox-wrapper {
          width: 50%;
          font-family: Gilroy;
          font-size: 16px;
          line-height: 150%;
          color: #ECEFF5;
          letter-spacing: 0.3px;
          margin: 0;
          margin-bottom: 36px;
          .ant-checkbox {
            margin-right: 42px; } }
        .ant-checkbox-wrapper-checked {
          color: #323F4A; } } }
    .bottom-opr {
      text-align: right;
      font-family: Gilroy;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.3px;
      .ant-btn-primary {
        padding: 10px 38px;
        height: 44px;
        margin-left: 25px; }
      .cancel {
        padding: 10px 15px;
        color: #264382;
        cursor: pointer; } } }
  .succeed-container {
    width: 478px;
    padding: 40px 80px;
    text-align: center;
    border-radius: 8px;
    .title {
      font-family: Gilroy;
      font-size: 36px;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #323F4A;
      margin-top: 5px;
      margin-bottom: 8px; }
    .body-box {
      padding: 60px 0 50px;
      p {
        font-family: Gilroy;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.3px;
        color: #545F6D;
        margin-top: 30px; } }
    .ant-btn-primary {
      width: 320px;
      height: 50px;
      font-family: Gilroy;
      font-size: 16px;
      line-height: 42px;
      letter-spacing: 0.3px; }
    .do-it-later {
      cursor: pointer;
      margin-top: 10px;
      font-size: 16px; } } }
