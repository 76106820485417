 :local(.reportStyle) {
  .draft-report {
    header {
      height: 66px;
      padding: 20px 15px;
      font-size: 14px;
      background: #FAFCFD;
      border: 1px solid #EAEDF3;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      text-align: right;
      font-weight: bold;
      .action-item {
        cursor: pointer;
        margin: 0 20px;
        color: #70808E;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        font-size: 14px; }
      .close {
        font-size: 20px;
        color: #E5E8ED;
        cursor: pointer; } }
    .sub-header {
      padding: 10px 30px;
      color: #FAFCFD;
      text-transform: uppercase;
      letter-spacing: 0.366667px;
      font-size: 14px;
      font-weight: bold;
      line-height: 27px;
      background: #70808E;
      .anticon {
        margin-right: 5px; } }
    .report-banner {
      position: relative;
      padding: 55px 38px;
      background: #E5E8ED;
      color: #4C6072;
      height: 188px;
      &.has-bg {
        color: #ffffff;
        h1 {
          color: #ffffff; } }
      .ant-progress {
        position: absolute;
        top: -8px;
        left: 0;
        width: 100%; }
      h1 {
        font-size: 32px;
        line-height: 29px;
        font-weight: normal; }
      p {
        font-size: 24px;
        line-height: 29px;
        opacity: 0.5;
        margin: 0; }
      button {
        position: absolute;
        bottom: 19px;
        right: 26px;
        height: 48px;
        width: 200px;
        background: #FFFFFF;
        color: #75879D;
        text-transform: uppercase;
        letter-spacing: 0.366667px;
        border: none;
        .anticon {
          vertical-align: bottom; } } }
    .draft-report-container {
      padding: 17px 0;
      .left, .right {
        display: inline-block;
        vertical-align: top; }
      .left {
        width: 185px;
        margin-right: 30px; }
      .right {
        width: calc(100% - 185px - 55px);
        .section-title {
          text-transform: capitalize; } }
      .ant-collapse-borderless {
        background: transparent;
        .ant-collapse-item {
          background: #ffffff;
          padding-bottom: 15px; } }
      .ant-collapse-header {
        margin: 0 10px;
        margin-top: 15px;
        padding-left: 16px;
        // margin: 0 -10px
        text-transform: uppercase;
        font-size: 24px;
        // &[aria-expanded="true"]
        //   border-bottom: 1px solid rgba(0,0,0,.1)
 }        //   padding-bottom: 30px
      .ant-collapse-header .ant-collapse-arrow {
        width: 40px;
        height: 40px;
        right: 6px;
        border-radius: 100%;
        top: 20px;
        left: auto;
        background: #F1F3F6;
        color: #75879D;
        svg {
          margin-top: -2px; } }
      .ant-collapse-extra {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 3px;
        // top: 50%
        // transform: translateY(-50%)
        padding: 8px;
        border-radius: 100%;
        right: 75px;
        text-align: center;
        background: #F1F3F6;
        color: #75879D; }
      .ant-collapse-content-box {
        padding: 4px 26px 20px 26px;
        .ant-divider {
          &.full {
            margin: 24px -26px;
            width: calc(100% + 52px); } }
        p, pre, textarea {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 27px;
          letter-spacing: 0.366667px;
          // text-transform: capitalize
          color: #3F4651;
          opacity: 0.8;
          cursor: pointer;
          white-space: inherit;
          margin: 0;
          .placeholder {
            opacity: 0.3;
 } }            // text-transform: capitalize
        .ant-upload-drag {
          margin-top: 25px;
          height: 272px;
          border-color: rgba(117, 135, 157, 0.3);
          border-width: 2px;
          background-color: rgba(241,243,245, .3); }
        .report-files {
          .upload-btn {
            margin-left: 35px; }
          .files-card {
            padding: 10px;
            color: #4C6072;
            border-radius: 8px;
            &:hover {
              background: rgba(196,196,196,.2); } }
          .card-cover {
            overflow: hidden;
            height: 134px;
            border-radius: 8px;
            &>img {
              width: 100%;
              height: 100%;
              object-fit: cover; }
            .video-thum, .file-content {
              position: relative;
              width: 100%;
              height: 100%;
              &>img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%); } }
            .video-thum {
              background: rgba(0, 0, 0, 0.8); }
            .file-content {
              background: #264382; } }
          .ant-card-body {
            padding: 0;
            .card-content {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .file-info {
                width: 85%;
                font-size: 14px;
                letter-spacing: 0.366667px;
                text-transform: capitalize;
                .ant-typography {
                  margin: 0; } } } } }
        // .single-file
        //   .cover-photo
        //     position: relative
        //     &>img
        //       width: 100%
        //       object-fit: cover
        //     .tool-bar
        //       position: absolute
        //       padding: 10px 0
        //       height: 61px
        //       background: #75879D
        //       text-align: right
        //       width: 100%
        //       top: 0
        //       color: #ffffff
        //       .upload-progress
        //         position: absolute
        //         width: 100%
        //         left: 0
        //         top: 45px
        //       &>.anticon
        //         font-size: 1.3em
        //         margin: 0 20px
        //         vertical-align: middle
        //       .cancel
        //         margin: 0 20px
        //       .ant-btn.white
        //         color: #ffffff
 }        //         border-color: #ffffff
      .report-blood-test-result {
        margin: 0 -26px;
        padding: 0 26px;
        .biomarkers-cotainer {
          margin: 0 -26px;
          .biomarker-item {
            padding: 0 26px; } } } } } }
.preview-report {
    width: 1440px;
    margin: 0 auto;
    background: #ffffff;
    img {
      max-width: 100%; }
    .text-section, pre {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 27px;
      letter-spacing: 0.366667px;
      text-transform: capitalize;
      color: #3F4651;
      opacity: 0.8;
      white-space: inherit;
      margin: 0; }
    .report-section {
      margin-left: 156px;
      margin-right: 195px;
      padding: 55px 0;
      .section-title {
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #4C6072;
        font-weight: 500; }
      .section-sub-title {
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.3px;
        text-transform: capitalize;
        color: #4C6072; } }
    .preview-banner {
      background: #E5E8ED;
      color: #4C6072;
      height: 188px;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
      h1 {
        position: relative;
        z-index: 1;
        margin-left: 156px;
        padding-top: 68px;
        font-size: 32px;
        line-height: 29px;
        font-weight: normal; }
      p {
        padding-left: 156px;
        font-size: 24px;
        line-height: 29px;
        opacity: 0.5;
        margin: 0; }
      img + h1 {
        color: #ffffff;
        &+p {
         color: #ffffff; } } }
    .report-blood-test-result {
        margin-left: -165px;
        margin-right: -195px;
        padding-left: 165px;
        padding-right: 195px;
        .biomarkers-cotainer {
          margin-left: -165px;
          margin-right: -195px;
          .biomarker-item {
            padding-left: 165px;
            padding-right: 195px; } } } }
@media screen and (max-width: 768px) {
  .preview-report {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    bottom: 0;
    -webkit-overflow-scrolling: touch;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    width: 100vw;
    max-width: 750px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    img {
      max-width: 100%; }
    .preview-banner {
      // height: auto
      padding: 20px;
      height: 234px;
      h1, p {
        margin: 0;
        padding: 0; }
      h1 {
        margin-top: 90px;
        font-size: 24px;
        line-height: 29px;
        color: #FAFCFD; }
      p {
        margin-top: 15px;
        font-size: 18px; } }
    .report-section {
      margin: 0;
      padding: 20px;
      &>img {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
        max-width: calc(100% + 40px); }
      .section-sub-title {
        font-size: 21px; }
      .ant-col-6 {
        width: 50% !important; }
      .report-blood-chats {
        height: auto;
        .summary {
          display: block;
          height: auto;
          padding-top: 15px;
          .pie {
            margin: 10px;
            margin-right: 0;
            float: left; }
          .pie-number {
            display: inline-block;
            margin-left: 35px;
            // &:nth-child(2)
            //   margin-left: 35px
            svg {
              width: 100px;
              text[text-anchor="start"] {
                font-size: 28px; } } } }
        .bars {
          height: auto;
          .VictoryContainer {
            height: auto;
            svg {
              height: auto;
              max-width: none; } } } }
      .report-blood-test-result {
        .category {
          .header {
            display: block;
            margin-bottom: 0;
            .right {
              margin-top: 15px;
              background: rgba(196,196,196,.1);
              span {
                margin: 15px; } } }
          .biomarkers-cotainer {
            margin: 0 -20px;
            .biomarker-item {
              padding: 20px;
              padding-bottom: 40px;
              display: block;
              height: auto;
              & > div {
                width: auto;
                display: inline-block;
                margin-right: 20px;
                margin-bottom: 10px; }
              .range-bar {
                width: 100%;
                margin: 0;
                .range-item {
                  width: 20%;
                  .item-bar {
                    width: 100%; } } } } } } } } } }
