 :local(.headerStyle) {
  // margin-top: -20px
  .header {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    background: #fff;
    padding: 20px;
    h1 {
      font-size: 18px;
      line-height: 29px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #4C6072;
      font-weight: bold; } }
  .menu {
    height: 50px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    background: #F9FAFB;
    border-top: 1px solid rgba(0,0,0,.1);
    img {
      width: 25px; }
    .page-title {
      padding:  0 25px;
      font-size: 14px;
      // line-height: 32px
      color: #3F4752;
      font-weight: normal; } }
  .trend-graph {
    margin-top: -8px !important;
    // margin-bottom: 10px
    padding-bottom: 0;
    .trend-title {
      font-family: Gilroy-Bold;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.3px;
      text-transform: capitalize;
      color: #383E48;
      font-weight: 700;
      margin-bottom: 25px; }
    .chat-axis-title {
      text-transform: uppercase;
      letter-spacing: 1.2px;
      color: #4C6072;
      opacity: 0.8;
      font-size: 12px;
      font-weight: bold;
      font-family: Heebo;
      .left, .right {
        display: inline-block;
        width: 50%; }
      .right {
        text-align: right; } }
    & > div {
      min-width: calc(100vw);
 }      // margin-top: 30px
    .VictoryContainer {
      margin: 0; } }
  .am-drawer {
    // top: 150px
    .am-drawer-draghandle {
      width: 0; } }
  .am-drawer-overlay {
    background: #ffffff; }
  .am-drawer-sidebar {
    .am-list-body::after {
      content: none; }
    .am-list-item {
      padding-left: 47px;
      &.am-list-item-active {
        background: transparent; }
      .am-list-line {
        padding: 0;
        .am-accordion-content-box {
          .menu-item {
            font-size: 14px;
            line-height: 200%;
            letter-spacing: 0.3px;
            margin: 10px 0;
            &:first-child {
              padding-top: 10px; }
            &:last-child {
              padding-bottom: 20px;
              margin-bottom: 0; }
            &:before {
              content: '';
              display: inline-block;
              width: 9px;
              height: 9px;
              background: #4C6072;
              margin-right: 18px;
              border-radius: 100%; } } } } } }

  .am-drawer-open.am-drawer.am-drawer-left .am-drawer-sidebar {
    width: 100%; }
  .am-accordion .am-accordion-item .am-accordion-header {
    padding: 3px 0;
    border: none;
    .arrow {
      display: none; } }
  .am-drawer-content {
    text-align: left !important;
    .is-momentumScrollable {
      overflow-x: hidden;
      overflow-y: scroll;
      position: absolute;
      z-index: 1;
      width: 100%;
      top: 0;
      bottom: 0;
      -webkit-overflow-scrolling: touch;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0,0,0); }

    .trend-graph {
      margin-top: 0;
      padding-bottom: 0;
      .chat-axis-title {
        text-transform: uppercase;
        letter-spacing: 0.6px;
        color: #4C6072;
        opacity: 0.8;
        font-family: Heebo;
        font-weight: bold;
        font-size: 12px;
        line-height: 19px;
        .left, .right {
          display: inline-block;
          width: 50%; }
        .right {
          text-align: right; } }
      & > div {
        min-width: calc(100vw);
 }        // margin-top: 30px
      .VictoryContainer {
        margin: 0; } }
    .category-container, .mobile-categories-container {
      background: #F9FAFB;
      padding: 0;
      .root-category {
        padding-left: 17px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: #ffffff;
        margin-top: 0 !important;
        .attachment-container {
          margin: 0;
          padding: 0; }
        &.extra {
          padding-top: 10px; } }
      .sub-category-container.permanent-category {
        .left {
          background: #ffffff;
          padding: 17px;
          .name > div {
            display: inline; }
          .show-more-text {
            color: #3D3D3D;
            opacity: 0.8;
            text-decoration: underline;
            cursor: pointer; }
          .description.mobile-show {
            .ant-typography-expand {
              display: inline-block;
              color: transparent;
              position: relative;
              &:before {
                content: 'Read more';
                position: absolute;
                color: #3D3D3D;
                opacity: 0.8;
                text-decoration: underline;
                width: 70px; } } } }
        .right {
          padding: 17px;
          .biomarker-container {
            margin: 0;
            margin-bottom: 17px;
            box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            &:first-child {
              border: none; }
            .range > div {
              height: 84px; }
            .personal-value-container {
              height: auto;
              .name {
                padding-bottom: 0; }
              .value {
                display: flex;
                justify-content: space-between;
                align-items: center; } } } } }

      .ant-divider:after {
        border: none; } }

    .mobile-categories-container {
      .root-category {
        background: #F9FAFB !important;
        padding: 0;
        .left .description {
          margin: 0;
          margin-top: 20px; }
        .attachment-name {
          background: #ffffff;
          margin-bottom: 0;
          padding: 20px;
          & + img {
            margin: 0; } } } }

    .detail-title {
      padding: 20px 17px;
      margin: 0;
      font-size: 24px;
      line-height: 29px; }
    .client-biomarker-container {
      background: #ffffff;
      .ranges-info {
        margin-bottom: 0 !important;
        box-shadow: none;
        .ranges-area-container {
          padding-bottom: 0;
          .options-container {
            margin-bottom: 20px; }
          .ranges-area {
            position: relative;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(0,0,0,.1);
            .range-title, .range-level {
              display: inline-block;
              font-size: 12px;
              color: rgba(76,96,114,.8);
              line-height: 1.4;
              // margin-bottom: 15px
              span:last-child {
                display: none; }
              span.name {
                display: inline-block; } }
            .range-level {
              color: #383E48;
              display: block;
              width: 100%;
              font-size: 18px;
              letter-spacing: 0.3px;
              color: #4C6072;
              text-align: left; }
            .range-value {
              letter-spacing: 0.6px;
              font-size: 24px;
              line-height: 36px;
              text-transform: capitalize;
              font-family: Gilroy-Bold;
              font-weight: bold; }
            .range-info {
              text-transform: uppercase !important; } } } }
      .biomarker-info {
        box-shadow: none;
        border-top: 1px solid #EAEDF3;
        .information {
          color: #44433D;
          width: 100%;
          position: relative; } } } } }
