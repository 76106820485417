.range-area-wrap {
    position: relative;
}

.ranges-container-wrap {
    position: relative;
    height: 40px;
    margin-top: 30px;
}

.progress-container-wrap {
    position: relative;
}

.hexagon-wrap {
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-top: 24px;
}

.range-info-wrap {
    margin-top: 120px;
}

.grouped-container {
    margin-top: 20px;
}

.section-name {
    font-size: 24px;
    letter-spacing: 0.3px;
    color: rgba(82, 96, 108, 1);
}

.display-name {
    font-size: 20px;
    letter-spacing: 0.3px;
    color: rgba(82, 96, 108, 1);
}

.section-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.display-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.metric-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.tab-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.tab-scroll {
    height: 200px;
    overflow-y: scroll;
    padding: 10px;
}

.tab-background {
    background: white;
}

.bar-wrap {
    flex: 2;
    padding: 50px 20px 0px 20px;
    border: 0.1px solid #e8e8e8;
    border-radius: 4px;
}

.tab-div {
    flex: 2;
    border: 0.1px solid #e8e8e8;
    padding: 10px;
    border-radius: 4px;
}

.action-btns {
    display: flex;
    gap: 20px;
    margin-left: 20px;
}