 :local(.appStyle) {
  .client-view-main-content {
    display: flex;
    justify-content: center;
    position: relative;
    .left,.right {
      display: inline-block;
      vertical-align: top; }
    .left {
      width: 280px;
      margin-right: 14px;
      position: sticky;
      top: 0;
      z-index: 2;
      &.close {
        position: absolute;
        left: 0;
        width: 0px; }
      .swift-left-btn {
        position: absolute;
        top: 0;
        right: -60px;
        &.close {
          left: 0; } } }
    .close {
      > div:first-child {
        padding-right: 0px; } }
    .right {
      width: calc(100% - 280px - 14px);
      min-height: calc(100vh - 71px); //calc(100vh - 71px - 45px)
      background: #F4F6F8;
      margin-top: -35px;
      padding-top: 35px;
      padding-left: 50px; //60px
      padding-bottom: 60px;
      position: relative;
      // height:
      .profile-contant {
        padding-right: 44px;
        padding-top: 40px; }
      .question-con {
        padding-right: 54px;
        padding-top: 40px;
        padding-bottom: 60px;
        position: relative;
        .page-title {
          padding-bottom: 1em; }
        .btn-group {
          position: absolute;
          right: 54px;
          top: 40px;
          font-family: Gilroy;
          font-size: 16px;
          color: #264382; }
        .btn-edit-answer {
          display: inline-block;
          margin-left: 20px;
          width: 202px;
          height: 44px;
          border-radius: 38px;
          border: 2px solid #264382;
          text-align: center;
          line-height: 40px;
          cursor: pointer; } }
      &.detail-right {
        width: 100%;
        padding: 0 25px;
        .page-title img {
          margin-right: 5px; } } } } }
