 :local(.emailStyle) {
  font-family: Gilroy;
  background: #F4F6F8;
  &.backup-style {
    .section-container > h3 {
      margin: 0;
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -23px;
      padding: 18px 32px;
      font-size: 24px; }
    .information-container.Opened {
      border: 1px solid #70808E;
      margin: 16px 0 32px 0; } }
  img {
    max-width: 100%;
    width: 100%;
    // & + .section-container
 }    //   margin-top: 0
  .section-container {
    background: #ffffff;
    padding: 20px;
    & > h3 {
      margin: 0;
      margin-bottom: 24px;
      padding: 14px 25px;
      font-size: 18px;
      font-family: Gilroy-Bold;
      color: #ffffff;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        cursor: pointer;
        display: none; }
      &:hover svg {
        display: block; }
      &.Overview {
        color: #383E48;
        padding: 0;
        margin: 0; }

      &.Rejuvenation {
        background: #5C00D1; }
      &.Exercise {
        background: #FF6B00; }
      &.Nutrition {
        background: #7BAD2D; }
      &.Weight {
        background: #3194EB; } }
    h4.sub-title {
      margin-top: 27px;
      margin-bottom: 16px;
      font-family: "Gilroy-Bold";
      font-weight: 800;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #383E48; } }
  .goal-name {
    font-family: Gilroy-Bold;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #383e48;
    padding-bottom: 10px; }
  .extra-information {
    padding: 11px 16px;
    padding-bottom: 0;
    margin: 16px 0;
    border: 1px solid #E4EAEF;
    border-radius: 8px;
    font-size: 12px;
    line-height: 17px;
    h5 {
      font-size: 12px;
      font-family: Gilroy-Bold;
      color: #383E48; }
    p {
      color: #70808E; }
    .information-container {
      padding: 5px 0;
      background: transparent; } }
  .summary-date {
    font-family: Gilroy-Bold;
    font-size: 12px;
    color: #70808E;
    text-transform: uppercase;
    margin-bottom: 10px; }
  .title {
    color: #383e48;
    padding-bottom: 0;
    padding-top: 0; }
  .extra-info {
    margin-top: 10px;
    background: rgba(244,245,247,.5);
    border: 1px solid #70808e;
    color: #383e48;
    min-height: 50px;
    display: flex;
    text-align: left;
    font-family: Gilroy;
    font-size: 14px;
    color: #70808e;
    .avatar {
      color: #479fec;
      font-size: 16px;
      padding-right: 16px; } }
  .profile-container {
    margin-top: 16px;
    padding-bottom: 0;
    .profile-info {
      width: 100%; }

    .Opened {
      width: 100%;
      &:first-child {
        margin-bottom: 16px; } } } }

