 :local(.phsForm) {
  .target-goal {
    .am-slider-wrapper {
      margin-top: 15px;
      .am-slider-rail {
        height: 8px;
        border-radius: 100px;
        background: #E6E6E6; }
      .am-slider-track.am-slider-track-1 {
        height: 8px;
        background: #2B4B8F; }
      .am-slider-handle {
        margin-top: -6px;
        border: none;
        box-shadow: 0px 2px 4px rgba(140, 140, 140, 0.3), 0px -2px 4px rgba(140, 140, 140, 0.2); } }
    .target-number {
      display: flex;
      justify-content: space-between;
      margin-top: 10px; } }

  .wrapper-title {
    color: #38435E;
    opacity: 0.5;
    font-size: 12px;
    font-weight: bold; }

  .ant-calendar-picker-input {
    height: 45px;
    .ant-calendar-range-picker-separator {
      vertical-align: baseline; } }

  .hide-label-colon {
    .ant-legacy-form-item-label > label::after {
      content: ''; } }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: none; } }
