 :local(.apeironFormStyle) {
  font-family: Gilroy;
  .ant-modal-content {
    border-radius: 8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1); }
  .ant-modal-body {
    padding: 32px; }
  .title {
    font-size: 24px;
    color: #4C6072;
    padding-bottom: 40px; }
  .ant-modal-footer {
    border: none;
    padding-bottom: 32px;
    footer.flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px; }
    .ant-btn-background-ghost {
      border: none;
      box-shadow: none; } } }
