.preview {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  .category {
    margin: 15px;
    padding: 20px;
    // width: 390px
    background: #fff;
    border-radius: 4px;
    box-shadow: -9px 10px 11px 0 rgba(87,106,128,0.1);
    overflow: auto;
    .sub-category {
      display: flex;
      &>div {
        margin: 0 10px; } }
    .biomarker-tag {
      display: block; }
    h5 {
      font-weight: bold;
      font-size: 24px;
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 10px;
      button {
        margin-left: 10px;
        vertical-align: middle; } }
    .item-inner-list {
      .ant-btn-group {
        margin: 10px 0;
        margin-right: 10px; } } } }
.logo {
  height: 32px;
  line-height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
  color: #ffffff;
  text-align: center;
  font-size: 20px; }
.btn-back {
  margin-left: 20px; }
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
  &:hover {
    color: #1890ff; } }
.edit-container {
  max-width: 800px;
  position: relative;
  // .page-loading-modal
  //   position: absolute
  //   background: rgba(255,255,255,.2)
  //   width: 100%
  //   height: 100%
  //   z-index: 2
  .value-type {
    margin-bottom: 20px;
    padding-left: 10px;
    &>span {
      color: rgba(76, 76, 76, 0.5);
      margin-right: 30px; } }
  .ranges-container {
    .range-sec-title {
      font-weight: bold; }
    .ant-btn-group {
      margin-right: 5px;
      margin-bottom: 30px; }
    .range-btn {
      &.show-range {
        border-color: green;
        color: green; } }
    .optimal-judge {
      margin-bottom: 30px;
      padding-left: 10px; } }
  .btn-publish {
    margin-right: 10px; } }

.preview-container {
  .preview-doc {
    max-width: 800px;
    .title {
      text-align: center;
      font-size: 34px; }
    .sub-title {
      margin-top: 40px;
      font-size: 26px; }
    .third-title {
      font-size: 22px; } } }

.page404-container {
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
