.tribute-container {
  z-index: 1000;
  ul {
    list-style: none;
    padding: 10px 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    li {
      padding: 5px 20px;
      &.highlight {
        background: #F4F6F9; } } }

  .menu-template {
    display: flex;
    .avatar {
      width: 45px;
      height: 45px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 20px;
      .avatar-placeholder {
        width: 45px;
        height: 45px;
        background: rgba(18,22,45,0.05);
        display: block; }
      img {
        max-width: 100%;
        object-fit: cover; } }
    .info {
      color: #4C6072;
      font-size: 13px;
      .name {
        font-size: 15px;
        color: #000000; } } } }
