 :local(.acsmTargetStyle) {
  margin: 5px 24px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  .zone-background {
    background: #EAEFF7;
    border-radius: 6px;
    overflow: hidden;
    height: 36px;
    display: flex;
    position: relative;
    .percentage {
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #383E48;
      position: absolute;
      top: 34%; }
    .hit-ideal-line {
      background-color: #383E48;
      width: 2px;
      height: 36px;
      position: absolute; }
    .left-border {
      border-radius: 6px 0px 0px 6px; }
    .right-border {
      border-radius: 0px 6px 6px 0px; } }
  .zone-target {
    margin-top: 2px;
    position: relative;
    height: 20px;
    .zone-target-label {
      // transform: translateX(-50%)
      position: absolute;
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 8px;
      line-height: 140%;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #70808E; } } }
