 :local(.exportClientsDataModelStyle) {
  font-family: Gilroy;
  text-align: left;
  .ant-modal-header {
    border: none;
    padding: 40px 46px;
    padding-bottom: 0; }
  .ant-modal-body {
    padding: 15px 32px;
    padding-bottom: 0;
    .title-center {
      padding-top: 10px;
      margin-bottom: 10px; }
    .radio-group-container {
      padding-top: 30px;
      padding-bottom: 20px;
      .ant-radio-wrapper {
        display: block;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        font-family: Gilroy;
        .ant-radio {
          padding-bottom: 1px; } } } }
  .ant-modal-footer {
    border: none;
    padding: 15px 46px;
    padding-bottom: 40px;
    .modal-footer {
      background: #ffffff;
      margin-top: 20px;
      position: relative;
      top: 15px;
      button {
        height: 48px;
        min-width: 115px;
        font-family: Lato;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        border: none; }
      .cancel {
        box-shadow: none;
        color: #264382; } } }
  .ant-modal-title {
    font-size: 24px;
    color: #4C6072;
    letter-spacing: 0.3px;
    line-height: 150%; } }
